import React from "react";
import { Button, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import {
  Property,
  softDeleteProperty,
} from "../../../services/propertiesService";
import { useOnUnexpectedError } from "../../common/alert/withAlertModal";
import UncontrolledModal from "../../common/modal/UncontrolledModal";

type DeletePropertyModalProps = {
  property: Property;
  onClose: () => void;
  onDeleted: (propertyName: string) => void;
};

const DeletePropertyModal: React.FC<DeletePropertyModalProps> = ({
  property,
  onClose,
  onDeleted,
}) => {
  const onUnexpectedError = useOnUnexpectedError();

  const doDelete = async () => {
    try {
      await softDeleteProperty(property.id);
      onDeleted(property.property_name);
    } catch (e) {
      onUnexpectedError(e);
    }
  };

  return (
    <UncontrolledModal size="lg" onFormSubmit={doDelete} onClosed={onClose}>
      <ModalHeader toggle={onClose}>
        Remove {property.property_name ?? "Property"}?
      </ModalHeader>
      <ModalBody>
        By clicking confirm, you are removing this property from your properties
        list, dashboards, and all reports.
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={onClose}>
          Cancel
        </Button>
        <Button type="submit" color="danger">
          Confirm
        </Button>
      </ModalFooter>
    </UncontrolledModal>
  );
};

export default DeletePropertyModal;
