import {
  FinancialImportDto,
  FinancialImportResponse,
} from "@joyhub-integration/shared";
import React, { useEffect, useState } from "react";
import {
  Button,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner,
} from "reactstrap";
import { useOnUnexpectedError } from "../../common/alert/withAlertModal";
import UncontrolledModal from "../../common/modal/UncontrolledModal";
import FinancialImportResponsePreview from "./FinancialImportResponsePreview";
import { getFinancialImportSummary } from "./financialImportsApi";

type InfoFinancialImportModalProps = {
  value: FinancialImportDto<true>;
  onClose: () => void;
};

const InfoFinancialImportModal: React.FC<InfoFinancialImportModalProps> = ({
  value,
  onClose,
}) => {
  const onUnexpectedError = useOnUnexpectedError();
  const [summary, setSummary] = useState<FinancialImportResponse<true>>();

  useEffect(() => {
    getFinancialImportSummary(value.id)
      .then(setSummary)
      .catch(onUnexpectedError);
  }, [value, onUnexpectedError]);

  return (
    <UncontrolledModal size="lg" onClosed={onClose}>
      <ModalHeader toggle={onClose}>
        Import Summary: {value.description}
      </ModalHeader>
      <ModalBody>
        {summary == null ? (
          <Spinner />
        ) : (
          <FinancialImportResponsePreview value={summary} />
        )}
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={onClose}>
          Close
        </Button>
      </ModalFooter>
    </UncontrolledModal>
  );
};

export default InfoFinancialImportModal;
