import { useCallback, useContext, useEffect, useState } from "react";
import PlatformContext from "../components/app/PlatformContext";
import { usePropertyId } from "./usePropertyId";

export const usePropertyNote = () => {
  const { propertyId, isPropertyDashboard } = usePropertyId();
  const { platform, setProperties: setPropertiesInContext } =
    useContext(PlatformContext);

  const [propertyNote, setPropertyNote] = useState<string>("");

  const { propertiesMap } = platform!;

  const updatePropertyNoteInContext = useCallback(
    (note: string) => {
      const allProperties = Object.values(propertiesMap);

      setPropertiesInContext(
        allProperties.map((property) =>
          property.id === propertyId ? { ...property, note } : property,
        ),
      );
    },
    [propertiesMap, propertyId, setPropertiesInContext],
  );

  useEffect(() => {
    if (propertyId) {
      setPropertyNote(propertiesMap[propertyId]?.note ?? "");
    }
  }, [propertyId, propertiesMap, setPropertyNote]);

  return {
    propertyId,
    isPropertyDashboard,
    propertyNote,
    updatePropertyNoteInContext,
  };
};
