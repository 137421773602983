import { CustomColumn } from "@joyhub-integration/shared";
import { apiUrl, axiosConfig } from "../utils/api";
import axios from "./axios";
import { UploadUrl } from "./uploadService";
import { ApplicationType, NavbarTheme, Organization } from "./usersService";

// not really overlord but..
export async function syncIntegration(id: number): Promise<number> {
  return axios
    .post(apiUrl(`/systems/${id}/sync`), undefined, axiosConfig)
    .then((res) => res.status);
}

export async function runLambda(id: number, params: any): Promise<number> {
  return axios
    .post(apiUrl(`/systems/${id}/lambda`), params, axiosConfig)
    .then((res) => res.status);
}

// not really overlord but..
export async function getIntegrationUploadUrl(id: number): Promise<UploadUrl> {
  return axios
    .post(apiUrl(`/systems/${id}/upload`), undefined, axiosConfig)
    .then((res) => res.data as UploadUrl);
}

// not really overlord but..
export async function receiveIntegrationUpload(
  id: number,
  key: string,
): Promise<number> {
  return axios
    .post(apiUrl(`/systems/${id}/receive`), { key }, axiosConfig)
    .then((res) => res.status);
}

export async function submitCommand(
  command: string,
  params: object,
): Promise<any> {
  return axios
    .post(
      apiUrl("/overlord/command"),
      { _type: command, ...params },
      axiosConfig,
    )
    .then((res) => res.data);
}

export async function purgeCache(organization: number): Promise<any> {
  return submitCommand("purgeCache", {
    organization,
  });
}

export async function deprecateFrontendInsight(frontendInsightId: number) {
  return submitCommand("deprecateFrontendInsight", {
    frontendInsightId,
  });
}

export async function computeInsights(
  organization: number,
  system: number,
  frontEndUserId: number,
  allTime: boolean,
  days: number,
  weeks: number,
  months: number,
  rewriteHistory: boolean,
  commonModel: boolean,
  insights?: string,
  dataDate?: string,
): Promise<any> {
  return submitCommand("computeInsights", {
    organization,
    system,
    "front-end-user-id": frontEndUserId,
    "all-time": allTime,
    days,
    weeks,
    months,
    "rewrite-history": rewriteHistory,
    "common-model": commonModel,
    "data-date": dataDate,
    insights,
    async: true,
  });
}

export async function createOrganization(
  name: string,
  domains: string[],
  application: ApplicationType,
  navbarTheme: NavbarTheme,
  logo: string | undefined,
  commonInsights: boolean | undefined,
  sesTls: boolean | undefined,
  slackEnabled?: boolean | undefined,
  slackChannel?: string | undefined,
) {
  return axios
    .post(
      apiUrl("/overlord/organizations"),
      {
        name,
        domains,
        application,
        navbarTheme,
        logo,
        commonInsights,
        sesTls,
        slackEnabled,
        slackChannel,
      },
      axiosConfig,
    )
    .then((res) => res.data as Organization);
}

export async function updateOrganization(
  id: number,
  name: string,
  domains: string[],
  application: ApplicationType,
  navbarTheme: NavbarTheme,
  logo: string | undefined,
  commonInsights: boolean | undefined,
  sesTls: boolean | undefined,
  slackEnabled?: boolean | undefined,
  slackChannel?: string | undefined,
  hsRecordId?: string | undefined,
) {
  return axios
    .put(
      apiUrl(`/overlord/organizations/${id}`),
      {
        name,
        domains,
        application,
        navbarTheme,
        logo,
        commonInsights,
        sesTls,
        slackEnabled,
        slackChannel,
        hsRecordId: hsRecordId ? Number(hsRecordId) : undefined,
      },
      axiosConfig,
    )
    .then((res) => res.data as Organization);
}

export async function updateCustomColumns(
  id: number,
  customColumns: CustomColumn[],
) {
  return axios
    .post(
      apiUrl(`/overlord/organizations/${id}/customColumns`),
      {
        customColumns,
      },
      axiosConfig,
    )
    .then((res) => {
      res.data as Organization;
    });
}

export async function linkOrganization(from: number, to: number) {
  return axios
    .post(apiUrl(`/overlord/organizations/${from}/link`), { to }, axiosConfig)
    .then(() => {});
}

export async function unlinkOrganization(from: number, to: number) {
  return axios
    .post(apiUrl(`/overlord/organizations/${from}/unlink`), { to }, axiosConfig)
    .then(() => {});
}

export const checkForHubSpotId = async (name: string) => {
  return axios
    .post(apiUrl("/overlord/organizations/hubspot"), { name }, axiosConfig)
    .then((res) => res.data);
};

export const disableOrganization = (id: number) =>
  axios
    .delete(apiUrl(`/overlord/organizations/${id}`), axiosConfig)
    .then((res) => res.data as Organization);
