import { head, uniqueId } from "lodash";
import { useEffect, useState } from "react";
import { Tooltip } from "reactstrap";
import insightsLibraryService from "../../../services/insightLibrary/insightsLibraryService";

const ChartInfo = ({ insightId }: { insightId: number }) => {
  const [id, setId] = useState<string>();
  const [description, setDescription] = useState<string>();
  const [tooltipOpen, setTooltipOpen] = useState(false);

  useEffect(() => {
    setId(`chart-info-${uniqueId()}`);
    insightsLibraryService
      .getInsights(insightId)
      .then((insights) => setDescription(head(insights)?.description));
  }, [insightId]);

  function toggleTooltip() {
    setTooltipOpen(!tooltipOpen);
  }

  return description && id ? (
    <>
      <span id={id} className="jh-chart-info">
        {/* Sub with an import when FA 6.0 is out of beta
              https://fontawesome.com/v6.0/icons/info?s=solid */}
        <svg
          className="svg-inline--fa fa-info fa-w-4"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 192 512"
        >
          <title>Info</title>
          <path
            fill="currentColor"
            d="M160 448h-32V224c0-17.69-14.33-32-32-32L32 192c-17.67 0-32 14.31-32 32s14.33 31.1 32 31.1h32v192H32c-17.67 0-32 14.31-32 32s14.33 32 32 32h128c17.67 0 32-14.31 32-32S177.7 448 160 448zM96 128c26.51 0 48-21.49 48-48S122.5 32.01 96 32.01s-48 21.49-48 48S69.49 128 96 128z"
          ></path>
        </svg>
      </span>
      <Tooltip
        isOpen={tooltipOpen}
        toggle={toggleTooltip}
        target={id}
        placement="left"
      >
        {description}
      </Tooltip>
    </>
  ) : null;
};

export default ChartInfo;
