import React, { ReactText, useState } from "react";
import { Tooltip as BootstrapTooltip } from "reactstrap";
import {
  Bar,
  CartesianGrid,
  ComposedChart,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { SecondaryAxisKeyValue, SortDirection } from "../../../services/models";
import { labelConstraints } from "../../../utils/chartLabelFormatter";
import {
  chartNumberFormatter,
  tooltipFormatter,
  tooltipSorter,
} from "../../../utils/chartNumberFormatter";

/**
 * Rechart JS defines bar chart on the
 */
interface JhBarChartProps {
  data: Array<Object>;
  dataKey: string;
  categoryKey: string;
  yAxisKeys: { [key: string]: SecondaryAxisKeyValue };
  id: string | number;
  yAxisUnit?: string;
  stackId?: string;
  tooltipFormatter?: Function;
  tooltipSortDir?: SortDirection;
}

interface BarTickProps {
  id: string | number;
  x: number;
  y: number;
  payload: {
    index: number;
    value: string;
  };
  maxChars: number;
  width: number;
}

const yAxisProportion = 0.25;
const yAxisWidthFallback = 200;

const BarTick: React.FC<BarTickProps> = (props) => {
  const { id, x, y, payload, maxChars } = props;

  const [tooltipOpen, setTooltipOpen] = useState<boolean>(false);
  const toggle = () => setTooltipOpen(!tooltipOpen);

  const willOverflow = payload.value.length > maxChars;
  const text = willOverflow
    ? `${payload.value.substring(0, maxChars - 1)}…`
    : payload.value;

  const tooltipId = `chart-${id}-tooltip-${payload.index}`;

  return (
    <g transform={`translate(${x},${y})`}>
      <text id={tooltipId} x={0} y={0} dy={16} textAnchor="end" fill="#666">
        {text}
      </text>
      {willOverflow ? (
        <BootstrapTooltip
          placement="right"
          isOpen={tooltipOpen}
          target={tooltipId}
          toggle={toggle}
        >
          {payload.value}
        </BootstrapTooltip>
      ) : null}
    </g>
  );
};

const ResponsiveBarChart = ({
  width,
  children,
  chartProps,
  ...rest
}: {
  width?: number;
  children: JSX.Element[];
  chartProps: JhBarChartProps;
  rest?: any;
}) => {
  const { data, categoryKey, yAxisKeys, tooltipSortDir, id } = chartProps;
  const unit = Object.values(yAxisKeys)[0].unit;

  const maxLabelLength = data.reduce<number>(
    (prev, current) => Math.max(prev, (current as any)[categoryKey].length),
    0,
  );
  const [maxWidth, maxChars] = labelConstraints(
    100,
    width ? width * yAxisProportion : yAxisWidthFallback,
    18,
    maxLabelLength,
  );

  return (
    <ComposedChart layout="vertical" width={width} data={data} {...rest}>
      <CartesianGrid stroke="#f5f5f5" />
      <XAxis
        type="number"
        tickFormatter={(v) => chartNumberFormatter(v as number, unit)}
      />
      <YAxis
        dataKey={categoryKey}
        type="category"
        tickLine={false}
        tick={(p) => (
          <BarTick width={maxWidth} maxChars={maxChars} {...p} id={id} />
        )}
        width={maxWidth}
        interval={0}
      />
      <Tooltip
        formatter={(v: string | number | ReactText[], axisKey: string) =>
          tooltipFormatter(v, axisKey, yAxisKeys)
        }
        itemSorter={tooltipSortDir ? tooltipSorter(tooltipSortDir) : undefined}
        isAnimationActive={false}
      />
      <Legend />
      {children}
    </ComposedChart>
  );
};

const JhBarChart: React.FC<JhBarChartProps> = (props) => {
  const { yAxisKeys, yAxisUnit, stackId } = props;

  const allColumnCharts = Object.entries(yAxisKeys).map(([key, value]) => {
    return (
      <Bar
        key={key}
        dataKey={key}
        unit={yAxisUnit}
        name={key}
        fill={value.colorHex}
        stroke={value.colorHex}
        stackId={stackId}
      />
    );
  });

  return (
    <ResponsiveContainer>
      <ResponsiveBarChart chartProps={props}>
        {allColumnCharts.map((item) => item)}
      </ResponsiveBarChart>
    </ResponsiveContainer>
  );
};

export default JhBarChart;
