import {
  AdHocInsightDto,
  AdHocInsightEntity,
  AdHocInsightHistoryEntity,
} from "@joyhub-integration/shared";
import { apiUrl, axiosConfig } from "../utils/api";
import axios from "./axios";

type GetAdHocInsightsResponse = {
  adHocInsights: Array<AdHocInsightEntity>;
};

type GetAdHocInsightHistoriesResponse = {
  adHocInsightHistories: Array<AdHocInsightHistoryEntity>;
};

export async function getAdHocInsights(): Promise<Array<AdHocInsightEntity>> {
  return axios
    .get<GetAdHocInsightsResponse>(apiUrl("/adHocInsight"), axiosConfig)
    .then((res) => res.data.adHocInsights);
}

export async function getAdHocInsight(id: number): Promise<AdHocInsightEntity> {
  return axios
    .get<AdHocInsightEntity>(apiUrl(`/adHocInsight/${id}`), axiosConfig)
    .then((res) => res.data);
}

export async function getAdHocInsightHistories(
  id: number,
): Promise<Array<AdHocInsightHistoryEntity>> {
  return axios
    .get<GetAdHocInsightHistoriesResponse>(
      apiUrl(`/adHocInsight/history/${id}`),
      axiosConfig,
    )
    .then((res) => res.data.adHocInsightHistories);
}

export async function deleteAdHocInsight(id: number): Promise<void> {
  return axios.delete(apiUrl(`/adHocInsight/${id}`), axiosConfig);
}

export async function postAdHocInsight(
  adHocInsight: AdHocInsightDto,
): Promise<AdHocInsightEntity> {
  return axios
    .post<AdHocInsightEntity>(
      apiUrl("/adHocInsight"),
      adHocInsight,
      axiosConfig,
    )
    .then((res) => res.data);
}

export async function putAdHocInsight(
  id: number,
  adHocInsight: AdHocInsightDto,
): Promise<AdHocInsightEntity> {
  return axios
    .put<AdHocInsightEntity>(
      apiUrl(`/adHocInsight/${id}`),
      adHocInsight,
      axiosConfig,
    )
    .then((res) => res.data);
}
