import { ReportSheet } from "@joyhub-integration/shared";
import React from "react";
import { Button, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import UncontrolledModal from "../../common/modal/UncontrolledModal";
import { BodySheet, SpecialSheet, TemplateSheet } from "./editReportUtil";

const DeleteSheetModal: React.FC<{
  sheet: ReportSheet | SpecialSheet;
  onClose: () => void;
  onSubmit: () => void;
}> = ({ sheet, onClose, onSubmit }) => {
  return (
    <UncontrolledModal isOpen={true} onClosed={onClose} onFormSubmit={onSubmit}>
      <ModalHeader>Delete Sheet</ModalHeader>
      <ModalBody>
        {sheet === BodySheet
          ? "Delete email body?"
          : sheet === TemplateSheet
            ? "Delete Excel template?"
            : `Delete sheet "${sheet.name}"?`}
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={onClose}>
          Cancel
        </Button>
        <Button color="danger" type="submit" className="ms-2">
          Delete
        </Button>
      </ModalFooter>
    </UncontrolledModal>
  );
};

export default DeleteSheetModal;
