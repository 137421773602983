import { faImage, faSpinnerThird } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useRef, useState } from "react";
import { Input, PopoverBody, UncontrolledPopover } from "reactstrap";
import {
  PropertyWithUnitCount,
  uploadPropertyImage,
} from "../../../services/propertiesService";
import { apiUrl } from "../../../utils/api";
import ButtonWithIcon from "../../common/button/ButtonWithIcon";

const ImagePreview: React.FC<{
  property: PropertyWithUnitCount;
}> = ({ property }) => {
  const imageInput = useRef<HTMLInputElement>(null);
  const [guid, setGuid] = useState<string | undefined>(property.image_guid);
  const [loading, setLoading] = useState<boolean>(false);
  const pid = property.id;

  const onImageUploadClicked = () => {
    if (!imageInput.current) return;
    imageInput.current.value = "";
    imageInput.current.click();
  };

  const onImageFileSelected = async () => {
    const file = imageInput.current?.files?.[0];
    if (!file) return;

    setLoading(true);
    const r = await uploadPropertyImage(pid, file);
    setGuid(r.data.guid);
    setLoading(false);
  };

  return (
    <>
      <ButtonWithIcon
        id={`pimg-${pid}`}
        icon={faImage}
        size="sm"
        style={{ marginTop: "-10px", marginBottom: "-6px" }}
        outline
        className="property-upload-icon" // styles that help with visibility when row is selected (and is dark blue)
        onClick={onImageUploadClicked}
      />
      <Input
        type="file"
        accept="image/*"
        innerRef={imageInput}
        onChange={onImageFileSelected}
        className="d-none"
      />
      <UncontrolledPopover target={`pimg-${pid}`} trigger="hover">
        <PopoverBody>
          {loading ? (
            <FontAwesomeIcon icon={faSpinnerThird} spin={true} />
          ) : guid != null ? (
            <img
              src={apiUrl(`/properties/${pid}/image/${guid}`)}
              height={160}
              alt={property.property_name}
            />
          ) : (
            <div className="text-muted text-center w-100">No image</div>
          )}
        </PopoverBody>
      </UncontrolledPopover>
    </>
  );
};

export default ImagePreview;
