import { faMugHot, faPaperPlane } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DashboardKindEnum, GoalYear } from "@joyhub-integration/shared";
import React, { useEffect, useState } from "react";
import {
  Button,
  ButtonDropdown,
  ButtonGroup,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  Input,
  Label,
  PopoverBody,
  Spinner,
  UncontrolledPopover,
} from "reactstrap";
import dashboardService from "../../../services/dashboardService";
import {
  createScheduledEmail,
  deleteScheduledEmail,
  getScheduledEmail,
  getScheduledEmails,
  ScheduledEmail,
} from "../../../services/scheduledEmailsService";
import withAlertModal, {
  WithAlertModalProps,
} from "../../common/alert/withAlertModal";
import ButtonWithIcon from "../../common/button/ButtonWithIcon";
import EmailsInput from "../../craport/EmailsInput";
import ScheduledEmails from "../../craport/ScheduledEmails";
import { DashboardDto } from "../../dashboard/dashboardDto";
import DeleteModal from "../common/DeleteModal";

type ScheduledEmailProps = {
  selected?: GoalYear;
  reloadGoals: () => void;
};

const GoalYearScheduledEmailButton: React.FC<
  WithAlertModalProps & ScheduledEmailProps
> = ({ selected, reloadGoals, setAlert, onUnexpectedError }) => {
  const [viewScheduledEmailsModalOpen, setViewScheduledEmailsModalOpen] =
    useState(false);
  const [scheduledEmails, setScheduledEmails] = useState<ScheduledEmail[]>([]);
  const [scheduledEmailToBeRemoved, setScheduledEmailToBeRemoved] =
    useState<ScheduledEmail>();

  const selection = selected?.propertyId;
  const [recipients, setRecipients] = useState<string[]>([]);
  const [sending, setSending] = useState(false);
  const [sendDropdownOpen, setSendDropdownOpen] = useState(false);
  const [weeklyMonthly, setWeeklyMonthly] = useState<"weekly" | "monthly">(
    "weekly",
  );
  const [daySelected, setDaySelected] = useState<string>();
  const [periodInMonthSelected, setPeriodInMonthSelected] = useState<
    "Start" | "Mid" | "End"
  >("Start");

  const [notifyOnGoalMet, setNotifyOnGoalMet] = useState(false);
  const [notifyOnGoalFailed, setNotifyOnGoalFailed] = useState(false);
  const [weekdaysOnly, setWeekdaysOnly] = useState<boolean>(false);
  const sendButtonDisabled =
    sending ||
    recipients.length === 0 ||
    (!notifyOnGoalFailed && !notifyOnGoalMet) ||
    (weeklyMonthly === "weekly" && !daySelected);

  const toggleSendDropdownOpen = () => {
    if (!selected) {
      return;
    }
    if (sendDropdownOpen) {
      setWeeklyMonthly("weekly");
      setDaySelected(undefined);
      setPeriodInMonthSelected("Start");
    }
    setSendDropdownOpen(!sendDropdownOpen);
  };

  function onScheduledEmailRemoveClick(scheduledEmail: ScheduledEmail) {
    setScheduledEmailToBeRemoved(scheduledEmail);
  }

  function onScheduledEmailDeleteSubmit() {
    setAlert("Scheduled email deleted successfully.", true);
    setScheduledEmailToBeRemoved(undefined);
    onScheduledEmailsChange();
  }

  useEffect(() => {
    if (selected?.definition_id) {
      getScheduledEmails(selected.definition_id).then((emails) =>
        setScheduledEmails(emails),
      );
    } else {
      setScheduledEmails([]);
    }
  }, [selected?.definition_id]);

  const doScheduleAutoEmail = async () => {
    setSending(true);
    const schedule =
      weeklyMonthly === "weekly"
        ? daySelected
        : `Month${periodInMonthSelected}${weekdaysOnly ? "Weekday" : ""}`;

    if (!selected) {
      return;
    }

    let definition_id;
    if (!selected.definition_id) {
      const dto: DashboardDto = {
        identifier: dashboardService.identifier(),
        name: "email_" + selected.key,
        description: "",
        shared: false, // unclear if this could ever be shared.
        internal: false,
        kind: DashboardKindEnum.GoalYearEmail,
        definition: {
          key: selected.key,
        },
        category: [],
        origin_id: undefined,
      };
      const dashboard = await dashboardService.createDashboard(dto);
      definition_id = dashboard.id;
      selected.definition_id = definition_id;
    } else {
      definition_id = selected.definition_id;
    }

    createScheduledEmail({
      emailAddresses: recipients,
      definition_id,
      active: true,
      schedule: schedule!,
      configuration: {
        properties: selection ?? {},
        notifyWhenGoal:
          notifyOnGoalMet && notifyOnGoalFailed
            ? "both"
            : notifyOnGoalMet
              ? "met"
              : notifyOnGoalFailed
                ? "failed"
                : undefined,
        schedule: schedule!,
      },
    })
      .then(() => {
        setAlert("Scheduled email created successfully", true);
        toggleSendDropdownOpen();
        onScheduledEmailsChange();
        reloadGoals();
      })
      .catch(onUnexpectedError)
      .finally(() => setSending(false));
  };

  const viewScheduledEmails = () => {
    setViewScheduledEmailsModalOpen(true);
  };

  function onScheduledEmailsChange(definition_id = selected?.definition_id) {
    if (definition_id) {
      getScheduledEmails(definition_id)
        .then(setScheduledEmails)
        .catch(onUnexpectedError);
    }
  }

  const renderWeekly = () => {
    const weekDays = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    return (
      <div className="flex-row mt-3">
        {weekDays.map((day) => {
          return (
            <div
              className="flex-col vertical-checkbox align-items-center"
              style={{ flex: 1 }}
              key={day}
            >
              <Input
                id={`weekday-${day}`}
                type="radio"
                checked={daySelected === day}
                onChange={() => {
                  if (daySelected === day) {
                    setDaySelected(undefined);
                  } else {
                    setDaySelected(day);
                  }
                }}
              />
              <Label for={`weekday-${day}`}>{day.substring(0, 2)}</Label>
            </div>
          );
        })}
      </div>
    );
  };

  const renderMonthly = () => {
    return (
      <>
        <ButtonGroup className="mt-3 w-100">
          <Button
            color="primary"
            outline={periodInMonthSelected !== "Start"}
            onClick={() => setPeriodInMonthSelected("Start")}
          >
            Start
          </Button>
          <Button
            color="primary"
            outline={periodInMonthSelected !== "Mid"}
            onClick={() => setPeriodInMonthSelected("Mid")}
          >
            Mid
          </Button>
          <Button
            color="primary"
            outline={periodInMonthSelected !== "End"}
            onClick={() => setPeriodInMonthSelected("End")}
          >
            End
          </Button>
        </ButtonGroup>
        <FormGroup switch className="mt-3">
          <Input
            id="weekdays_only"
            type="switch"
            checked={weekdaysOnly}
            onChange={() => setWeekdaysOnly(!weekdaysOnly)}
          />
          <Label check for="weekdays_only">
            Weekdays only
          </Label>
        </FormGroup>
      </>
    );
  };

  return (
    <>
      <ButtonDropdown isOpen={sendDropdownOpen} toggle={toggleSendDropdownOpen}>
        <DropdownToggle id="paper-plane" tag="div">
          <ButtonWithIcon
            icon={faPaperPlane}
            disabled={!selected}
            className="goal-email-icon"
          >
            {scheduledEmails.length ? (
              <span className="schedule-count">
                {scheduledEmails.length < 10 ? scheduledEmails.length : "*"}
              </span>
            ) : null}
          </ButtonWithIcon>
        </DropdownToggle>
        {!sendDropdownOpen ? (
          <UncontrolledPopover
            placement="bottom"
            trigger="hover"
            target="paper-plane"
          >
            <PopoverBody>Email Goal Reminder</PopoverBody>
          </UncontrolledPopover>
        ) : null}
        <DropdownMenu style={{ width: 350 }}>
          <div className="pe-3 ps-3">
            <div className="mb-3">
              <strong>Email Report</strong>
              {scheduledEmails.length ? (
                <>
                  <span className="ms-2">( </span>
                  <span
                    className="btn-link view-scheduled-emails-btn"
                    onClick={viewScheduledEmails}
                  >
                    {scheduledEmails.length} scheduled
                  </span>
                  <span> )</span>
                </>
              ) : null}
            </div>
            <FormGroup>
              <EmailsInput onChange={setRecipients} />
            </FormGroup>

            <div className="mt-3">
              <ButtonGroup className="w-100">
                <Button
                  color="primary"
                  outline={weeklyMonthly !== "weekly"}
                  onClick={() => setWeeklyMonthly("weekly")}
                >
                  Weekly
                </Button>
                <Button
                  color="primary"
                  outline={weeklyMonthly !== "monthly"}
                  onClick={() => setWeeklyMonthly("monthly")}
                >
                  Monthly
                </Button>
              </ButtonGroup>
            </div>

            {weeklyMonthly === "weekly"
              ? renderWeekly()
              : weeklyMonthly === "monthly"
                ? renderMonthly()
                : null}

            <div className="flex-row mt-3">
              <FormGroup switch className="flex-grow-1">
                <Input
                  type="switch"
                  id="goalAchieved"
                  name="goalAchieved"
                  style={{ zIndex: 0 }}
                  onChange={(evt) => {
                    setNotifyOnGoalMet(evt.target.checked);
                  }}
                />
                <Label for="goalAchieved" check>
                  Goal Achieved
                </Label>
              </FormGroup>
              <FormGroup switch className="flex-grow-1">
                <Input
                  type="switch"
                  id="goalFailed"
                  name="goalFailed"
                  style={{ zIndex: 0 }}
                  onChange={(evt) => {
                    setNotifyOnGoalFailed(evt.target.checked);
                  }}
                />
                <Label for="goalFailed" check>
                  Goal Failed
                </Label>
              </FormGroup>
            </div>

            <div className="flex-row mt-3 align-items-center">
              <FontAwesomeIcon className="weekly-coffee me-2" icon={faMugHot} />
              <p className="mb-0 text-wrap">
                Automatic emails go out first thing in the morning.
              </p>
            </div>

            <div className="mt-3 w-100">
              <Button
                color="primary"
                className="w-100 mb-2 position-relative"
                onClick={doScheduleAutoEmail}
                disabled={sendButtonDisabled}
                style={sending ? { cursor: "wait" } : {}}
              >
                Schedule
                {sending ? (
                  <Spinner
                    color="white"
                    size="sm"
                    style={{
                      position: "absolute",
                      right: ".625rem",
                      top: ".625rem",
                    }}
                  />
                ) : null}
              </Button>
            </div>
          </div>
        </DropdownMenu>
      </ButtonDropdown>
      {viewScheduledEmailsModalOpen ? (
        <ScheduledEmails
          onClose={() => setViewScheduledEmailsModalOpen(false)}
          onScheduledEmailsChange={onScheduledEmailsChange}
          scheduledEmails={scheduledEmails}
          onRemoveClick={onScheduledEmailRemoveClick}
        />
      ) : null}
      {scheduledEmailToBeRemoved ? (
        <DeleteModal<ScheduledEmail>
          id={scheduledEmailToBeRemoved.id}
          entityName="Scheduled Email"
          identificationKey="id"
          getEntity={getScheduledEmail}
          deleteEntity={deleteScheduledEmail}
          onClose={() => setScheduledEmailToBeRemoved(undefined)}
          onSubmit={onScheduledEmailDeleteSubmit}
        />
      ) : null}
    </>
  );
};

export default withAlertModal(GoalYearScheduledEmailButton);
