import {
  NonGroupPropertiesSelection,
  PropertiesSelection,
} from "@joyhub-integration/shared";
import clsx from "clsx";
import { orderBy } from "natural-orderby";
import React, { useMemo, useState } from "react";
import { DropdownItem, Input } from "reactstrap";
import {
  Property,
  getSelectedPropertyIds,
  propertyMatcher,
} from "../../services/propertiesService";
import { PropertyGroup } from "../properties/propertyGroupService";

const PropertySelector: React.FC<{
  allProperties: Property[];
  propertyGroups: PropertyGroup[];
  backEnd?: boolean;
  selection: PropertiesSelection;
  setSelection: (sel: NonGroupPropertiesSelection) => void;
}> = ({ allProperties, propertyGroups, backEnd, selection, setSelection }) => {
  const [filterText, setFilterText] = useState<string>("");

  const sortedEndianProperties = useMemo(
    () =>
      orderBy(
        allProperties.filter((p) => (backEnd ? p.back_end : p.front_end)),
        (p) => p.property_name,
        "asc",
      ),
    [allProperties, backEnd],
  );

  const selectedProperties = useMemo(
    () =>
      new Set(
        getSelectedPropertyIds(
          selection ?? {},
          sortedEndianProperties,
          propertyGroups,
        ),
      ),
    [selection, sortedEndianProperties, propertyGroups],
  );

  const filteredProperties = useMemo(
    () => sortedEndianProperties.filter(propertyMatcher(filterText)),
    [sortedEndianProperties, filterText],
  );
  const setSelectedProperties = (selectedProperties: Set<number>) => {
    const ids = Array.from(selectedProperties);
    setSelection(ids.length === 0 ? {} : ids.length === 1 ? ids[0] : { ids });
  };

  const onPropertyClick = (e: React.MouseEvent<HTMLElement>, id: number) => {
    if (e.ctrlKey || e.metaKey || e.shiftKey) {
      // TODO: handle shift properly
      const newSet = new Set(selectedProperties);
      if (!newSet.delete(id)) {
        newSet.add(id);
      }
      setSelectedProperties(newSet);
    } else {
      setSelectedProperties(new Set([id]));
    }
  };

  function onFilterChange(e: React.ChangeEvent<HTMLInputElement>) {
    setFilterText(e.target.value);
  }

  return (
    <>
      <Input
        className="dashboard-property-dropdown-filter my-2 mx-3"
        type="text"
        value={filterText}
        onChange={onFilterChange}
        placeholder="Filter"
      />

      <div
        style={{ maxHeight: "50vh", overflowY: "scroll" }}
        className="px-3 pb-2"
      >
        {!filteredProperties.length ? (
          <div className="dashboard-property-dropdown-item">
            {sortedEndianProperties.length ? "No matches" : "No properties"}
          </div>
        ) : null}

        {filteredProperties.map((p) => (
          <DropdownItem
            key={p.id}
            className={clsx("dashboard-property-dropdown-item bordered", {
              active: selectedProperties.has(p.id),
            })}
            onClick={(e) => onPropertyClick(e, p.id)}
            toggle={false}
          >
            {p.property_name}
          </DropdownItem>
        ))}
      </div>
    </>
  );
};

export default PropertySelector;
