import { faPlus } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { FormEvent, useContext, useState } from "react";
import { Button, ButtonGroup, Form, FormGroup, Input, Label } from "reactstrap";
import {
  updateOrganizationConfiguration,
  uploadOrganizationLogo,
} from "../../../services/organizationService";
import { getPlatform } from "../../../services/platformService";
import PlatformContext from "../../app/PlatformContext";
import withAlertModal, {
  WithAlertModalProps,
} from "../../common/alert/withAlertModal";
import { LoadilyFadily } from "../../common/allFadily";
import { ModernCrumbar } from "../../layout/ModernCrumbar";
import { ColorPicker } from "./ColorPicker";
import { ExamplePieChart } from "./ExamplePieChart";
import "./style.css";

function buttonChooser<T extends string>(
  id: string,
  labels: Record<T, string>,
  value: T,
  setValue: (t: T) => void,
) {
  return (
    <ButtonGroup id={id} className="d-block d-flex w-100">
      {Object.keys(labels).map((v) => (
        <Button
          key={v}
          className="w-100"
          color={value === v ? "secondary" : "light"}
          onClick={() => setValue(v as T)}
        >
          {labels[v as T]}
        </Button>
      ))}
    </ButtonGroup>
  );
}

const AppearanceSettings: React.FC<WithAlertModalProps> = ({
  setAlert,
  onUnexpectedError,
}) => {
  const { platform, setPlatform } = useContext(PlatformContext);
  const { organization } = platform!;

  const [submitting, setSubmitting] = useState(false);
  const [file, setFile] = useState<File>();
  const [navbarTheme, setNavBarTheme] = useState(
    organization.configuration.navbarTheme ?? "dark",
  );
  const [colors, setColors] = useState(organization.configuration.colors ?? []);

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    setSubmitting(true);

    (file
      ? uploadOrganizationLogo(organization.id, file)
      : Promise.resolve(organization.configuration.logo)
    )
      .then((logo) =>
        updateOrganizationConfiguration(
          organization.id,
          navbarTheme,
          logo,
          colors,
          organization.configuration.defaultPropertyGroup,
        ),
      )
      .then(getPlatform)
      .then(setPlatform)
      .then(() => setAlert("Organization settings updated.", true))
      .catch(onUnexpectedError)
      .finally(() => setSubmitting(false));
  };

  const handleAddColor = () => {
    setColors((prevColors) => [...prevColors, "#000000"]);
  };

  const handleUpdateColor = (index: number, color: string) => {
    setColors((prevColors) => {
      return prevColors.map((item, i) => (i === index ? color : item));
    });
  };

  const handleRemoveColor = (index: number) => {
    setColors((prevColors) => {
      return prevColors.filter((_, i) => i !== index);
    });
  };

  return (
    <>
      <ModernCrumbar primary="Appearance Settings" />
      <LoadilyFadily className="jh-page-layout jh-page-content">
        <div className="admin-section">
          <Form onSubmit={handleSubmit}>
            <FormGroup>
              <Label for="org-logo">Logo</Label>
              <Input
                className="upload-file-input"
                type="file"
                id="org-logo"
                accept="image/*"
                onChange={(e) => setFile(e.target.files![0])}
              />
            </FormGroup>
            <FormGroup>
              <Label for="org-navbar-theme">Navbar Theme</Label>
              {buttonChooser(
                "org-navbar-theme",
                { dark: "Dark", light: "Light" },
                navbarTheme!,
                setNavBarTheme,
              )}
            </FormGroup>
            <FormGroup>
              <Label for="org-logo">Chart Colors</Label>
              <div
                className="d-grid"
                style={{
                  gridTemplateColumns: "auto 210px",
                  gap: 10,
                }}
              >
                <div
                  className="d-grid"
                  style={{
                    gridTemplateColumns: "repeat(4, 1fr)",
                    gridAutoRows: 50,
                    gap: 10,
                  }}
                >
                  {colors.map((color, index) => (
                    <div className="p-2" key={index}>
                      <ColorPicker
                        color={color}
                        updateColor={(color) => handleUpdateColor(index, color)}
                        removeColor={() => handleRemoveColor(index)}
                      />
                    </div>
                  ))}
                  <Button
                    size="sm"
                    color="transparent"
                    className="add-btn"
                    onClick={handleAddColor}
                  >
                    <FontAwesomeIcon size="sm" icon={faPlus} className="me-1" />
                    Add Color
                  </Button>
                </div>
                <div>
                  <ExamplePieChart colors={colors} />
                </div>
              </div>
            </FormGroup>
            <hr />
            <FormGroup className="pull-right mt-4">
              <Button
                type="submit"
                color="primary"
                className="w-25"
                disabled={submitting}
              >
                Save
              </Button>
            </FormGroup>
          </Form>
        </div>
      </LoadilyFadily>
    </>
  );
};
export default withAlertModal(AppearanceSettings);
