import React, { useState } from "react";
import {
  Alert,
  Button,
  ModalBody,
  ModalFooter,
  ModalHeader,
  UncontrolledAlert,
} from "reactstrap";
import { unexpectedError } from "../../../constants";
import { disableOrganization } from "../../../services/overlordService";
import { Organization } from "../../../services/usersService";
import UncontrolledModal from "../../common/modal/UncontrolledModal";

interface DisableOrganizationModalProps {
  organization: Organization;
  onClose: () => void;
  onSubmit: () => void;
}

const DisableOrganizationModal: React.FC<DisableOrganizationModalProps> = ({
  organization,
  onClose,
  onSubmit,
}) => {
  const [serverError, setServerError] = useState<string>("");

  function doSubmit() {
    disableOrganization(organization.id)
      .then(() => onSubmit())
      .catch((err) => {
        const response = err.response;
        if (response && response.status === 400 && response.data.message) {
          setServerError(response.data.message);
        } else {
          setServerError(unexpectedError);
        }
      });
  }

  return (
    <UncontrolledModal onClosed={onClose}>
      <ModalHeader>Disable Organization</ModalHeader>
      <ModalBody>
        {serverError ? (
          <UncontrolledAlert color="danger">{serverError}</UncontrolledAlert>
        ) : null}
        <div className="mb-4">
          <Alert color="warning">
            This will disable and hide the organization '{organization.name}'.
            Please contact Support to have the organization re-enabled.
          </Alert>
          You will be redirected to the Revolution RE organization once
          disabled. Continue?
        </div>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={onClose}>
          Cancel
        </Button>{" "}
        <Button color="primary" onClick={doSubmit}>
          Disable
        </Button>
      </ModalFooter>
    </UncontrolledModal>
  );
};

export default DisableOrganizationModal;
