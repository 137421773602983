import { faSearch } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  AccountTreeEntity,
  FullAccountTreeNode,
  formatGlCode,
  isFakeGlCode,
  persistentGlCode,
} from "@joyhub-integration/shared";
import clsx from "clsx";
import lodash, { mapValues } from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  Button,
  Col,
  Collapse,
  DropdownItem,
  FormGroup,
  Input,
  Label,
  Popover,
  Row,
} from "reactstrap";
import {
  FinancialAccounts,
  Integration,
} from "../../../services/integrationsService";
import { setOf, setRemove, setToggle } from "../../../utils/set";
import {
  useOnUnexpectedError,
  useSetAlert,
} from "../../common/alert/withAlertModal";
import JhSelect, { jhItem } from "../../common/JhSelect/JhSelect";
import { Revealer } from "../../craport/editor/Revealer";
import { findOption } from "../../craport/editUtil";
import {
  financeBookOptions,
  getFullGlTree,
} from "../../finance/financeService";

type AccountsConfig = {
  tree?: string;
  book?: string;
  accounts: FinancialAccounts;
};

type AccountsSectionProps<T extends string> = {
  id: string;
  system: Integration;
  accountTrees: AccountTreeEntity[];
  accountNames: [T, string][];
  config: AccountsConfig;
  updateConfig: (key: "tree" | "book", value: string | undefined) => void;
  setConfigAccounts: (accounts: Record<string, string[]>) => void;
};

const FinancialAccountsSection = <T extends string>({
  id,
  system,
  accountTrees,
  accountNames,
  config,
  updateConfig,
  setConfigAccounts,
}: AccountsSectionProps<T>) => {
  const [accounts, setAccounts] = useState<FullAccountTreeNode[]>([]);
  const [search, setSearch] = useState("");
  const debouncedSetSearch = useMemo(
    () => lodash.debounce(setSearch, 300),
    [setSearch],
  );
  const onUnexpectedError = useOnUnexpectedError();
  const setAlert = useSetAlert();

  const treeOptions = useMemo(
    () =>
      accountTrees.map(({ tree_code, tree_name }) =>
        jhItem(tree_code!, `${tree_name} (${tree_code})`),
      ),
    [accountTrees],
  );

  const accountTree = useMemo(
    () =>
      config.tree == null
        ? undefined
        : accountTrees.find((acTree) => acTree.tree_code === config.tree),
    [config.tree, accountTrees],
  );

  useEffect(() => {
    if (accountTree?.tree_id != null) {
      getFullGlTree(system.id, accountTree.tree_id)
        .then((accounts) => setAccounts(accounts))
        .catch(onUnexpectedError);
    } else {
      setAccounts([]);
    }
  }, [system.id, accountTree?.tree_id, setAccounts, onUnexpectedError]);

  const [open, setOpen] = useState(new Set<number>());

  const inverse = useMemo(
    () =>
      Object.fromEntries(
        Object.entries(config.accounts).flatMap(([k, vs]) =>
          vs.map((v) => [v, k]),
        ),
      ),
    [config],
  );

  const unmapped = useMemo(
    () => accountNames.filter(([key]) => !config.accounts[key]?.length),
    [accountNames, config],
  );

  useEffect(() => {
    if (!accounts.length) return;
    // find all the known account codes
    const accountCodes = new Set<string>();
    const loop = (account: FullAccountTreeNode) => {
      const code = persistentGlCode(account);
      if (code != null) accountCodes.add(code);
      account.children.forEach(loop);
    };
    accounts.forEach(loop);
    // check to see if the config has an unknown account
    if (
      Object.values(config.accounts).some((acs) =>
        acs.some((ac) => !accountCodes.has(ac)),
      )
    ) {
      // if so then filter the config down
      setConfigAccounts(
        mapValues(config.accounts, (acs) =>
          acs.filter((ac) => accountCodes.has(ac)),
        ),
      );
      setAlert(
        "Some account mappings were not found in this tree and have been removed. Select Cancel to undo these changes.",
        false,
      );
    }
  }, [config, accounts, setConfigAccounts, setAlert]);

  useEffect(() => {
    const selecteds = setOf(Object.values(config.accounts).flat());
    const open = new Set<number>();
    const loop = (account: FullAccountTreeNode, topLevel: boolean): boolean => {
      const code = persistentGlCode(account);
      const formattedCode = isFakeGlCode(account.tree_node_code)
        ? null
        : formatGlCode(
            account.tree_node_code,
            accountTree?.gl_account_format_mask,
          );
      let childSelected = false;
      for (const child of account.children) {
        childSelected = loop(child, false) || childSelected;
      }
      if (childSelected || topLevel) open.add(account.tree_node_id);
      const thisSelected =
        search === ""
          ? code != null && selecteds.has(code)
          : formattedCode?.includes(search) ||
            account.tree_node_name
              ?.toLowerCase()
              ?.includes(search.toLowerCase());
      return childSelected || thisSelected || false;
    };
    for (const account of accounts) {
      loop(account, true);
    }
    setOpen(open);
  }, [accountTree, config, accounts, search]);

  const updateMapping = useCallback(
    (code: string, insight: string | undefined) => {
      const update: Record<string, string[]> = {};
      for (const [k] of accountNames) {
        update[k] = (config.accounts[k] ?? [])
          .filter((c) => c !== code)
          .concat(k === insight ? [code] : []);
      }
      setConfigAccounts(update);
    },
    [accountNames, config, setConfigAccounts],
  );

  const [openCode, setOpenCode] = useState<string>();

  const renderAccount = (account: FullAccountTreeNode, depth: number) => {
    const code = account.tree_node_code;
    const persistedCode = persistentGlCode(account);
    const formattedCode = formatGlCode(
      code,
      accountTree?.gl_account_format_mask,
    );
    const name = account.tree_node_name;
    const label =
      code == null || isFakeGlCode(code) ? name : `${name} (${formattedCode})`;
    const hasChildren = account.children.some(
      (ac) => !!ac.children.length || !!ac.tree_node_code,
    );
    const mapped = inverse[persistedCode ?? "Never"];
    const searched =
      search !== "" && label?.toLowerCase()?.includes(search.toLowerCase());
    const setMapping = (code: string, mapping: string | undefined) =>
      updateMapping(code, mapping);

    const isHidden =
      (!code && !hasChildren) ||
      account.accounts[0]?.gl_account_type === "Header";

    return isHidden ? null : (
      <div key={account.tree_node_id} className="account-entry">
        <div
          className={clsx("account-label d-flex align-items-baseline", {
            "account-header": !code,
            "account-open": open.has(account.tree_node_id),
            "account-found": searched || persistedCode === openCode,
          })}
        >
          {!hasChildren ? null : (
            <Button
              className="account-revealer"
              onClick={() =>
                setOpen(
                  setRemove(
                    setToggle(open, account.tree_node_id),
                    account.children.map((ac) => ac.tree_node_id),
                  ),
                )
              }
            >
              ▶
            </Button>
          )}
          <span className="account-label">{label}</span>
          <span className="account-line flex-grow-1" />
          {persistedCode == null ? null : (
            <>
              <Button
                className="account-dropdown dropdown-toggle"
                id={`dropdown-${account.id}`}
                onClick={(e) => {
                  e.stopPropagation();
                  setOpenCode(
                    openCode === persistedCode ? undefined : persistedCode,
                  );
                }}
              >
                <span className={clsx({ "account-unmapped": !mapped })}>
                  {accountNames.find(([k]) => k === mapped)?.[1] ?? "Unmapped"}
                </span>
              </Button>
              {openCode !== persistedCode ? null : (
                <Popover
                  target={`dropdown-${account.id}`}
                  isOpen={true}
                  positionFixed
                  delay={0}
                  fade={false}
                  placement="left"
                  container="body"
                  innerClassName="popup-account-list"
                  toggle={() => setOpenCode(undefined)}
                >
                  <DropdownItem
                    toggle={false}
                    onClick={() => setMapping(persistedCode, undefined)}
                  >
                    Unmapped
                  </DropdownItem>
                  {accountNames.map(([key, label]) => (
                    <DropdownItem
                      key={key}
                      toggle={false}
                      onClick={() => setMapping(persistedCode, key)}
                    >
                      {label}
                    </DropdownItem>
                  ))}
                </Popover>
              )}
            </>
          )}
        </div>
        {!hasChildren ? null : (
          <Collapse isOpen={open.has(account.tree_node_id)}>
            {!open.has(account.tree_node_id)
              ? null
              : account.children.map((ac) => renderAccount(ac, 1 + depth))}
          </Collapse>
        )}
      </div>
    );
  };

  return (
    <div onClick={() => setOpenCode(undefined)}>
      <FormGroup row>
        <Col sm={config.book == null ? 12 : 9}>
          <JhSelect
            id="tree"
            placeholder="Account Tree"
            options={treeOptions}
            value={findOption(treeOptions, config.tree)}
            onValueUpdate={(option) => updateConfig("tree", option?.value)}
            isDisabled={!treeOptions.length}
            isSearchable={true}
            isClearable={true}
            pointy={true}
          />
        </Col>
        {config.book == null ? null : (
          <Col sm={3}>
            <JhSelect
              id="book"
              options={financeBookOptions}
              value={findOption(financeBookOptions, config.book)}
              onValueUpdate={(option) => updateConfig("book", option.value)}
              isDisabled={!treeOptions.length}
              isSearchable={false}
              pointy={true}
            />
          </Col>
        )}
      </FormGroup>
      <Row>
        <Col>
          <div className="d-flex finance-modal-account-tree-header">
            <strong>Account</strong>
            <Input
              id={`${id}-search`}
              type="text"
              className={clsx("account-search-field", {
                valuable: search !== "",
              })}
              defaultValue={search}
              onChange={(e) => debouncedSetSearch(e.target.value)}
              onKeyPress={(e) => {
                if (e.code === "Enter") e.preventDefault();
              }}
            />
            <Label for={`${id}-search`} className="account-search-icon">
              <FontAwesomeIcon icon={faSearch} size="xs" />
            </Label>
            <strong className="ms-auto">Mapped Insight</strong>
          </div>
          <div className="finance-modal-account-tree">
            {accounts.map((ac) => renderAccount(ac, 0))}
          </div>

          {unmapped.length === 0 ? null : (
            <Revealer label={`${unmapped.length} Unmapped Insights`}>
              <div className="finance-modal-unmapped-accounts mb-2">
                {unmapped.map(([, label]) => label).join(", ")}
              </div>
            </Revealer>
          )}
        </Col>
      </Row>
    </div>
  );
};
export default FinancialAccountsSection;
