import {
  faSortAmountDown,
  faSortAmountUp,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { GetReportResult } from "../../../services/reportsService";

export type ReportSortValue = [keyof GetReportResult, "asc" | "desc"];

interface ReportHeaderColumnProps {
  field: string;
  label: string;
  sortValue: ReportSortValue;
  onSortToggle: (field: string) => void;
  isFirst?: boolean;
}

const ReportHeaderColumn: React.FC<ReportHeaderColumnProps> = ({
  field,
  label,
  sortValue,
  onSortToggle,
  isFirst,
}) => {
  return (
    <th>
      <button
        style={{ marginLeft: isFirst ? 15 : 0 }}
        className="button-th"
        onClick={() => onSortToggle(field)}
      >
        {label}
        {sortValue[0] === field ? (
          <FontAwesomeIcon
            className="icon-th"
            icon={sortValue[1] === "asc" ? faSortAmountUp : faSortAmountDown}
          />
        ) : null}
      </button>
    </th>
  );
};

export default ReportHeaderColumn;
