import {
  FinancialImportDto,
  FinancialImportRequest,
  FinancialImportResponse,
} from "@joyhub-integration/shared";
import axios from "../../../services/axios";
import { apiUrl, axiosConfig, axiosJsonConfig } from "../../../utils/api";

export async function getFinancialImports(
  offset: number,
  limit: number,
): Promise<Array<FinancialImportDto<true>>> {
  return axios
    .get<{
      imports: FinancialImportDto<true>[];
    }>(apiUrl(`/financialImports?offset=${offset}&limit=${limit}`), axiosConfig)
    .then((res) => res.data.imports);
}

export async function getFinancialImportSummary(
  id: number,
): Promise<FinancialImportResponse<true>> {
  return axios
    .get<
      FinancialImportResponse<true>
    >(apiUrl(`/financialImports/${id}/summary`), axiosConfig)
    .then((res) => res.data);
}

export async function validateFinancialImport(
  importRequest: FinancialImportRequest,
): Promise<FinancialImportResponse<true>> {
  return axios
    .post<
      FinancialImportResponse<true>
    >(apiUrl("/financialImports/validate"), importRequest, axiosJsonConfig)
    .then((res) => res.data);
}

export async function submitFinancialImport(
  importRequest: FinancialImportRequest,
): Promise<FinancialImportResponse<true>> {
  return axios
    .post<
      FinancialImportResponse<true>
    >(apiUrl("/financialImports"), importRequest, axiosJsonConfig)
    .then((res) => res.data);
}

export async function reingestFinancialImport(id: number): Promise<void> {
  return axios.post(apiUrl(`/financialImports/${id}/ingest`), {}, axiosConfig);
}

export async function deleteFinancialImport(id: number): Promise<void> {
  return axios.delete(apiUrl(`/financialImports/${id}`), axiosConfig);
}

export function financialImportDownloadUrl(id: number): string {
  return apiUrl(`/financialImports/${id}/download`);
}
