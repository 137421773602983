import {
  faCogs,
  faDownload,
  faFileImport,
  faRedo,
} from "@fortawesome/pro-light-svg-icons";
import { dateStr, durationOf } from "@joyhub-integration/shared";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useNavigate, useParams } from "react-router";
import {
  getSyncFiles,
  ingestSyncFile,
  SyncFilesEntity,
  syncFileUrl,
} from "../../../services/integrationsService";
import { dateOf } from "../../../utils/date";
import PlatformContext from "../../app/PlatformContext";
import withAlertModal, {
  WithAlertModalProps,
} from "../../common/alert/withAlertModal";
import { LoadilyFadily } from "../../common/allFadily";
import ActionBar from "../../common/button/ActionBar";
import { ButtonWithIconProps } from "../../common/button/ButtonWithIcon";
import TableWithSelection, {
  KeyValue,
} from "../../common/table/TableWithSelection";
import { ModernCrumbar } from "../../layout/ModernCrumbar";

const Limit = 1000;

const sizeStr = (n: number) =>
  n < 1024
    ? `${n}B`
    : n < 1048576
      ? `${Number(n / 1024).toFixed(0)}KB`
      : `${Number(n / 1048576).toFixed(0)}MB`;

type SyncFilesParams = {
  id: string;
  sync: string;
};

const SyncFilesPage: React.FC<WithAlertModalProps> = ({
  setAlert,
  onUnexpectedError,
}) => {
  const params = useParams<SyncFilesParams>();
  const [syncFiles, setSyncFiles] = useState<Array<SyncFilesEntity>>([]);
  const [more, setMore] = useState(false);
  const [selected, setSelected] = useState<SyncFilesEntity>();
  const [loaded, setLoaded] = useState(false);
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(0);
  const { platform } = useContext(PlatformContext);
  const navigate = useNavigate();
  const system_id = parseInt(params.id!);
  const sync_id = parseInt(params.sync!);

  const loadFiles = useCallback(
    (offset: number) => {
      setLoading(true);
      return getSyncFiles(system_id, sync_id, offset, Limit)
        .then((syncFiles) => {
          setSyncFiles((sf) => [...sf.slice(0, offset), ...syncFiles]);
          setMore(syncFiles.length === Limit);
        })
        .catch(onUnexpectedError)
        .finally(() => setLoading(false));
    },
    [onUnexpectedError, system_id, sync_id],
  );

  useEffect(() => {
    loadFiles(0).finally(() => setLoaded(true));
  }, [loadFiles, refresh]);

  const loadMore = () => {
    if (!loading) loadFiles(syncFiles.length).then(() => {});
  };

  const overlordButtons: ButtonWithIconProps[] = platform?.superAdmin
    ? [
        {
          icon: faDownload,
          disabled: !selected,
          label: "Download File",
          tag: "a",
          className: "btn btn-secondary",
          href: selected
            ? `${syncFileUrl(system_id, sync_id, selected.id)}`
            : undefined,
          download: true,
        },
        {
          icon: faCogs,
          disabled: !selected,
          onClick: () =>
            ingestSyncFile(system_id, sync_id, selected!.id)
              .then(() => setAlert("Ingest started", true))
              .catch(onUnexpectedError),
          label: "Reingest File",
        },
        {
          icon: faFileImport,
          disabled: !selected,
          label: "Import History",
          onClick: () =>
            selected
              ? navigate(
                  `/admin/integrations/${system_id}/syncs/${sync_id}/files/${selected.id}/imports`,
                )
              : undefined,
        },
      ]
    : [];

  const buttonProps = [...overlordButtons];

  const rightButtonProps = [
    {
      icon: faRedo,
      onClick: () => setRefresh((r) => 1 + r),
      outline: true,
      tooltip: "Refresh",
      disabled: loading,
    },
  ];

  const tableCols = useMemo(
    () =>
      new Array<KeyValue<SyncFilesEntity>>(
        {
          key: "path",
          title: "Filename",
          toValue: (s) => s.path.replace(/.*\//, ""),
        },
        {
          key: "created",
          title: "Created",
          toValue: (s) => dateStr(s.created),
          sortValue: (s) => dateOf(s.created)?.getTime(),
        },
        {
          key: "size",
          title: "Size",
          toValue: (s) => (s.size == null ? "–" : sizeStr(s.size)),
          sortValue: (s) => s.size,
        },
        {
          key: "success",
          title: "Import",
          toValue: (s) =>
            s.importHistory[0]?.success === true &&
            s.importHistory[0].skipped !== true
              ? "Success"
              : s.importHistory[0]?.skipped === true
                ? "Skipped"
                : s.importHistory[0]?.success === false
                  ? "Failure"
                  : "–",
        },
        {
          key: "duration",
          title: "Duration",
          toValue: (s) =>
            durationOf(
              s.importHistory[0]?.started,
              s.importHistory[0]?.finished,
            ),
          sortValue: (s) => {
            const start = dateOf(s.importHistory[0]?.started)?.getTime();
            const finish = dateOf(s.importHistory[0]?.finished)?.getTime();
            return start == null || finish == null ? undefined : finish - start;
          },
        },
        {
          key: "reason",
          title: "Reason",
          toValue: (s) => s.importHistory[0]?.reason ?? "",
        },
      ),
    [],
  );

  return (
    <>
      <ModernCrumbar
        primary="Sync History"
        primaryPath={`/admin/integrations/${params.id}`}
        secondary={`File List for Sync #${sync_id}`}
      />
      <LoadilyFadily loaded={loaded} className="jh-page-layout">
        <ActionBar
          buttonProps={buttonProps}
          rightButtonProps={rightButtonProps}
        />
        <div className="jh-page-content pt-0 admin-page page-scroll">
          <TableWithSelection<SyncFilesEntity>
            selected={selected}
            onSelectedChange={(selected) => setSelected(selected)}
            columns={tableCols}
            rows={syncFiles}
            sortColumn="created"
            sortDirection="desc"
            moar={more ? loadMore : undefined}
          />
        </div>
      </LoadilyFadily>
    </>
  );
};

export default withAlertModal(SyncFilesPage);
