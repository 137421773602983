import React, { useEffect, useMemo, useState } from "react";
import { ActionMeta, OnChangeValue } from "react-select";
import CreatableSelect from "react-select/creatable";
import { getEmailAutocomplete } from "../../services/emailService";
import { Option } from "../../services/models";
import { isValidEmail } from "../../utils/emails";
import withAlertModal, {
  WithAlertModalProps,
} from "../common/alert/withAlertModal";

interface EmailsInputProps {
  onChange: (selected: string[]) => void;
}

const EmailsInput: React.FC<WithAlertModalProps & EmailsInputProps> = ({
  onUnexpectedError,
  onChange,
}) => {
  const [availableEmails, setAvailableEmails] = useState<string[]>();
  const [selectedEmails, setSelectedEmails] = useState<string[]>([]);

  useEffect(() => {
    onChange(selectedEmails);
  }, [selectedEmails, onChange]);

  const toEmailOption = (email: string) => {
    return {
      label: email,
      value: email,
    };
  };

  const options = useMemo(() => {
    if (availableEmails) {
      return availableEmails.map(toEmailOption);
    } else {
      return [];
    }
  }, [availableEmails]);

  const selectEmailsValue = useMemo(() => {
    return selectedEmails.map(toEmailOption);
  }, [selectedEmails]);

  function onEmailsUpdate(
    value: OnChangeValue<Option, boolean>,
    action: ActionMeta<Option>,
  ) {
    if (action.action === "pop-value" || action.action === "remove-value") {
      const removed = action.removedValue;
      if (removed?.value != null) {
        const newSelectedEmails = selectedEmails.filter(
          (email) => email !== removed.value,
        );
        setSelectedEmails(newSelectedEmails);
      }
    } else if (action.action === "select-option") {
      const email = action.option?.value as string;
      const emailFound = availableEmails?.find(
        (availableEmail) => availableEmail === email,
      );
      if (emailFound) {
        const newSelectedEmails = [...selectedEmails];
        newSelectedEmails.push(emailFound);
        setSelectedEmails(newSelectedEmails);
      }
    }
  }

  function onCreateEmail(email: string) {
    if (isValidEmail(email)) {
      const newSelectedEmails = [...selectedEmails, email];
      setSelectedEmails(newSelectedEmails);
    }
  }

  function queryAutoComplete(newValue: string) {
    if (newValue) {
      getEmailAutocomplete(newValue)
        .then(({ emailAddresses }) => {
          setAvailableEmails(emailAddresses);
        })
        .catch(onUnexpectedError);
    } else {
      setAvailableEmails([]);
    }
  }

  return (
    <CreatableSelect
      placeholder="Add emails"
      options={options}
      value={selectEmailsValue}
      onChange={onEmailsUpdate}
      onCreateOption={onCreateEmail}
      onInputChange={queryAutoComplete}
      isMulti
      isSearchable
      isClearable={false}
      isValidNewOption={isValidEmail}
      styles={{
        indicatorsContainer: () => ({ display: "none" }),
      }}
    />
  );
};

export default withAlertModal(EmailsInput);
