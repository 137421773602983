import axios from "axios";
import { apiUrl, axiosConfig } from "../../../utils/api";

export type FinancialAccount = {
  name: string;
  actual: number;
  budget: number;
  category: "income" | "expense" | "debt" | "net";
};

export type FinancialAccountInfo = Record<string, FinancialAccount>;

export const getFinancialAccounts = async () =>
  axios
    .get<{
      accounts: FinancialAccountInfo;
    }>(apiUrl(`/insights/financials`), axiosConfig)
    .then((res) => res.data.accounts);
