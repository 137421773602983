import { GraphAxis } from "@joyhub-integration/shared";
import { isEqual, isUndefined } from "lodash";
import { useMemo } from "react";
import { ChangeCard } from "./ChangeCard";
import { RelValueCard } from "./RelValueCard";
import { TodayInsight } from "./TodayInsight";
import { SnapshotPropsType } from "./type";

export const Snapshot = (props: SnapshotPropsType) => {
  const { data, yAxisItems } = props;

  const getAxisItems = (axis?: GraphAxis) =>
    data.columns
      .map((column, index) => ({ column, index }))
      .filter(({ column }) => isEqual(column.graphAxis, axis))
      .sort((prev, next) => {
        const prevUpdatedTime = prev.column?.updatedAtAxis ?? 0;
        const nextUpdatedTime = next.column?.updatedAtAxis ?? 0;
        return prevUpdatedTime - nextUpdatedTime;
      });

  const yItems = getAxisItems("y");
  const xItems = getAxisItems("x");

  const isPropertyNameXAxis = useMemo(() => {
    const items = getAxisItems("x");
    const firstItem = items.length > 0 ? items[0] : undefined;
    return !!(firstItem && firstItem.column.attribute === "property_name");
  }, [getAxisItems]);

  const xAxisIndex = useMemo(() => {
    if (isPropertyNameXAxis) {
      return data.rows.length - 1;
    }
    const xAxisIdx = Number(data.xAxisIndex);
    return !isNaN(xAxisIdx) ? xAxisIdx : data.rows.length - 1;
  }, [data.xAxisIndex, data.rows.length]);

  return (
    <div className="w-100 h-100 d-flex flex-col align-items-center">
      {(isEqual(data.snapshotType, "Number") ||
        isUndefined(data.snapshotType)) && (
        <TodayInsight
          data={data}
          yItems={yAxisItems ?? yItems}
          xItems={xItems}
          xAxisIndex={xAxisIndex}
        />
      )}
      {isEqual(data.snapshotType, "Compare") && (
        <ChangeCard
          data={data}
          yItems={yAxisItems ?? yItems}
          xItems={xItems}
          xAxisIndex={xAxisIndex}
        />
      )}
      {isEqual(data.snapshotType, "Goal") && (
        <RelValueCard
          data={data}
          yItems={yAxisItems ?? yItems}
          xItems={xItems}
          xAxisIndex={xAxisIndex}
        />
      )}
    </div>
  );
};
