import axios from "axios";
import { find } from "lodash";
import { MapData, TagData, TagValue, UnitData } from "./unitMapApi";

export const API_KEY = "j0IBNUTuWl8KyTdNsR84UjQlySjpQmgV";

const UnitMapAPI = axios.create({
  baseURL: "https://api.unitmap.com/v1/",
  headers: {
    "API-key": API_KEY,
  },
});

// Consider https://www.npmjs.com/package/axios-cache-adapter + https://github.com/localForage/localForage

// TODO: Include pagination info in response
export const getMaps = async (page = 1): Promise<MapData[]> => {
  return UnitMapAPI.get(`maps?page=${page}&per-page=1000`).then(
    (response) => response.data.data as MapData[],
  );
};

export const getUnits = async (
  mapId: number,
  page = 1,
): Promise<UnitData[]> => {
  return UnitMapAPI.get(`maps/${mapId}/units?page=${page}&per-page=1000`).then(
    (response) => response.data.data as UnitData[],
  );
};

export const getLevelTags = async (mapId: number): Promise<TagData[]> => {
  return UnitMapAPI.get(`maps/${mapId}/levels/tags`).then(
    (response) => response.data.data as TagData[],
  );
};

// TODO: Should this hit the /floors API instead? Expects an asset ID, which is
// maybe different from a map ID?
export const getFloors = async (mapId: number): Promise<TagValue[]> => {
  return getLevelTags(mapId)
    .then((tags) => find(tags, (tag) => tag.key === "floor"))
    .then((floorTag) => floorTag?.values as TagValue[]);
};
