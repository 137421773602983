import { faCalculatorAlt } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  exhaustiveCheck,
  ignoreCaseIncludesMatcher,
  InsightsSelection,
  isAllInsights,
  isInsightsByIds,
  singularPlural,
} from "@joyhub-integration/shared";
import clsx from "clsx";
import { orderBy } from "natural-orderby";
import React, { useEffect, useMemo, useState } from "react";
import { Button, DropdownItem, Input, Popover } from "reactstrap";
import { KnownInsight } from "../../services/insightsService";
import { useInsightsSelectionQueryParam } from "../../utils/useQueryParams";

interface InsightPickerProps {
  insights: KnownInsight[];
  className?: string;
  color?: string;
}

const InsightsPicker: React.FC<InsightPickerProps> = ({
  insights,
  className,
  color,
}) => {
  const [pickerPopupOpen, setPickerPopupOpen] = useState(false);
  const [selection] = useInsightsSelectionQueryParam();

  const label = useMemo(() => {
    if (selection == null || isAllInsights(selection)) {
      return "All insights";
    } else if (isInsightsByIds(selection)) {
      return singularPlural(selection.ids, "insight");
    }
    exhaustiveCheck(selection);
  }, [selection]);

  return (
    <>
      <Button
        id="insights-picker"
        color={color ?? "light"}
        className={clsx(className, { active: pickerPopupOpen })}
        onClick={(e) => {
          e.stopPropagation(); // to prevent e from reaching the window and toggling popover
          setPickerPopupOpen(!pickerPopupOpen);
        }}
      >
        <FontAwesomeIcon icon={faCalculatorAlt} className="me-2" />
        {label}
      </Button>
      <Popover
        target="insights-picker"
        boundariesElement={undefined /*"viewport"*/}
        placement="bottom"
        isOpen={pickerPopupOpen}
        toggle={() => setPickerPopupOpen(false)}
        trigger="legacy"
      >
        <InsightsPickerPane
          insights={insights}
          toggle={() => setPickerPopupOpen(false)}
        />
      </Popover>
    </>
  );
};

interface InsightsPickerPaneProps {
  insights: KnownInsight[];
  toggle: () => void;
}

const InsightsPickerPane: React.FC<InsightsPickerPaneProps> = ({
  insights,
  toggle,
}) => {
  const [selection, setSelection] = useInsightsSelectionQueryParam();
  const [newSelection, setNewSelection] = useState<InsightsSelection>();

  useEffect(() => {
    window.addEventListener("click", toggle, { once: true });
    return () => window.removeEventListener("click", toggle);
  }, [toggle]);

  const applySelection = () => {
    setSelection(newSelection, "replaceIn");
    toggle();
  };

  const currentSelection = useMemo(
    () => newSelection ?? selection ?? {},
    [newSelection, selection],
  );

  return (
    <div
      className="pt-3 pb-2"
      style={{ width: "32rem" }}
      onClick={(e) => e.stopPropagation()}
    >
      <InsightsSelector
        insights={insights}
        selection={currentSelection}
        setSelection={setNewSelection}
      />
      <DropdownItem divider />
      <div className="flex-row justify-content-end mx-3 mt-3 mb-2">
        <Button
          outline
          color="secondary"
          size="sm"
          onClick={() => setNewSelection({})}
        >
          Reset
        </Button>
        <Button
          outline
          color="secondary"
          className="ms-2"
          size="sm"
          onClick={toggle}
        >
          Close
        </Button>
        <Button
          color="primary"
          className="ms-2"
          size="sm"
          onClick={applySelection}
          disabled={newSelection == null}
        >
          Apply
        </Button>
      </div>
    </div>
  );
};

interface InsightsSelectorProps {
  insights: KnownInsight[];
  selection: InsightsSelection;
  setSelection: (sel: InsightsSelection) => void;
}

const InsightsSelector: React.FC<InsightsSelectorProps> = ({
  insights,
  selection,
  setSelection,
}) => {
  const [filterText, setFilterText] = useState("");

  const sortedInsights = useMemo(
    () => orderBy(insights, (i) => i.name, "asc"),
    [insights],
  );

  const selectedInsightIds = useMemo(() => {
    if (isAllInsights(selection)) {
      return new Set(sortedInsights.map((i) => i.id));
    } else if (isInsightsByIds(selection)) {
      return new Set(selection.ids);
    }
    exhaustiveCheck(selection);
  }, [selection, sortedInsights]);

  const filteredInsights = useMemo(() => {
    const filterMatches = ignoreCaseIncludesMatcher(filterText);
    return sortedInsights.filter((insight) => filterMatches(insight.name));
  }, [filterText, sortedInsights]);

  const setSelectedInsightIds = (ids: Set<number>) => {
    if (ids.size === sortedInsights.length) {
      setSelection({});
    } else {
      setSelection({ ids: Array.from(ids) });
    }
  };

  const onInsightClick = (e: React.MouseEvent<HTMLElement>, id: number) => {
    if (e.ctrlKey || e.metaKey || e.shiftKey) {
      // TODO: handle shift properly
      const newSet = new Set(selectedInsightIds);
      if (!newSet.delete(id)) {
        newSet.add(id);
      }
      setSelectedInsightIds(newSet);
    } else {
      setSelectedInsightIds(new Set([id]));
    }
  };

  function onFilterChange(e: React.ChangeEvent<HTMLInputElement>) {
    setFilterText(e.target.value);
  }

  return (
    <>
      <Input
        className="dashboard-property-dropdown-filter my-2 mx-3"
        type="text"
        value={filterText}
        onChange={onFilterChange}
        placeholder="Filter"
      />
      <div style={{ maxHeight: "50vh", overflowY: "scroll" }}>
        {!filteredInsights.length ? (
          <div className="dashboard-property-dropdown-item">
            {sortedInsights.length ? "No matches" : "No insights"}
          </div>
        ) : null}
        {filteredInsights.map((insight) => (
          <DropdownItem
            key={insight.id}
            className={clsx("dashboard-property-dropdown-item bordered", {
              active: selectedInsightIds.has(insight.id),
            })}
            onClick={(e) => onInsightClick(e, insight.id)}
            toggle={false}
          >
            {insight.name}
          </DropdownItem>
        ))}
      </div>
    </>
  );
};

export default InsightsPicker;
