import { PropertiesSelection } from "@joyhub-integration/shared";
import {
  calculateInstantNumber,
  calculateInstantPercentage,
} from "../../services/calculateInsight";
import { getInstantData } from "../../services/dataService";
import { KnownInsight } from "../../services/insightsService";
import { formatted, toPercentage } from "../../utils/number";
import { JhTableCol, TableRow } from "../common/table/JhTable";

interface InsightIdsWithCalculationType {
  insightIds: number[];
  calculationType: "NUMBER" | "PERCENTAGE";
}

export interface RowDefinition {
  header: string;
  insightIds: InsightIdsWithCalculationType[];
}

export async function getData(
  columns: JhTableCol[],
  rowDefinitions: RowDefinition[],
  insightsMap: Record<string, KnownInsight>,
  selection: PropertiesSelection,
): Promise<TableRow[]> {
  const insightIds = rowDefinitions.reduce<Set<number>>((prev, current) => {
    for (const insightIdsWithCalculationType of current.insightIds) {
      for (const insightId of insightIdsWithCalculationType.insightIds) {
        prev.add(insightId);
      }
    }
    return prev;
  }, new Set<number>());
  return getInstantData(Array.from(insightIds), selection).then(
    (instantData) => {
      return rowDefinitions.map((rowDefinition) => {
        const values = rowDefinition.insightIds.map((insightIds) => {
          if (insightIds.calculationType === "NUMBER") {
            const calculation = calculateInstantNumber(
              insightIds.insightIds,
              instantData.overall,
              insightsMap,
            );
            return formatted(calculation.value, calculation.unit);
          } else {
            const calculation = calculateInstantPercentage(
              insightIds.insightIds,
              instantData.overall,
            );
            return toPercentage(calculation);
          }
        });
        const row: TableRow = {};
        for (let i = 0; i < values.length; i++) {
          const key = columns[i].key;
          const value = values[i];
          row[key] = value;
        }
        return row;
      });
    },
  );
}
