import {
  faBars,
  faClose,
  faEdit,
  faEye,
  faEyeSlash,
  faLockAlt,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  CustomColumn,
  CustomColumnType,
  PropertyColumnKey,
} from "@joyhub-integration/shared";
import type { XYCoord } from "dnd-core";
import React, { useEffect, useRef, useState } from "react";
import { useDrag, useDrop } from "react-dnd";
import Creatable from "react-select/creatable";
import { Button, ButtonGroup } from "reactstrap";
import ButtonWithIcon, {
  ButtonWithIconProps,
} from "../../common/button/ButtonWithIcon";

type ListItemProps = {
  column: CustomColumn;
  dataType: CustomColumnType;
  columnKey: PropertyColumnKey;
  columnName: string | null;
  index: number;
  handleEdit: (index: number, c: CustomColumn) => void;
  handleDataTypeSelected: (type: CustomColumnType) => void;
  handleMove: (to: number, from: number) => void;
  handleRemove: (index: number) => void;
  setEditing: (index: number | null) => void;
  editingIndex: number | null;
  textDisabled: boolean;
  numberDisabled: boolean;
  percentageDisabled: boolean;
  dateDisabled: boolean;
  yearDisabled: boolean;
  dollarDisabled: boolean;
  autoCompleteOptions?: {
    label: string | null;
    value: string | null;
  }[];
};

const EditColumnListItem: React.FC<ListItemProps> = ({
  column,
  dataType,
  columnKey,
  columnName,
  index,
  handleEdit,
  handleDataTypeSelected,
  handleMove,
  handleRemove,
  setEditing,
  editingIndex,
  textDisabled,
  numberDisabled,
  percentageDisabled,
  dateDisabled,
  yearDisabled,
  dollarDisabled,
  autoCompleteOptions,
}) => {
  const [name, setName] = useState(columnName);
  const dropRef = useRef<HTMLLIElement>(null);
  const editMode = editingIndex === index;

  function isCustom(): boolean {
    return columnKey.startsWith("user_");
  }

  useEffect(() => {
    if (column.name !== name || column.dataType !== dataType) {
      handleEdit(index, {
        name: name?.trim() ?? columnName,
        table: "property",
        dataType,
        columnKey,
        hidden: column.hidden,
      });
    }
  }, [column, columnKey, columnName, dataType, handleEdit, index, name]);

  const [{ opacity }, dragRef, preview] = useDrag({
    type: "column",
    item: { name: column.name, index },
    collect: (monitor) => ({
      opacity: monitor.isDragging() ? 0 : 1,
    }),
  });
  const [, drop] = useDrop<{ index: number; name: string }>({
    accept: "column",
    hover(draggedItemOverThisOne, monitor) {
      if (!dropRef.current) {
        return;
      }
      const dragIndex = draggedItemOverThisOne.index;
      const hoverIndex = index;

      if (dragIndex === hoverIndex) {
        return;
      }
      const hoverBoundingRect = dropRef.current?.getBoundingClientRect();
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      handleMove(hoverIndex, dragIndex);
      draggedItemOverThisOne.index = hoverIndex;
    },
  });

  const remove = () => {
    if (window.confirm(`Are you sure you want to delete the ${name} column`)) {
      handleRemove(index);
    }
  };

  drop(preview(dropRef));

  const lockButtonProps: ButtonWithIconProps = {
    className: "property-icon-button",
    iconStyle: { width: 11, height: 11 },
    icon: faLockAlt,
  };

  const eyeButtonProps: ButtonWithIconProps = {
    className: "property-eye-button",
    onClick: () => {
      handleEdit(index, {
        name: name,
        table: "property",
        dataType: column.dataType,
        columnKey: column.columnKey,
        hidden: !column.hidden,
      });
    },
    iconStyle: { width: 15, height: 15 },
    icon: column.hidden ? faEyeSlash : faEye,
  };

  return (
    <li
      key={`colitem-${index}`}
      className="list-group-item d-flex align-items-center justify-content-between"
      ref={dropRef}
      style={{ opacity: opacity }}
    >
      <div className="d-flex align-items-center flex-grow-1">
        <div
          ref={dragRef}
          style={{ cursor: "pointer" }}
          onDragStart={() => setEditing(null)}
        >
          <FontAwesomeIcon icon={faBars} />
        </div>
        {editMode ? (
          <Creatable
            isClearable={true}
            aria-invalid
            autoFocus={true}
            className="ms-2 flex-grow-1"
            defaultValue={{ label: name, value: name }}
            isMulti={false}
            options={autoCompleteOptions}
            onChange={(evt, action) => {
              if (action.action === "clear") {
                setName(columnName);
              } else if (
                action.action === "select-option" ||
                action.action === "create-option"
              ) {
                setName(evt?.value ?? null);
              }
            }}
          />
        ) : (
          <div className="d-flex align-items-center">
            <ButtonWithIcon {...eyeButtonProps}></ButtonWithIcon>
            <span style={{ marginBottom: 1, paddingLeft: 5 }} className="ms-2">
              {(name ?? "") ? name : columnName}
            </span>
            {!isCustom() ? (
              <ButtonWithIcon {...lockButtonProps}></ButtonWithIcon>
            ) : null}
          </div>
        )}
      </div>
      <div className="d-flex">
        <ButtonGroup className="ps-3">
          <Button
            color="secondary"
            outline={dataType !== "text"}
            onClick={() => handleDataTypeSelected("text")}
            disabled={!editMode || textDisabled}
          >
            Text
          </Button>
          <Button
            color="secondary"
            outline={dataType !== "number"}
            onClick={() => handleDataTypeSelected("number")}
            disabled={!editMode || numberDisabled}
          >
            Number
          </Button>
          <Button
            color="secondary"
            outline={dataType !== "date"}
            onClick={() => handleDataTypeSelected("date")}
            disabled={!editMode || dateDisabled}
          >
            Date
          </Button>
          <Button
            color="secondary"
            outline={dataType !== "year"}
            onClick={() => handleDataTypeSelected("year")}
            disabled={!editMode || yearDisabled}
          >
            Year
          </Button>
          <Button
            style={{ fontSize: 20, width: 50 }}
            color="secondary"
            outline={dataType !== "bool"}
            onClick={() => handleDataTypeSelected("bool")}
            disabled={!editMode || yearDisabled}
          >
            ☑
          </Button>
          <Button
            style={{ fontSize: 20, width: 50 }}
            color="secondary"
            outline={dataType !== "percentage"}
            onClick={() => handleDataTypeSelected("percentage")}
            disabled={!editMode || percentageDisabled}
          >
            %
          </Button>
          <Button
            style={{ fontSize: 20, width: 50 }}
            color="secondary"
            outline={dataType !== "dollar"}
            onClick={() => handleDataTypeSelected("dollar")}
            disabled={!editMode || dollarDisabled}
          >
            $
          </Button>
        </ButtonGroup>
        {editMode ? (
          <Button className="ms-2" onClick={() => setEditing(null)}>
            Save
          </Button>
        ) : (
          <ButtonWithIcon
            className="ms-4"
            icon={faEdit}
            onClick={() => {
              setEditing(index);
            }}
          />
        )}
        <ButtonWithIcon
          className="ms-2 btn-outline-danger"
          outline={true}
          icon={faClose}
          onClick={remove}
          disabled={!isCustom()}
        />
      </div>
    </li>
  );
};

export default EditColumnListItem;
