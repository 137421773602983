import { enumify } from "../util/enum";
import { PureDate } from "../util/pureDate";

// "Number" ought to be undefined so "X/Number" is just "X"
// 'Dollar-Dollar" is a horror to support trade-out...
export const Unit = enumify(
  "Number",
  "Dollar",
  "Sqft",
  "Month",
  "Day",
  "Dollar-Dollar",
  "NoValue",
  "Year",
  "Percent",
  "DollarsCents",
  "DecimalHundredths",
);
export type Unit = typeof Unit.type;

export const isCountOfTotal = (a: any): a is CountOfTotal =>
  typeof a === "object" &&
  typeof a?.count === "number" &&
  typeof a?.total === "number";

export const isUnitOfCountOfTotal = (a: any): a is UnitOf<CountOfTotal> =>
  typeof a === "object" &&
  Unit.includesValue(a?.count) &&
  Unit.includesValue(a?.total);

export type Dimensions<A extends string> = Record<A, string>;

export type DimensionedValue<A, B extends string> = {
  dimensions: Dimensions<B>;
  value: A;
};

export type Dimensioned<A, B extends string> = DimensionedValue<A, B>[];

export type CountOfTotal = { count: number; total: number }; // TODO: this should likely be just num/denom

export type RentSqFtCount = {
  rent: number;
  cRent: number;
  sqFt: number;
  cSqFt: number;
  rentedSqFt: number;
  cRentedSqFt: number;
  rentPerSqFt: number;
  cRentPerSqFt: number;
  marketRent: number;
  cMarketRent: number;
  marketRentPerSqFt: number;
  cMarketRentPerSqFt: number;
  concessions: number;
  cConcessions: number;
  effectiveRent: number;
  cEffectiveRent: number;
};
export const rentSqftCountToAverageRent = <D extends string>({
  dimensions,
  value: { rent, cRent },
}: DimensionedValue<RentSqFtCount, D>) => ({
  dimensions,
  value: { count: rent, total: cRent },
});

export type TabularInsightRow = Record<string, any>;

export type LeaseDetailsRow = {
  type: string; // New Lease | Renewal | In-Place Lease
  date: PureDate; // application/renewal/start
  startDate: PureDate;
  endDate: PureDate;
  previousTerm: number;
  term: number;
  moveInDate: PureDate | null;
  unitNumber: string;
  unitType: string;
  sqft: number;
  marketRent: number;
  previousRent: number;
  rent: number;
  tradeOut: number | null; // new - old, could calculate this outside SQL
  tradeOutPercent: number | null; // Could calculate this outside SQL
  varianceToMarket: number; // new - market, could calculate this outside SQL
  previousRentPSF: number; // Could calculate this outside SQL
  rentPSF: number; // Could calculate this outside SQL
  // I'm not confident that all this user data will exist always, so nullable
  email: string | null;
  firstName: string | null;
  lastName: string | null;
  birthDate: string | null;
  phone: string | null;
  address1: string | null;
  address2: string | null;
  city: string | null;
  state: string | null;
  zipCode: string | null;
  outstandingBalance: number | null;
};

export interface UnitDetailsRow {
  areaSqft: number | null;
  propertyCode: string;
  propertyName: string;
  bedrooms: number | null;
  bathrooms: number | null;
  unitType: string | null;
  building: string | null;
  unit: string | null;
  buildingUnit: string | null;
  occupancyStatus: string | null;
  makeReadyStatus: string | null;
  marketRent: number | null;
  effectiveRent: number | null;
  marketRentPSF: number | null;
  day: Date | null;
}

export interface ExtendedUnitDetailsRow extends UnitDetailsRow {
  leaseType: string | null;
  residentName: string | null;
  email: string | null;
  moveInDate: Date | null;
  leaseStart: Date | null;
  leaseEnd: Date | null;
  newLeaseRent: number | null;
  newLeaseTerm: number | null;
  previousLeaseRent: number | null;
  previousLeaseTerm: number | null;
  tradeOut: number | null;
  tradeOutPercent: number | null;
  varianceToMarket: number | null;
  newRentPSF: number | null;
  previousRentPSF: number | null;
}

export type MortgageDetailsRow = {
  period: number | null;
  mortgageDate: Date | null;
  balance: number | null;
  principal: number | null;
  interest: number | null;
  payoff: number | null;
  interest_only_period: number | null;
  loan_amount: number | null;
  interest_rate: number | null;
  capitalization_rate: number | null;
  term: number | null;
  amortization: number | null;
  origination_date: Date | null;
};

export type InsightValue =
  | number
  | CountOfTotal
  | Dimensioned<number | CountOfTotal, string>
  | TabularInsightRow[];

export type InsightValues = { [id: number]: InsightValue };

export type InsightComputation<A, Context = InsightValues> = (
  id: number,
  date: PureDate,
  context: Context,
) => A;

export type InsightResult<A> = undefined | A | Promise<A | undefined>;

export type UnitOf<A> =
  A extends Dimensioned<infer B, infer C>
    ? B extends number
      ? Unit
      : Record<keyof B, Unit>
    : A extends number
      ? Unit
      : A extends Array<any>
        ? Unit
        : Record<keyof A, Unit>;

export type DimensionDescription<A> =
  A extends Dimensioned<infer B, infer C> ? Record<C, string> : never;

export type InsightId = number;

export enum InsightDescriptionPeriod {
  Today = "the current day",
  Last7Days = "the seven days up to and including the indicated date",
  Last30Days = "the thirty days up to and including the indicated date",
  Last60Days = "the sixty days up to and including the indicated date",
  Last90Days = "the ninety days up to and including the indicated date",
  Monthly = "monthly; figures will be partial for current month",
  MonthlyOrLast30Days = "monthly, or last 30 days for dates in the current month",
  // A tricky one; should just be "next 60 days" really, but the dimensional
  // breakdown description, "lease start", doesn't really make clear that it's
  // these two 30-day periods.
  Next30Days = "the thirty days following (and excluding) the indicated date",
  Next3060Days = "the next thirty days, and the thirty following those (1-30, and 31-60)",
  Next60Days = "the sixty days following (and excluding) the indicated date",
  Next90Days = "the ninety days following (and excluding) the indicated date",
  Quarterly = "quarterly; figures will be partial for current quarter",
  SpotMeasurement = "the indicated date",
  ThreeMonthOutlook = "the indicated month, and the two following",
  ThreeMonth3060Outlook = "various, possibly including the indicated month, the two following, the next thirty days and the thirty following those",
}

// It might be worth structuring these such that vendor-specific notes start
// with "[Vendor]:" or similar, so that they can be programmatically filtered
// out if not relevant to the current presentation in the front end.  That
// said, many vendor-specific notes may disappear anyway under CM.
export enum InsightDescriptionNote {
  RealPageHistoryIsImperfect = "RealPage data is largely a current snapshot, so precision may be lost prior to the current date.",
  UnreliableRealPageAndEntrataLeaseSignedDates = "This should use the date that leases are signed to determine whether to count them, but that information is not reliably available from RealPage and Entrata, so for them we use lease start date.",
  NoRenewalRentForRealPage = "Renewal rent is not available from RealPage, so its tradeout is always zero for renewals.",
  EntrataHistoryIsImperfect = "Entrata data is largely a current snapshot, so precision may be lost prior to the current date.",
}

/** InsightDescription: structured description of the insight.
 *
 * short and detail are short and long (if needed) descriptions of the insight.
 *
 * period and notes both refer to enums with a set of standard values; notes
 * might conceivably be collated and aggregated at the bottom of reports or
 * dashboards, with footnote marks on the relevant insights.
 *
 * A presentation of this information should *also* look at the insight's
 * dimensions field if present, displaying its values under a "Broken down by:"
 * heading, or similar.
 *
 * seeAlso is most likely to be relevant for calculated insights, linking to
 * the original numerator and denominator insights; these might be presented
 * as hyperlinks.
 *
 * So a display template might look something like the below; there's a sample
 * implementation in cmd/command/manual.ts.
 *
 * <insight.name> (prettyprint(<insight.identifier>)) [if defined]
 * <short>
 * For: <period>
 * Broken down by: <Object.values(insight.dimensions).sort().join(', ')>
 * <detail> [if defined]
 * Note(s):
 *   1. <notes> [one row for each note, if defined]
 * See also: <insight>, <insight> [links, if seeAlso defined]
 */
export type InsightDescription = {
  short: string;
  period: InsightDescriptionPeriod;
  detail?: string;
  notes?: InsightDescriptionNote[];
  seeAlso?: (InsightDefinition | DimensionalInsight<any, any>)[];
};

export type InsightIdentifier = {
  name: string /* Well known name for this insight */;
  period?: /* For insights over a period, the period of the insight */
  | "w" // 7 days
    | "m" // month to date
    | "1d" // 1 day, also known as today
    | "30d" // last 30 days
    | "m30" // month to date, or last 30 days for dates in the current month
    | string; // dynamic period strings that are not well typed;
  dimension?: Dimension /* For single-dimensioned insights, the dimension */;
  aspect?: string /* goal, budget, proForma */;
};

export type InsightDefinition<A = any> = {
  id: InsightId;
  name: string;
  description?: InsightDescription;
  dimensions?: DimensionDescription<A>;
  unit: UnitOf<A>;
  aggregate: (as: A[]) => InsightValue | undefined;
  identifier: InsightIdentifier;
  goalType?: GoalType;
  kind?: "financial" | "leaseDetails" | "mortgage";
  common?: boolean;
};

// you desire the insight value to be greater than a min goal and less than a max goal
export type GoalType = "min" | "max";

export type DerivedCountOfTotalInsightDefinition =
  InsightDefinition<CountOfTotal> & {
    count: InsightDefinition<number>;
    total: InsightDefinition<number>;
  };

export const isDerivedCountOfTotalInsightDefinition = (
  insightDef: InsightDefinition,
): insightDef is DerivedCountOfTotalInsightDefinition => {
  return (insightDef as any).count != null && (insightDef as any).total != null;
};

export type DerivedNumeratorInsightDefinition = InsightDefinition<number> & {
  numerator: InsightDefinition<CountOfTotal>;
};

export const isDerivedNumeratorInsightDefinition = (
  insightDef: InsightDefinition,
): insightDef is DerivedNumeratorInsightDefinition => {
  return (insightDef as any).numerator != null;
};

// An implementation is internal if the `insight` field is a `number`.  This
// is crap but expedient.
type InsightCore<A> = {
  compute: InsightComputation<InsightResult<A>>;
  dependencies: readonly InsightImplementation[];
  instant: boolean; // is this just an instantaneous calculation and so can't look back in time...
  noCache: boolean; // If true, don't cache values - recompute every time.
};
type PublishedInsightImpl<A> = InsightCore<A> & {
  insight: InsightDefinition<A>;
};
type InternalInsightImpl<A> = InsightCore<A> & {
  insight: number;
  source: string;
};
export type InsightImplementation<A = any> =
  | InternalInsightImpl<A>
  | PublishedInsightImpl<A>;

export const isInternalInsight = <A>(
  i: InsightImplementation<A>,
): i is InternalInsightImpl<A> => typeof i.insight === "number";

export const isInsightDefinition = <A>(
  a: InsightDefinition<A> | number,
): a is InsightDefinition<A> => typeof a === "object";

export const insightID = (a: InsightImplementation): number =>
  isInternalInsight(a) ? a.insight : a.insight.id;

export const ByBedrooms = "bedrooms";
export type ByBedrooms = typeof ByBedrooms;
export type BedroomsDimension = {
  [ByBedrooms]: number;
};

export const BySource = "source";
export type BySource = typeof BySource;
export type SourceDimension = {
  [BySource]: string;
};

export const ByStatus = "status";
export type ByStatus = typeof ByStatus;
export type StatusDimension = {
  [ByStatus]: string;
};

export const ByUnitType = "unitType";
export type ByUnitType = typeof ByUnitType;
export type UnitTypeDimension = {
  [ByUnitType]: string;
};

export const ByLeaseEnd = "leaseEnd";
export type ByLeaseEnd = typeof ByLeaseEnd;
export type LeaseEndDimension = {
  [ByLeaseEnd]: string;
};

export const ByLeaseStart = "leaseStart";
export type ByLeaseStart = typeof ByLeaseStart;
export type MoveInDimension = {
  [ByLeaseStart]: string;
};

export const ByPriority = "priority";
export type ByPriority = typeof ByPriority;

export const ByAge = "age";
export type ByAge = typeof ByAge;
// Value is somewhat freeform, varies by insight:
// Work orders: '0-2 days', '3-7 days', '2 weeks', '30 days', '60 days',
//   '90 days', '91+ days'
// Delinquency: 'Current', '30-59 days', '60-89 days', '90+ days', 'Total'
export type AgeDimension = {
  [ByAge]: string;
};

// Ugh.  Returned by RP query, then broken up into dimensioned counts of total.
export type DelinquentResult = {
  delinquentCurrent: number;
  cDelinquentCurrent: number;
  delinquent30: number;
  cDelinquent30: number;
  delinquent60: number;
  cDelinquent60: number;
  delinquent90: number;
  cDelinquent90: number;
  cDelinquent30Plus: number;
  cDelinquentTotal: number;
};

export const ByFinancialCode = "financialCode";
export type ByFinancialCode = typeof ByFinancialCode;
export type FinancialCodeDimension = {
  [ByFinancialCode]: string;
};

export const ByIncome = "income";
export type ByIncome = typeof ByIncome;

export const ByReason = "reason";
export type ByReason = typeof ByReason;

export type JustACount = { count: number };

export const dimCountByBedroomsAndUnitTypeAndStatus = ({
  bedrooms,
  unitType,
  status,
  count,
}: BedroomsDimension & UnitTypeDimension & StatusDimension & JustACount) => ({
  dimensions: { bedrooms: `${bedrooms}`, unitType, status },
  value: count,
});

export const dimCountByBedroomsAndUnitType = ({
  bedrooms,
  unitType,
  count,
}: BedroomsDimension & UnitTypeDimension & JustACount) => ({
  dimensions: { bedrooms: `${bedrooms}`, unitType },
  value: count,
});

export const dimVariousByBedroomsAndUnitTypeAndSource = <T>({
  source,
  bedrooms,
  unitType,
  ...rest
}: BedroomsDimension & UnitTypeDimension & SourceDimension & T) => ({
  dimensions: { source, bedrooms: `${bedrooms}`, unitType },
  value: rest,
});
export const dimCountByBedroomsAndUnitTypeAndSource = ({
  source,
  bedrooms,
  unitType,
  count,
}: BedroomsDimension & UnitTypeDimension & SourceDimension & JustACount) => ({
  dimensions: { source, bedrooms: `${bedrooms}`, unitType },
  value: count,
});

export const ByNextEvent = "nextEvent";
export type ByNextEvent = typeof ByNextEvent;
export type NextEventDimension = {
  [ByNextEvent]: string;
};

export const dimCountByBedroomsAndUnitTypeAndNextEvent = ({
  bedrooms,
  unitType,
  nextEvent,
  count,
}: BedroomsDimension &
  UnitTypeDimension &
  NextEventDimension &
  JustACount) => ({
  dimensions: { bedrooms: `${bedrooms}`, unitType, nextEvent },
  value: count,
});

export type NewCompletedCount = {
  newItems: number;
  newCompleted: number;
  openCompleted: number;
  totalCompletedAge: number;
  total: number;
};
export type DimensionedNewCompletedCount = Dimensioned<
  NewCompletedCount,
  ByPriority
>;

export type PropertyInfo = {
  property_id: number;
  system_id: number;
  prop_id: number; // The native RRE property id
};

/** Date range; this should always be inclusive-inclusive.
 * Should probably be a class for nice bonuses like cloning etc.
 */
export type DateRange = { from: PureDate; to: PureDate };

export type DateToRange = {
  desc: string;
  period: string;
  expand: (d: Date) => DateRange;
};

/** Date-ranged insight query returning dimensioned counts; used by generic
 * insight code.
 */
export type UnwindowedSelector<
  T extends PropertyInfo,
  Value,
  Dims extends string,
> = (
  insight: number,
  date: PureDate,
  { property_id, system_id }: T,
) => Promise<Dimensioned<Value, Dims>>;
export type Selector<T extends PropertyInfo, Value, Dims extends string> = (
  dtr: DateToRange,
) => UnwindowedSelector<T, Value, Dims>;

/** Date-ranged insight query returning simple counts; used by generic
 * insight code.
 */
export type SimpleSelector<Context> = (
  dtr: DateToRange,
) => (insight: number, date: PureDate, context: Context) => Promise<number>;

export const Total = "total";
export type Total = typeof Total;

export const Dimension = [
  ByAge,
  ByBedrooms,
  ByFinancialCode,
  ByIncome,
  ByNextEvent,
  BySource,
  ByStatus,
  ByUnitType,
  ByLeaseEnd,
  ByLeaseStart,
  ByReason,
] as const;

export type Dimension = (typeof Dimension)[number];
export const isTotal = (v: Dimension | Total): v is Total => v === Total;
export type Dimensional<V, DS extends Dimension | Total> = { [Total]: V } & {
  [D in DS]: V;
};

export const dimensionText: Dimensional<string, Dimension> = {
  age: "Age",
  bedrooms: "Bedrooms",
  financialCode: "Financial Code",
  income: "Income Bracket",
  leaseEnd: "Lease End",
  leaseStart: "Lease Start",
  nextEvent: "Next Event",
  source: "Ad Source",
  status: "Status",
  total: "(total)",
  unitType: "Unit Type",
  reason: "Move-Out Reason",
};

export type DimensionalInsight<V, DS extends Dimension | Total> = {
  [D in DS]: D extends Total
    ? InsightDefinition<V>
    : InsightDefinition<Dimensioned<V, D>>;
};

export type DimensionalImplementation<V, DS extends Dimension | Total> = {
  [D in DS]: D extends Total
    ? InsightImplementation<V>
    : InsightImplementation<Dimensioned<V, D>>;
};

export const isInsightDimensional = <V, DS extends Dimension | Total>(
  a: InsightDefinition<V> | DimensionalInsight<V, DS>,
): a is DimensionalInsight<V, DS> => !("id" in a);
