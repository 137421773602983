import { PropertiesSelection } from "@joyhub-integration/shared";
import { apiUrl, axiosConfig } from "../utils/api";
import { dateToYMD } from "../utils/date";
import axios from "./axios";
import { KnownInsight } from "./insightsService";

export const Units = [
  "Number",
  "Percent",
  "Dollar",
  "Minute",
  "CountOfTotal",
] as const;

export type Unit = (typeof Units)[number];

export type Dimensions = { [dimension: string]: string };

export type DimensionedValue<A> = {
  dimensions: { [dimension: string]: string };
  value: A;
};

export type CountOfTotal = { count: number; total: number };

export const isCountOfTotal = (a: any): a is CountOfTotal =>
  typeof a === "object" &&
  typeof a.count === "number" &&
  typeof a.total === "number";

export type DimensionedNumbers = DimensionedValue<number>[];

export type DimensionedCountOfTotals = DimensionedValue<CountOfTotal>[];

export const isDimensioned = (
  a: any,
): a is DimensionedValue<number | CountOfTotal>[] =>
  Array.isArray(a) && typeof a[0]?.dimensions === "object";

// Array<A> | Array<B> causes type aggravation...
export type InsightValue =
  | DimensionedValue<number | CountOfTotal>[]
  | CountOfTotal
  | number;

export type DateString = string;

export type InsightValues = {
  [id: number]: InsightValue;
};

export type InsightRangeValue = {
  insights: InsightValues;
  goals?: InsightValues;
  date: DateString;
};

export type InsightRange = InsightRangeValue[];

export type PropertyInsights<A> = {
  [id: number]: A;
};

export type TagInsights<A> = {
  [tag: string]: A;
};

export interface InstantInsights {
  byProperty: PropertyInsights<InsightValues>;
  byTag: TagInsights<InsightValues>;
  overall: InsightValues;
  goals: {
    byProperty: PropertyInsights<InsightValues>;
    byTag: TagInsights<InsightValues>;
    overall: InsightValues;
  };
  date: DateString;
}

export interface InstantInsightsWithMap extends InstantInsights {
  insightMap: { [id: string]: KnownInsight };
}

export type RangedInsights = {
  byProperty?: PropertyInsights<InsightRange>;
  byTag: TagInsights<InsightRange>;
  overall: InsightRange;
};

export type Breakout = "property";

export async function getInstantData(
  insightIds: number[],
  selection: PropertiesSelection,
  atDate?: Date,
  breakout?: Breakout,
): Promise<InstantInsights> {
  const insightQueryParam = `insight=${insightIds.join(",")}`;
  const propertiesQueryParam =
    "&properties=" + encodeURIComponent(JSON.stringify(selection));
  const atQueryParam = atDate ? `&at=${dateToYMD(atDate)}` : "";
  const breakoutParam = breakout ? `&breakout=${breakout}` : "";
  const allQueryParams = `${insightQueryParam}${propertiesQueryParam}${atQueryParam}${breakoutParam}`;
  return axios
    .get(apiUrl(`/data/instant?${allQueryParams}`), axiosConfig)
    .then((res) => {
      return res.data as InstantInsights;
    });
}

export async function getRangeDataByIds(
  insightIds: number[],
  from: Date,
  to: Date,
  selection: PropertiesSelection,
  breakout?: Breakout,
): Promise<RangedInsights> {
  const baseQueryParam = `insight=${insightIds.join(",")}&from=${dateToYMD(
    from,
  )}&to=${dateToYMD(to)}`;
  const propertiesQueryParam =
    "&properties=" + encodeURIComponent(JSON.stringify(selection));
  const breakoutParam = breakout ? `&breakout=${breakout}` : "";
  const allQueryParams = `${baseQueryParam}${propertiesQueryParam}${breakoutParam}`;
  return axios
    .get(apiUrl(`/data/range?${allQueryParams}`), axiosConfig)
    .then((res) => {
      return res.data as RangedInsights;
    });
}

export async function getRangeDataOrDefault(
  from: Date,
  to: Date,
  rangeInsightIds: Set<number>,
  selection: PropertiesSelection,
  breakout?: Breakout,
): Promise<RangedInsights> {
  const rangeDataPromise =
    rangeInsightIds.size > 0
      ? getRangeDataByIds(
          Array.from(rangeInsightIds),
          from,
          to,
          selection,
          breakout,
        )
      : Promise.resolve({
          byProperty: {},
          byTag: {},
          overall: [],
        });
  return rangeDataPromise;
}
