import {
  faFileImport,
  faLayerPlus,
  faPencilAlt,
  faPlus,
  faTrash,
} from "@fortawesome/pro-light-svg-icons";
import {
  AdHocInsightEntity,
  ignoreCaseIncludesMatcher,
} from "@joyhub-integration/shared";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router";
import { Input } from "reactstrap";
import {
  deleteAdHocInsight,
  getAdHocInsight,
  getAdHocInsights,
} from "../../../services/adHocInsightService";
import withAlertModal, {
  WithAlertModalProps,
} from "../../common/alert/withAlertModal";
import { LoadilyFadily } from "../../common/allFadily";
import ActionBar from "../../common/button/ActionBar";
import ButtonWithIcon from "../../common/button/ButtonWithIcon";
import TableWithSelection, {
  KeyValue,
} from "../../common/table/TableWithSelection";
import { ModernCrumbar } from "../../layout/ModernCrumbar";
import DeleteModal from "../common/DeleteModal";
import AddEditAdHocInsightModal from "./AddEditAdHocInsightModal";
import TestProcessModal from "./TestProcessModal";

const AdHocInsightsAdminPage: React.FC<WithAlertModalProps> = ({
  setAlert,
  onUnexpectedError,
}) => {
  const [adHocInsights, setAdHocInsights] =
    useState<Array<AdHocInsightEntity>>();
  const [selected, setSelected] = useState<AdHocInsightEntity>();
  const [AdHocInsightFilter, setAdHocInsightFilter] = useState("");
  const [modal, setModal] = useState<"create" | "edit" | "delete" | "test">();
  const navigate = useNavigate();

  const fetchAdHocInsights = useCallback(
    async function () {
      return getAdHocInsights()
        .then((adHocIns) => {
          setAdHocInsights(adHocIns);
        })
        .catch(onUnexpectedError);
    },
    [onUnexpectedError],
  );

  useEffect(() => {
    fetchAdHocInsights();
  }, [fetchAdHocInsights]);

  const filteredAdHocInsights = useMemo(() => {
    const matcher = ignoreCaseIncludesMatcher(AdHocInsightFilter);
    return adHocInsights?.filter((adHocInsight) => matcher(adHocInsight.name));
  }, [adHocInsights, AdHocInsightFilter]);

  const onAdHocInsightSubmitted = (adHocInsight: AdHocInsightEntity) => {
    fetchAdHocInsights().then(() => {
      const verb = modal === "edit" ? "edited" : "added";
      setAlert(`Ad Hoc Insight ${adHocInsight.name} ${verb}`, true);
      setSelected(undefined);
      setModal(undefined);
    });
  };

  const onAdHocInsightDeleted = (adHocInsight: AdHocInsightEntity) => {
    fetchAdHocInsights().then(() => {
      setAlert(`Ad Hoc Insight ${adHocInsight.name} deleted`, true);
      setSelected(undefined);
      setModal(undefined);
    });
  };

  const buttonProps = [
    {
      icon: faPencilAlt,
      disabled: !selected,
      onClick: () => setModal("edit"),
      tooltip: "Edit Ad Hoc Report",
    },
    {
      icon: faLayerPlus,
      disabled: !selected,
      onClick: () => setModal("test"),
      tooltip: "Test Ad Hoc Report",
    },
    {
      icon: faFileImport,
      disabled: !selected,
      onClick: () => navigate(`${selected?.id}`),
      tooltip: "History",
    },
    {
      icon: faTrash,
      disabled: !selected,
      color: "danger",
      onClick: () => setModal("delete"),
      tooltip: "Delete Ad Hoc Report",
    },
  ];

  const rightButtons = [
    <Input
      className="w-auto ms-3 py-1 rounded-pill"
      size={36}
      type="text"
      placeholder="Filter Reports"
      onChange={(e) => setAdHocInsightFilter(e.target.value)}
    />,
  ];

  const tableCols: Array<KeyValue<AdHocInsightEntity>> = [
    {
      key: "name",
      title: "Name",
    },
    {
      key: "description",
      title: "Description",
      sortable: false,
    },
    {
      key: "active",
      title: "Status",
      toValue: (i) => (i.active ? "Active" : "Inactive"),
    },
  ];

  return (
    <>
      <ModernCrumbar primary="Manage Ad Hoc Reports">
        <ButtonWithIcon
          label="Add Report"
          icon={faPlus}
          onClick={() => setModal("create")}
          size="sm"
          color="primary"
          className="ms-auto align-self-center"
        />
      </ModernCrumbar>
      <LoadilyFadily loaded={adHocInsights != null} className="jh-page-layout">
        <ActionBar buttonProps={buttonProps} rightButtonProps={rightButtons} />
        <div className="jh-page-content pt-0 admin-page page-scroll">
          <TableWithSelection<AdHocInsightEntity>
            selected={selected}
            onSelectedChange={(selected) => setSelected(selected)}
            columns={tableCols}
            sortColumn="name"
            rows={filteredAdHocInsights}
          />
        </div>
      </LoadilyFadily>
      {modal === "create" || modal === "edit" ? (
        <AddEditAdHocInsightModal
          currentSelected={modal === "edit" ? selected : undefined}
          onSubmit={onAdHocInsightSubmitted}
          onClose={() => setModal(undefined)}
        />
      ) : modal === "delete" && selected ? (
        <DeleteModal
          id={selected.id}
          entityName="Ad Hoc Insight"
          identificationKey="name"
          getEntity={getAdHocInsight}
          deleteEntity={deleteAdHocInsight}
          onClose={() => setModal(undefined)}
          onSubmit={onAdHocInsightDeleted}
        />
      ) : modal === "test" && selected ? (
        <TestProcessModal
          currentSelected={selected}
          onClose={() => setModal(undefined)}
        />
      ) : null}
    </>
  );
};

export default withAlertModal(AdHocInsightsAdminPage);
