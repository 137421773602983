import React, {
  ChangeEvent,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { FormFeedback, FormGroup, FormText, Input, Label } from "reactstrap";
import { RealPageWSConfiguration } from "../../../../services/integrationsService";
import { getOrganizationsForPropertyConnect } from "../../../../services/organizationService";
import { Platform } from "../../../../services/platformService";
import { Organization } from "../../../../services/usersService";
import PlatformContext from "../../../app/PlatformContext";
import { IntegrationFormProps, ValidationError } from "./common";

const RealPageWSForm: React.FC<IntegrationFormProps> = (props) => {
  const {
    configuration,
    setConfiguration,
    validationError,
    setConfigurationValidation,
  } = props;
  const realPageConfig = configuration as RealPageWSConfiguration;

  useEffect(() => {
    if (realPageConfig.type !== "ws") {
      setConfiguration({
        type: "ws",
        protocol: "FTP",
        server: "",
        username: "",
        siteId: "",
        pmcId: "",
        groupName: "",
      });
    }
  }, [realPageConfig.type, setConfiguration]);
  const [propertyConnectOrganizations, setPropertyConnectOrganizations] =
    useState<Organization[]>();
  const platform = useContext(PlatformContext).platform as Platform;

  const validate = useCallback(
    (newConfig: RealPageWSConfiguration): ValidationError => {
      const isPcApp = platform.organization.application === "PC";
      if (isPcApp && !newConfig.client) {
        return { message: "Client is required.", field: "client" };
      }
      return {};
    },
    [platform.organization.application],
  );

  useEffect(() => {
    setConfigurationValidation(validate(realPageConfig));
  }, [realPageConfig, setConfigurationValidation, validate]);

  useEffect(() => {
    const isPcApp = platform.organization.application === "PC";
    if (isPcApp) {
      getOrganizationsForPropertyConnect().then((organizations) => {
        setPropertyConnectOrganizations(organizations);
      });
    }
  }, [platform.organization.application]);

  function onConfigChange(
    e: ChangeEvent<HTMLInputElement>,
    key: keyof RealPageWSConfiguration,
  ) {
    const newConfig = {
      ...realPageConfig,
      [key]: e.target.value,
    };
    setConfiguration(newConfig);
  }

  return (
    <>
      <FormGroup>
        <Label>RealPage Server</Label>
        <Input
          type="text"
          value={realPageConfig.server}
          onChange={(e) => onConfigChange(e, "server")}
          invalid={validationError.field === "server"}
          autoComplete="off"
          placeholder="mycompany.onesite.realpage.com"
        />
        <FormText color="muted">
          Enter the host name of your RealPage OneSite system. It will be in the
          form of "companyname.onesite.realpage.com". Do not use
          "my.realpage.com".
        </FormText>
      </FormGroup>
      <FormGroup>
        <Label>Username</Label>
        <Input
          type="text"
          value={realPageConfig.username}
          onChange={(e) => onConfigChange(e, "username")}
          invalid={validationError.field === "username"}
          autoComplete="off"
        />
        {validationError.field === "username" && validationError.message ? (
          <FormFeedback>{validationError.message}</FormFeedback>
        ) : null}
        <FormText color="muted">
          Enter a username that has role of SDE Manager and access to the
          properties being analyzed.
        </FormText>
      </FormGroup>
      <FormGroup>
        <Label>Password</Label>
        <Input
          type="text"
          value={realPageConfig.secret}
          onChange={(e) => onConfigChange(e, "secret")}
          invalid={validationError.field === "password"}
          autoComplete="off"
          className="secret-blur"
        />
        {validationError.field === "password" && validationError.message ? (
          <FormFeedback>{validationError.message}</FormFeedback>
        ) : null}
        <FormText color="muted">
          * Credentials stored securely in AWS Secrets Manager. Enter the
          password for the above user.
        </FormText>
      </FormGroup>
      <FormGroup>
        <Label>Property Management Company (PMC) ID</Label>
        <Input
          type="text"
          value={realPageConfig.pmcId}
          onChange={(e) => onConfigChange(e, "pmcId")}
          invalid={validationError.field === "pmcId"}
          autoComplete="off"
        />
        <FormText color="muted">
          Please enter a valid ID for your property management company (i.e.,
          Realpage licensee) associated with the system that is providing
          reports.
        </FormText>
        {validationError.field === "path" && validationError.message ? (
          <FormFeedback>{validationError.message}</FormFeedback>
        ) : null}
      </FormGroup>
      <FormGroup>
        <Label>Sample Site ID</Label>
        <Input
          type="text"
          value={realPageConfig.siteId}
          onChange={(e) => onConfigChange(e, "siteId")}
          invalid={validationError.field === "siteId"}
          autoComplete="off"
        />
        <FormText color="muted">
          Please enter a valid site ID for one or more of your properties. Site
          IDs can have up to 7 numbers. This will be used for testing purposes.
        </FormText>
        {validationError.field === "siteId" && validationError.message ? (
          <FormFeedback>{validationError.message}</FormFeedback>
        ) : null}
      </FormGroup>
      <FormGroup>
        <Label>Group Name</Label>
        <Input
          type="text"
          value={realPageConfig.groupName}
          onChange={(e) => onConfigChange(e, "groupName")}
          invalid={validationError.field === "groupName"}
          autoComplete="off"
        />
        <FormText color="muted">
          Enter the name of the Home Office Group containing the reports.
        </FormText>
        {validationError.field === "groupName" && validationError.message ? (
          <FormFeedback>{validationError.message}</FormFeedback>
        ) : null}
      </FormGroup>

      {propertyConnectOrganizations && propertyConnectOrganizations.length ? (
        <FormGroup>
          <Label>Revolution RE Client</Label>
          <Input
            type="select"
            className="custom-select"
            value={realPageConfig.client}
            onChange={(e) => {
              const client = e.target.value
                ? parseInt(e.target.value, 10)
                : undefined;
              setConfiguration({ ...realPageConfig, client: client });
            }}
          >
            <option value=""></option>
            {propertyConnectOrganizations.map((org) => (
              <option key={org.id} value={org.id}>
                {org.name}
              </option>
            ))}
          </Input>
          {validationError.field === "client" && validationError.message ? (
            <FormFeedback className="jh-block">
              {validationError.message}
            </FormFeedback>
          ) : null}
        </FormGroup>
      ) : null}
    </>
  );
};

export default RealPageWSForm;
