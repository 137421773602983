import axios from "axios";
import { apiUrl, axiosConfig } from "../utils/api";

const ecsSessionHistoryUrl = (system_id: number) =>
  apiUrl(`/ybr/sessions/${system_id}`);
const ecsSessionEventsUrl = (session: string) =>
  apiUrl(`/ybr/events/${session}`);

export async function getEcsSessionHistory(system_id: number): Promise<any> {
  return axios
    .get<any>(ecsSessionHistoryUrl(system_id), axiosConfig)
    .then((res) => res.data);
}

export async function getEcsSessionEvents(session: string): Promise<any> {
  return axios
    .get<any>(ecsSessionEventsUrl(session), axiosConfig)
    .then((res) => res.data);
}
