import { faCaretRight } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Label } from "reactstrap";
import { CardMenuItemType, CardType } from "../../type";
import "./style.css";

interface CardMenuItemPropsType {
  item: CardMenuItemType;
  onClick: (value: CardType) => void;
}

export const CardMenuItem = (props: CardMenuItemPropsType) => {
  const {
    item: { title, icon, type },
    onClick,
  } = props;

  return (
    <Button
      onClick={() => onClick(type)}
      className="d-flex rounded-1 px-4 py-2 item justify-content-center align-items-center menu-item-button border-0"
    >
      <FontAwesomeIcon
        icon={icon}
        className="color-pop"
        style={{ paddingRight: "16px", fontSize: "2.5em" }}
      />
      <div className="d-flex flex-column flex-grow-1 flex-shrink-1">
        <Label style={{ fontSize: "20px", marginBottom: 0 }}>{title}</Label>
      </div>
      <FontAwesomeIcon
        icon={faCaretRight}
        size="2x"
        className="color-pop"
        style={{ paddingLeft: "16px" }}
      />
    </Button>
  );
};
