import React, { ChangeEvent, useContext, useEffect, useState } from "react";
import { FormGroup, Input, InputGroup, Label } from "reactstrap";
import { JoyHubConfiguration } from "../../../../services/integrationsService";
import PlatformContext from "../../../app/PlatformContext";
import { IntegrationFormProps } from "./common";

const JoyHubForm: React.FC<IntegrationFormProps> = (props) => {
  const { validationError } = props;
  const { superAdmin, overlordDomain } = useContext(PlatformContext).platform!;
  const [joyHubConfig, setJoyHubConfig] = useState<JoyHubConfiguration>({});

  useEffect(() => {
    if (props.configuration && Object.keys(props.configuration).length) {
      const config = props.configuration as JoyHubConfiguration;
      setJoyHubConfig(config);
    }
  }, [props.configuration]);

  function onConfigChange(
    e: ChangeEvent<HTMLInputElement>,
    key: keyof JoyHubConfiguration,
  ) {
    const newConfig = {
      ...joyHubConfig,
      [key]: e.target.value,
    };
    setJoyHubConfig(newConfig);
    props.setConfiguration(newConfig);
  }

  return overlordDomain && superAdmin ? (
    <>
      <FormGroup>
        <Label>API Key</Label>
        <InputGroup>
          <Input
            type="text"
            value={joyHubConfig.secret ?? ""}
            onChange={(e) => onConfigChange(e, "secret")}
            invalid={validationError.field === "secret"}
            autoComplete="off"
            className="secret-blur"
          />
        </InputGroup>
      </FormGroup>
    </>
  ) : null;
};

export default JoyHubForm;
