import { faFilter } from "@fortawesome/pro-light-svg-icons";
import { faFilter as faFilterSolid } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  AccountTreeEntity,
  FullAccountTreeNode,
  PureDate,
  formatGlCode,
} from "@joyhub-integration/shared";
import { orderBy } from "natural-orderby";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { Button, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import axios from "../../services/axios";
import { Property } from "../../services/propertiesService";
import { apiUrl, axiosConfig } from "../../utils/api";
import { toShortMonthAndYear } from "../../utils/date";
import { formattedDollar } from "../../utils/number";
import { usePropertiesSelectionQueryParam } from "../../utils/useQueryParams";
import Loading from "../app/Loading";
import PlatformContext from "../app/PlatformContext";
import withAlertModal, {
  WithAlertModalProps,
} from "../common/alert/withAlertModal";
import UncontrolledModal from "../common/modal/UncontrolledModal";
import { getMonths } from "./AccountTreeView";
import { ByPropertyResponse } from "./types";
import { bookName, formatAmount } from "./utils";

interface AccountByPropertyProps {
  system: number;
  numMonths: number;
  accountTree: AccountTreeEntity;
  book: number;
  treeNode: FullAccountTreeNode;
  propertiesLabel: string;
}

type GlLineItem = {
  transaction_amount: number;
  transaction_date: string;
  transaction_reference: string;
  transaction_description: string;
};

type GlDetails = {
  details: GlLineItem[];
};

type GlDetail = { month: string; property: Property; data?: GlLineItem[] };

const AccountByProperty: React.FC<
  AccountByPropertyProps & WithAlertModalProps
> = ({
  system,
  numMonths,
  accountTree,
  book,
  treeNode,
  propertiesLabel,
  onUnexpectedError,
}) => {
  const [loaded, setLoaded] = useState(false);
  const [totals, setTotals] = useState<Record<number, Record<string, number>>>(
    {},
  );
  const [filtered, setFiltered] = useState(false);
  const [selection] = usePropertiesSelectionQueryParam();
  const { platform } = useContext(PlatformContext);
  const { propertiesMap } = platform!;
  const [detail, setDetail] = useState<GlDetail>();

  const months = useMemo(() => getMonths(numMonths), [numMonths]);
  const from = months[0];
  const to = months[months.length - 1];

  const glAccounts = useMemo(() => {
    const accounts: number[] = [];
    const accumulateGlAccounts = (node: FullAccountTreeNode) => {
      node.children.forEach(accumulateGlAccounts);
      node.accounts.forEach((ac) => accounts.push(ac.gl_account_id));
    };
    accumulateGlAccounts(treeNode);
    return accounts;
  }, [treeNode]);

  useEffect(() => {
    if (detail?.month && !detail.data) {
      axios
        .get(
          apiUrl(
            `/gl/details?account=${glAccounts.join(
              ",",
            )}&system=${system}&book=${book}&property=${
              detail.property.id
            }&month=${detail.month}`,
          ),
          axiosConfig,
        )
        .then((res) => res.data as GlDetails)
        .then(({ details }) => setDetail({ ...detail, data: details }))
        .catch(onUnexpectedError);
    }
  }, [detail, book, glAccounts, system, onUnexpectedError]);

  useEffect(() => {
    const propertiesParam = encodeURIComponent(JSON.stringify(selection ?? {}));
    setLoaded(false);
    axios
      .get(
        apiUrl(
          `/gl/totals/byProperty?account=${glAccounts.join(
            ",",
          )}&system=${system}&book=${book}&properties=${propertiesParam}&from=${from}&to=${to}`,
        ),
        axiosConfig,
      )
      .then((res) => res.data as ByPropertyResponse)
      .then(({ totals }) => {
        const dollars: Record<number, Record<string, number>> = {};
        const sumtotal: Record<string, number> = {};
        dollars[-1] = sumtotal;
        for (const { property_id, post_month, total } of totals) {
          let monthly = dollars[property_id];
          if (monthly === undefined) dollars[property_id] = monthly = {};
          monthly[post_month] = total;
          monthly["Total"] = (monthly["Total"] ?? 0) + total;
          sumtotal[post_month] = (sumtotal[post_month] ?? 0) + total;
          sumtotal["Total"] = (sumtotal["Total"] ?? 0) + total;
        }
        setTotals(dollars);
        setLoaded(true);
      })
      .catch(onUnexpectedError);
  }, [
    system,
    book,
    accountTree,
    selection,
    from,
    to,
    glAccounts,
    onUnexpectedError,
  ]);

  const renderPropertyRow = (property: Property) => {
    const monthly = totals[property.id];
    const hidden =
      filtered &&
      (monthly === undefined || !Object.values(monthly).some((v) => v !== 0));
    return hidden ? null : (
      <tr key={property.id}>
        <th>{property.property_code}</th>
        <th>{property.property_name}</th>
        {months.map((month, i) => (
          <td key={i}>
            <a
              href="#na"
              onClick={(e) => {
                e.preventDefault();
                setDetail({ month, property }); // this should just be a real hyperlink
              }}
            >
              {formatAmount(monthly?.[month] ?? 0)}
            </a>
          </td>
        ))}
        <td>{formatAmount(monthly?.["Total"] ?? 0)}</td>
      </tr>
    );
  };
  const renderTotalRow = () => {
    const monthly = totals[-1];
    return (
      <tr className="total-row">
        <th />
        <th />
        {months.map((month, i) => (
          <td key={i}>{formatAmount(monthly?.[month] ?? 0)}</td>
        ))}
        <td>{formatAmount(monthly?.["Total"] ?? 0)}</td>
      </tr>
    );
  };

  const properties = useMemo(() => {
    const propertyIds = Object.keys(totals)
      .map((key) => parseInt(key, 10))
      .filter((n) => n >= 0);
    return propertyIds.map((id) => propertiesMap[id]);
  }, [totals, propertiesMap]);

  return !loaded ? (
    <Loading />
  ) : (
    <div key={accountTree?.tree_id}>
      {detail ? (
        <UncontrolledModal onClosed={() => setDetail(undefined)} size="lg">
          <ModalHeader>
            {`${toShortMonthAndYear(new PureDate(detail.month))}: ${
              detail.property.property_name
            }`}
          </ModalHeader>
          <ModalBody>
            <dl>
              {detail.data?.map((detail, index) => (
                <React.Fragment key={index}>
                  <dt>
                    {detail.transaction_date}
                    {detail.transaction_reference === null
                      ? ""
                      : `: ${detail.transaction_reference}`}
                    <span className="float-right">
                      {formattedDollar(-detail.transaction_amount)}
                    </span>
                  </dt>
                  <dd>{detail.transaction_description}</dd>
                </React.Fragment>
              ))}
            </dl>
          </ModalBody>
          <ModalFooter>
            <Button onClick={() => setDetail(undefined)}>Close</Button>
          </ModalFooter>
        </UncontrolledModal>
      ) : null}
      <div className="account-tree">
        <div className="print-header">
          <div className="report-properties">
            Properties = {propertiesLabel}
          </div>
          <div className="report-title">
            {formatGlCode(
              treeNode.tree_node_code,
              accountTree.gl_account_format_mask,
            )}{" "}
            – {treeNode.tree_node_name} (by property)
          </div>
          <div className="report-period">
            Period = {toShortMonthAndYear(new PureDate(from))} –{" "}
            {toShortMonthAndYear(new PureDate(to))}
          </div>
          <div className="report-period">Book = {bookName(book)}</div>
        </div>
        <div className="finance-table-wrapper jh-max-height">
          <table className="finance-table">
            <thead>
              <tr>
                <th style={{ width: "3%" }} className="br-0" />
                <th style={{ width: "6%" }} className="br-0" />
                {months.map((month, i) => (
                  <th style={{ width: "7%" }} key={i}>
                    {toShortMonthAndYear(new PureDate(month))}
                  </th>
                ))}
                <th style={{ width: "7%" }}>
                  Total
                  <FontAwesomeIcon
                    icon={filtered ? faFilterSolid : faFilter}
                    size="sm"
                    className={`ms-1 finance-filter`}
                    onClick={() => setFiltered((f) => !f)}
                  />
                </th>
              </tr>
            </thead>
            <tbody>
              {orderBy(properties, (p) => p.property_code, "asc").map(
                renderPropertyRow,
              )}
              {renderTotalRow()}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default withAlertModal(AccountByProperty);
