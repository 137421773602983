import { generateCognitoPassword } from "@joyhub-integration/shared";
import { useEffect, useRef, useState } from "react";
import { Button, Card, CardBody } from "reactstrap";
import { User } from "../../../services/usersService";

interface EmailPreviewProps {
  user: User;
  templateFunction: (id: number, password: string) => Promise<string>;
  passwordCallback: (password: string) => void;
}

const EmailPreview: React.FC<EmailPreviewProps> = ({
  user,
  templateFunction,
  passwordCallback,
}) => {
  const [password, setPassword] = useState<string | undefined>();
  const [showPreview, setShowPreview] = useState<boolean>(false);
  const [emailText, setEmailText] = useState<string>("");
  const cardContents = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (showPreview) {
      const pw = generateCognitoPassword();
      setPassword(pw);
      passwordCallback(pw);
    }
  }, [showPreview, passwordCallback]);

  useEffect(() => {
    //the first render will be shadowed in blur, so you cannot see the placeholder
    templateFunction(user.id, password ?? "place_holder").then((data) =>
      setEmailText(data),
    );
  }, [password, templateFunction, user.id]);

  function copyToClipboard() {
    const range = document.createRange();
    if (cardContents.current) {
      range.selectNode(cardContents.current);
      document.getSelection()?.removeAllRanges();
      document.getSelection()?.addRange(range);

      document.execCommand("copy"); // this is deprecated, and yet, nothing has replaced it
    }
  }

  return (
    <>
      <Card>
        <CardBody
          style={{
            fontSize: "14px",
            ...(!showPreview
              ? {
                  filter: "blur(5px)",
                  "pointer-events": "none",
                }
              : {}),
          }}
        >
          <div
            ref={cardContents}
            dangerouslySetInnerHTML={{ __html: emailText }}
          />
        </CardBody>
        {!showPreview && (
          <Button
            style={{
              position: "absolute",
              left: "50%",
              top: "50%",
              transform: "translate(-50%, -50%)",
            }}
            size="sm"
            onClick={() => setShowPreview(true)}
          >
            Show
          </Button>
        )}
      </Card>
      <div className="mt-2 d-flex">
        <Button
          id="copybtn"
          color="primary"
          disabled={!showPreview}
          onClick={copyToClipboard}
        >
          Copy
        </Button>
      </div>
    </>
  );
};

export default EmailPreview;
