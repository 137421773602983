import React from "react";
import {
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Scatter,
  ScatterChart,
  Tooltip,
  XAxis,
  YAxis,
  ZAxis,
} from "recharts";
import { FrontEndUnit } from "../../../services/insightsService";
import { chartNumberFormatter } from "../../../utils/chartNumberFormatter";

interface JhScatterChartProps {
  xAxisKey: string;
  xAxisKeyUnit: FrontEndUnit;
  data: Object;
  yAxisKey: string;
  yAxisKeyUnit: FrontEndUnit;
  zAxisKey?: string;
  tooltipFormatter?: Function;
  stackId?: string;
}

const JhScatterChart: React.FC<JhScatterChartProps> = (props) => {
  const { xAxisKey, xAxisKeyUnit, data, yAxisKey, yAxisKeyUnit, zAxisKey } =
    props;

  const allScatterCharts = Object.entries(data).map(([key, value]) => {
    return (
      <Scatter
        key={key}
        data={value.data}
        legendType="none"
        fill={value.color}
        fillOpacity={0}
        stroke={value.color}
      />
    );
  });

  function tooltipFormatter(
    value: string | number | (string | number)[],
    axisKey: string,
  ) {
    const unit = axisKey === xAxisKey ? xAxisKeyUnit : yAxisKeyUnit;
    const genericFormatter = (n: number) => n.toLocaleString();
    if (typeof value === "number") {
      return chartNumberFormatter(value, unit, genericFormatter);
    }
    return value;
  }

  return (
    <ResponsiveContainer>
      <ScatterChart>
        <CartesianGrid vertical={false} stroke="#d6d9da" />
        <XAxis type="number" dataKey={xAxisKey} />
        <YAxis
          type="number"
          dataKey={yAxisKey}
          tickFormatter={(v) => chartNumberFormatter(v as number, yAxisKeyUnit)}
        />
        {zAxisKey ? (
          <ZAxis type="category" dataKey={zAxisKey} range={[100, 100]} />
        ) : null}
        <Tooltip formatter={tooltipFormatter} isAnimationActive={false} />
        <Legend />
        {allScatterCharts}
      </ScatterChart>
    </ResponsiveContainer>
  );
};

export default JhScatterChart;
