import {
  GenericReportData,
  GenericReportDefinition,
  PureDate,
} from "@joyhub-integration/shared";
import clsx from "clsx";
import { useContext, useEffect, useMemo, useState } from "react";
import { Link, useSearchParams } from "react-router";
import { Alert, NavItem } from "reactstrap";
import dashboardService from "../../services/dashboardService";
import {
  getActualReport,
  SingleReportResult,
} from "../../services/reportsService";
import {
  asPropertiesQuery,
  usePropertiesSelectionQueryParam,
} from "../../utils/useQueryParams";
import PlatformContext from "../app/PlatformContext";
import withAlertModal, {
  WithAlertModalProps,
} from "../common/alert/withAlertModal";
import { LoadilyFadily } from "../common/allFadily";
import { getReportData } from "../craport/Craport";
import { ModernCrumbar } from "../layout/ModernCrumbar";
import Table from "./table/Table";

const reportKey = "propertyOverview";

const RegionalDirectorDashboard = ({
  setAlert,
  onUnexpectedError,
}: WithAlertModalProps) => {
  const [selection] = usePropertiesSelectionQueryParam();
  const [search] = useSearchParams();
  const { sheet: sheetName, ...params } = Object.fromEntries(search);

  const [info, setInfo] = useState<SingleReportResult>();
  const [loaded, setLoaded] = useState(false);
  const [error, setError] = useState<string>();

  const { person, organization_role } = useContext(PlatformContext).platform!;
  const [data, setData] = useState<GenericReportData<boolean>>();

  const reportDefinition = info?.definition as GenericReportDefinition;
  const sheets = reportDefinition?.sheets ?? [];
  const sheetDefinition = sheets.find((s) => s.name === sheetName) ?? sheets[0];
  const sheet = sheetDefinition?.name;
  const sheetData = data?.sheets?.[0];

  useEffect(() => {
    getActualReport(reportKey)
      .then((report) => {
        setInfo(report);
        dashboardService.viewDashboard(report.id);
        try {
          window.analytics.track("Report Viewed", {
            reportName: report.name,
            reportId: report.id,
            userId: person.id,
          });
        } catch (e) {
          console.error("Unable to fire segment track event", e);
        }
      })
      .catch((err) => {
        if (err?.response?.data?.status === 404) {
          setAlert(`No report found with identifier '${reportKey}'.`, false);
        } else {
          onUnexpectedError(err);
        }
      });
  }, [setAlert, onUnexpectedError, person]);

  const viewConfiguration = useMemo(() => {
    const asOf = new PureDate();
    return { properties: selection ?? {}, asOf };
  }, [selection]);

  useEffect(() => {
    setData(undefined);
    setError(undefined);
    if (sheet != null) {
      setLoaded(false);
      getReportData(reportKey, { sheet, ...viewConfiguration })
        .then((data) => {
          setData(data);
          setLoaded(true);
        })
        .catch(() => {
          setError(`Error loading report: ${reportKey} (${sheet})`);
        });
    }
  }, [sheet, viewConfiguration, setData]);

  return (
    <>
      <ModernCrumbar>
        <ol className="navbar-nav pe-2">
          {sheets.map((sheet, index) => {
            const active = sheet.name === sheetData?.name;
            return (
              <NavItem key={index}>
                <Link
                  to={{
                    search: new URLSearchParams(
                      index === 0
                        ? params
                        : {
                            ...params,
                            sheet: sheet.name,
                          },
                    ).toString(),
                  }}
                  className={clsx("nav-link text-truncate", active && "active")}
                >
                  {sheet.name}
                </Link>
              </NavItem>
            );
          })}
        </ol>

        {(organization_role == "Administrator" ||
          organization_role == "Editor") &&
        info &&
        !info.immutable ? (
          <Link
            className="btn btn-primary btn-sm ms-auto"
            to={`/reports/${info.shared ? "library" : "my"}/${
              info.id
            }/edit?${asPropertiesQuery(selection)}`}
            id="edit-report"
          >
            Edit Report
          </Link>
        ) : null}
      </ModernCrumbar>
      <div className="action-bar-padding" />
      <div className="jh-page-layout mx-4 report-page regional-director-page">
        {error && <Alert color="danger">{error}</Alert>}

        <LoadilyFadily loaded={loaded || error !== undefined}>
          {sheetData && <Table data={sheetData} />}
        </LoadilyFadily>
      </div>
    </>
  );
};

export default withAlertModal(RegionalDirectorDashboard);
