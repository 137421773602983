import { enumify, numericEnumValues } from "./util/enum";

export const SystemVendor = enumify(
  "Entrata",
  "Fortress",
  "JoyHub",
  "Radix",
  "ResMan",
  "Manual",
  "Metadata",
  "RealPage",
  "S3Replication",
  "Yardi",
);
export type SystemVendor = typeof SystemVendor.type;

export const Application = enumify("BI", "PC", "DV");
export type Application = typeof Application.type;

export const Theme = enumify("dark", "light");
export type Theme = typeof Theme.type;

export enum ClientTier {
  Prospect,
  Basic,
  Enterprise,
  New, // Default
  Other, // Ignored
}
export const clientTiers = numericEnumValues(ClientTier);

export enum Model {
  Legacy,
  Common,
}
