import { DashboardKindEnum } from "@joyhub-integration/shared";
import { find } from "lodash";
import { useEffect, useState } from "react";
import { FormFeedback, FormGroup, Label } from "reactstrap";
import { getReports } from "../../../../../services/reportsService";
import { ValidationError } from "../../../../admin/integrations/addEditIntegrationModal/common";
import JhSelect, { IDropListItem } from "../../../../common/JhSelect/JhSelect";
import { reportToOption } from "../../utils";

interface ReportFieldPropsType {
  description?: string;
  linkedReport?: string;
  validationError: ValidationError;
  updateReport: (report: string) => void;
}

export const ReportField = (props: ReportFieldPropsType) => {
  const {
    description = "Select a report",
    linkedReport,
    validationError,
    updateReport,
  } = props;

  const [reportOptions, setReportOptions] = useState<IDropListItem[]>();
  const [reportOption, setReportOption] = useState<IDropListItem>();

  useEffect(() => {
    const fetchReports = async () => {
      const reports = await getReports();
      const genericReports = reports
        .filter((report) => report.kind === DashboardKindEnum.GenericReport)
        .map(reportToOption);

      setReportOptions([{ label: "No Report", value: "" }, ...genericReports]);
    };

    fetchReports();
  }, []);

  useEffect(() => {
    const selectedReport = find(
      reportOptions,
      (report) => report.value === (linkedReport ?? ""),
    );

    setReportOption(selectedReport);
  }, [linkedReport, reportOptions]);

  const isInvalid = validationError.field === "report";

  return (
    <FormGroup>
      <Label>{description}</Label>
      <JhSelect
        value={reportOption}
        options={reportOptions}
        onValueUpdate={(value) => updateReport(value.value)}
        isMulti={false}
        pointy
        invalid={isInvalid}
      />
      {isInvalid && validationError.message && (
        <FormFeedback className="jh-block">
          {validationError.message}
        </FormFeedback>
      )}
    </FormGroup>
  );
};
