import { IconProp, SizeProp } from "@fortawesome/fontawesome-svg-core";
import React from "react";
import { Button, ButtonProps } from "reactstrap";
import IconWithText from "../icon/IconWithText";

interface ButtonWithIconAndTextProps extends ButtonProps {
  icon: IconProp;
  text: string;
  color?: string;
  textClassName?: string;
  iconSize?: SizeProp;
  iconClassName?: string;
  align?: "start" | "center";
}

const ButtonWithIconAndText: React.FC<ButtonWithIconAndTextProps> = (props) => {
  const {
    style,
    icon,
    text,
    iconSize,
    iconClassName,
    textClassName,
    align,
    children,
    ...buttonProps
  } = props;
  const buttonStyle = style || undefined;
  const textColorClass =
    props.color === "success"
      ? "jh-font-color-success"
      : props.color === "danger"
        ? "jh-font-color-danger"
        : "";
  const textClass = textClassName
    ? `${textColorClass} ${textClassName}`
    : textColorClass;
  return (
    <Button style={buttonStyle} {...buttonProps}>
      <IconWithText
        icon={icon}
        text={text}
        textClassName={textClass}
        iconClassName={iconClassName}
        iconSize={iconSize}
        align={align}
      >
        {children}
      </IconWithText>
    </Button>
  );
};

export default ButtonWithIconAndText;
