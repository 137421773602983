import React, { useEffect, useState } from "react";
import CalculateInsightWorker from "../../../services/calculateInsightWorker";
import { InstantInsights } from "../../../services/dataService";
import insightDefinitions from "../../../services/insightLibrary/insightDefinitions";
import insightLibraryService from "../../../services/insightLibrary/insightsLibraryService";
import { KnownInsight as BackendInsight } from "../../../services/insightsService";
import { InsightVisualizationType } from "../../../services/models";

// Create new instance
const calculateInsightWorker = new CalculateInsightWorker();

interface NumberChartProps {
  insightId: number;
  vizType: InsightVisualizationType;
  instantValuesNow: InstantInsights;
  instantValuesAYearAgo: InstantInsights;
  insightsMap: { [id: string]: BackendInsight };
}

const NumberChart: React.FC<NumberChartProps> = (props) => {
  const {
    insightId,
    vizType,
    instantValuesNow,
    instantValuesAYearAgo,
    insightsMap,
  } = props;
  const [className, setClassName] = useState<string>();
  const [value, setValue] = useState<string>();
  useEffect(() => {
    const insight = insightDefinitions.find((i) => i.id === insightId);
    const numOrPercentage: "NUMBER" | "PERCENTAGE" =
      vizType === "NUMBER"
        ? "NUMBER"
        : vizType === "PERCENTAGE"
          ? "PERCENTAGE"
          : insight?.visualizationType.includes("NUMBER")
            ? "NUMBER"
            : "PERCENTAGE";

    calculateInsightWorker
      .calculateInstantInsightValue(
        insightLibraryService.getAllChildInsightIds(insightId),
        numOrPercentage,
        instantValuesNow.overall,
        instantValuesAYearAgo.overall,
        insightsMap,
        insight?.delta,
        vizType === "YOY CHANGE",
      )
      .then((value) => {
        if (insight?.delta || vizType === "YOY CHANGE") {
          const positive =
            insight?.positive === true
              ? "insight-positive"
              : insight?.positive === false
                ? "insight-negative"
                : "";
          const trend = value.startsWith("+")
            ? "trend-positive"
            : value.startsWith("-")
              ? "trend-negative"
              : "trend-zero";
          setClassName(`${positive} ${trend}`);
        }
        setValue(value);
      });
  }, [
    insightId,
    vizType,
    instantValuesNow,
    instantValuesAYearAgo,
    insightsMap,
  ]);
  if (!value) return null;
  return (
    <div className={`dashboard-number-insight ${className}`}>
      <strong>{value}</strong>
    </div>
  );
};

export default NumberChart;
