import preval from "preval.macro";
import React from "react";
import { Button } from "reactstrap";
import { ReactComponent as Logo } from "../../images/logo-h.svg";
import { setSessionOrganization } from "../../services/sessionOrganization";
import { loginUrl, setSessionReturnUrl } from "../../services/sessionService";
import { prId } from "../../utils/api";
import "./session.css";

export const buildDate = preval`module.exports = new Date().toISOString().substring(0, 10)`;
export const commitId = (
  process.env.REACT_APP_AWS_COMMIT_ID || "0123456789ABCDEF"
).substring(0, 16);
export const branchName = process.env.REACT_APP_AWS_BRANCH;

// uses document.location.replace to avoid the "login" page being in your history.

const Login: React.FC<{ loggedOut?: boolean }> = ({ loggedOut }) => {
  const onClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    const { pathname, search } = document.location;
    setSessionOrganization(null);
    setSessionReturnUrl(`${pathname}${search}`);
    document.location.replace(loginUrl());
  };
  return (
    <a className="session-container" href={loginUrl()} onClick={onClick}>
      <div className="session-messages">
        <Logo id="session-logo" title="Revolution RE" />
        <div className="session-stuff">
          {loggedOut && (
            <div className="session-message">
              You have successfully logged out.
            </div>
          )}
          <div
            style={{
              position: "fixed",
              left: "0",
              bottom: "2rem",
              width: "100vw",
              fontSize: "0.85rem",
            }}
          >
            {buildDate}
            {prId != null ? ` | ${branchName}` : null}
            {` | ${commitId}`}
          </div>
          <Button color="primary" className="p-2 w-50 login-button">
            {loggedOut ? "Log in again" : "Log in"}
          </Button>
        </div>
      </div>
    </a>
  );
};

export default Login;
