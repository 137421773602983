import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router";

import PlatformContext from "../components/app/PlatformContext";

export const usePropertyId = () => {
  const { propertyId: propertyIdParam } = useParams();
  const { platform } = useContext(PlatformContext);

  const [propertyId, setPropertyId] = useState<number | undefined>();

  const { embedInfo } = platform!;

  useEffect(() => {
    if (!platform) return;

    const propertyIdValue = embedInfo
      ? (embedInfo.propertyId ?? undefined)
      : propertyIdParam
        ? parseInt(propertyIdParam)
        : undefined;

    setPropertyId(propertyIdValue);
  }, [platform, embedInfo, propertyIdParam, setPropertyId]);

  return {
    propertyId,
    isPropertyDashboard: !!propertyId,
  };
};
