import { find } from "lodash";
import {
  Area,
  Bar,
  ComposedChart,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { DateString } from "../../../services/dataService";

export type BarGraphDatum<K extends string> = {
  date: DateString;
  target?: number;
} & Record<K, number | undefined>;

export type BarGraphData<K extends string> = BarGraphDatum<K>[];

const fills = ["#3a4875", "#0575d6"];

const BarChart = <K extends string>({
  keys,
  labels,
  data,
  formatter = (number) => number.toLocaleString(),
  yFormatter,
  dateFormat,
}: {
  keys: K[];
  labels: Record<K, string>;
  data: BarGraphData<K>;
  formatter?: (value: number) => string;
  yFormatter?: (value: number) => string;
  dateFormat: Intl.DateTimeFormatOptions;
}) => {
  const dateFormatter = (date: DateString) =>
    new Date(date).toLocaleString("en-US", dateFormat);

  return (
    <ResponsiveContainer minHeight={250}>
      <ComposedChart
        data={data}
        margin={{
          top: 0,
          right: 10,
          left: 0,
          bottom: 0,
        }}
      >
        <XAxis
          dataKey="date"
          tickFormatter={dateFormatter}
          axisLine={false}
          tickLine={false}
        />
        <YAxis
          domain={[(dataMin: number) => Math.max(0, dataMin - 0.1), 1]}
          tickFormatter={yFormatter ?? formatter}
          axisLine={false}
          tickLine={false}
        />
        <Tooltip formatter={formatter} />
        <Legend align="right" verticalAlign="bottom" />
        {keys.map((key, idx) => (
          <Bar key={key} dataKey={key} name={labels[key]} fill={fills[idx]} />
        ))}
        {find(data, (d) => d.target) && (
          <Area
            dataKey="target"
            name="Goal"
            strokeDasharray="8 8"
            strokeWidth="4"
            stroke="#111111"
          />
        )}
      </ComposedChart>
    </ResponsiveContainer>
  );
};

export default BarChart;
