import {
  CommonAccountMapping,
  ComputeHistoryEntity,
  uriEncodeWhereDefined,
} from "@joyhub-integration/shared";
import { apiUrl, axiosConfig, axiosJsonConfig, idSuffix } from "../utils/api";
import axios from "./axios";

export const FTPProtocols = ["FTP", "FTPS", "SFTP"] as const;

export type FTPProtocol = (typeof FTPProtocols)[number];

// these don't belong in configuration but modeling them so is expedient
export interface BaseConfiguration {
  secret?: string;
  client?: number;
}

export interface EntrataConfiguration extends BaseConfiguration {
  domain: string;
  username: string;
  ignorePmsFinancials?: boolean;
}

export interface ResManConfiguration extends BaseConfiguration {
  accountId: string;
  username: string;
}

export interface FortressConfiguration extends BaseConfiguration {}

export interface ManualConfiguration extends BaseConfiguration {
  financials?: string;
}

export interface JoyHubConfiguration extends BaseConfiguration {}

export const RealPageSyncTypes = ["ftp", "ws"] as const;

export type RealPageSyncType = (typeof RealPageSyncTypes)[number];

export interface RealPageFTPConfiguration extends BaseConfiguration {
  type: RealPageSyncType;
  server: string;
  username: string;
  protocol: FTPProtocol;
  path: string;
  delete: boolean;
  ignoreCsv: boolean;
}

export interface RealPageWSConfiguration extends BaseConfiguration {
  type: RealPageSyncType;
  server: string;
  username: string;
  protocol: FTPProtocol;
  pmcId: string;
  siteId: string;
  groupName: string;
}

export type RealPageConfiguration =
  | RealPageFTPConfiguration
  | RealPageWSConfiguration;

export interface YardiConfiguration extends BaseConfiguration {
  protocol: FTPProtocol;
  server: string;
  username: string;
  path: string;
  encrypted?: boolean;
  certificate?: string;
  privateKey?: string;
  decryptionPassword?: string;
  propListIds?: string;
  ebsVolumeSize?: number;
  ignorePmsFinancials?: boolean;
  useGlTotal?: boolean;
}

export const IntegrationVendors = [
  "Entrata",
  "Fortress",
  "JoyHub",
  "RealPage",
  "ResMan",
  "Yardi",
] as const;

export type IntegrationVendor = (typeof IntegrationVendors)[number];

export type IntegrationConfiguration =
  | BaseConfiguration
  | EntrataConfiguration
  | RealPageConfiguration
  | ResManConfiguration
  | YardiConfiguration
  | ManualConfiguration;

export type GlBook = "cash" | "accrual";

export type GlTreeCode = string;
export type GlAccountCode = string;

export type FinancialAccounts = Record<string, GlAccountCode[]>;

export type ActualConfig = {
  tree: GlTreeCode;
  book: GlBook;
  accounts: FinancialAccounts;
};

export type BudgetConfig = {
  tree: GlTreeCode;
  accounts: FinancialAccounts;
};

export type InsightConfig = {
  actual?: ActualConfig;
  budget?: BudgetConfig;
  common?: CommonAccountMapping;
  commonFinancials?: boolean;
};

export interface Integration {
  id: number;
  organization_id: number;
  vendor: IntegrationVendor;
  name: string;
  configuration: IntegrationConfiguration;
  target_organization_id?: number;
  active: boolean;
  last_sync?: SyncHistoryEntity;
  last_computation?: ComputeHistoryEntity;
  organization_name: string;
  insight_config: InsightConfig;
}

export interface IntegrationDto {
  name: string;
  vendor: IntegrationVendor;
  configuration: IntegrationConfiguration;
  secret?: string;
  target_organization_id?: number;
  active: boolean;
}

export interface TestIntegrationDto extends IntegrationDto {
  id?: number;
}

export interface TestIntegrationResponse {
  success: boolean;
  detail: string[];
}

export interface TransferUser {
  id: number;
  username: string;
  email: string;
  password?: string;
  kind?: string;
}

export async function getIntegrations(): Promise<Array<Integration>> {
  return axios
    .get(apiUrl("/systems"), axiosConfig)
    .then((res) => res.data.systems as Array<Integration>);
}

export async function getIntegrationById(id: number): Promise<Integration> {
  return axios
    .get(apiUrl(`/systems/${id}`), axiosConfig)
    .then((res) => res.data as Integration);
}

export async function createIntegration(
  integrationDto: IntegrationDto,
  syncOnSave: boolean = true,
): Promise<Integration> {
  return axios
    .post(
      apiUrl("/systems"),
      { config: integrationDto, syncOnSave },
      axiosConfig,
    )
    .then((res) => res.data as Integration);
}

export async function testIntegration(
  integrationDto: TestIntegrationDto,
): Promise<TestIntegrationResponse> {
  return axios
    .post(apiUrl("/systems/test"), integrationDto, axiosConfig)
    .then((res) => res.data as TestIntegrationResponse);
}

export async function editIntegration(
  id: number,
  integrationDto: IntegrationDto,
): Promise<Integration> {
  return axios
    .put(apiUrl(`/systems/${id}`), integrationDto, axiosConfig)
    .then((res) => res.data as Integration);
}

export async function setIntegrationStatus(
  id: number,
  active: boolean,
): Promise<boolean> {
  return axios
    .put(apiUrl(`/systems/${id}/active`), active.valueOf(), axiosJsonConfig)
    .then((res) => res.data as boolean);
}

export async function deleteIntegration(id: number): Promise<void> {
  return axios.delete(apiUrl(`/systems/${id}`), axiosConfig);
}

export async function getTransferUsers(
  integration: number,
): Promise<TransferUser[]> {
  return axios
    .get<{
      transferUsers: TransferUser[];
    }>(apiUrl(`/systems/${integration}/transferUsers`), axiosConfig)
    .then((res) => res.data.transferUsers);
}

export async function deleteTransferUser(
  integration: number,
  id: number,
): Promise<void> {
  return axios.delete(
    apiUrl(`/systems/${integration}/transferUsers/${id}`),
    axiosConfig,
  );
}

export async function createTransferUser(
  integration: number,
  username: string,
  kind: string,
  password: boolean,
): Promise<TransferUser> {
  return axios
    .post(
      apiUrl(`/systems/${integration}/transferUsers`),
      { username, kind, password },
      axiosConfig,
    )
    .then((res) => res.data as TransferUser);
}

export async function resetTransferUserPassword(
  integration: number,
  id: number,
): Promise<TransferUser> {
  return axios
    .post(
      apiUrl(`/systems/${integration}/transferUsers/${id}/resetPassword`),
      {},
      axiosConfig,
    )
    .then((res) => res.data as TransferUser);
}

export const transferUserKinds: Record<string, string> = {
  "": "Default",
  financial: "Financials",
  insights: "Insights",
};

const syncHistoryUrl = (system_id: number, sync_id?: number) =>
  apiUrl(`/systems/${system_id}/syncHistory${idSuffix(sync_id)}`);

export type SyncHistoryEntity = {
  id: number;
  system_id: number;
  cause_id: number;
  data_date: string;
  started: string;
  updated: string | null;
  finished: string | null;
  success_count: number;
  failure_count: number;
  file_count: number;
  file_success_count: number;
  file_failure_count: number;
};

export type GetSyncHistoryResult = {
  syncHistory: SyncHistoryEntity[];
};

export async function getSyncHistory(
  system_id: number,
): Promise<Array<SyncHistoryEntity>> {
  return axios
    .get<GetSyncHistoryResult>(syncHistoryUrl(system_id), axiosConfig)
    .then((res) => res.data.syncHistory);
}

const computeHistoryUrl = (system_id: number) =>
  apiUrl(`/systems/${system_id}/computeHistory`);

export type GetComputeHistoryResult = {
  computeHistory: ComputeHistoryEntity<true>[];
};

// before is epoch ms, as extracted from Date.
export async function getComputeHistory(
  system_id: number,
  before: number | undefined,
  offset: number,
  limit: number,
): Promise<Array<ComputeHistoryEntity<true>>> {
  return axios
    .get<GetComputeHistoryResult>(
      uriEncodeWhereDefined(computeHistoryUrl(system_id), {
        before,
        offset,
        limit,
      }),
      axiosConfig,
    )
    .then((res) => res.data.computeHistory);
}

export type ImportFileEntity = {
  id: number;
  system_id: number;
  path: string;
  size: number | null;
  created: string;
  sync_history_id: number | null;
};

export type ImportHistoryEntity = {
  id: number;
  import_file_id: number;
  started: string;
  finished: string | null;
  success: boolean | null;
  reason: string | null;
  logs: any | null;
  skipped: boolean | null;
};

export type SyncFilesEntity = ImportFileEntity & {
  importHistory: ImportHistoryEntity[];
  success?: never; // to satisfy the types..
  duration?: never;
  reason?: never;
};

export type ImportHistoriesResponse = {
  importFile: ImportFileEntity;
  histories: ImportHistoryEntity[];
};

export type GetSyncFilesResult = {
  importFiles: SyncFilesEntity[];
};

export const syncFileUrl = (
  system_id: number,
  sync_id: number,
  file_id?: number,
) => `${syncHistoryUrl(system_id, sync_id)}/importFiles${idSuffix(file_id)}`;

export const getImportHistoryById = (
  systemId: number,
  syncId: number,
  importHistoryId: number,
): Promise<ImportHistoryEntity> => {
  return axios
    .get(
      `${syncHistoryUrl(systemId, syncId)}/importHistory/${importHistoryId}`,
      axiosConfig,
    )
    .then((res) => res.data);
};

export const getImportHistoriesByImportFileId = (
  systemId: number,
  syncId: number,
  importFileId: number,
): Promise<ImportHistoriesResponse> => {
  return axios
    .get(
      `${syncHistoryUrl(
        systemId,
        syncId,
      )}/importFiles/${importFileId}/importHistories`,
      axiosConfig,
    )
    .then((res) => res.data);
};

export async function getSyncFiles(
  system_id: number,
  sync_id: number,
  offset: number,
  limit: number,
): Promise<Array<SyncFilesEntity>> {
  return axios
    .get<GetSyncFilesResult>(
      `${syncFileUrl(system_id, sync_id)}?offset=${offset}&limit=${limit}`,
      axiosConfig,
    )
    .then((res) => res.data.importFiles);
}

export const syncDownloadUrl = (system_id: number, sync_id: number) =>
  apiUrl(`/systems/${system_id}/syncHistory/${sync_id}/download`);

export async function reingestSyncFiles(
  system_id: number,
  sync_id: number,
): Promise<any> {
  return axios.post(
    `${syncHistoryUrl(system_id, sync_id)}/reingest`,
    {},
    axiosConfig,
  );
}

export async function ingestSyncFile(
  system_id: number,
  sync_id: number,
  file_id: number,
): Promise<any> {
  return axios.post(
    `${syncFileUrl(system_id, sync_id, file_id)}/ingest`,
    {},
    axiosConfig,
  );
}

export async function putSystemInsightConfig(
  system_id: number,
  config: InsightConfig,
) {
  return axios
    .put<InsightConfig>(
      apiUrl(`/systems/${system_id}/insight_config`),
      config,
      axiosJsonConfig,
    )
    .then((res) => res.data);
}

export async function parseCommonMapping(
  structure: string,
): Promise<{ mapping: CommonAccountMapping }> {
  return axios
    .post(apiUrl(`/gl/common/mapping`), { structure }, axiosConfig)
    .then((res) => res.data);
}
