import { CustomColumn, PropertyColumnKey } from "@joyhub-integration/shared";
import React, { useState } from "react";
import { getNextCustomColumn } from "../../../utils/customColumns";
import EditColumnListItem from "./EditColumnListItem";

type ListItemProps = {
  columns: CustomColumn[];
  column: CustomColumn;
  index: number;
  defaultDisplayTitle: string;
  handleEdit: (index: number, c: CustomColumn) => void;
  handleMove: (to: number, from: number) => void;
  handleRemove: (index: number) => void;
  setEditing: (index: number | null) => void;
  editingIndex: number | null;
  numOfCustomTextColumns: number;
  numOfCustomNumberColumns: number;
  numOfCustomPercentageColumns: number;
  numOfCustomDateColumns: number;
  numOfCustomYearColumns: number;
  numOfCustomDollarColumns: number;
};

const CustomColumnListItem: React.FC<ListItemProps> = ({
  columns,
  column,
  index,
  defaultDisplayTitle,
  handleEdit,
  handleMove,
  handleRemove,
  setEditing,
  editingIndex,
  numOfCustomTextColumns,
  numOfCustomNumberColumns,
  numOfCustomPercentageColumns,
  numOfCustomDateColumns,
  numOfCustomYearColumns,
  numOfCustomDollarColumns,
}) => {
  const [columnKey, setColumnKey] = useState<PropertyColumnKey>(
    column.columnKey,
  );
  const [dataType, setDataType] = useState(column.dataType);

  const autoCompleteOptions =
    defaultDisplayTitle.trim() !== ""
      ? [
          {
            label: defaultDisplayTitle,
            value: defaultDisplayTitle,
          },
        ]
      : [];

  return (
    <EditColumnListItem
      key={`colitem-${index}`}
      column={column}
      dataType={dataType}
      columnKey={columnKey}
      columnName={column.name}
      index={index}
      handleEdit={handleEdit}
      handleMove={handleMove}
      handleRemove={handleRemove}
      setEditing={setEditing}
      editingIndex={editingIndex}
      textDisabled={numOfCustomTextColumns >= 4}
      numberDisabled={numOfCustomNumberColumns >= 4}
      percentageDisabled={numOfCustomPercentageColumns >= 4}
      dateDisabled={numOfCustomDateColumns >= 4}
      yearDisabled={numOfCustomYearColumns >= 4}
      dollarDisabled={numOfCustomDollarColumns >= 4}
      handleDataTypeSelected={(type) => {
        const colKey = getNextCustomColumn(columns, type)?.[1];
        if (colKey) setColumnKey(colKey);
        setDataType(type);
      }}
      autoCompleteOptions={autoCompleteOptions}
    ></EditColumnListItem>
  );
};

export default CustomColumnListItem;
