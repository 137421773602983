import { NonGroupPropertiesSelection } from "@joyhub-integration/shared";
import axios from "axios";
import { apiUrl, axiosConfig } from "../../utils/api";

/**
 * This provides services for the /properties page, and is distinct from propertyService.ts
 */

export interface PropertyGroupDto {
  name: string;
  selection: NonGroupPropertiesSelection;
  sharedWithOrg: boolean;
}

export interface PropertyGroup {
  id: number;
  organization_id: number;
  name: string;
  selection: NonGroupPropertiesSelection;
  shared_with_org: boolean;
  creator_id: number;
}

const propertyGroupService = {
  createPropertyGroup(
    propertyGroupDto: PropertyGroupDto,
  ): Promise<PropertyGroup> {
    return axios
      .post<PropertyGroup>(
        apiUrl("/propertyGroups"),
        propertyGroupDto,
        axiosConfig,
      )
      .then((res) => {
        return res.data;
      });
  },

  getPropertyGroups(): Promise<Array<PropertyGroup>> {
    return axios
      .get<Array<PropertyGroup>>(apiUrl("/propertyGroups"), axiosConfig)
      .then((res) => {
        return res.data;
      });
  },

  updatePropertyGroup(
    propertyGroupId: number,
    propertyGroupDto: PropertyGroupDto,
  ): Promise<PropertyGroup> {
    return axios
      .put(
        apiUrl(`/propertyGroups/${propertyGroupId}`),
        propertyGroupDto,
        axiosConfig,
      )
      .then((res) => res.data as PropertyGroup);
  },

  deletePropertyGroup(propertyGroupId: number): Promise<void> {
    return axios.delete(
      apiUrl(`/propertyGroups/${propertyGroupId}`),
      axiosConfig,
    );
  },
};

export default propertyGroupService;
