import { faRedo, faWaveform } from "@fortawesome/pro-light-svg-icons";
import { dateStr, durationOf } from "@joyhub-integration/shared";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import {
  getImportHistoriesByImportFileId,
  ImportFileEntity,
  ImportHistoryEntity,
} from "../../../services/integrationsService";
import { dateOf } from "../../../utils/date";
import { useOnUnexpectedError } from "../../common/alert/withAlertModal";
import { LoadilyFadily } from "../../common/allFadily";
import ActionBar from "../../common/button/ActionBar";
import { ButtonWithIconProps } from "../../common/button/ButtonWithIcon";
import TableWithSelection, {
  KeyValue,
} from "../../common/table/TableWithSelection";
import { ModernCrumbar } from "../../layout/ModernCrumbar";
import ImportHistoryLogsModal from "./ImportHistoryLogsModal";

export type ImportHistoryPageParams = {
  systemId: string;
  syncId: string;
  importFileId: string;
};

const ImportHistoryPage: React.FC = () => {
  const params = useParams<ImportHistoryPageParams>();
  const systemId = parseInt(params.systemId!);
  const syncId = parseInt(params.syncId!);
  const importFileId = parseInt(params.importFileId!);

  const onUnexpectedError = useOnUnexpectedError();
  const [importFile, setImportFile] = useState<ImportFileEntity | null>(null);
  const [histories, setHistories] = useState<ImportHistoryEntity[]>([]);
  const [selected, setSelected] = useState<ImportHistoryEntity>();
  const [loaded, setLoaded] = useState(false);
  const [loading, setLoading] = useState(false);
  const [viewingLog, setViewingLog] = useState(false);
  const [refresh, setRefresh] = useState(0);

  useEffect(() => {
    setLoading(true);
    getImportHistoriesByImportFileId(systemId, syncId, importFileId)
      .then(({ importFile, histories }) => {
        setImportFile(importFile);
        setHistories(histories);
      })
      .catch(onUnexpectedError)
      .finally(() => {
        setLoading(false);
        setLoaded(true);
      });
  }, [systemId, syncId, importFileId, onUnexpectedError, refresh]);

  const buttonProps: ButtonWithIconProps[] = [
    {
      icon: faWaveform,
      disabled: !selected,
      label: "View Log",
      onClick: () => setViewingLog(true),
    },
  ];

  const rightButtonProps: ButtonWithIconProps[] = [
    {
      icon: faRedo,
      outline: true,
      tooltip: "Refresh",
      disabled: loading,
      onClick: () => setRefresh((r) => r + 1),
    },
  ];

  const tableCols: Array<KeyValue<ImportHistoryEntity>> = [
    {
      key: "started",
      title: "Started",
      toValue: (s) => dateStr(s.started),
      sortValue: (s) => dateOf(s.started)?.getTime(),
    },
    {
      key: "success",
      title: "Result",
      toValue: (s) =>
        s.success === true ? "Success" : s.success === false ? "Failure" : "-",
    },
    {
      key: "duration",
      title: "Duration",
      toValue: (s) => durationOf(s.started, s.finished),
      sortValue: (s) => {
        const start = dateOf(s.started).getTime();
        const finish = dateOf(s.finished)?.getTime();
        return start == null || finish == null ? undefined : finish - start;
      },
    },
  ];

  return (
    <>
      <ModernCrumbar
        primary="File List"
        primaryPath={`/admin/integrations/${systemId}/files/${syncId}`}
        secondary={`Import History for ${importFile?.path?.replace(
          /.*\//,
          "",
        )}`}
      />
      <LoadilyFadily loaded={loaded} className="jh-page-layout">
        <ActionBar
          buttonProps={buttonProps}
          rightButtonProps={rightButtonProps}
        />
        <div className="jh-page-content">
          <TableWithSelection<ImportHistoryEntity>
            selected={selected}
            onSelectedChange={(s) => setSelected(s)}
            columns={tableCols}
            rows={histories}
            sortColumn="started"
            sortDirection="desc"
          />
        </div>
        {viewingLog && selected ? (
          <ImportHistoryLogsModal
            onClose={() => setViewingLog(false)}
            systemId={systemId}
            syncId={syncId}
            importHistoryId={selected.id}
          />
        ) : null}
      </LoadilyFadily>
    </>
  );
};

export default ImportHistoryPage;
