import {
  AccountTreeEntity,
  CommonAccountMapping,
} from "@joyhub-integration/shared";
import { sortBy } from "lodash";
import React, { useEffect, useState } from "react";
import {
  Button,
  Input,
  Label,
  ModalBody,
  ModalFooter,
  ModalHeader,
  NavItem,
  NavLink,
} from "reactstrap";
import {
  ActualConfig,
  BudgetConfig,
  GlBook,
  GlTreeCode,
  Integration,
  putSystemInsightConfig,
} from "../../../services/integrationsService";
import {
  useOnUnexpectedError,
  useSetAlert,
} from "../../common/alert/withAlertModal";
import UncontrolledModal from "../../common/modal/UncontrolledModal";
import { getGlTrees } from "../../finance/financeService";
import CommonAccountsSection from "./CommonAccountsSection";
import { getFinancialAccounts } from "./financialAccounts";
import FinancialAccountsSection from "./FinancialAccountsSection";

interface FinancialAccountsModalProps {
  system: Integration;
  onClose: () => void;
  onSubmit: () => void;
}

type MaybeTree = {
  tree?: GlTreeCode;
};

const FinancialAccountsModal: React.FC<FinancialAccountsModalProps> = ({
  system,
  onClose,
  onSubmit,
}) => {
  const [accountTrees, setAccountTrees] = useState<AccountTreeEntity[]>([]);
  const [accountNames, setAccountNames] = useState<Array<[string, string]>>([]);
  const [submitting, setSubmitting] = useState(false);
  const [actual, setActual] = useState<Omit<ActualConfig, "tree"> & MaybeTree>(
    system.insight_config.actual ?? { book: "accrual", accounts: {} },
  );
  const [budget, setBudget] = useState<Omit<BudgetConfig, "tree"> & MaybeTree>(
    system.insight_config.budget ?? { accounts: {} },
  );
  const [common, setCommon] = useState<CommonAccountMapping>(
    system.insight_config.common ?? {},
  );
  const [commonly, setCommonly] = useState<boolean>(
    !!system.insight_config.commonFinancials,
  );
  const [mode, setMode] = useState<"budget" | "actual" | "common">(
    commonly ? "common" : "actual",
  );
  const onUnexpectedError = useOnUnexpectedError();
  const setAlert = useSetAlert();

  useEffect(() => {
    getGlTrees(system.id)
      .then((trees) => setAccountTrees(trees))
      .catch(onUnexpectedError);
  }, [system.id, onUnexpectedError]);

  useEffect(() => {
    getFinancialAccounts()
      .then((accounts) => {
        setAccountNames(
          sortBy(Object.entries(accounts), ([, { name }]) => name).map(
            ([key, { name }]) => [key, name],
          ),
        );
      })
      .catch(onUnexpectedError);
  }, [onUnexpectedError]);

  const onFormSubmit: React.FormEventHandler = (e) => {
    e.preventDefault();
    setSubmitting(true);
    const { tree: actualTree, ...actualRest } = actual;
    const { tree: budgetTree, ...budgetRest } = budget;
    const config = {
      actual:
        actualTree == null ? undefined : { tree: actualTree, ...actualRest },
      budget:
        budgetTree == null ? undefined : { tree: budgetTree, ...budgetRest },
      common: common,
      commonFinancials: commonly,
    };
    putSystemInsightConfig(system.id, config)
      .then((res) => {
        if (res != null)
          setAlert("Insight recomputation has been initiated.", true);
        onSubmit();
      })
      .catch((e) => {
        setSubmitting(false);
        onUnexpectedError(e);
      });
  };

  const switchMode =
    (mode: "budget" | "actual" | "common") => (e: React.MouseEvent) => {
      e.preventDefault();
      setMode(mode);
    };

  return (
    <UncontrolledModal
      size="xl"
      onClosed={onClose}
      onFormSubmit={onFormSubmit}
      fullscreen
    >
      <ModalHeader toggle={onClose} style={{ borderBottom: "none" }}>
        <div
          className="flex-row"
          style={{ alignItems: "center", justifyContent: "center" }}
        >
          Financial Accounts – {system.name}
        </div>
      </ModalHeader>
      <ModalBody style={{ overflow: "auto" }}>
        <ul className="nav nav-tabs" style={{ margin: "-1rem -1rem 1rem" }}>
          <NavItem style={{ width: "1.5rem" }} />
          <NavItem>
            <NavLink
              active={mode === "actual"}
              href="#"
              onClick={switchMode("actual")}
            >
              Actual Accounts
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              active={mode === "budget"}
              href="#"
              onClick={switchMode("budget")}
            >
              Budget Accounts
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              active={mode === "common"}
              href="#"
              onClick={switchMode("common")}
            >
              Common Financial Model
            </NavLink>
          </NavItem>
        </ul>
        {mode === "common" ? (
          <CommonAccountsSection
            system={system}
            config={common}
            updateConfig={(config) => setCommon(config)}
          />
        ) : (
          <FinancialAccountsSection
            id={mode}
            key={mode}
            system={system}
            accountTrees={accountTrees}
            accountNames={accountNames}
            config={mode === "actual" ? actual : budget}
            updateConfig={(k, v) =>
              mode === "actual"
                ? setActual(
                    k === "book"
                      ? { ...actual, book: v as GlBook }
                      : { ...actual, tree: v },
                  )
                : setBudget({ ...budget, tree: v })
            }
            setConfigAccounts={(accounts) =>
              mode === "actual"
                ? setActual({ ...actual, accounts })
                : setBudget({ ...budget, accounts })
            }
          />
        )}
      </ModalBody>
      <ModalFooter>
        <Label check className="me-auto">
          <Input
            type="checkbox"
            className="me-2"
            checked={commonly}
            onChange={(e) => setCommonly(e.target.checked)}
          />
          Use Common Model Financial Insights
        </Label>
        <Button color="secondary" onClick={onClose}>
          Cancel
        </Button>
        <Button type="submit" color="primary" disabled={submitting}>
          Submit
        </Button>
      </ModalFooter>
    </UncontrolledModal>
  );
};

export default FinancialAccountsModal;
