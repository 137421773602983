import {
  faFileSignature,
  faFileSpreadsheet,
  faMoneyCheckAlt,
  faPencil,
  faTachometerAlt,
  faTools,
  faTrashAlt,
  faTrashRestore,
  faTvRetro,
  faUsersClass,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DashboardKindEnum, checkPermission } from "@joyhub-integration/shared";
import React, { useContext } from "react";
import { Badge, CardText } from "reactstrap";
import { Dashboard } from "../../services/dashboardService";
import { dateToTimeString } from "../../utils/date";
import PlatformContext from "../app/PlatformContext";
import ButtonWithIcon from "../common/button/ButtonWithIcon";
import JhCard from "../common/card/JhCard";

const getIcon = (dashboardName: string) => {
  switch (dashboardName) {
    case "Leasing":
      return faFileSignature;
    case "Marketing":
      return faTvRetro;
    case "Rent Analysis":
      return faMoneyCheckAlt;
    case "Residents":
      return faUsersClass;
    case "Work Orders":
      return faTools;
    case "Reports (Demo)":
      return faFileSpreadsheet;
    default:
      return faTachometerAlt;
  }
};

export interface DashboardCardProps {
  dashboard: Dashboard;
  onEdit: (id: number) => void;
  onDelete: (id: number) => void;
  onUnhide: (id: number) => void;
}

const DashboardCard: React.FC<DashboardCardProps> = ({
  dashboard,
  onEdit,
  onDelete,
  onUnhide,
}) => {
  const {
    id,
    identifier,
    name,
    description,
    hidden,
    immutable,
    kind,
    category,
  } = dashboard;
  const { organization_role } = useContext(PlatformContext).platform!;

  const platform = useContext(PlatformContext).platform!;

  const body = (
    <div className="flex-col h-100">
      <div
        className="flex-row align-items-center flex-grow-1 h-100"
        style={hidden ? { opacity: 0.5 } : {}}
      >
        <div className="card-icon">
          <FontAwesomeIcon
            icon={getIcon(name)}
            size="5x"
            className="color-pop"
          />
        </div>
        <div className="card-content flex-grow">
          <CardText>{description}</CardText>
          <div className="small text-muted mb-1 mx-3">
            <span style={{ fontWeight: 600 }}>Last modified </span>
            {dateToTimeString(new Date(dashboard.edited))}
          </div>
        </div>
      </div>
    </div>
  );

  const footer = (
    <div
      className="flex-row justify-content-between"
      style={{ alignItems: "center" }}
    >
      <div>
        {category.map((cat, index) => (
          <Badge key={index} className="me-1">
            {cat}
          </Badge>
        ))}
      </div>

      {!checkPermission("dashboards", "update", organization_role) ? null : (
        <div className="card-hover-actions">
          <ButtonWithIcon
            icon={faPencil}
            size="1x"
            className="card-edit btn"
            color="transparent"
            tooltip="Edit Dashboard"
            id={`edit-${id}`}
            disabled={immutable}
            onClick={() => onEdit(id)}
          />
          {checkPermission("dashboards", "delete", organization_role) &&
            (hidden ? (
              <ButtonWithIcon
                icon={faTrashRestore}
                size="1x"
                className="card-trash"
                color="transparent"
                tooltip="Restore Dashboard"
                id={`restore-${id}`}
                onClick={() => onUnhide(id)}
              />
            ) : (
              <ButtonWithIcon
                icon={faTrashAlt}
                size="1x"
                className="card-trash text-hover-danger"
                color="transparent"
                tooltip="Delete Dashboard"
                id={`delete-${id}`}
                onClick={() => onDelete(id)}
              />
            ))}
        </div>
      )}
    </div>
  );

  const title = (
    <div className="flex-row align-items-center">
      <div className="me-2">{name}</div>
    </div>
  );

  const propertyId = Object.keys(platform.propertiesMap)[0];
  const prefix =
    kind === DashboardKindEnum.Dashboard ? "" : `/properties/${propertyId}`;
  const to = `${prefix}/dashboards/${identifier}`;

  return (
    <JhCard
      title={title}
      description={description}
      linkTo={hidden ? undefined : to}
      bodyClass="p-0"
    >
      {{
        JhCardBody: body,
        JhCardFooter: footer,
      }}
    </JhCard>
  );
};

export default DashboardCard;
