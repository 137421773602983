import { faCheck } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { sortBy } from "lodash";
import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  DropdownItem,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import {
  PropertyWithUnitCount,
  getPropertySharing,
  setPropertySharing,
} from "../../../services/propertiesService";
import { setToggle } from "../../../utils/set";
import PlatformContext from "../../app/PlatformContext";
import { useOnUnexpectedError } from "../../common/alert/withAlertModal";
import UncontrolledModal from "../../common/modal/UncontrolledModal";

interface SharePropertyModalProps {
  property: PropertyWithUnitCount;
  onClose: () => void;
}

const SharePropertyModal: React.FC<SharePropertyModalProps> = ({
  property,
  onClose,
}) => {
  const onUnexpectedError = useOnUnexpectedError();
  const { organization, organizations } = useContext(PlatformContext).platform!;
  const [shared, setShared] = useState(new Set<number>());

  useEffect(() => {
    getPropertySharing(property.id)
      .then((organizations) => setShared(new Set(organizations)))
      .catch(onUnexpectedError);
  }, [property.id, onUnexpectedError]);

  const onSubmit = () => {
    setPropertySharing(property.id, Array.from(shared))
      .then(onClose)
      .catch(onUnexpectedError);
  };

  return (
    <UncontrolledModal onClosed={onClose} onFormSubmit={onSubmit}>
      <ModalHeader>{`Share ${property.property_name}`}</ModalHeader>
      <ModalBody
        className="px-0"
        style={{ overflow: "auto", maxHeight: "50vh" }}
      >
        {sortBy(organizations, "name")
          .filter((org) => org.id !== organization.id)
          .map((org) => (
            <DropdownItem
              toggle={false}
              onClick={() => setShared(setToggle(shared, org.id))}
            >
              <span className="d-inline-block" style={{ width: "1.5rem" }}>
                {shared.has(org.id) && <FontAwesomeIcon icon={faCheck} />}
              </span>
              {org.name}
            </DropdownItem>
          ))}
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={onClose}>
          Cancel
        </Button>
        <Button type="submit" color="primary">
          Share
        </Button>
      </ModalFooter>
    </UncontrolledModal>
  );
};

export default SharePropertyModal;
