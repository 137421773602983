import React, { useEffect, useMemo, useState } from "react";
import {
  Button,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  ModalBody,
  ModalFooter,
  ModalHeader,
  UncontrolledAlert,
} from "reactstrap";
import { unexpectedError } from "../../../../constants";
import {
  createIntegration,
  editIntegration,
  getIntegrationById,
  Integration,
  IntegrationConfiguration,
  IntegrationDto,
  IntegrationVendor,
  TestIntegrationDto,
} from "../../../../services/integrationsService";
import UncontrolledModal from "../../../common/modal/UncontrolledModal";
import vendorToLogo from "../vendorImages";
import { ValidationError } from "./common";
import SelectVendor from "./SelectVendor";
import TestIntegrationModal from "./TestIntegrationModal";
import { vendorToFormComponentMap } from "./vendorToFormComponentMap";

interface AddEditIntegrationModalProps {
  vendor?: IntegrationVendor;
  onSubmit: (integration: Integration) => void;
  onClose: () => void;
  id?: number;
  setSelectedIntegrationVendor: (vendor: IntegrationVendor | undefined) => void;
}

const AddEditIntegrationModal: React.FC<AddEditIntegrationModalProps> = (
  props,
) => {
  const [serverError, setServerError] = useState<string>("");
  const [validationError, setValidationError] = useState<ValidationError>({});
  const [name, setName] = useState<string>("");
  const [active, setActive] = useState<boolean>(true);
  const [configuration, setConfiguration] = useState<IntegrationConfiguration>(
    {},
  );
  const [configurationValidation, setConfigurationValidation] =
    useState<ValidationError>({});
  const [testingIntegration, setTestingIntegration] = useState<boolean>(false);
  const [loaded, setLoaded] = useState(false);
  const [syncOnSave, setSyncOnSave] = useState<boolean>(true);

  useEffect(() => {
    if (props.id != null) {
      getIntegrationById(props.id)
        .then((integration) => {
          const config = {
            client: integration.target_organization_id,
            ...integration.configuration,
          };
          setName(integration.name);
          setConfiguration(config);
          setActive(integration.active);
          setLoaded(true);
        })
        .catch(() => setServerError(unexpectedError));
    }
  }, [props.id, setLoaded]);

  function validate(): ValidationError {
    if (!name) {
      return { message: "Name is required.", field: "name" };
    }
    return configurationValidation;
  }

  const integrationDto = useMemo<IntegrationDto>(() => {
    const { secret, client, ...config } = configuration;
    return {
      name: name,
      active: active,
      vendor: props.vendor as IntegrationVendor,
      configuration: config as IntegrationConfiguration,
      secret: secret === "" ? undefined : secret,
      target_organization_id: client ?? undefined,
    };
  }, [name, active, props.vendor, configuration]);

  const testIntegrationDto = useMemo<TestIntegrationDto>(
    () => ({ ...integrationDto, id: props.id }),
    [integrationDto, props.id],
  );

  function onSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    const validationError = validate();
    if (Object.keys(validationError).length) {
      setValidationError(validationError);
    } else {
      setValidationError(validationError);
      const promise = props.id
        ? editIntegration(props.id, integrationDto)
        : createIntegration(integrationDto);
      promise
        .then((integration) => props.onSubmit(integration))
        .catch((err) => {
          const response = err.response;
          if (response && response.status === 400 && response.data.message) {
            setServerError(response.data.message);
          } else {
            setServerError(unexpectedError);
          }
        });
    }
  }

  function onTest(e: React.MouseEvent) {
    e.preventDefault();
    const validationError = validate();
    if (Object.keys(validationError).length) {
      setValidationError(validationError);
    } else {
      setTestingIntegration(true);
    }
  }

  const VendorFormComponent =
    props.vendor && vendorToFormComponentMap[props.vendor];

  return props.id != null && !loaded ? null : (
    <UncontrolledModal
      size="lg"
      onClosed={props.onClose}
      onFormSubmit={onSubmit}
    >
      <ModalHeader toggle={props.onClose}>
        <div
          className="flex-row"
          style={{ alignItems: "center", justifyContent: "center" }}
        >
          {!props.vendor ? (
            <span>{props.id != null ? `Edit ${name}` : "Add Integration"}</span>
          ) : (
            <div className="d-flex flex-row align-items-center">
              <img
                src={vendorToLogo[props.vendor]}
                alt={`${props.vendor} Logo`}
                height={25}
              />
            </div>
          )}
        </div>
      </ModalHeader>
      <ModalBody>
        {props.vendor ? (
          <>
            {serverError ? (
              <UncontrolledAlert color="danger">
                {serverError}
              </UncontrolledAlert>
            ) : null}
            <FormGroup>
              <Label>Name</Label>
              <Input
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
                invalid={validationError.field === "name"}
              />
              {validationError.field === "name" && validationError.message ? (
                <FormFeedback>{validationError.message}</FormFeedback>
              ) : null}
            </FormGroup>
            {!!VendorFormComponent ? (
              <VendorFormComponent
                isCreate={props.id === undefined}
                systemId={props.id}
                configuration={configuration}
                setConfiguration={setConfiguration}
                setConfigurationValidation={setConfigurationValidation}
                validationError={validationError}
              />
            ) : null}
          </>
        ) : (
          <SelectVendor
            setSelectedVendor={props.setSelectedIntegrationVendor}
          />
        )}
      </ModalBody>
      <ModalFooter>
        {!props.id && (
          <Label check className="me-auto">
            <Input
              type="checkbox"
              checked={syncOnSave}
              onChange={(e) => setSyncOnSave(e.target.checked)}
              className="me-1"
            />
            Sync on save
          </Label>
        )}
        <Button color="secondary" onClick={props.onClose}>
          Cancel
        </Button>
        <Button color="primary" onClick={onTest}>
          Test
        </Button>
        <Button type="submit" color="primary" disabled={!props.vendor}>
          Save
        </Button>
      </ModalFooter>
      {testingIntegration ? (
        <TestIntegrationModal
          integrationDto={testIntegrationDto}
          onClose={() => setTestingIntegration(false)}
        />
      ) : null}
    </UncontrolledModal>
  );
};

export default AddEditIntegrationModal;
