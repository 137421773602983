import { faPlus } from "@fortawesome/pro-light-svg-icons";
import { DashboardKindEnum } from "@joyhub-integration/shared";
import download from "downloadjs";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useNavigate, useParams } from "react-router";
import { Button } from "reactstrap";
import { BooleanParam, useQueryParam } from "use-query-params";
import { useBreakpoint } from "../../hooks/useBreakpoint";
import dashboardService, {
  BaseDashboard,
  Dashboard as DashboardType,
} from "../../services/dashboardService";
import { OtherDashboardInsight as DashboardInsight } from "../../services/models";
import { downloadAttachment } from "../../utils/download";
import { usePropertiesSelectionQueryParam } from "../../utils/useQueryParams";
import DeleteModal from "../admin/common/DeleteModal";
import PlatformContext from "../app/PlatformContext";
import withAlertModal, {
  WithAlertModalProps,
} from "../common/alert/withAlertModal";
import { LoadilyFadily } from "../common/allFadily";
import ButtonWithIcon from "../common/button/ButtonWithIcon";
import PrintHeader from "../common/PrintHeader";
import CommunityDirectorDashboard from "../communityDirector/CommunityDirectorDashboard";
import { ModernCrumbar } from "../layout/ModernCrumbar";
import PropertiesPicker from "../picker/PropertiesPicker";
import PropertyPicker from "../picker/PropertyPicker";
import CopyModal from "./CopyModal";
import "./dashboard.css";
import DashboardAddEditInsight from "./DashboardAddEditInsight";
import { DashboardBoard } from "./DashboardBoard";
import { DashboardButtons } from "./DashboardButtons";
import { DashboardDto } from "./dashboardDto";
import DashboardGrid from "./DashboardGrid";
import DashboardHeader from "./DashboardHeader";
import DashboardSwitcher from "./DashboardSwitcher";

type DashboardParams = {
  id: string;
  propertyId: string;
};

const Dashboard: React.FC<WithAlertModalProps> = ({
  onUnexpectedError,
  setAlert,
}) => {
  const { id: dashboardIdParam, propertyId: propertyIdParam } =
    useParams<DashboardParams>();
  const [propertyId, setPropertyId] = useState<number | undefined>();
  const [dashboardParam, setDashboardParam] = useState<string | undefined>();
  const [addEditInsightModalOpen, setAddEditInsightModalOpen] = useState<
    [number, number] | boolean
  >(false);
  const [dashboardInsightToEdit, setDashboardInsightToEdit] =
    useState<DashboardInsight>();
  const [dashboardInsightToRemove, setDashboardInsightToRemove] =
    useState<DashboardInsight>();
  const [copying, setCopying] = useState<boolean>(false);
  const [pdfDownloading, setPDFDownloading] = useState<boolean>(false);
  const [footnote] = useState<boolean>(true); // TODO: this should be driven by the dashboard
  const [editMode, setEditMode] = useQueryParam("edit", BooleanParam);
  const [selection, setSelection] = usePropertiesSelectionQueryParam();
  const [loaded, setLoaded] = useState(false);
  const navigate = useNavigate();
  const platform = useContext(PlatformContext).platform!;
  const breakpoint = useBreakpoint();

  const { insightsMap, propertiesMap, organization_role, embedInfo } = platform;
  const [dashboards, setDashboards] = useState<DashboardType[]>([]);
  const [isAppliedParam, setIsAppliedParam] = useState<boolean>(false);
  const [dashboardName, setDashboardName] = useState<string>("");
  const dashboardKind = useMemo(
    () =>
      propertyId != null
        ? DashboardKindEnum.PropertyDashboard
        : DashboardKindEnum.Dashboard,
    [propertyId],
  );

  useEffect(() => {
    if (!platform) return;

    const dashboardParamValue = embedInfo
      ? embedInfo.identifier
      : dashboardIdParam;

    let propertyIdValue;
    if (embedInfo)
      propertyIdValue =
        embedInfo.propertyId === null ? undefined : embedInfo.propertyId;
    else
      propertyIdValue =
        propertyIdParam == null ? undefined : parseInt(propertyIdParam);

    setDashboardParam(dashboardParamValue);
    setPropertyId(propertyIdValue);
    setIsAppliedParam(true);
  }, [
    platform,
    embedInfo,
    dashboardIdParam,
    propertyIdParam,
    setDashboardParam,
    setPropertyId,
  ]);

  useEffect(() => {
    if (selection == null && propertyId != null)
      setSelection(propertyId, "replaceIn");
  }, [dashboardParam, propertyId, selection, setSelection, propertiesMap]);

  const loadDashboards = useCallback(
    () =>
      dashboardService
        .getDashboards(false, true, dashboardKind)
        .then(setDashboards)
        .finally(() => setLoaded(true)),
    [dashboardKind],
  );

  useEffect(() => {
    if (isAppliedParam) loadDashboards().catch(onUnexpectedError);
  }, [isAppliedParam, editMode, loadDashboards, onUnexpectedError]);

  const dashboard = useMemo<DashboardType | undefined>(
    () =>
      dashboards.find(
        (d) =>
          !d.shared &&
          d.identifier.toLowerCase() === dashboardParam?.toLowerCase(),
      ) ??
      dashboards.find(
        (d) => d.identifier.toLowerCase() === dashboardParam?.toLowerCase(),
      ) ??
      dashboards[0],
    [dashboardParam, dashboards],
  );

  useEffect(() => {
    if (dashboard?.id != null && dashboard?.name != null) {
      setDashboardName(dashboard.name);
      dashboardService.viewDashboard(dashboard.id).then(() => {});
      try {
        window.analytics.track("Dashboard Viewed", {
          dashboardName: dashboard.name,
          dashboardId: dashboard.id,
          userId: platform.person.id,
        });
      } catch (e) {
        console.error("Unable to fire segment track event", e);
      }
    }
  }, [dashboard?.id, dashboard?.name, platform.person.id]);

  function onAddEditInsightSubmit() {
    loadDashboards()
      .then(() => {
        setDashboardInsightToEdit(undefined);
        setAddEditInsightModalOpen(false);
      })
      .catch(onUnexpectedError);
  }

  function onSetDashboardInsightToEdit(di: DashboardInsight) {
    setDashboardInsightToEdit(di);
    setAddEditInsightModalOpen(true);
  }

  function onAddEditClose() {
    setDashboardInsightToEdit(undefined);
    setAddEditInsightModalOpen(false);
  }

  function toggleAddEditInsight(xy: [number, number] | boolean) {
    if (!xy) {
      onAddEditClose();
    } else {
      setAddEditInsightModalOpen(xy);
    }
  }

  function exportToJSON(dashboard: DashboardType) {
    const json = JSON.stringify(dashboard.definition);
    download(json, `${dashboard.name}.json`, "application/json");
  }

  function onCopySubmit(d: BaseDashboard) {
    loadDashboards().then(() => {
      setCopying(false);
      const prefix = propertyId == null ? "" : `/properties/${propertyId}`;
      navigate(`${prefix}/dashboards/${d.identifier}`, {
        state: { copied: true },
      });
    });
  }

  function onDownloadPDF() {
    if (dashboard) {
      setPDFDownloading(true);
      setAlert(
        "Kindly wait for about 20 seconds as the PDF is generated.",
        true,
      );
      dashboardService
        .downloadDashboardPDF(dashboard.id, {
          propertyId,
          properties: selection!,
        })
        .then(downloadAttachment("Dashboard"))
        .catch(onUnexpectedError)
        .finally(() => setPDFDownloading(false));
    }
  }

  // for the old dashboard feature
  function updateOldDashboardLayout(layout: ReactGridLayout.Layouts) {
    if (
      (organization_role == "Administrator" || organization_role == "Editor") &&
      editMode &&
      dashboard != null
    )
      dashboardService
        .setDashboardLayoutAndName(dashboard.id, layout)
        .then(() => loadDashboards())
        .catch(onUnexpectedError);
  }

  const communityDirectorDashboard = dashboardIdParam === "_home";
  const activeDashboardId = communityDirectorDashboard ? -1 : dashboard?.id;

  // for the old dashboard feature
  async function handleCloseOldEditMode() {
    if (dashboard) {
      const dto = {
        identifier: dashboard.identifier,
        name: dashboardName,
        description: dashboard.description,
        shared: dashboard.shared,
        internal: dashboard.internal,
        kind: dashboard.kind,
        definition: dashboard.definition,
        category: dashboard.category,
      } as DashboardDto;
      await dashboardService.updateDashboard(dashboard.id, dto);
    }

    setEditMode(undefined);
  }

  // new style dashboard layout
  const [layout, setLayout] = useState<any[]>([]);

  const handleSaveLayout = async () => {
    if (dashboard)
      await dashboardService
        .setDashboardLayoutAndName(
          dashboard.id,
          { lg: layout.flat() },
          dashboardName,
        )
        .catch(onUnexpectedError);

    setEditMode(undefined);
  };

  return (
    <>
      <ModernCrumbar primary={embedInfo ? dashboard?.name : undefined}>
        {embedInfo ? (
          <div className="d-flex ms-auto align-items-center">
            {dashboardKind === "PropertyDashboard" ? (
              <PropertyPicker right className="me-2" theme="dark" />
            ) : (
              <PropertiesPicker right className="me-2" theme="dark" />
            )}
          </div>
        ) : (
          <>
            <DashboardSwitcher
              activeDashboardId={activeDashboardId}
              dashboards={dashboards}
              propertyId={propertyId}
              loaded={loaded}
            />
            {editMode ? (
              <>
                <Button
                  size="sm"
                  color="danger"
                  className="ms-auto align-self-center"
                  onClick={() => navigate(-1)}
                >
                  Cancel
                </Button>
                <Button
                  size="sm"
                  color="success"
                  className="ms-2 align-self-center"
                  disabled={!dashboardName.trim()}
                  onClick={() => {
                    if (dashboard?.definition?.isNew) {
                      handleSaveLayout().then();
                    } else {
                      handleCloseOldEditMode().then();
                    }
                  }}
                >
                  Save
                </Button>
              </>
            ) : dashboard ? (
              <div className="d-flex align-items-center ms-auto flex-shrink-0">
                <DashboardButtons
                  propertyId={propertyId}
                  dashboard={dashboard}
                  onCopy={() => setCopying(true)}
                  onDownloadDefinition={() => exportToJSON(dashboard!)}
                  onDownloadPDF={onDownloadPDF}
                  onUnexpectedError={onUnexpectedError}
                  setAlert={setAlert}
                  pdfDownloading={pdfDownloading}
                />
                {!dashboard.immutable &&
                !communityDirectorDashboard &&
                (organization_role == "Administrator" ||
                  organization_role == "Editor") ? (
                  <Button
                    onClick={() => setEditMode(true)}
                    type="submit"
                    color="primary"
                    size="sm"
                    className="ms-2"
                  >
                    Edit Dashboard
                  </Button>
                ) : null}
              </div>
            ) : null}
          </>
        )}
      </ModernCrumbar>

      <LoadilyFadily
        loaded={activeDashboardId != null}
        className="jh-page-layout"
      >
        {communityDirectorDashboard ? (
          <CommunityDirectorDashboard />
        ) : dashboard == null ? null : (
          <>
            <DashboardHeader
              editMode={!!editMode}
              dashboardName={dashboardName}
              setDashboardName={setDashboardName}
            />
            <div
              className={`jh-page-content pt-0 dashboard-main position-relative mt-3 ${
                editMode ? "dashboard-editing" : "dashboard-viewing"
              }`}
              style={{
                padding:
                  breakpoint === "xs"
                    ? 0
                    : breakpoint === "sm"
                      ? 10
                      : undefined,
              }}
            >
              <PrintHeader title={dashboard.name} fullDate />
              <div className="dashboard-container">
                <div className="dashboard-content-container">
                  {dashboard?.definition?.isNew ? (
                    <DashboardBoard
                      editMode={
                        (organization_role == "Administrator" ||
                          organization_role == "Editor") &&
                        !!editMode
                      }
                      dashboard={dashboard}
                      selection={selection ?? {}}
                      dashboardKind={dashboardKind}
                      toggleAddEditInsight={toggleAddEditInsight}
                      setDashboardInsightToEdit={onSetDashboardInsightToEdit}
                      setDashboardInsightToRemove={setDashboardInsightToRemove}
                      layout={layout}
                      setLayout={setLayout}
                    />
                  ) : (
                    <>
                      <DashboardGrid
                        dashboardId={dashboard.id}
                        dashboardInsights={dashboard.definition?.insights ?? []}
                        setDashboardLayout={updateOldDashboardLayout}
                        setDashboardInsightToEdit={onSetDashboardInsightToEdit}
                        setDashboardInsightToRemove={
                          setDashboardInsightToRemove
                        }
                        layout={dashboard.definition?.layout}
                        selection={selection ?? {}}
                        editMode={
                          (organization_role == "Administrator" ||
                            organization_role == "Editor") &&
                          !!editMode
                        }
                        propertiesHash={propertiesMap}
                        insightsMap={insightsMap}
                        dashboardKind={dashboardKind}
                      />
                      {editMode ? (
                        <div className="floaty-buttons">
                          <ButtonWithIcon
                            className="jh-icon-with-text dashboard-add-insight-button"
                            icon={faPlus}
                            color="primary"
                            onClick={() => toggleAddEditInsight(true)}
                            label={<span>Add a Card</span>}
                          />
                        </div>
                      ) : null}
                    </>
                  )}
                </div>
                {footnote && !editMode ? (
                  <div className="dashboard-footnote">
                    * Current month calculated over last 30 days
                  </div>
                ) : null}
              </div>
              {dashboardInsightToRemove ? (
                <DeleteModal<DashboardInsight>
                  id={dashboardInsightToRemove.id}
                  entityName="Dashboard Insight"
                  identificationKey="name"
                  getEntity={(id) =>
                    dashboardService.getDashboardInsight(dashboard.id, id)
                  }
                  deleteEntity={(id) =>
                    dashboardService.deleteDashboardInsight(dashboard.id, id)
                  }
                  onClose={() => setDashboardInsightToRemove(undefined)}
                  onSubmit={() =>
                    loadDashboards()
                      .then(() => setDashboardInsightToRemove(undefined))
                      .catch(onUnexpectedError)
                  }
                />
              ) : null}
              {copying ? (
                <CopyModal
                  id={dashboard.id}
                  originalName={dashboard.name}
                  onSubmit={(d) => onCopySubmit(d)}
                  onClose={() => setCopying(false)}
                />
              ) : null}
              {addEditInsightModalOpen ? (
                <DashboardAddEditInsight
                  dashboardId={dashboard.id}
                  dashboardKind={dashboardKind}
                  isOldDashboard={!dashboard?.definition?.isNew}
                  dashboardInsightId={dashboardInsightToEdit?.id}
                  xy={
                    Array.isArray(addEditInsightModalOpen)
                      ? addEditInsightModalOpen
                      : undefined
                  }
                  onClose={onAddEditClose}
                  onSubmit={onAddEditInsightSubmit}
                />
              ) : null}
            </div>
          </>
        )}
      </LoadilyFadily>
    </>
  );
};

export default withAlertModal(Dashboard);
