import { faDownload, faUpload } from "@fortawesome/pro-light-svg-icons";
import { GoalYear, mapDefined, MonthNumber } from "@joyhub-integration/shared";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  Alert,
  Button,
  ButtonGroup,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import {
  importGoals,
  loadGoalFetchOptions,
  loadGoals,
} from "../../../services/goalsService";
import { errorMessage } from "../../../utils/api";
import {
  useInsightsSelectionQueryParam,
  usePropertiesSelectionQueryParam,
} from "../../../utils/useQueryParams";
import PlatformContext from "../../app/PlatformContext";
import withAlertModal, {
  WithAlertModalProps,
} from "../../common/alert/withAlertModal";
import { LoadilyFadily } from "../../common/allFadily";
import ButtonWithIcon from "../../common/button/ButtonWithIcon";
import UncontrolledModal from "../../common/modal/UncontrolledModal";
import TableWithSelection, {
  KeyValue,
} from "../../common/table/TableWithSelection";
import { ModernCrumbar } from "../../layout/ModernCrumbar";
import InsightsPicker from "../../picker/InsightsPicker";
import "./goals.scss";
import GoalsTemplateGeneratorModal from "./GoalsTemplateGeneratorModal";
import GoalYearScheduledEmailButton from "./GoalYearScheduledEmailButton";

const GoalsAdminPage: React.FC<WithAlertModalProps> = ({
  setAlert,
  onUnexpectedError,
}) => {
  const platform = useContext(PlatformContext).platform!;
  const { insightsMap } = platform;
  const [loaded, setLoaded] = useState(false);
  const [generatingTemplate, setGeneratingTemplate] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [importErrors, setImportErrors] = useState(new Array<string>());
  const fileInput = useRef<HTMLInputElement>(null);
  const [goalYears, setGoalYears] = useState(new Array<GoalYear>());
  const [offset, setOffset] = useState(0);
  const [probablyMoreData, setProbablyMoreData] = useState(false);
  const [year, setYear] = useState(new Date().getFullYear());
  const [yearOptions, setYearOptions] = useState(new Array<number>());
  const [pSelection] = usePropertiesSelectionQueryParam();
  const [iSelection] = useInsightsSelectionQueryParam();
  const [insightIds, setInsightIds] = useState<number[]>([]);
  const [yearOptionsOpen, setYearOptionsOpen] = useState(false);
  const [selected, setSelected] = useState<GoalYear>();

  const fetchGoalOptions = useCallback(async () => {
    loadGoalFetchOptions(year)
      .then((options) => {
        setInsightIds(options.insightIds);
        setYearOptions(options.years);
      })
      .catch(onUnexpectedError);
  }, [year, setInsightIds, setYearOptions, onUnexpectedError]);

  const fetchGoals = useCallback(async () => {
    loadGoals(year, pSelection || {}, iSelection || {}, 0, pageSize)
      .then((goalYears) => {
        setGoalYears(goalYears);
        setProbablyMoreData(goalYears.length === pageSize);
        setOffset(goalYears.length);
      })
      .catch(onUnexpectedError);
  }, [onUnexpectedError, year, pSelection, iSelection]);

  useEffect(() => {
    fetchGoalOptions();
  }, [fetchGoalOptions]);

  const insights = useMemo(
    () => mapDefined(insightIds, (insightId) => insightsMap[insightId]),
    [insightIds, insightsMap],
  );

  useEffect(() => {
    fetchGoals().finally(() => setLoaded(true));
  }, [fetchGoals, setLoaded]);

  const handleLoadMoreClick = () => {
    setOffset((offset) => offset + pageSize);
    loadGoals(year, pSelection || {}, iSelection || {}, offset, pageSize).then(
      (moreGoalYears) => {
        setGoalYears((goalYears) => [...goalYears, ...moreGoalYears]);
        setProbablyMoreData(moreGoalYears.length === pageSize);
      },
    );
  };

  const handleGenerateTemplateModalClose = () => setGeneratingTemplate(false);
  const handleImportErrorsModalClose = () => setImportErrors([]);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (!files?.length) return;
    setUploading(true);
    const file = files[0];
    importGoals(file)
      .then(() => Promise.all([fetchGoalOptions(), fetchGoals()]))
      .then(() => {
        setAlert("Goals set.", true);
      })
      .catch((err) => {
        const importErrors = err.response?.data?.errors;
        if (importErrors?.length) {
          setImportErrors(importErrors as string[]);
        } else {
          setAlert(errorMessage(err), false);
        }
      })
      .finally(() => {
        setUploading(false);
      });
  };

  const handleUploadGoalsClick = () => {
    if (uploading || !fileInput.current) return;
    fileInput.current.value = "";
    fileInput.current.click();
  };

  return (
    <>
      <ModernCrumbar primary="Manage Goals" />
      <LoadilyFadily loaded={loaded} className="jh-page-layout">
        <Input
          type="file"
          accept=".xlsx"
          innerRef={fileInput}
          onChange={handleFileChange}
          className="d-none"
        />
        <div className="flex-row jh-action-bar px-4 py-3">
          <ButtonGroup>
            <GoalYearScheduledEmailButton
              selected={selected}
              reloadGoals={fetchGoals}
            />
          </ButtonGroup>
          <div className="d-flex justify-content-between">
            <ButtonGroup>
              <ButtonWithIcon
                icon={faDownload}
                onClick={() => setGeneratingTemplate(true)}
                label="Build Template"
              />
              <ButtonWithIcon
                icon={faUpload}
                onClick={handleUploadGoalsClick}
                label="Upload Goals"
              />
            </ButtonGroup>
            <div className="d-flex flex-row ms-2">
              <InsightsPicker insights={insights} className="me-2" />
              <Dropdown
                isOpen={yearOptionsOpen}
                toggle={() =>
                  setYearOptionsOpen((yearOptionsOpen) => !yearOptionsOpen)
                }
              >
                <DropdownToggle id="year-picker" color="light" caret>
                  {year}
                </DropdownToggle>
                <DropdownMenu end>
                  {yearOptions.map((yearOption) => (
                    <DropdownItem
                      key={yearOption}
                      value={yearOption}
                      onClick={() => setYear(yearOption)}
                    >
                      {yearOption}
                    </DropdownItem>
                  ))}
                </DropdownMenu>
              </Dropdown>
            </div>
          </div>
        </div>
        <div className="jh-page-content pt-0 admin-page page-scroll">
          <TableWithSelection<GoalYear>
            selected={selected}
            onSelectedChange={(selected) => setSelected(selected)}
            columns={tableCols}
            rows={goalYears}
          />

          {probablyMoreData && (
            <div className="d-flex justify-content-end">
              <div>
                <Button onClick={handleLoadMoreClick} outline>
                  Load More
                </Button>
              </div>
            </div>
          )}
        </div>

        {!!importErrors.length && (
          <ImportErrorsModal
            importErrors={importErrors}
            onClose={handleImportErrorsModalClose}
          />
        )}
        {generatingTemplate && (
          <GoalsTemplateGeneratorModal
            onClose={handleGenerateTemplateModalClose}
          />
        )}
      </LoadilyFadily>
    </>
  );
};

interface ImportErrorsModalProps {
  importErrors: string[];
  onClose: () => void;
}

const ImportErrorsModal: React.FC<ImportErrorsModalProps> = ({
  importErrors,
  onClose,
}) => {
  return (
    <UncontrolledModal>
      <ModalHeader>Import Failure</ModalHeader>
      <ModalBody>
        <Alert color="warning" isOpen={!!importErrors.length}>
          <p>0 Goals Imported.</p>
          <ul>
            {importErrors.map((importError, i) => (
              <li key={i}>{importError}</li>
            ))}
          </ul>
        </Alert>
      </ModalBody>
      <ModalFooter>
        <Button onClick={onClose}>OK</Button>
      </ModalFooter>
    </UncontrolledModal>
  );
};

const pageSize = 50;

export default withAlertModal(GoalsAdminPage);

const toValue = (t: GoalYear, idx: number) => {
  const index = (idx - 2) as MonthNumber;
  return t.goalByMonth[index];
};

const tableCols: Array<KeyValue<GoalYear>> = [
  {
    key: "propertyName",
    title: "Property",
  },
  {
    key: "insightName",
    title: "Insight",
  },
  {
    key: "goalByMonth[0]",
    title: "Jan",
    toValue,
    sortable: false,
  },
  {
    key: "goalByMonth[1]",
    title: "Feb",
    toValue,
    sortable: false,
  },
  {
    key: "goalByMonth[2]",
    title: "Mar",
    toValue,
    sortable: false,
  },
  {
    key: "goalByMonth[3]",
    title: "Apr",
    toValue,
    sortable: false,
  },
  {
    key: "goalByMonth[4]",
    title: "May",
    toValue,
    sortable: false,
  },
  {
    key: "goalByMonth[5]",
    title: "Jun",
    toValue,
    sortable: false,
  },
  {
    key: "goalByMonth[6]",
    title: "Jul",
    toValue,
    sortable: false,
  },
  {
    key: "goalByMonth[7]",
    title: "Aug",
    toValue,
    sortable: false,
  },
  {
    key: "goalByMonth[8]",
    title: "Sep",
    toValue,
    sortable: false,
  },
  {
    key: "goalByMonth[9]",
    title: "Oct",
    toValue,
    sortable: false,
  },
  {
    key: "goalByMonth[10]",
    title: "Nov",
    toValue,
    sortable: false,
  },
  {
    key: "goalByMonth[11]",
    title: "Dec",
    toValue,
    sortable: false,
  },
];
