import { ReportColumn, ReportSheetData } from "@joyhub-integration/shared";
import { isEqual } from "lodash";
import { CSSProperties } from "react";
import { Snapshot } from "./Snapshot";
import VegaGraph from "./VegaGraph";

interface UnicardPropsType {
  data: ReportSheetData;
  className?: string;
  style?: CSSProperties;
  actions?: boolean;
  yAxisItems?: {
    column: ReportColumn<"Unresolved">;
    index: number;
  }[];
}

const Unicard = (props: UnicardPropsType) => {
  const { data, yAxisItems } = props;

  const isSnapshot = isEqual(data.graphType, "Snapshot");

  return isSnapshot ? (
    <Snapshot data={data} yAxisItems={yAxisItems} />
  ) : (
    <VegaGraph {...props} />
  );
};

export default Unicard;
