import { faArrowAltDown, faArrowAltUp } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";
import { CSSProperties } from "react";
import AnimatedNumber from "./AnimatedNumber";

const formatPercent = (number: number) =>
  number.toLocaleString("en-US", {
    style: "percent",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

const TrendArrow = ({
  delta,
  positiveGood = true,
  animated = true,
  percent = true,
  formatter,
  style,
}: {
  delta: number;
  positiveGood?: boolean;
  animated?: boolean;
  percent?: boolean;
  formatter?: (number: number) => string;
  style?: CSSProperties;
}) => {
  const icon = delta > 0 ? faArrowAltUp : faArrowAltDown;
  const good = (positiveGood && delta > 0) || (!positiveGood && delta < 0);
  return (
    <div
      className={clsx(
        "text-nowrap",
        style ? undefined : good ? "text-success" : "text-danger",
      )}
      style={style}
    >
      <FontAwesomeIcon icon={icon} />
      &nbsp;
      {animated ? (
        <AnimatedNumber
          formatter={
            formatter ? formatter : percent ? formatPercent : undefined
          }
        >
          {delta}
        </AnimatedNumber>
      ) : formatter ? (
        formatter(delta)
      ) : percent ? (
        formatPercent(delta)
      ) : (
        delta
      )}
    </div>
  );
};

export default TrendArrow;
