import { keys, reduce } from "lodash";
import {
  createRef,
  useCallback,
  useEffect,
  useReducer,
  useRef,
  useState,
} from "react";
import { DateRangePicker, RangeKeyDict } from "react-date-range";
import Select, { OnChangeValue } from "react-select";
import { Button, Popover } from "reactstrap";
import { Option } from "../../services/models";
import {
  DateRange,
  FilterType,
  getMatchingUnits,
  UnitFilters,
  UnitFilterValues,
  UnitMetadata,
} from "../../services/unitMap/unitMetadata";
import { formattedDollar } from "../../utils/number";
import JhRange from "../common/JhRange/JhRange";

const selectedFiltersReducer = (
  state: Partial<UnitFilterValues>,
  newState: Partial<UnitFilterValues>,
) => {
  return keys(newState).length > 0 ? { ...state, ...newState } : {};
};

const formatFilterValue = (value: number | string, unit: string) => {
  if (unit === "usd") {
    return formattedDollar(value as number);
  } else if (unit === undefined) {
    return value;
  }

  return `${value} ${unit}`;
};

const UnitFilter = ({
  mapId,
  onFilter,
  unitData,
  filters,
}: {
  mapId: number;
  onFilter: (units: string[]) => void;
  unitData: UnitMetadata[];
  filters?: UnitFilters;
}) => {
  const container = useRef<HTMLDivElement>(null);
  const [filterValues, setFilterValues] = useReducer(
    selectedFiltersReducer,
    {},
  );
  const [openFilter, setOpenFilter] = useState<string>();
  const [matchingUnitCount, setMatchingUnitCount] = useState(0);

  const onMultiFilterChange =
    (key: string, type: FilterType) => (value: OnChangeValue<Option, true>) => {
      setFilterValues({
        [key]: { type, values: value?.map((option) => option.value) },
      });
    };

  const onRangeChange = useCallback(
    (key: string, type: FilterType) => (values: number[]) => {
      setFilterValues({
        [key]: {
          type,
          values,
        },
      });
    },
    [],
  );

  const onDateRangeChange = useCallback(
    (key: string, type: FilterType) => (range: RangeKeyDict) => {
      const startDate = range.range1.startDate;
      const endDate = range.range1.endDate;
      if (startDate) {
        setFilterValues({
          [key]: {
            type,
            values: {
              startDate,
              endDate: endDate ?? undefined,
            },
          },
        });

        if (endDate !== startDate) {
          setOpenFilter(undefined);
        }
      } else {
        setFilterValues({
          [key]: undefined,
        });
        setOpenFilter(undefined);
      }
    },
    [],
  );

  useEffect(() => {
    getMatchingUnits(mapId, filterValues, unitData).then((units) => {
      onFilter(units);
      setMatchingUnitCount(units.length);
    });
  }, [mapId, filterValues, unitData, onFilter]);

  const toggleFilter = (filterKey: string) => () => {
    return openFilter !== filterKey
      ? setOpenFilter(filterKey)
      : setOpenFilter(undefined);
  };

  const resetFilters = () => {
    selectRefs.forEach((ref) => {
      ref.current?.select.setValue(undefined);
    });
    setFilterValues({});
    setOpenFilter(undefined);
  };

  let selectRefs: any[] = [];

  const filterCount = reduce(
    filterValues,
    (sum, filter) => {
      if (filter?.type === "multi") {
        return sum + (filter?.values as string[])?.length;
      }
      if (filter?.values) {
        return sum + 1;
      }
      return sum;
    },
    0,
  );

  return filters ? (
    <div
      className="flex-col py-3 px-4"
      style={{ width: "18em", backgroundColor: "#F6F8F9", overflowY: "auto" }}
      ref={container}
    >
      <div className="mb-2" style={{ fontSize: "1.25em", fontWeight: 600 }}>
        Highlight units by
      </div>
      {filterCount > 0 && (
        <Button
          className="mb-2 p-0 text-left"
          color="link"
          onClick={resetFilters}
        >
          Reset ({filterCount} filter, {matchingUnitCount} units)
        </Button>
      )}
      {keys(filters).map((filterKey) => {
        const filter = filters[filterKey];
        const values = filterValues[filterKey]?.values;
        switch (filter.type) {
          case "multi":
            const selectRef = createRef();
            selectRefs.push(selectRef);
            return (
              <Select
                // @ts-ignore
                ref={selectRef}
                key={filterKey}
                isMulti
                placeholder={filter.name}
                options={filter.options}
                className="mb-2"
                onChange={onMultiFilterChange(filterKey, filter.type)}
                hideSelectedOptions={false}
              />
            );
          case "range":
          case "discreteRange":
            const rangeValues = (values as number[]) ?? [
              filter.min,
              filter.max,
            ];
            const rangeLabel = `${formatFilterValue(
              rangeValues[0],
              filter.unit,
            )} - ${formatFilterValue(rangeValues[1], filter.unit)}`;
            return (
              <div key={filterKey}>
                <Button
                  id={filterKey}
                  type="button"
                  className="mb-2 w-100 d-flex flex-wrap justify-content-center"
                  color={
                    filterValues[filterKey] === undefined
                      ? "light"
                      : "secondary"
                  }
                  tag="div"
                  onClick={toggleFilter(filterKey)}
                >
                  {filter.name}
                  <div
                    className="d-flex align-items-center ms-2"
                    style={{ fontSize: "0.8em" }}
                  >
                    ({rangeLabel})
                  </div>
                </Button>
                <Popover
                  boundariesElement={undefined /*"viewport"*/}
                  container={container}
                  placement="left-start"
                  trigger="focus"
                  isOpen={openFilter === filterKey}
                  target={filterKey}
                  toggle={toggleFilter(filterKey)}
                >
                  <div
                    className="p-4 text-center"
                    style={{
                      width: "30em",
                    }}
                  >
                    <JhRange
                      values={rangeValues}
                      min={filter.min! as number}
                      max={filter.max! as number}
                      step={1}
                      onChange={onRangeChange(filterKey, filter.type)}
                    />
                    <output style={{ marginTop: "30px" }} id="output">
                      {rangeLabel}
                    </output>
                    <div className="d-flex justify-content-between mt-2">
                      <Button
                        color="primary"
                        outline
                        onClick={() =>
                          setFilterValues({ [filterKey]: undefined })
                        }
                      >
                        Reset
                      </Button>
                      <Button
                        color="primary"
                        onClick={() => setOpenFilter(undefined)}
                      >
                        OK
                      </Button>
                    </div>
                  </div>
                </Popover>
              </div>
            );
          case "dateRange":
            const startDate = (values as DateRange)?.startDate;
            const endDate = (values as DateRange)?.endDate;
            const ranges = [
              {
                startDate,
                endDate,
              },
            ];
            return (
              <div key={filterKey}>
                <Button
                  id={filterKey}
                  type="button"
                  className="mb-2 w-100 d-flex flex-wrap justify-content-center"
                  color={
                    filterValues[filterKey] === undefined
                      ? "light"
                      : "secondary"
                  }
                  tag="div"
                  onClick={toggleFilter(filterKey)}
                >
                  {filter.name}
                  <div
                    className="d-flex align-items-center ms-2"
                    style={{ fontSize: "0.8em" }}
                  >
                    (
                    {startDate
                      ? `${startDate.toDateString()} - ${
                          endDate?.toDateString() ?? "End of time"
                        }`
                      : "Any"}
                    )
                  </div>
                </Button>
                <Popover
                  boundariesElement={undefined /*"viewport"*/}
                  container={container}
                  placement="left-start"
                  isOpen={openFilter === filterKey}
                  target={filterKey}
                  toggle={toggleFilter(filterKey)}
                >
                  <DateRangePicker
                    key={filterKey}
                    onChange={onDateRangeChange(filterKey, filter.type)}
                    showPreview={true}
                    ranges={ranges}
                    startDatePlaceholder="Start"
                    endDatePlaceholder="End"
                    initialFocusedRange={[0, 0]}
                  />
                </Popover>
              </div>
            );
          default:
            return null;
        }
      })}
    </div>
  ) : null;
};

export default UnitFilter;
