import React from "react";
import { Spinner } from "reactstrap";
import { ReactComponent as Logo } from "../../images/logo-h.svg";
import "./loading.css";

const Loading: React.FC<{ joyhubLogo?: boolean }> = ({ joyhubLogo }) => {
  return joyhubLogo ? (
    <div
      className="loading-page-main session-container"
      style={{ opacity: 0.5 }}
    >
      <div className="session-messages">
        <Logo id="session-logo" title="Revolution RE" />
        <Spinner id="loading-spinner" color="secondary" type="border" />
      </div>
    </div>
  ) : (
    <div className="loading-page-main inside" style={{ opacity: 0.5 }}>
      <Spinner id="loading-spinner" color="secondary" type="border" />
    </div>
  );
};

export default Loading;
