import { DashboardKindEnum } from "@joyhub-integration/shared";
import React, { useContext, useEffect, useMemo, useState } from "react";
import Select, { ActionMeta, OnChangeValue } from "react-select";
import CreatableSelect from "react-select/creatable";
import {
  Button,
  Col,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  ModalBody,
  ModalFooter,
  ModalHeader,
  UncontrolledAlert,
} from "reactstrap";
import { unexpectedError } from "../../constants";
import dashboardService, {
  BaseDashboard,
  DashboardDefinition,
} from "../../services/dashboardService";
import { Option } from "../../services/models";
import PlatformContext from "../app/PlatformContext";
import UncontrolledModal from "../common/modal/UncontrolledModal";
import { DashboardDto } from "./dashboardDto";
import EditIdentifier from "./EditIdentifier";

interface AddEditDashboardProps {
  id?: number;
  kind: DashboardKindEnum.Dashboard | DashboardKindEnum.PropertyDashboard;
  onSubmit: (data: BaseDashboard) => void;
  onClose: () => void;
}

interface ValidationError {
  message?: string;
  field?: string;
}

const AddEditDashboard: React.FC<AddEditDashboardProps> = (props) => {
  const [serverError, setServerError] = useState<string>("");
  const [identifier, setIdentifier] = useState<string>(() =>
    dashboardService.identifier(),
  );
  const [dashboardName, setDashboardName] = useState<string>("");
  const [dashboardDesc, setDashboardDesc] = useState<string>("");
  const [dashboardCategory, setDashboardCategory] = useState<string[]>([]);
  const [dashboardDefinition, setDashboardDefinition] =
    useState<DashboardDefinition>({
      insights: [],
      isNew: !props.id,
    });
  const [availableDashboardCategories, setAvailableDashboardCategories] =
    useState<string[]>([]);
  const [shared, setShared] = useState(false);
  const [role, setRole] = useState<number>();
  const [fileError, setFileError] = useState(false);
  const [validationError, setValidationError] = useState<ValidationError>({});
  const { platform } = useContext(PlatformContext);
  const { overlordDomain, superAdmin, roles } = platform!;

  function validateForm(): ValidationError {
    /*if (!dashboardName) {
      return { message: "Dashboard Name is required.", field: "dashboardName" };
    } else*/ if (fileError) {
      return { message: "Not a dashboard JSON file", field: "file" };
    }
    return {};
  }

  function onCategoryUpdate(
    value: OnChangeValue<Option, true>,
    action: ActionMeta<Option>,
  ) {
    if (
      action.action === "create-option" ||
      action.action === "select-option"
    ) {
      const added = value[value.length - 1];
      const newCategory = [...dashboardCategory];
      newCategory.push(added.value);
      setDashboardCategory(newCategory);
    } else if (
      action.action === "pop-value" ||
      action.action === "remove-value"
    ) {
      const removed = action.removedValue;
      const newCategory = dashboardCategory.filter(
        (cat) => cat !== removed.value,
      );
      setDashboardCategory(newCategory);
    }
  }

  function onRoleUpdate(value: OnChangeValue<{ value: number }, false>) {
    setRole(value?.value ?? undefined);
  }

  function parseFile(file: File) {
    const reader = new FileReader();
    reader.addEventListener("load", (event) => {
      try {
        const json = JSON.parse(event.target?.result as string);
        if (!json.insights || !json.layout) throw Error("Not a dashboard");
        setFileError(false);
        setDashboardDefinition(json as DashboardDefinition);
      } catch (e) {
        setFileError(true);
      }
    });
    reader.readAsText(file);
  }

  function onSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    const validationError = validateForm();
    if (Object.keys(validationError).length) {
      setValidationError(validationError);
    } else {
      setValidationError(validationError);
      const dashboardDto: DashboardDto = {
        identifier,
        name: dashboardName,
        description: dashboardDesc,
        shared,
        internal: false, // no internal dashboards for now, just reports
        role_id: role || undefined,
        kind: props.kind,
        definition: dashboardDefinition,
        category: dashboardCategory,
      };
      const promise = props.id
        ? dashboardService.updateDashboard(props.id, dashboardDto)
        : dashboardService.createDashboard(dashboardDto);
      promise
        .then((dashboard) => props.onSubmit(dashboard))
        .catch((err) => {
          const response = err.response;
          if (response && response.status === 400 && response.data.message) {
            setServerError(response.data.message);
          } else {
            setServerError(unexpectedError);
          }
        });
    }
  }

  useEffect(() => {
    dashboardService
      .getAvailableDashboardCategories()
      .then((cats) => setAvailableDashboardCategories(cats));
  }, []);

  useEffect(() => {
    if (props.id) {
      dashboardService
        .getDashboardById(props.id)
        .then((res) => {
          setIdentifier(res.identifier);
          setDashboardName(res.name);
          setDashboardDesc(res.description);
          setShared(res.shared || false);
          setDashboardCategory(res.category);
          setDashboardDefinition(res.definition);
          setRole(res.role_id);
        })
        .catch(() => setServerError(unexpectedError));
    }
  }, [props.id]);

  function categoryToOption(category: string) {
    return { label: category, value: category };
  }

  const categoryOptions = availableDashboardCategories.map(categoryToOption);
  const selectedCategory = dashboardCategory.map(categoryToOption);

  const roleOptions = useMemo(
    () =>
      [{ value: 0, label: "No restriction" }].concat(
        roles.map((role) => ({ value: role.id, label: role.name })),
      ),
    [roles],
  );
  const selectedRole =
    roleOptions.find((o) => o.value === role) ?? roleOptions[0];

  return (
    <UncontrolledModal onClosed={props.onClose} onFormSubmit={onSubmit}>
      <ModalHeader>
        {props.id ? "Edit Dashboard" : "Add Dashboard"}
        <EditIdentifier identifier={identifier} setIdentifier={setIdentifier} />
      </ModalHeader>
      <ModalBody>
        {serverError ? (
          <UncontrolledAlert color="danger">{serverError}</UncontrolledAlert>
        ) : null}
        <FormGroup row>
          <Label sm={3}>Name</Label>
          <Col sm={9}>
            <Input
              type="text"
              value={dashboardName}
              onChange={(e) => setDashboardName(e.target.value)}
              invalid={validationError.field === "dashboardName"}
            />
            {validationError.field === "dashboardName" &&
            validationError.message ? (
              <FormFeedback>{validationError.message}</FormFeedback>
            ) : null}
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label sm={3}>Description</Label>
          <Col sm={9}>
            <Input
              type="textarea"
              value={dashboardDesc}
              onChange={(e) => setDashboardDesc(e.target.value)}
              invalid={validationError.field === "dashboardDesc"}
            />
            {validationError.field === "dashboardDesc" &&
            validationError.message ? (
              <FormFeedback>{validationError.message}</FormFeedback>
            ) : null}
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label sm={3}>Category</Label>
          <Col sm={9}>
            <CreatableSelect
              value={selectedCategory}
              options={categoryOptions}
              onChange={onCategoryUpdate}
              isMulti
              isSearchable
              isClearable={false}
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label sm={3}>Access Restriction</Label>
          <Col sm={9}>
            <Select
              value={selectedRole}
              options={roleOptions}
              onChange={onRoleUpdate}
              isSearchable
              isClearable={false}
            />
          </Col>
        </FormGroup>
        {superAdmin ? (
          <FormGroup row>
            <Label sm={3}>Import Dashboard</Label>
            <Col sm={9}>
              <Input
                id="file"
                type="file"
                accept=".json"
                onChange={(e) => parseFile(e.target.files![0])}
                invalid={validationError.field === "file"}
              >
                {validationError.field === "file" && validationError.message ? (
                  <FormFeedback>{validationError.message}</FormFeedback>
                ) : null}
              </Input>
            </Col>
          </FormGroup>
        ) : null}
        {superAdmin && overlordDomain ? (
          <FormGroup check row>
            <Col sm={{ size: 9, offset: 3 }}>
              <Label check className="ms-1">
                <Input
                  type="checkbox"
                  checked={shared}
                  onChange={() => setShared(!shared)}
                />{" "}
                Share with all organizations
              </Label>
            </Col>
          </FormGroup>
        ) : null}
      </ModalBody>
      <ModalFooter>
        <Label check className="me-auto">
          <Input
            type="checkbox"
            checked={dashboardDefinition.isNew}
            disabled={!!props.id}
            onChange={() =>
              setDashboardDefinition({
                ...dashboardDefinition,
                isNew: !dashboardDefinition.isNew,
              })
            }
            className="me-2"
          />
          New dashboard layout
        </Label>
        <Button color="secondary" onClick={props.onClose}>
          Cancel
        </Button>
        <Button type="submit" color="primary">
          {props.id ? "Save" : "Add"}
        </Button>
      </ModalFooter>
    </UncontrolledModal>
  );
};

export default AddEditDashboard;
