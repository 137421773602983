import clsx from "clsx";
import React, { CSSProperties, useMemo } from "react";
import { useWindowScroll } from "react-use";
import { Platform } from "../../services/platformService";
import "./layout.scss";
import { ModernFooter } from "./ModernFooter";
import { ModernNavbar } from "./ModernNavbar";

interface LayoutProps {
  children: React.ReactNode;
  platform?: Platform;
}

const isFirefox = () => {
  //keep them in this order
  //the earlier ones includes the later ones
  if (navigator.appVersion.match(/Edge\//)) {
    return false;
  } else if (navigator.appVersion.match(/like Gecko$/)) {
    return false;
  } else if (navigator.appVersion.match(/Chrome\//)) {
    return false;
  } else if (navigator.appVersion.match(/Safari\//)) {
    return false;
  } else {
    return true;
  }
};

// https://caniuse.com/?search=vw
// Currently all browsers but Firefox incorrectly consider 100vw to be the entire page width, including vertical scroll bar
const computeScrollbarDimensions = () => {
  const ff = isFirefox();
  const el = document.documentElement;
  return {
    scrollbarWidth: ff ? 0 : window.innerWidth - el.clientWidth,
    scrollbarHeight: ff ? 0 : window.innerHeight - el.clientHeight,
  };
};

const Layout: React.FC<LayoutProps> = ({ children, platform }) => {
  const { y } = useWindowScroll();
  const { scrollbarWidth, scrollbarHeight } = computeScrollbarDimensions();

  const cssVariables = useMemo(
    () =>
      ({
        "--scrollbar-width": `${scrollbarWidth}px`,
        "--scrollbar-height": `${scrollbarHeight}px`,
      }) as CSSProperties,
    [scrollbarWidth, scrollbarHeight],
  );

  return (
    <div
      className={clsx("layout", y >= 48 && "window-scrolled")}
      style={cssVariables}
    >
      {platform && <ModernNavbar platform={platform} />}
      {children}
      {platform && !platform.embedInfo && <ModernFooter platform={platform} />}
    </div>
  );
};

export default Layout;
