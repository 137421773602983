import React, { useCallback, useContext } from "react";
import { toast } from "react-fox-toast";
import { unexpectedError } from "../../../constants";

export interface WithAlertModalProps {
  setAlert: (message: string, success: boolean) => void;
  onUnexpectedError: (e?: any) => void;
}

const WithAlertContext = React.createContext<WithAlertModalProps>({
  setAlert: () => {},
  onUnexpectedError: () => {},
});

export const useSetAlert = (): WithAlertModalProps["setAlert"] =>
  useContext(WithAlertContext).setAlert;

export const useOnUnexpectedError =
  (): WithAlertModalProps["onUnexpectedError"] =>
    useContext(WithAlertContext).onUnexpectedError;

function withAlertModal<T>(Component: React.FC<T & WithAlertModalProps>) {
  return function (props: T) {
    const setAlert = useCallback(function (message: string, success: boolean) {
      if (success) toast.success(message);
      else toast.error(message);
    }, []);

    const onUnexpectedError = useCallback(
      (e: any) => {
        if (e) console.warn(e);
        setAlert(unexpectedError, false);
      },
      [setAlert],
    );

    return (
      <WithAlertContext.Provider value={{ setAlert, onUnexpectedError }}>
        <Component
          {...props}
          setAlert={setAlert}
          onUnexpectedError={onUnexpectedError}
        />
      </WithAlertContext.Provider>
    );
  };
}

export default withAlertModal;
