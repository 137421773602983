import {
  faCogs,
  faDownload,
  faFolderTree,
  faRedo,
} from "@fortawesome/pro-light-svg-icons";
import {
  CauseEntity,
  dateStr,
  durationOf,
  notNull,
} from "@joyhub-integration/shared";
import { keyBy } from "lodash";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { getCauses } from "../../../services/causeService";
import {
  getIntegrationById,
  getSyncHistory,
  Integration,
  reingestSyncFiles,
  syncDownloadUrl,
  SyncHistoryEntity,
} from "../../../services/integrationsService";
import { dateOf } from "../../../utils/date";
import PlatformContext from "../../app/PlatformContext";
import withAlertModal, {
  WithAlertModalProps,
} from "../../common/alert/withAlertModal";
import { LoadilyFadily } from "../../common/allFadily";
import ActionBar from "../../common/button/ActionBar";
import { ButtonWithIconProps } from "../../common/button/ButtonWithIcon";
import TableWithSelection, {
  KeyValue,
} from "../../common/table/TableWithSelection";
import { ModernCrumbar } from "../../layout/ModernCrumbar";
import { tooltippedCause } from "./ComputeHistoryPage";

type SyncHistoryParams = {
  id: string;
};

const SyncHistoryPage: React.FC<WithAlertModalProps> = ({
  setAlert,
  onUnexpectedError,
}) => {
  const params = useParams<SyncHistoryParams>();
  const [syncHistory, setSyncHistory] = useState<Array<SyncHistoryEntity>>();
  const [causes, setCauses] = useState<Record<number, CauseEntity>>({});
  const [selected, setSelected] = useState<SyncHistoryEntity>();
  const [integration, setIntegration] = useState<Integration>();
  const [loaded, setLoaded] = useState(false);
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(0);
  const systemId = parseInt(params.id!);
  const { admin, organization, superAdmin } =
    useContext(PlatformContext).platform!;
  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    Promise.all([getSyncHistory(systemId), getIntegrationById(systemId)])
      .then(([history, integration]) => {
        setSyncHistory(history);
        setIntegration(integration);
        return admin
          ? getCauses(history.map((h) => h.cause_id).filter(notNull))
          : [];
      })
      .then((newCauses) => {
        setCauses(keyBy(newCauses, "id"));
      })
      .catch(onUnexpectedError)
      .finally(() => {
        setLoading(false);
        setLoaded(true);
      });
  }, [admin, onUnexpectedError, refresh, systemId]);

  const buttonProps: ButtonWithIconProps[] = [
    {
      label: "File List",
      icon: faFolderTree,
      onClick: () => navigate(`files/${selected!.id}`),
      className: "jh-btn-primary",
      disabled: !selected,
    },
    {
      label: "Download Files",
      icon: faDownload,
      href: selected ? `${syncDownloadUrl(systemId, selected!.id)}` : undefined,
      className: "jh-btn-primary",
      disabled: !selected,
      download: true,
    },
    ...(!superAdmin
      ? []
      : [
          {
            label: "Reingest Failing Files",
            icon: faCogs,
            onClick: () =>
              reingestSyncFiles(systemId, selected!.id)
                .then(() => setAlert("Ingest started", true))
                .catch(onUnexpectedError),
            className: "jh-btn-primary",
            disabled: !selected,
            download: true,
          },
        ]),
  ];

  const rightButtonProps: ButtonWithIconProps[] = [
    {
      icon: faRedo,
      onClick: () => setRefresh((r) => 1 + r),
      outline: true,
      tooltip: "Refresh",
      disabled: loading,
    },
  ];

  const tableCols = useMemo(
    () =>
      new Array<KeyValue<SyncHistoryEntity>>(
        {
          key: "started",
          title: "Started",
          toValue: (s) => dateStr(s.started),
          sortValue: (s) => dateOf(s.started)?.getTime(),
        },
        {
          key: "finished",
          title: "Duration",
          toValue: (s) => durationOf(s.started, s.finished),
        },
        {
          key: "dataDate",
          title: "Data from",
          toValue: (s) => s.data_date, // ISO, could be localised
        },
        { key: "success_count", title: "Sync Successes" },
        { key: "failure_count", title: "Sync Failures" },
        { key: "file_success_count", title: "Import Successes" },
        { key: "file_failure_count", title: "Import Failures" },
        ...(admin
          ? [
              {
                key: "cause",
                title: "Cause",
                toValue: ({ id, cause_id }: SyncHistoryEntity) =>
                  tooltippedCause(id, causes[cause_id]),
              },
            ]
          : []),
      ),
    [admin, causes],
  );

  return (
    <>
      <ModernCrumbar
        primary="Manage Integrations"
        primaryPath="/admin/integrations"
        secondary={`"${integration?.name ?? "?"}" Sync History for ${
          organization?.name ?? "Unknown org"
        } (${integration?.vendor ?? "?"})`}
      />
      <LoadilyFadily loaded={loaded} className="jh-page-layout">
        <ActionBar
          buttonProps={buttonProps}
          rightButtonProps={rightButtonProps}
        />
        <div className="jh-page-content pt-0 admin-page page-scroll">
          <TableWithSelection<SyncHistoryEntity>
            selected={selected}
            onSelectedChange={(selected) => setSelected(selected)}
            columns={tableCols}
            rows={syncHistory}
            sortColumn="started"
            sortDirection="desc"
          />
        </div>
      </LoadilyFadily>
    </>
  );
};

export default withAlertModal(SyncHistoryPage);
