import {
  ReportDataColumn,
  ReportDataRow,
  ReportGrouping,
  ReportSorting,
  naturallyCompareRows,
  rowComparer,
} from "@joyhub-integration/shared";
import clsx from "clsx";
import { groupBy } from "lodash";
import React from "react";
import { propertyDashboardUrl } from "../../../services/dashboardService";
import Row from "./Row";

const Body = ({
  rows,
  columns,
  grouping,
  sortOrder,
}: {
  rows: ReportDataRow[];
  columns: ReportDataColumn[];
  grouping?: ReportGrouping;
  sortOrder: ReportSorting[];
}) => {
  const {
    Normal: normalRows,
    Group: groupRows,
    Overall: overallRows,
  } = groupBy(rows, "type");
  const footerGroups = grouping?.layout === "Footer";
  const compareRows = rowComparer(sortOrder);
  const compareGroups =
    grouping?.sort === "Alphabetic" ? naturallyCompareRows : compareRows;

  return (
    <tbody>
      {normalRows
        ?.filter((row) => footerGroups || row.group == null)
        .sort(compareRows)
        .map((row, j) => {
          const to =
            typeof row.key !== "number"
              ? undefined
              : propertyDashboardUrl(row.key);
          return <Row key={`row-${j}`} row={row} columns={columns} to={to} />;
        })}
      {groupRows
        ?.sort(compareGroups)
        .map(({ key: groupValue, ...groupRow }, index) => (
          <React.Fragment key={index}>
            <Row
              className={clsx("Group", { First: index === 0 })}
              row={groupRow}
              columns={columns}
            />
            {normalRows
              ?.filter((p) => !footerGroups && p.group === groupValue)
              .sort(compareRows)
              .map((row, index) => {
                const to =
                  typeof row.key !== "number"
                    ? undefined
                    : propertyDashboardUrl(row.key);
                return (
                  <Row
                    key={index}
                    className="Grouped"
                    row={row}
                    columns={columns}
                    to={to}
                  />
                );
              })}
          </React.Fragment>
        ))}
      {overallRows?.map((row, index) => (
        <Row key={index} className="Overall" row={row} columns={columns} />
      ))}
    </tbody>
  );
};

export default Body;
