import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  ModalBody,
  ModalFooter,
  ModalHeader,
  UncontrolledAlert,
} from "reactstrap";
import { unexpectedError } from "../../constants";
import dashboardService, {
  BaseDashboard,
} from "../../services/dashboardService";
import { SingleReportResult } from "../../services/reportsService";
import UncontrolledModal from "../common/modal/UncontrolledModal";
import EditIdentifier from "../dashboard/EditIdentifier";

interface CopyModalProps {
  report: SingleReportResult;
  onSubmit: (d: BaseDashboard) => void;
  onClose: () => void;
}

interface ValidationError {
  message?: string;
  field?: string;
}

const CopyModal: React.FC<CopyModalProps> = ({
  report,
  onClose,
  onSubmit: onSubmitProp,
}) => {
  const id = report.id;
  const originalName = report.name;
  const replaceable = report.immutable;
  const [serverError, setServerError] = useState("");
  const [validationError, setValidationError] = useState<ValidationError>({});
  const [identifier, setIdentifier] = useState("");
  const [name, setName] = useState(originalName);
  const [replace, setReplace] = useState(false);

  useEffect(() => {
    const copyName = `Copy of ${originalName}`;
    if (replace) {
      if (name === copyName) setName(originalName);
      // react-hooks/exhaustive-deps suggests useReducer for report.identifier?
      setIdentifier(report.identifier);
    } else {
      if (name === originalName) setName(copyName);
      setIdentifier(dashboardService.identifier());
    }
  }, [name, originalName, replace, report.identifier]);

  function validateForm(): ValidationError {
    if (!name) {
      return { message: "Name is required.", field: "name" };
    } else if (!identifier) {
      return { message: "Identifier is required.", field: "identifier" };
    }
    return {};
  }

  function onSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    const validationError = validateForm();
    setValidationError(validationError);
    if (!Object.keys(validationError).length) {
      setServerError("");
      dashboardService
        .copyDashboard(id, identifier, name)
        .then((d) => onSubmitProp(d))
        .catch(() => setServerError(unexpectedError));
    }
  }

  return (
    <UncontrolledModal onClosed={onClose} onFormSubmit={onSubmit}>
      <ModalHeader>
        Copy report
        <EditIdentifier identifier={identifier} setIdentifier={setIdentifier} />
      </ModalHeader>
      <ModalBody>
        {serverError ? (
          <UncontrolledAlert color="danger">{serverError}</UncontrolledAlert>
        ) : null}
        <FormGroup row>
          <Label sm={3}>Name</Label>
          <Col sm={9}>
            <Input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              invalid={validationError.field === "name"}
            />
            {validationError.field === "name" && validationError.message ? (
              <FormFeedback>{validationError.message}</FormFeedback>
            ) : null}
          </Col>
        </FormGroup>
        {replaceable && (
          <FormGroup row>
            <Col sm={{ size: 9, offset: 3 }}>
              <Label check>
                <Input
                  id="replace"
                  type="checkbox"
                  checked={replace}
                  onChange={(e) => setReplace(e.target.checked)}
                  className="me-2"
                />
                Replace the Original Report for this Organization
              </Label>
            </Col>
          </FormGroup>
        )}
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={onClose}>
          Cancel
        </Button>
        <Button type="submit" className="jh-btn-primary">
          Copy
        </Button>
      </ModalFooter>
    </UncontrolledModal>
  );
};

export default CopyModal;
