import {
  AccountTreeEntity,
  FullAccountTreeNode,
  PureDate,
  formatGlCode,
} from "@joyhub-integration/shared";
import clsx from "clsx";
import React, { useEffect, useMemo, useState } from "react";
import { Col, Row } from "reactstrap";
import axios from "../../services/axios";
import { apiUrl, axiosConfig } from "../../utils/api";
import { arrayToDict } from "../../utils/arrayToDict";
import { dateToYMD, toShortMonthAndYear } from "../../utils/date";
import { usePropertiesSelectionQueryParam } from "../../utils/useQueryParams";
import Loading from "../app/Loading";
import { useOnUnexpectedError } from "../common/alert/withAlertModal";
import { MonthlyTotalsResponse } from "./types";
import { bookName, formatAmount } from "./utils";

// Different systems indent differently, Flat matches a Moss document, Depth is more readable
// but is a little bit tied to the structure of GL tree we generate from our imports.
const IndentStyle: "Flat" | "Depth" = "Depth" as any;

interface AccountTreeViewProps {
  system: number;
  numMonths: number;
  accountTree: AccountTreeEntity;
  treeNodes: FullAccountTreeNode[];
  book: number;
  propertiesLabel: string;
  periodLabel?: string;
  onBreakout: (node: FullAccountTreeNode) => void;
}

export const getMonths = (numMonths: number) =>
  Array(numMonths)
    .fill(undefined)
    .map((_, index) => {
      const date = new Date();
      date.setDate(1);
      date.setMonth(date.getMonth() - numMonths + 1 + index);
      return dateToYMD(date);
    });

const AccountTreeView: React.FC<AccountTreeViewProps> = ({
  system,
  numMonths,
  accountTree,
  book,
  treeNodes,
  propertiesLabel,
  periodLabel,
  onBreakout,
}) => {
  const [loaded, setLoaded] = useState(false);
  const [totals, setTotals] = useState<Record<number, Record<string, number>>>(
    {},
  );
  const [selection] = usePropertiesSelectionQueryParam();
  const onUnexpectedError = useOnUnexpectedError();

  const months = useMemo(() => getMonths(numMonths), [numMonths]);
  const from = months[0];
  const to = months[months.length - 1];

  useEffect(() => {
    setLoaded(false);
    const propertiesParam = encodeURIComponent(JSON.stringify(selection ?? {}));
    axios
      .get(
        apiUrl(
          `/gl/monthly?tree=${accountTree.tree_id}&system=${system}&book=${book}&properties=${propertiesParam}&from=${from}&to=${to}`,
        ),
        axiosConfig,
      )
      .then((res) => res.data as MonthlyTotalsResponse)
      .then(({ totals }) => {
        const dollarAmounts = arrayToDict(
          totals,
          (t) => `${t.gl_account_id}:${t.post_month}`,
        );
        const dollars: Record<number, Record<string, number>> = {};
        const computeTotal = (
          node: FullAccountTreeNode,
          month: string,
        ): number | undefined => {
          let total: number | undefined = undefined;
          node.children.forEach((child) => {
            const subtotal = computeTotal(child, month);
            if (subtotal !== undefined) total = (total ?? 0) + subtotal;
          });
          node.accounts.forEach((account) => {
            const subtotal =
              dollarAmounts[`${account.gl_account_id}:${month}`]?.total;
            if (subtotal !== undefined) total = (total ?? 0) + subtotal;
          });
          if (total !== undefined) {
            let monthly = dollars[node.tree_node_id];
            if (monthly === undefined)
              dollars[node.tree_node_id] = monthly = {};
            monthly[month] = total;
            monthly["Total"] = (monthly["Total"] ?? 0) + total;
          }
          return total;
        };
        treeNodes.forEach((node) => {
          months.forEach((month) => {
            computeTotal(node, month);
          });
        });
        setTotals(dollars);
        setLoaded(true);
      })
      .catch(onUnexpectedError);
  }, [
    system,
    months,
    book,
    accountTree,
    treeNodes,
    from,
    to,
    onUnexpectedError,
    selection,
  ]);

  // )`${isTotalRow ? "total-row" : ""} ${hasTotal ? "clickable" : ""}

  // A tree node usually has one account but sometimes has multiple accounts. The report only
  // displays the tree node, with the tree node code, not the underlying accounts or their codes.
  // Looking at samples this seems reasonable.
  const renderAccountNodes = (
    nodes: FullAccountTreeNode[],
    depth: number,
    parent: number,
  ) => {
    const hasAnything = nodes.some(
      (node) => totals[node.tree_node_id]?.Total !== undefined,
    );
    return (
      hasAnything &&
      nodes.map((node, index) => {
        const monthly = totals[node.tree_node_id];
        const hasTotal = monthly?.Total !== undefined;
        const coalesce = hasTotal ? 0 : undefined; // If the row has a total, coalesce values to 0
        // This works because Header/Total rows are associated with just a single account row
        const accountType = node.accounts[0]?.gl_account_type;
        const isTotalRow = accountType === "Total";
        const isHeaderRow = accountType === "Header";
        const indentClass =
          IndentStyle === "Flat"
            ? !isTotalRow && !isHeaderRow
              ? "indented"
              : ""
            : `depth-${depth + (isHeaderRow ? -1 : 0)}`;
        return (
          <React.Fragment key={node.tree_node_id}>
            {renderAccountNodes(node.children, 1 + depth, node.tree_node_id)}
            {isHeaderRow || hasTotal ? (
              <tr
                className={clsx(
                  isTotalRow && "total-row",
                  isHeaderRow && "header-row",
                )}
              >
                <th>
                  {formatGlCode(
                    node.tree_node_code,
                    accountTree.gl_account_format_mask,
                  )}
                </th>
                <th className={clsx(indentClass, "node-name")}>
                  {hasTotal ? (
                    <a
                      href="#na"
                      onClick={(e) => {
                        e.preventDefault();
                        onBreakout(node); // this should just be a real hyperlink
                      }}
                    >
                      {node.tree_node_name}
                    </a>
                  ) : (
                    node.tree_node_name
                  )}
                </th>
                {months.map((month, i) => (
                  <td key={i}>{formatAmount(monthly?.[month] ?? coalesce)}</td>
                ))}
                <td>{formatAmount(monthly?.["Total"])}</td>
              </tr>
            ) : null}
          </React.Fragment>
        );
      })
    );
  };

  return !loaded ? (
    <Loading />
  ) : (
    <Row key={accountTree.tree_id}>
      <Col className="account-tree">
        <div className="print-header">
          <div className="print-properties">Properties = {propertiesLabel}</div>
          <div className="print-title">
            {accountTree.tree_name} ({periodLabel ?? `${months.length} months`})
          </div>
          <div className="print-period">
            Period = {toShortMonthAndYear(new PureDate(from))} –{" "}
            {toShortMonthAndYear(new PureDate(to))}
          </div>
          <div className="print-period">Book = {bookName(book)}</div>
        </div>
        <div className="finance-table-wrapper jh-max-height">
          <table className="finance-table">
            <thead>
              <tr>
                <th style={{ width: "3%" }} className="br-0" />
                <th style={{ width: "6%" }} className="br-0" />
                {months.map((month, i) => (
                  <th style={{ width: "7%" }} key={i}>
                    {toShortMonthAndYear(new PureDate(month))}
                  </th>
                ))}
                <th style={{ width: "7%" }}>Total</th>
              </tr>
            </thead>
            <tbody>{renderAccountNodes(treeNodes, 0, -1)}</tbody>
          </table>
        </div>
      </Col>
    </Row>
  );
};

export default AccountTreeView;
