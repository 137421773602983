import {
  AdHocReportRequest,
  AllInsightsData,
  AllInsightsRequest,
  DashboardKindEnum,
  GenericReportData,
  PropertiesSelection,
  PureDate,
  ReportColumn,
  ReportInsight,
  ReportSheet,
  SendAdHocReportRequest,
  SheetRows,
} from "@joyhub-integration/shared";
import { PropertyGroup } from "../components/properties/propertyGroupService";
import { apiUrl, axiosBlobConfig, axiosJsonConfig } from "../utils/api";
import { isNumberArray } from "../utils/array";
import { toFullMonthDayAndYear } from "../utils/date";
import axios from "./axios";
import { Insight } from "./models";
import { Property, getPropertySelectionLabel } from "./propertiesService";
import { SendEmailResult } from "./scheduledEmailsService";
import { Organization } from "./usersService";

export const insightSheet = (
  insight: Insight,
  forceByProperty: boolean,
  subtitle?: string,
): ReportSheet => {
  const byDimension =
    insight.categoryInformation != null &&
    insight.categoryInformation.dimensionKey !== "month" &&
    insight.categoryInformation.dimensionKey !== "resultCol";
  const byProperty =
    (insight.calculationType === "INSTANT" &&
      insight.visualizationType[0] === "TABLE") ||
    insight.byPropertyCalculationType != null ||
    forceByProperty;

  let columns: ReportColumn[] = [];
  const insights = insight.drillInInsights ?? insight.insightIds;
  const bucketize =
    insight.tableChartOptions?.bucketCols ||
    insight.tableChartOptions?.bucketRows;
  if (insight.export != null) {
    columns.push(...insight.export.columns);
  } else if (isNumberArray(insights)) {
    if (insights.length === 1) {
      columns.push({
        header: insight.name,
        insight: insights[0],
      });
    } else if (insights.length === 2) {
      columns.push({
        header: insight.name,
        insight: { numerator: insights[0], denominator: insights[1] },
      });
    }
  } else {
    for (const {
      insightIds,
      resultCol,
      tableColOrRowBucket,
      subBucket,
      yoy,
      numFmt,
      operand,
      dimensionFilter,
    } of insights) {
      const filter =
        dimensionFilter == null ? null : Object.entries(dimensionFilter)[0];
      const header = bucketize
        ? resultCol.split("-")[1].trim()
        : !resultCol.startsWith("dimensions")
          ? resultCol
          : resultCol.split(".")[1]; // "dimensions.Foo"
      const firstInsight =
        filter == null
          ? insightIds[0]
          : { insight: insightIds[0], dimension: filter[0], value: filter[1] };
      const insight: ReportInsight =
        insightIds.length === 1
          ? firstInsight
          : operand === "-"
            ? { minuend: firstInsight, subtrahends: [insightIds[1]] }
            : { numerator: firstInsight, denominator: insightIds[1] };
      // Just assuming that all by dimension views are sum over 12 months
      const period = byDimension
        ? ({ interval: "Month", count: 12 } as const)
        : undefined;
      const aggregate = byDimension ? "Sum" : undefined;
      const versus = yoy
        ? ({
            insight,
            timeFrame: "PriorYear",
            comparison: "Delta",
          } as const)
        : undefined;
      // If a dimensioned insight is the only column then don't bother with a bucket
      // title, but if there are other columns then we need a bucket title.
      const headerAsBucket =
        resultCol.startsWith("dimensions") &&
        insights.length > 1 &&
        !byDimension
          ? header
          : undefined;
      columns.push({
        header,
        insight,
        period,
        aggregate,
        versus,
        bucket: tableColOrRowBucket ?? headerAsBucket,
        subBucket,
        format: { numFmt },
      });
    }
  }
  const name = "Data"; // sheet name can only include basic characters
  const titles = [
    {
      value: insight.name,
      bold: true,
      size: 24,
    },
    ...(subtitle ? [{ value: subtitle }] : []),
  ];
  let leadColumns: ReportColumn[];
  let rows: SheetRows;

  if (byDimension) {
    const dimension = insight.categoryInformation!.dimensionKey;
    leadColumns = [
      {
        header: "",
        dimension,
        width: 36,
      },
    ];
    rows = { rows: "Dimension", dimension };
  } else if (byProperty) {
    leadColumns = [
      //{ header: "", propertyAttr: "property_code", width: 12 },
      {
        header: "",
        attribute: "property_name",
        width: 36,
        sort: { order: "Ascending" },
      },
    ];
    rows = { rows: "Property" };
  } else {
    leadColumns = [
      {
        header: "",
        dateFmt: "mmmm yyyy",
        width: 24,
        sort: { order: "Descending" },
      },
    ];
    rows = { rows: "Date", interval: "Month", count: 12 };
  }
  return {
    name,
    titles,
    rows,
    columns: [...leadColumns, ...columns],
  };
};

export const downloadAdHocReportData = async (req: AdHocReportRequest<false>) =>
  axios.post(apiUrl("/reports/adhoc/download"), req, axiosBlobConfig);

export const getAdHocReportData = async (req: AdHocReportRequest<false>) =>
  axios
    .post<
      GenericReportData<true>
    >(apiUrl("/reports/adhoc/data"), req, axiosJsonConfig)
    .then((res) => res.data);

export const renderAdHocReport = async (req: AdHocReportRequest<false>) =>
  axios
    .post<{
      body: string;
    }>(apiUrl("/reports/adhoc/render"), req, axiosJsonConfig)
    .then((res) => res.data.body);

export const sendAdHocReport = async (req: SendAdHocReportRequest<false>) =>
  axios
    .post<SendEmailResult>(apiUrl("/reports/adhoc/send"), req, axiosJsonConfig)
    .then((res) => res.data);

export const getAllData = async (def: AllInsightsRequest<false>) =>
  axios
    .post<AllInsightsData<true>>(apiUrl("/exports/all"), def, axiosJsonConfig)
    .then((res) => res.data);

export const downloadAllData = async (def: AllInsightsRequest<false>) =>
  axios.post(apiUrl("/exports/download"), def, axiosBlobConfig);

// TODO: We ought to have some global Redux state with all properties etc
// so we don't have to pass everything around everywhere.

export const insightReportRequest = (
  insight: Insight,
  selection: PropertiesSelection,
  allProperties: Property[],
  propertyGroups: PropertyGroup[],
  date: PureDate = new PureDate(),
  byProperty: boolean = false,
  organization: Organization,
): AdHocReportRequest<false> => {
  const propertiesLabel = getPropertySelectionLabel(
    selection,
    allProperties,
    propertyGroups,
    true,
    organization.configuration.customColumns ?? [],
  );
  const subtitle = `${toFullMonthDayAndYear(
    date,
  )}; Properties: ${propertiesLabel}`;
  return {
    kind: DashboardKindEnum.GenericReport,
    name: insight.name,
    definition: {
      asOf: date.toDateString(),
      sheets: [insightSheet(insight, byProperty, subtitle)],
    },
    configuration: {
      properties: selection,
    },
  };
};
