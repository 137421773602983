import clsx from "clsx";
import { useCallback, useEffect, useRef, useState } from "react";
import { API_KEY } from "../../services/unitMap/unitMap";
import { Map, Unit } from "../../services/unitMap/unitMapSdk";

const UNIT_BASE_COLOR = "#fff";
const UNIT_HIGHLIGHT_COLOR = "#189ae3";
const UNIT_HOVER_COLOR = "#cde3f7";

const mouseOverUnit = ({ unit }: { unit: Unit }) => {
  unit.color(UNIT_HOVER_COLOR);
};

const UnitMap = ({
  className,
  mapId,
  floorId,
  highlightedUnits,
  onUnitClick,
}: {
  className: string;
  mapId: number;
  floorId?: number;
  highlightedUnits?: string[];
  onUnitClick: ({
    unit,
    originalEvent,
  }: {
    unit: Unit;
    originalEvent: MouseEvent;
  }) => void;
}) => {
  const map = useRef<Map>();
  const mapContainer = useRef<HTMLDivElement>(null);

  const [unitMapReady, setUnitMapReady] = useState(false);
  const [unitMapLoaded, setUnitMapLoaded] = useState(false);

  const mouseOffUnit = useCallback(
    ({ unit }: { unit: Unit }) => {
      if (highlightedUnits?.includes(unit.id)) {
        unit.color(UNIT_HIGHLIGHT_COLOR);
      } else {
        unit.color(UNIT_BASE_COLOR);
      }
    },
    [highlightedUnits],
  );

  useEffect(() => {
    setUnitMapLoaded(false);

    if (mapId && unitMapReady) {
      map.current?.load(mapId, () => setUnitMapLoaded(true));

      map.current?.on("unit:click", onUnitClick);
      map.current?.on("unit:mouseover", mouseOverUnit);
    }

    return () => {
      map.current?.off("unit:click", onUnitClick);
      map.current?.off("unit:mouseover", mouseOverUnit);
    };
  }, [mapId, unitMapReady, onUnitClick]);

  useEffect(() => {
    if (floorId && unitMapLoaded) {
      const levels = map.current?.levels();
      levels?.where({ floor: floorId }).show();
      levels?.has({ floor: true }).except({ floor: floorId }).hide();
    }
  }, [floorId, unitMapLoaded]);

  useEffect(() => {
    if (unitMapLoaded) {
      map.current?.units().color(UNIT_BASE_COLOR);
      if (highlightedUnits && highlightedUnits.length > 0) {
        map.current?.units().find(highlightedUnits).color(UNIT_HIGHLIGHT_COLOR);
      }

      map.current?.on("unit:mouseout", mouseOffUnit);
    }

    return () => {
      map.current?.off("unit:mouseout", mouseOffUnit);
    };
  }, [highlightedUnits, unitMapLoaded, mouseOffUnit]);

  useEffect(() => {
    setUnitMapReady(false);
    if (mapContainer.current) {
      map.current = window.unitmap(mapContainer.current, API_KEY);
      setUnitMapReady(true);
    }
  }, [mapContainer]);

  return <div ref={mapContainer} className={clsx(className)}></div>;
};

export default UnitMap;
