import axios from "axios";
import { apiUrl } from "../utils/api";
import { ConcurrencyManager } from "../utils/axios-concurrency";
import { getPlatformUrl } from "./platformUrl";
import { getSessionOrganization } from "./sessionOrganization";

export let lastRequestTime = new Date().getTime();

const MAX_CONCURRENT_REQUESTS = 5;

ConcurrencyManager(axios, MAX_CONCURRENT_REQUESTS);

axios.interceptors.request.use((request) => {
  lastRequestTime = new Date().getTime();
  const org = getSessionOrganization();
  if (org != null && request.url !== getPlatformUrl()) {
    request.headers.set("X-Organization-Id", `${org}`);
  }

  const pathname = document.location.pathname;
  const matchEmbed = pathname.match(/\/embed\/(.*)/);

  if (matchEmbed && matchEmbed[1]) {
    const embedUUID = matchEmbed[1];
    request.headers.set("Embed-UUID", embedUUID);
    const properties = new URLSearchParams(document.location.search).get(
      "properties",
    );
    if (properties) request.headers.set("Embed-Properties", properties);
  }

  return request;
});

axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (
      error?.response?.status === 401 &&
      error?.response?.config?.url !== apiUrl("/platform")
    ) {
      window.location.reload();
      return new Promise(() => {}); // suppress the error modal
    }
    return Promise.reject(error);
  },
);

export default axios;
