import {
  faBuilding,
  faCalendarAlt,
  faCheck,
  faEye,
  faPaperclip,
  faPauseCircle,
  faPlayCircle,
  faTrashAlt,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useEffect, useMemo, useState } from "react";
import ClampLines from "react-clamp-lines";
import {
  Button,
  Card,
  CardBody,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import { Platform } from "../../services/platformService";
import { getPropertySelectionLabel } from "../../services/propertiesService";
import {
  fetchRenderedEmail,
  ScheduledEmail,
  updateScheduledEmailStatus,
} from "../../services/scheduledEmailsService";
import PlatformContext from "../app/PlatformContext";
import withAlertModal, {
  WithAlertModalProps,
} from "../common/alert/withAlertModal";
import IconWithText from "../common/icon/IconWithText";
import UncontrolledModal from "../common/modal/UncontrolledModal";

interface ScheduledEmailsProps {
  scheduledEmails: ScheduledEmail[];
  onClose: () => void;
  onScheduledEmailsChange: (definition_id?: number) => void;
  onRemoveClick: (scheduledEmail: ScheduledEmail) => void;
}

const getSchedule = (schedule: string): string => {
  if (!schedule) return "Unknown";
  if (schedule.startsWith("Month")) {
    const withoutMonth = schedule.split("Month")[1];
    const period = withoutMonth.split("Weekday")[0];
    return `${period} of Month`;
  } else {
    return `Weekly on ${schedule}`;
  }
};

const getGoalText = (notifyWhenGoal: string): string => {
  if (!notifyWhenGoal) return "Unknown";
  if (notifyWhenGoal === "met") {
    return "Notifies when the goal is met.";
  } else if (notifyWhenGoal === "failed") {
    return "Notifies when goal has not been met.";
  } else {
    return "Always notifies.";
  }
};

const ScheduledEmails: React.FC<WithAlertModalProps & ScheduledEmailsProps> = ({
  scheduledEmails,
  onClose,
  onRemoveClick,
  onScheduledEmailsChange,
  onUnexpectedError,
}) => {
  const { propertiesMap, propertyGroups, organization } = useContext(
    PlatformContext,
  ).platform as Platform;
  const sortedScheduledEmails = useMemo(() => {
    return scheduledEmails.sort((a, b) => a.id - b.id);
  }, [scheduledEmails]);
  const [previewEmailId, setPreviewEmailId] = useState<number>();

  const renderEmailCard = (scheduledEmail: ScheduledEmail) => {
    const setEmailStatus = (id: number, active: boolean) => {
      updateScheduledEmailStatus(id, active)
        .then(() => {
          onScheduledEmailsChange(scheduledEmail.definition_id);
        })
        .catch(onUnexpectedError);
    };

    const attachmentType = scheduledEmail.configuration.attachmentType;
    const attachmentTypeText =
      attachmentType === "all"
        ? "Excel, PDF"
        : attachmentType === "excel"
          ? "Excel"
          : (attachmentType?.toUpperCase() ?? "Excel");

    return (
      <Card
        key={scheduledEmail.id}
        className="scheduled-email-card mb-2 flex-row"
      >
        <CardBody style={{ flex: 20 }}>
          <ClampLines
            id={scheduledEmail.id.toString()}
            text={scheduledEmail.emailAddresses.join(", ")}
            lines={2}
            buttons={false}
            innerElement="p"
            className="card-text flex-grow"
          />
          <div className="flex-row justify-content-end align-items-end">
            {scheduledEmail.configuration.notifyWhenGoal ? (
              <IconWithText
                icon={faCheck}
                text={getGoalText(scheduledEmail.configuration.notifyWhenGoal)}
              />
            ) : null}
            <IconWithText
              className="ms-3 me-2"
              icon={faPaperclip}
              text={attachmentTypeText}
            />
            <IconWithText
              className="ms-3 me-2"
              icon={faCalendarAlt}
              text={getSchedule(scheduledEmail.schedule)}
            />
            <IconWithText
              className="ms-3 me-2"
              icon={faBuilding}
              text={getPropertySelectionLabel(
                scheduledEmail.configuration.properties,
                Object.values(propertiesMap),
                propertyGroups,
                undefined,
                organization.configuration.customColumns ?? [],
              )}
            />
          </div>
        </CardBody>
        <div
          className="flex-col align-items-center justify-content-around p-2 scheduled-email-actions"
          style={{ flex: 1 }}
        >
          <FontAwesomeIcon
            className="scheduled-email-action-icon mb-2"
            icon={scheduledEmail.active ? faPauseCircle : faPlayCircle}
            onClick={() =>
              setEmailStatus(scheduledEmail.id, !scheduledEmail.active)
            }
          />
          <FontAwesomeIcon
            className="scheduled-email-action-icon"
            icon={faTrashAlt}
            onClick={() => onRemoveClick(scheduledEmail)}
          />
          {scheduledEmail.configuration.notifyWhenGoal ? (
            <FontAwesomeIcon
              className="scheduled-email-action-icon mt-2"
              icon={faEye}
              onClick={() => setPreviewEmailId(scheduledEmail.id)}
              title="Preview Email"
            />
          ) : null}
        </div>
      </Card>
    );
  };

  return (
    <>
      <UncontrolledModal onClosed={onClose} size="lg">
        <ModalHeader>Scheduled Emails</ModalHeader>
        <ModalBody style={{ maxHeight: "50vh", overflow: "auto" }}>
          {sortedScheduledEmails.length
            ? sortedScheduledEmails.map(renderEmailCard)
            : "No scheduled emails"}
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={onClose}>
            Close
          </Button>
        </ModalFooter>
      </UncontrolledModal>
      {previewEmailId ? (
        <GoalEmailPreview
          previewEmailId={previewEmailId}
          onClose={() => setPreviewEmailId(undefined)}
        />
      ) : null}
    </>
  );
};

export default withAlertModal(ScheduledEmails);

const GoalEmailPreview: React.FC<{
  previewEmailId: number;
  onClose: () => void;
}> = ({ previewEmailId, onClose }) => {
  const [email, setEmail] = useState<{ subject: string; body: string }>();
  useEffect(() => {
    fetchRenderedEmail(previewEmailId).then((res) => setEmail(res));
  }, [previewEmailId]);
  return email ? (
    <UncontrolledModal size="lg">
      <ModalHeader>Email Preview</ModalHeader>
      <ModalBody style={{ maxHeight: "50vh", overflow: "auto" }}>
        <div className="mb-2">
          <strong>Subject: </strong>
          {email.subject}
        </div>
        <div dangerouslySetInnerHTML={{ __html: email.body }}></div>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={onClose}>
          Close
        </Button>
      </ModalFooter>
    </UncontrolledModal>
  ) : null;
};
