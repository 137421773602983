import React, { useContext, useEffect, useState } from "react";
import {
  GoalWithCurrentValue,
  getCurrentValuesForGoals,
} from "../../services/goalsService";
import { InsightCustomComponentProps } from "../../services/models";
import { Platform } from "../../services/platformService";
import { chunk } from "../../utils/array";
import PlatformContext from "../app/PlatformContext";
import Gauge from "../common/charts/Gauge";

// This may be incorrect because if your goal was to have 0 vacant
// units then 0 as a value would be perfectly cromulent, but for
// now this serves to hide gauges that look or are empty
const hasValue = (goalWithValue: GoalWithCurrentValue) =>
  !!goalWithValue.currentValue.count &&
  !!goalWithValue.currentValue.total &&
  !!goalWithValue.target.count &&
  !!goalWithValue.target.total;

const PerformanceVsGoals: React.FC<InsightCustomComponentProps> = ({
  selection,
}) => {
  const { insightsMap } = useContext(PlatformContext).platform as Platform;
  const [goalsWithCurrentValue, setGoalsWithCurrentValue] = useState<
    GoalWithCurrentValue[]
  >([]);
  useEffect(() => {
    getCurrentValuesForGoals(insightsMap, selection).then((gcv) =>
      setGoalsWithCurrentValue(gcv.filter(hasValue)),
    );
  }, [insightsMap, selection]);

  function renderGauge(goalWithValue: GoalWithCurrentValue) {
    return (
      <div className="flex-col align-items-center w-100">
        <div style={{ width: "95%", height: "70%" }}>
          <Gauge
            insight={goalWithValue.insight}
            goalType={goalWithValue.goalType}
            target={goalWithValue.target}
            value={goalWithValue.currentValue}
          />
        </div>
        <div
          className="text-capitalize"
          style={{
            height: "10%",
            textAlign: "center",
            fontSize: "20px",
            fontWeight: 300,
          }}
        >
          {goalWithValue.name}
        </div>
      </div>
    );
  }

  if (!goalsWithCurrentValue.length)
    return (
      <div className="w-100 h-100 d-flex justify-content-center align-items-center">
        <div>No Goals Set</div>
      </div>
    );
  const PerRow = 2;
  const chunks = chunk(goalsWithCurrentValue, PerRow);
  const height = `${100 / Math.ceil(goalsWithCurrentValue.length / PerRow)}%`;
  return (
    <div className="w-100 h-100 d-flex flex-col justify-content-start">
      {chunks.map(([first, second], idx) => {
        return (
          <div
            key={idx}
            className="flex-row"
            style={{ height: height, marginBottom: "1rem" }}
          >
            {renderGauge(first)}
            {second ? renderGauge(second) : <div className="w-100" />}
          </div>
        );
      })}
    </div>
  );
};

export default PerformanceVsGoals;
