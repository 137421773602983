import {
  PropertiesSelection,
  isAdministrator,
  isAllProperties,
  isPropertiesByColumns,
  isPropertiesByGroup,
  isPropertiesComparison,
} from "@joyhub-integration/shared";
import clsx from "clsx";
import React, { useContext, useEffect, useMemo, useState } from "react";
import {
  Button,
  DropdownItem,
  Nav,
  NavItem,
  NavLink,
  Popover,
} from "reactstrap";
import {
  Property,
  getSelectedPropertyIds,
} from "../../services/propertiesService";
import { usePropertiesSelectionQueryParam } from "../../utils/useQueryParams";
import PlatformContext from "../app/PlatformContext";
import { PropertyGroup } from "../properties/propertyGroupService";
import ColumnSelector from "./ColumnSelector";
import ComparisonSelector from "./ComparisonSelector";
import PropertyGroupEditor from "./PropertyGroupEditor";
import PropertySelector from "./PropertySelector";

const defaultTab = (selection: PropertiesSelection | undefined) =>
  isPropertiesByColumns(selection)
    ? "columns"
    : isPropertiesComparison(selection)
      ? "comparison"
      : "properties";

const PropertiesPickerPane: React.FC<{
  allProperties: Property[];
  propertyGroups: PropertyGroup[];
  backEnd?: boolean;
  toggle: () => void;
}> = ({ allProperties, propertyGroups, backEnd, toggle }) => {
  const [selection, setSelection] = usePropertiesSelectionQueryParam();
  const [newSelection, setNewSelection] = useState<PropertiesSelection>();
  const [save, setSave] = useState(false);
  const { organization_role } = useContext(PlatformContext).platform!;

  const resolvedSelection = useMemo(
    () =>
      selection != null && isPropertiesByGroup(selection)
        ? propertyGroups.find((g) => g.id === selection.group)?.selection
        : selection,
    [selection, propertyGroups],
  );

  const [tab, setTab] = useState(defaultTab(resolvedSelection));

  useEffect(() => {
    window.addEventListener("click", toggle, { once: true });
    return () => window.removeEventListener("click", toggle);
  }, [toggle]);

  const applySelection = () => {
    setSelection(newSelection, "replaceIn");
    toggle();
  };

  const currentSelection = useMemo(
    () => newSelection ?? resolvedSelection ?? {},
    [newSelection, resolvedSelection],
  );

  const selectedPropertyCount = useMemo(
    () =>
      getSelectedPropertyIds(currentSelection, allProperties, propertyGroups)
        .length,
    [currentSelection, allProperties, propertyGroups],
  );

  return (
    <div
      className="pt-3 pb-2"
      style={{ width: "32rem" }}
      onClick={(e) => {
        e.stopPropagation();
        setSave(false);
      }}
    >
      <Nav className="px-3" tabs>
        {["properties", "columns", "comparison"].map((k) => (
          <NavItem key={k} className="me-1">
            <NavLink
              href=""
              onClick={(e) => {
                e.preventDefault();
                setTab(k);
              }}
              active={tab === k}
            >
              {k === "properties"
                ? `Properties (${selectedPropertyCount})`
                : k === "columns"
                  ? "Filters"
                  : "Comparison"}
            </NavLink>
          </NavItem>
        ))}
      </Nav>
      {tab === "properties" ? (
        <PropertySelector
          allProperties={allProperties}
          propertyGroups={propertyGroups}
          backEnd={backEnd}
          selection={currentSelection}
          setSelection={setNewSelection}
        />
      ) : tab === "columns" ? (
        <ColumnSelector
          allProperties={allProperties}
          selection={currentSelection}
          setSelection={setNewSelection}
        />
      ) : (
        <ComparisonSelector
          allProperties={allProperties}
          propertyGroups={propertyGroups}
          selection={currentSelection}
          setSelection={setNewSelection}
        />
      )}

      <DropdownItem divider />
      <div
        className={`flex-row dashboard-property-dropdown-actions ${
          isAdministrator(organization_role)
            ? "justify-content-between"
            : "justify-content-end"
        }`}
      >
        {isAdministrator(organization_role) && (
          <>
            <Popover
              target="save-property-group"
              isOpen={save}
              placement="bottom-start"
            >
              {isPropertiesComparison(currentSelection) ? null : (
                <PropertyGroupEditor
                  selection={currentSelection}
                  onEdited={(group) => {
                    setSelection({ group }, "replaceIn");
                    toggle();
                  }}
                  onDeleted={() => {}}
                />
              )}
            </Popover>
            <Button
              id="save-property-group"
              outline
              color="primary"
              size="sm"
              className={clsx("ms-2", { active: save })}
              disabled={
                isAllProperties(currentSelection) ||
                isPropertiesComparison(currentSelection)
              }
              onClick={(e) => {
                e.stopPropagation();
                setSave(!save);
              }}
            >
              Save property group
            </Button>
          </>
        )}
        <div>
          <Button
            outline
            color="secondary"
            size="sm"
            onClick={() => setNewSelection({})}
          >
            Reset
          </Button>
          <Button outline color="secondary" size="sm" onClick={toggle}>
            Close
          </Button>
          <Button
            color="primary"
            size="sm"
            onClick={applySelection}
            disabled={newSelection == null}
          >
            Apply
          </Button>
        </div>
      </div>
    </div>
  );
};

export default PropertiesPickerPane;
