import { faCaretLeft } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { isEqual } from "lodash";
import { Button, Label, ModalHeader } from "reactstrap";
import { CardType } from "../type";
import { cardItems } from "../utils";
import "./style.css";

interface CardModalHeaderPropsType {
  isEdit: boolean;
  cardType: CardType;
  updateCardType: (value: CardType) => void;
  refreshDashboardInsight: () => void;
}

export const CardModalHeader = (props: CardModalHeaderPropsType) => {
  const { isEdit, cardType, updateCardType, refreshDashboardInsight } = props;

  const isMenuSelected = isEqual(cardType, "menu");

  const headerTitle: { [key: string]: string } = {};

  cardItems.forEach((item) => {
    headerTitle[item.type] = item.title;
  });

  const backToMenu = () => {
    refreshDashboardInsight();
    updateCardType("menu");
  };

  return (
    <ModalHeader
      className="px-0"
      style={{
        paddingTop: "12px",
        paddingBottom: "12px",
      }}
    >
      {isMenuSelected ? (
        <div className="px-4">{isEdit ? "Edit a Card" : "Add a Card"}</div>
      ) : (
        <Button
          className="d-flex justify-content-center align-items-center header-button"
          onClick={backToMenu}
          style={{
            backgroundColor: "white",
            borderWidth: 0,
            padding: 0,
            marginLeft: "20px",
          }}
        >
          <FontAwesomeIcon
            icon={faCaretLeft}
            className="color-pop"
            style={{
              paddingRight: "4px",
              paddingTop: 0,
              paddingBottom: 0,
              fontSize: "30px",
            }}
          />
          <Label style={{ fontSize: "20px", marginBottom: 0 }}>
            {headerTitle[cardType]}
          </Label>
        </Button>
      )}
    </ModalHeader>
  );
};
