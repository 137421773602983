import { faFileDownload } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AttachmentType, ReportSheet } from "@joyhub-integration/shared";
import React, { useEffect, useState } from "react";
import {
  Button,
  ButtonDropdown,
  ButtonGroup,
  DropdownMenu,
  DropdownToggle,
  PopoverBody,
  Spinner,
  UncontrolledPopover,
} from "reactstrap";
import withAlertModal from "../common/alert/withAlertModal";
import JhSelect, { IDropListItem } from "../common/JhSelect/JhSelect";

type DownloadName = {
  id: number | undefined;
  filename: string;
};

interface DownloadReportProps {
  downloading: boolean;
  downloadOpen: boolean;
  multiDownload: boolean;
  templateFiles: DownloadName[] | undefined;
  sheetNames: ReportSheet<"Unresolved">[];
  doDownload: (
    template: number | undefined,
    attachmentType: AttachmentType,
  ) => void;
  doDownloadOne: (attachmentType: AttachmentType, sheetNumber?: number) => void;
  setDownloadOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const DownloadReport: React.FC<DownloadReportProps> = (props) => {
  const {
    downloading,
    sheetNames,
    doDownloadOne,
    setDownloadOpen,
    downloadOpen,
  } = props;

  const [attachmentType, setAttachmentType] = useState<AttachmentType>("excel");
  const [sheetOptions, setSheetOptions] = useState<IDropListItem[]>();
  const [sheetOption, setSheetOption] = useState<IDropListItem>();

  const handleSheetChange = (value: IDropListItem) => {
    setSheetOption(value);
  };

  useEffect(() => {
    const options = sheetNames.map((sn, index) => ({
      label: sn.name,
      value: index.toString(),
    }));
    setSheetOptions(options);

    if (options.length) setSheetOption(options[0]);
  }, [sheetNames]);

  return (
    <ButtonDropdown
      isOpen={downloadOpen}
      toggle={() => setDownloadOpen(!downloadOpen)}
    >
      <DropdownToggle
        id="download-report"
        className="jh-action-icon ms-1 dropdown-toggle"
      >
        <FontAwesomeIcon icon={faFileDownload} />
      </DropdownToggle>
      {!downloadOpen ? (
        <UncontrolledPopover
          placement="bottom"
          trigger="hover"
          target="download-report"
        >
          <PopoverBody>Download Report</PopoverBody>
        </UncontrolledPopover>
      ) : null}
      <DropdownMenu style={{ width: 400 }}>
        <div className="px-3">
          <div className="mb-3">
            <strong>Download Report</strong>
          </div>
          {/* Commenting out the code for multiple files download. Will revisit later.
          {templateFiles && templateFiles.length > 1 && (
            <div className="mt-3">
              {templateFiles.map(({ id, filename }, index) => (
                <DropdownItem key={index} onClick={() => doDownload(id)} toggle={false}>
                  {filename}
                </DropdownItem>
              ))}
            </div>
          )} */}
          <div className="mt-3">
            <ButtonGroup className="w-100">
              <Button
                color="primary"
                outline={attachmentType !== "excel"}
                onClick={() => setAttachmentType("excel")}
              >
                Excel
              </Button>
              <Button
                color="primary"
                outline={attachmentType !== "pdf"}
                onClick={() => setAttachmentType("pdf")}
              >
                PDF
              </Button>
              <Button
                color="primary"
                outline={attachmentType !== "csv"}
                onClick={() => setAttachmentType("csv")}
              >
                CSV
              </Button>
            </ButtonGroup>
          </div>
          {attachmentType === "csv" && (
            <>
              <div
                className="mt-2"
                style={{
                  fontSize: 14,
                  fontStyle: "italic",
                }}
              >
                Please which sheet to download as CSV
              </div>
              <JhSelect
                className="mt-1"
                value={sheetOption}
                options={sheetOptions}
                onValueUpdate={handleSheetChange}
                isMulti={false}
                pointy
                placeholder="Select sheet name"
              />
            </>
          )}
          <div className="mt-3 w-100">
            <Button
              color="primary"
              className="w-100 mb-2 position-relative"
              onClick={() =>
                doDownloadOne(
                  attachmentType,
                  parseInt(sheetOption?.value ?? "0"),
                )
              }
              disabled={downloading}
              style={downloading ? { cursor: "wait" } : {}}
            >
              Download
              {downloading ? (
                <Spinner
                  color="light"
                  size="sm"
                  style={{
                    position: "absolute",
                    right: ".625rem",
                    top: ".625rem",
                  }}
                />
              ) : null}
            </Button>
          </div>
        </div>
      </DropdownMenu>
    </ButtonDropdown>
  );
};

export default withAlertModal(DownloadReport);
