import { ReactText } from "react";
import { Payload } from "recharts/types/component/DefaultTooltipContent";
import { FrontEndUnit } from "../services/insightsService";
import {
  ChartOrTableDataValue,
  SecondaryAxisKeyValue,
  SortDirection,
} from "../services/models";
import {
  CountOfTotal,
  formatDays,
  formatMonths,
  formattedDollar,
  formattedPercentage,
  kmbFormatted,
  ratioToNumber,
  toPercentage,
} from "./number";

export function chartNumberFormatter(
  value: number,
  unit: FrontEndUnit,
  genericFormatter?: (n: number) => string,
) {
  if (unit === "Percent") {
    return formattedPercentage(value);
  } else if (unit === "Dollar") {
    return formattedDollar(value, !genericFormatter);
  } else if (unit === "Month") {
    return formatMonths(value);
  } else if (unit === "Day") {
    return formatDays(value);
  } else {
    return genericFormatter ? genericFormatter(value) : kmbFormatted(value);
  }
}

export function tooltipFormatter(
  value: string | number | ReactText[],
  axisKey: string,
  axisKeysToOrientation: { [key: string]: SecondaryAxisKeyValue },
) {
  const unit = axisKeysToOrientation[axisKey].unit;
  const genericFormatter = (n: number) => {
    if (n === undefined || n === null || Number.isNaN(n)) {
      return "–";
    }
    return n.toLocaleString();
  };
  if (typeof value === "number") {
    return chartNumberFormatter(value, unit, genericFormatter);
  }
  return value;
}

export function tableValueFormatter(
  value: string | ChartOrTableDataValue,
  unit: FrontEndUnit,
) {
  if (typeof value === "string") {
    return value;
  } else if (typeof value.value === "number") {
    return chartNumberFormatter(value.value, unit);
  } else if (value.calculationType === "PERCENTAGE") {
    const countOfTotal = value.value as CountOfTotal;
    return toPercentage(countOfTotal);
  } else {
    const countOfTotal = value.value as CountOfTotal;
    return chartNumberFormatter(ratioToNumber(countOfTotal, unit), unit);
  }
}

export function tooltipSorter(sortDir: SortDirection) {
  const multiplier = sortDir === "asc" ? 1 : -1;
  return function (a: Payload<number, string>) {
    return (a.value as number) * multiplier;
  };
}
