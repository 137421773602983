import { faHome, faThunderstorm } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect } from "react";
import { Platform } from "../../services/platformService";
import { setSessionOrganization } from "../../services/sessionOrganization";
import ButtonWithIcon from "../common/button/ButtonWithIcon";

type OrganizationChangeProps = {
  goHome: () => void;
  platform: Platform;
};

const OrganizationChange: React.FC<OrganizationChangeProps> = ({
  goHome,
  platform,
}) => {
  // clear the session state so if you just reload the page it goes back to working.
  useEffect(() => setSessionOrganization(null), []);
  return (
    <>
      <div className="hero-error-wrapper">
        <FontAwesomeIcon
          icon={faThunderstorm}
          size="8x"
          className="hero-error"
        />
      </div>
      <div className="error-page-main">
        <h1>Oh noes!</h1>
        <p>
          You switched to the {platform.organization.name} organization in
          another tab.
        </p>
        <div className="flex-row error-page-actions">
          <ButtonWithIcon icon={faHome} color="transparent" onClick={goHome} />
        </div>
      </div>
    </>
  );
};

export default OrganizationChange;
