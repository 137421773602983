import { PureDate } from "@joyhub-integration/shared";
import { reverse } from "lodash";
import { useMemo } from "react";
import { RangedInsights } from "../../../services/dataService";
import { Occupancy as OccupancyId } from "../../../services/insightLibrary/backendInsightIds";
import { insightValue, previousInsights } from "../cdUtil";
import BarChart, { BarGraphDatum } from "../visualizations/BarChart";
import DashboardCard from "./DashboardCard";
import { Skelington } from "./Skelington";

type CDOccupancyDatum = BarGraphDatum<"currentPeriod" | "previousPeriod">;

type CDOccupancyData = {
  data: Array<CDOccupancyDatum>;
  delta?: number;
};

const occupancyData = (
  insights: RangedInsights | undefined,
  date: PureDate,
): CDOccupancyData | undefined => {
  if (insights == null) return undefined;
  const data = new Array<CDOccupancyDatum>();
  for (let month = 0; month < 6; ++month) {
    const current = previousInsights(insights, date, month);
    const previous = previousInsights(insights, date, month + 12);
    data.push({
      date: date.lastDayOfPriorMonth(month).toJSON(),
      currentPeriod: insightValue(current?.insights, OccupancyId),
      previousPeriod: insightValue(previous?.insights, OccupancyId),
      target: insightValue(current?.goals, OccupancyId),
    });
  }
  return {
    delta: undefined,
    data: reverse(data),
  };
};

const Occupancy = ({
  insights,
  date,
  onClick,
}: {
  insights?: RangedInsights;
  date: PureDate;
  onClick?: () => void;
}) => {
  const occupancy = useMemo(
    () => occupancyData(insights, date),
    [insights, date],
  );
  return (
    <DashboardCard
      title="Occupancy"
      background="inner"
      delta={occupancy?.delta}
      onClick={onClick}
    >
      <Skelington value={occupancy} height={5}>
        {({ data }) => (
          <BarChart
            keys={["previousPeriod", "currentPeriod"]}
            labels={{
              currentPeriod: "Current year occupancy",
              previousPeriod: "Prior year occupancy",
            }}
            data={data}
            formatter={(number) =>
              number.toLocaleString("en-US", {
                style: "percent",
                maximumFractionDigits: 2,
              })
            }
            yFormatter={(number) =>
              number.toLocaleString("en-US", {
                style: "percent",
                maximumFractionDigits: 0,
              })
            }
            dateFormat={{ month: "long" }}
          />
        )}
      </Skelington>
    </DashboardCard>
  );
};

export default Occupancy;
