import { faLockAlt } from "@fortawesome/pro-light-svg-icons";
import {
  CustomColumn,
  PropertyColumnDefaultTitle,
  defaultSortedPropertyColumns,
  getCustomColumnName,
} from "@joyhub-integration/shared";
import { uniqBy } from "lodash";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import {
  Alert,
  Button,
  ButtonProps,
  ListGroup,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import { updateCustomColumns } from "../../../services/overlordService";
import { getPlatform } from "../../../services/platformService";
import { getNextCustomColumn } from "../../../utils/customColumns";
import PlatformContext from "../../app/PlatformContext";
import ButtonWithIcon, {
  ButtonWithIconProps,
} from "../../common/button/ButtonWithIcon";
import UncontrolledModal from "../../common/modal/UncontrolledModal";
import CustomColumnListItem from "./CustomColumnListItem";
import FixedColumnListItem from "./FixedColumnListItem";
import "./properties.scss";

type EditColumnsModalProps = {
  onClose: (changesMade: boolean) => void;
  onSave: (customColumns: CustomColumn[]) => void;
  saveOnClose?: boolean;
};

/**
 * Modal that provides CRUD for metadata columns. It can save on close or just pass data
 * back to caller.
 * */
export const EditColumnsModal: React.FC<EditColumnsModalProps> = ({
  onClose,
  onSave,
}) => {
  const { platform, setPlatform } = useContext(PlatformContext);
  const { organization } = platform!;

  const [numOfCustomTextColumns, setNumOfCustomTextColumns] = useState(0);
  const [numOfCustomNumberColumns, setNumOfCustomNumberColumns] = useState(0);
  const [numOfCustomPercentageColumns, setNumOfCustomPercentageColumns] =
    useState(0);
  const [numOfCustomDateColumns, setNumOfCustomDateColumns] = useState(0);
  const [numOfCustomYearColumns, setNumOfCustomYearColumns] = useState(0);
  const [numOfCustomDollarColumns, setNumOfCustomDollarColumns] = useState(0);
  const [columns, setColumns] = useState(
    defaultSortedPropertyColumns(
      organization.configuration.customColumns ?? [],
    ),
  );
  const [editIndex, setEditIndex] = useState<null | number>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string>();
  const [duplicates, setDuplicates] = useState<boolean>(false);
  const [hidden, setHidden] = useState(false);

  const cloudColumnNames = defaultSortedPropertyColumns(
    organization.configuration.customColumns ?? [],
  ).map((col) => {
    return {
      key: col.columnKey,
      name: col.name,
    };
  });

  const save = useCallback(() => {
    setLoading(true);
    setEditIndex(null);
    updateCustomColumns(organization.id, columns)
      .then(getPlatform)
      .then(setPlatform)
      .then()
      .then(() => setLoading(false))
      .then(() => {
        onSave(columns);
        onClose(true);
      })
      .catch((e) => {
        setError(JSON.stringify(e.message));
        setLoading(false);
      });
  }, [organization.id, columns, onClose, onSave, setPlatform]);

  const handleMove = useCallback((toIdx: number, fromIdx: number) => {
    setColumns((prevCols) => {
      const newCols = [...prevCols];
      const [removed] = newCols.splice(fromIdx, 1);
      newCols.splice(toIdx, 0, removed);
      return newCols;
    });
  }, []);

  const handleEdit = useCallback((index: number, column: CustomColumn) => {
    setColumns((prevCols) => {
      const newCols = [...prevCols];
      newCols[index] = column;
      return newCols;
    });
  }, []);

  const handleRemove = useCallback(
    (idx: number) => {
      setColumns((prevCols) => {
        const newCols = [...prevCols];
        const [_removed] = newCols.splice(idx, 1);
        return newCols;
      });
      if (editIndex === idx) {
        setEditIndex(null);
      }
    },
    [editIndex],
  );

  const addColumn = () => {
    const nextColumnType = getNextCustomColumn(
      columns.filter(
        (col) => col.columnKey.includes("user_") && col.table === "property",
      ),
    );
    if (nextColumnType)
      setColumns((prevColumns) => {
        return [
          ...prevColumns,
          {
            name: "",
            table: "property",
            dataType: nextColumnType[0],
            columnKey: nextColumnType[1],
            hidden,
          },
        ];
      });
    setEditIndex(columns.length);
  };

  useEffect(() => {
    const dedupe = uniqBy(columns, (c) =>
      getCustomColumnName(c)?.toLowerCase(),
    );
    setDuplicates(dedupe.length !== columns.length);
    setNumOfCustomTextColumns(
      columns.filter(
        (col) => col.dataType === "text" && col.columnKey.startsWith("user_"),
      ).length,
    );
    setNumOfCustomNumberColumns(
      columns.filter(
        (col) => col.dataType === "number" && col.columnKey.startsWith("user_"),
      ).length,
    );
    setNumOfCustomPercentageColumns(
      columns.filter(
        (col) =>
          col.dataType === "percentage" && col.columnKey.startsWith("user_"),
      ).length,
    );
    setNumOfCustomDateColumns(
      columns.filter(
        (col) => col.dataType === "date" && col.columnKey.startsWith("user_"),
      ).length,
    );
    setNumOfCustomYearColumns(
      columns.filter(
        (col) => col.dataType === "year" && col.columnKey.startsWith("user_"),
      ).length,
    );
    setNumOfCustomDollarColumns(
      columns.filter(
        (col) => col.dataType === "dollar" && col.columnKey.startsWith("user_"),
      ).length,
    );
  }, [columns]);

  useEffect(() => {
    setEditIndex(null);
  }, [hidden]);

  const activeButtonProps: ButtonProps = {
    onClick: () => setHidden(false),
    label: "Active",
    outline: hidden,
    style: { marginLeft: 10, height: 40, width: 80 },
  };

  const hiddenButtonProps: ButtonProps = {
    onClick: () => setHidden(true),
    label: "Hidden",
    outline: !hidden,
    style: { height: 40, width: 100 },
  };

  const lockButtonProps: ButtonWithIconProps = {
    className: "property-icon-button",
    iconStyle: { marginLeft: 15, width: 11, height: 11 },
    icon: faLockAlt,
  };

  function showColumn(col: CustomColumn): boolean {
    return !hidden
      ? col.hidden === undefined || col.hidden === false
      : col.hidden === true;
  }

  return (
    <UncontrolledModal size="xl" onClosed={() => onClose(false)}>
      <div className="d-flex align-items-center edit-column-modal-header">
        <ModalHeader className="edit-column-modal-header-component">
          Add/Edit Columns
        </ModalHeader>
        <div>
          <Button {...activeButtonProps}>Active</Button>
          <Button {...hiddenButtonProps}>Hidden</Button>
          <ButtonWithIcon {...lockButtonProps}></ButtonWithIcon>
          <span style={{ marginLeft: 20 }} className="lock-message-text">
            Represents fixed fields. You cannot change the data type or delete.
          </span>
        </div>
      </div>
      <ModalBody>
        <DndProvider backend={HTML5Backend}>
          <div className="d-flex w-100 justify-content-between ps-5">
            <h4>Name</h4>
            <h4>Data type</h4>
            <h4>&nbsp;</h4>
          </div>
          <ListGroup>
            {columns.map((c, idx) => {
              if (!showColumn(c)) return null;
              if (c.columnKey.includes("user_")) {
                return (
                  <CustomColumnListItem
                    key={`custom-${c.columnKey}-${idx}`}
                    defaultDisplayTitle={
                      cloudColumnNames.find((col) => col.key === c.columnKey)
                        ?.name ?? ""
                    }
                    columns={columns}
                    column={c}
                    index={idx}
                    handleEdit={handleEdit}
                    handleMove={handleMove}
                    handleRemove={handleRemove}
                    setEditing={setEditIndex}
                    editingIndex={editIndex}
                    numOfCustomDateColumns={numOfCustomDateColumns}
                    numOfCustomDollarColumns={numOfCustomDollarColumns}
                    numOfCustomPercentageColumns={numOfCustomPercentageColumns}
                    numOfCustomNumberColumns={numOfCustomNumberColumns}
                    numOfCustomTextColumns={numOfCustomTextColumns}
                    numOfCustomYearColumns={numOfCustomYearColumns}
                  />
                );
              } else {
                const originalDisplayTitle =
                  PropertyColumnDefaultTitle[
                    c.columnKey as keyof typeof PropertyColumnDefaultTitle
                  ] ?? "";
                return (
                  <FixedColumnListItem
                    key={`fixed-${c.columnKey}-${idx}`}
                    originalDisplayTitle={originalDisplayTitle}
                    defaultDisplayTitle={
                      cloudColumnNames.find((col) => col.key === c.columnKey)
                        ?.name ?? originalDisplayTitle
                    }
                    column={c}
                    index={idx}
                    handleEdit={handleEdit}
                    handleMove={handleMove}
                    setEditing={setEditIndex}
                    editingIndex={editIndex}
                  ></FixedColumnListItem>
                );
              }
            })}
          </ListGroup>
        </DndProvider>
        <Button
          disabled={
            editIndex !== null ||
            loading ||
            columns.filter(
              (col) =>
                col.columnKey.includes("user_") && col.table === "property",
            ).length >= 24
          }
          className="mt-3 w-25 float-right"
          onClick={() => {
            addColumn();
          }}
        >
          Add Column
        </Button>
      </ModalBody>
      {error ? (
        <Alert className="mx-5" color="danger">
          {error}
        </Alert>
      ) : null}
      {duplicates ? (
        <Alert className="mx-5" color="danger">
          Duplicate column names found.
        </Alert>
      ) : null}
      <ModalFooter>
        <Button
          color="secondary"
          onClick={() => onClose(false)}
          disabled={loading}
        >
          Cancel
        </Button>
        <Button
          color="primary"
          onClick={() => {
            save();
          }}
          disabled={loading || duplicates}
        >
          Save
        </Button>
      </ModalFooter>
    </UncontrolledModal>
  );
};

export default EditColumnsModal;
