import React, { CSSProperties, useEffect, useRef, useState } from "react";
import { useMeasure } from "react-use";
import { UncontrolledTooltip } from "reactstrap";
import CalculateInsightWorker from "../../../services/calculateInsightWorker";
import { SequenceValue } from "../../../services/calculateInsightWorker/calculateInsightWorker";
import { InstantInsights } from "../../../services/dataService";
import definitions from "../../../services/insightLibrary/insightDefinitions";
import { KnownInsight as BackendInsight } from "../../../services/insightsService";
import { ComplexInsightIds } from "../../../services/models";
import { chunk } from "../../../utils/array";
import { colsPerRow } from "../../dashboard/colsPerRow";

interface SequenceValueWithClassName extends SequenceValue {
  className?: string;
}

interface SequenceChartItemProps {
  sequenceItemId: string;
  sequenceValue: SequenceValueWithClassName;
  width: number;
  className?: string;
}

const SequenceChartItem: React.FC<SequenceChartItemProps> = ({
  sequenceItemId,
  sequenceValue,
  width,
}) => {
  const labelRef = useRef<HTMLDivElement>(null);
  const [showTooltip, setShowTooltip] = useState<boolean>(false);

  useEffect(() => {
    const showTooltip =
      labelRef.current &&
      labelRef.current.offsetHeight < labelRef.current.scrollHeight;
    setShowTooltip(!!showTooltip);
  }, [labelRef]);
  return (
    <div
      className={`dashboard-sequence-insight-item ${
        sequenceValue.className ?? ""
      }`}
      style={{ flex: `1 1 ${width}px` }}
    >
      <div className="dashboard-sequence-item-pair">
        <strong className="dashboard-sequence-insight-item-value">
          {sequenceValue.value}
        </strong>
        <div
          id={sequenceItemId}
          className="dashboard-sequence-insight-item-label"
          ref={labelRef}
        >
          {sequenceValue.label}
        </div>
      </div>
      {showTooltip ? (
        <UncontrolledTooltip placement="bottom" target={sequenceItemId}>
          {sequenceValue.label}
        </UncontrolledTooltip>
      ) : null}
    </div>
  );
};

interface SequenceChartProps {
  insightId: number;
  instantValuesNow: InstantInsights;
  instantValuesAYearAgo: InstantInsights;
  insightsMap: Record<string, BackendInsight>;
  style?: CSSProperties;
}

const SequenceChart: React.FC<SequenceChartProps> = ({
  insightId,
  instantValuesNow,
  instantValuesAYearAgo,
  insightsMap,
  style,
  ...rest
}) => {
  const [ref, { width }] = useMeasure<HTMLDivElement>();
  const [values, setValues] = useState<SequenceValueWithClassName[]>();
  const definition = definitions.find((d) => d.id === insightId)!;

  useEffect(() => {
    const complexInsightIdsSet = definition.insightIds as ComplexInsightIds[];
    const promises = complexInsightIdsSet.map((complexInsightIds, index) => {
      const calculateInsightWorker = new CalculateInsightWorker();
      return calculateInsightWorker
        .calculateInstantSequenceValues(
          complexInsightIds,
          instantValuesNow.overall,
          instantValuesAYearAgo.overall,
          insightsMap,
          true,
        )
        .then((sequenceValue) => {
          let className = undefined;
          if (complexInsightIds?.delta || complexInsightIds.yoy) {
            const positive =
              complexInsightIds?.positive === true
                ? "insight-positive"
                : complexInsightIds?.positive === false
                  ? "insight-negative"
                  : "";
            const trend = sequenceValue.value.startsWith("+")
              ? "trend-positive"
              : sequenceValue.value.startsWith("-")
                ? "trend-negative"
                : "trend-zero";
            className = `${positive} ${trend}`;
          }
          return {
            ...sequenceValue,
            className: `${className ?? ""} ${
              complexInsightIds.className ?? ""
            }`,
          };
        });
    });
    Promise.all(promises).then((values) => {
      /* If this is a sequence chart with a value and YoY change and the YoY change
       * is empty then rewrite it as just the number by itself. */
      if (
        values.length === 2 &&
        complexInsightIdsSet[1].yoy &&
        values[1].value === "–"
      ) {
        const { className, ...rest } = values[0];
        values = [{ ...rest, className: `${className} without-percentage` }];
      }
      setValues(values);
    });
  }, [
    insightId,
    insightsMap,
    instantValuesNow,
    instantValuesAYearAgo,
    definition,
  ]);

  if (!values) return null;
  const sequenceValueChunks = chunk(values, colsPerRow.lg);
  const chartWidth = width - 36; // the padding;
  return (
    <div
      className={`d-flex flex-col w-100 h-100 ${definition.className ?? ""}`}
      ref={ref}
    >
      {sequenceValueChunks.map((sequenceValues, rowIdx) => (
        <div
          key={`dashboard-sequence-row-${rowIdx}-insight-${insightId}`}
          className="dashboard-sequence-row"
        >
          {sequenceValues.map((sequenceValue, idx) => (
            <SequenceChartItem
              key={`dashboard-sequence-insight-item-label-insight-${insightId}-index-${idx}`}
              sequenceItemId={`dashboard-sequence-insight-item-label-insight-${insightId}-index-${idx}`}
              sequenceValue={sequenceValue}
              width={
                rowIdx > 0 && rowIdx === sequenceValueChunks.length - 1
                  ? chartWidth / colsPerRow.lg
                  : chartWidth / sequenceValues.length
              }
            />
          ))}
        </div>
      ))}
    </div>
  );
};

export default SequenceChart;
