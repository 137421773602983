import { InsightDefinition } from "@joyhub-integration/shared";
import { stringSimilarity } from "string-similarity-js";
import { KnownInsight } from "../../services/insightsService";

export const siblings = (
  i: InsightDefinition,
  all: Record<string, KnownInsight>,
): InsightDefinition[] | undefined =>
  Object.values(all).filter(
    (other) =>
      other !== i &&
      ((i.identifier && other.identifier.name === i.identifier.name) ||
        stringSimilarity(other.name, i.name) > 0.75),
  );
