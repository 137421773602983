import {
  ExtendedUnitDetailsRow,
  LeaseDetailsRow,
  MortgageDetailsRow,
  UnitDetailsRow,
} from "../insight/insightTypes";
import { PartialRecord } from "../util/record";
import { ExcelNumberFormats } from "./excelReport";

const ShortMonthDayYearExcelFormat = "mmm d yyyy";

const mortgageAttributeFormat: PartialRecord<keyof MortgageDetailsRow, string> =
  {
    period: ExcelNumberFormats.Integer,
    mortgageDate: ExcelNumberFormats.DateMDYYYY,
    balance: ExcelNumberFormats.DollarsCents,
    principal: ExcelNumberFormats.DollarsCents,
    interest: ExcelNumberFormats.DollarsCents,
    payoff: ExcelNumberFormats.DollarsCents,
    interest_only_period: ExcelNumberFormats.Integer,
    loan_amount: ExcelNumberFormats.DollarsCents,
    interest_rate: ExcelNumberFormats.PercentHundredths,
    capitalization_rate: ExcelNumberFormats.PercentHundredths,
    term: ExcelNumberFormats.Integer,
    amortization: ExcelNumberFormats.Integer,
    origination_date: ExcelNumberFormats.DateMDYYYY,
  };

const leaseAttributeFormat: PartialRecord<keyof LeaseDetailsRow, string> = {
  sqft: ExcelNumberFormats.Integer,
  marketRent: ExcelNumberFormats.Dollars,
  previousRent: ExcelNumberFormats.Dollars,
  previousTerm: ExcelNumberFormats.Integer,
  rent: ExcelNumberFormats.Dollars,
  term: ExcelNumberFormats.Integer,
  tradeOut: ExcelNumberFormats.DeltaDollars,
  previousRentPSF: ExcelNumberFormats.DollarsCents,
  rentPSF: ExcelNumberFormats.DollarsCents,
  tradeOutPercent: ExcelNumberFormats.DeltaPercentTenths,
  varianceToMarket: ExcelNumberFormats.DeltaDollars,
  date: ShortMonthDayYearExcelFormat,
  moveInDate: ShortMonthDayYearExcelFormat,
  startDate: ShortMonthDayYearExcelFormat,
  endDate: ShortMonthDayYearExcelFormat,
  outstandingBalance: ExcelNumberFormats.Dollars,
};

const unitAttributeFormat: PartialRecord<keyof ExtendedUnitDetailsRow, string> =
  {
    day: ShortMonthDayYearExcelFormat,
    areaSqft: ExcelNumberFormats.Integer,
    effectiveRent: ExcelNumberFormats.Dollars,
    marketRent: ExcelNumberFormats.Dollars,
    marketRentPSF: ExcelNumberFormats.DollarsCents,
    moveInDate: ShortMonthDayYearExcelFormat,
    leaseStart: ShortMonthDayYearExcelFormat,
    leaseEnd: ShortMonthDayYearExcelFormat,
    newLeaseRent: ExcelNumberFormats.Dollars,
    newLeaseTerm: ExcelNumberFormats.Integer,
    previousLeaseRent: ExcelNumberFormats.Dollars,
    previousLeaseTerm: ExcelNumberFormats.Integer,
    tradeOut: ExcelNumberFormats.DeltaDollars,
    tradeOutPercent: ExcelNumberFormats.DeltaPercentTenths,
    varianceToMarket: ExcelNumberFormats.DeltaDollars,
    newRentPSF: ExcelNumberFormats.DollarsCents,
    previousRentPSF: ExcelNumberFormats.DollarsCents,
  };

export const leaseAttributeExcelFormat = (
  attribute: string | undefined,
): string | undefined =>
  leaseAttributeFormat[attribute as keyof LeaseDetailsRow];

export const unitAttributeExcelFormat = (
  attribute: string | undefined,
): string | undefined => unitAttributeFormat[attribute as keyof UnitDetailsRow];

export const mortgageAttributeExcelFormat = (
  attribute: string,
): string | undefined =>
  mortgageAttributeFormat[attribute as keyof MortgageDetailsRow];
