import { CustomColumnWithValue } from "@joyhub-integration/shared";
import React, { useState } from "react";
import { editCustomColumn } from "../../../services/propertiesService";
import withAlertModal, {
  WithAlertModalProps,
} from "../../common/alert/withAlertModal";
import "./properties.scss";
import PropertyCell, { PropertyCellProps } from "./PropertyCell";

type PropertyColumnCellProps = {
  propertyId: number;
  propertyColumns: CustomColumnWithValue[];
  customColumn: CustomColumnWithValue;
};

type OmittedPropertyCellProps = Omit<
  PropertyCellProps,
  | "editMode"
  | "setEditMode"
  | "setCellValue"
  | "cellValue"
  | "onSendValue"
  | "columnKey"
  | "dataType"
>;

const PropertyCustomColumnCell: React.FC<
  WithAlertModalProps & PropertyColumnCellProps & OmittedPropertyCellProps
> = ({
  id,
  propertyId,
  propertyColumns,
  customColumn,
  isLocked,
  originalValue,
  textColumnOptions,
  onUnexpectedError,
  onBeginEdit,
  onFinishEdit,
  onSavedValue,
}) => {
  const [cellValue, setCellValue] = useState<string>(originalValue);
  const [editMode, setEditMode] = useState(false);

  const sendValue = (value: string) => {
    if (value === customColumn.value) {
      return;
    }
    let newColumn = customColumn;
    if (value !== "") {
      switch (newColumn.dataType) {
        case "date":
          const dateString = value;
          const parts = dateString.split("/");
          const formattedDate = `${parts[0].padStart(
            2,
            "0",
          )}/${parts[1].padStart(2, "0")}/${parts[2]}`;
          newColumn.value = formattedDate;
          break;
        case "number":
        case "percentage":
        case "dollar":
        case "year":
          newColumn.value = Number(value);
          break;
        case "text":
          newColumn.value = value;
          break;
        case "bool":
          const newValue =
            value === "true" || value === "Comp"
              ? true
              : value === "false" || value === "Own"
                ? false
                : null;
          newColumn.value = newValue;
          break;
      }
    } else {
      newColumn.value = null;
    }
    let newPropertyColumn = propertyColumns.find(
      (col) => col.columnKey === newColumn.columnKey,
    );
    if (!newPropertyColumn) {
      setCellValue(originalValue);
      return;
    }
    editCustomColumn(propertyId, newPropertyColumn)
      .then(() => {
        if (newColumn.dataType === "text") {
          onSavedValue(originalValue, value);
        }
      })
      .catch((e) => {
        setCellValue(originalValue);
        onUnexpectedError(e);
      });
  };

  return (
    <PropertyCell
      key={id}
      id={id}
      columnKey={customColumn.columnKey}
      dataType={customColumn.dataType}
      isLocked={isLocked}
      originalValue={originalValue}
      textColumnOptions={textColumnOptions}
      cellValue={cellValue}
      setCellValue={setCellValue}
      onSendValue={sendValue}
      onBeginEdit={onBeginEdit}
      onFinishEdit={onFinishEdit}
      setEditMode={setEditMode}
      onSavedValue={onSavedValue}
      editMode={editMode}
    />
  );
};

export default withAlertModal(PropertyCustomColumnCell);
