import { isPropertiesById } from "@joyhub-integration/shared";
import React from "react";
import { InsightCustomComponentProps } from "../services/models";
import { apiUrl } from "../utils/api";

type ImageCardConfig = {
  image?: string;
};

const ImageCard: React.FC<InsightCustomComponentProps<ImageCardConfig>> = ({
  dashboardInsight,
  selection,
}) => {
  const solePropertyId = isPropertiesById(selection) ? selection : undefined;

  const image = dashboardInsight?.stuff?.image;

  const prefix = (s: string) =>
    s.includes("/") ? s : apiUrl(`/organizations/data/${s}`);
  const interpolate = (s: string) =>
    solePropertyId != null
      ? s.replace(/PROPERTY_ID/, solePropertyId.toString())
      : s;
  const src =
    image != null && !image.startsWith("data:")
      ? prefix(interpolate(image))
      : image;
  return (
    <div className="w-100 h-100 d-flex flex-col justify-content-center align-items-center">
      <img {...{ src }} alt="" className="mw-100 mh-100" />
    </div>
  );
};

export default ImageCard;
