import {
  CustomColumn,
  CustomColumnType,
  CustomColumnWithValue,
  PropertyColumnKey,
} from "@joyhub-integration/shared";
import { PropertyWithUnitCount } from "../services/propertiesService";

//currently, we only alow 4 different custom columns per type;
export function getNextCustomColumn(
  columns: CustomColumn[],
  forType?: CustomColumnType,
): [CustomColumnType, PropertyColumnKey] | null {
  const columnTypeKeys: { [key in CustomColumnType]: string } = {
    text: "user_text_",
    number: "user_numeric_",
    date: "user_date_",
    year: "user_int_",
    bool: "user_bool_",
    percentage: "user_decimal_",
    dollar: "user_money_",
  };
  const types: CustomColumnType[] =
    forType == null
      ? (Object.keys(columnTypeKeys) as CustomColumnType[])
      : [forType];
  for (const type of types) {
    const filteredColumns = columns.filter(
      (column) => column.dataType === type,
    );
    if (filteredColumns.length < 4) {
      const columnKeyPrefix = columnTypeKeys[type];
      for (let i = 1; i <= 4; i++) {
        const columnKey = `${columnKeyPrefix}${i}`;
        if (!filteredColumns.some((col) => col.columnKey === columnKey)) {
          return [type, columnKey as PropertyColumnKey];
        }
      }
    }
  }

  return null;
}

export function getPropertyCustomFieldValues(
  customColumns: CustomColumnWithValue[],
): {
  [key: string]: Date | number | string | null;
} {
  const customFieldValues: {
    [key: string]: Date | number | string | null;
  } = {} as any;

  customColumns.forEach((customColumn) => {
    const columnKey = customColumn.columnKey as keyof typeof PropertyColumnKey;
    customFieldValues[columnKey] = customColumn.value ?? null;
  });

  return customFieldValues;
}

export function getCustomFieldValuesFromProperty(
  property: PropertyWithUnitCount,
  customColumns: CustomColumn[],
): CustomColumnWithValue[] {
  return customColumns.map((customColumn) => ({
    ...customColumn,
    propertyId: property.id,
    value:
      property[customColumn.columnKey as keyof PropertyWithUnitCount] ?? null,
  }));
}

export const getColumnDataType = (key: PropertyColumnKey): CustomColumnType => {
  switch (key) {
    case "is_comparable":
      return "bool";
    case "unit_count":
    case "total_sqft":
    case "rentable_sqft":
    case "parcel_size":
    case "floor_count":
    case "building_count":
    case "elevator_count":
    case "parking_count":
    case "commercial_unit_count":
    case "latitude":
    case "longitude":
      return "number";
    case "year_built":
    case "year_updated":
    case "term":
    case "interest_only_period":
    case "amortization":
      return "year";
    case "capitalization_rate":
    case "interest_rate":
      return "percentage";
    case "purchase_date":
    case "origination_date":
      return "date";
    case "purchase_price":
    case "loan_amount":
      return "dollar";
    default:
      return "text";
  }
};

export function defaultSortedPropertyColumns(
  customColumns: CustomColumn[],
): CustomColumn[] {
  let columns = customColumns.filter((col) => col.table === "property");

  Object.values(PropertyColumnKey)
    .reverse()
    .forEach((key) => {
      if (
        !key.includes("user_") &&
        !customColumns.some((col) => col.columnKey === key)
      ) {
        //if the property fields don't exist in the `customColumns` array; then we need to add them
        columns.unshift({
          columnKey: key,
          dataType: getColumnDataType(key),
          name: null,
          table: "property",
          hidden: false,
        });
      }
    });
  return columns;
}

export type PropertyColumnCellType = {
  key: keyof typeof PropertyColumnKey;
  title: string;
  toValue: (p: PropertyWithUnitCount, colIdx: number) => JSX.Element;
};
