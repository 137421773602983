import { useEffect } from "react";
import { useQuill } from "react-quilljs";
import { InsightCustomComponentProps } from "../../services/models";
import "./style.css";

type HtmlInsightCardConfig = {
  htmlContent?: string;
};

const HtmlInsightCard: React.FC<
  InsightCustomComponentProps<HtmlInsightCardConfig>
> = ({ dashboardInsight }) => {
  const { quill, quillRef } = useQuill({
    readOnly: true,
    placeholder: "This card intentionally left blank",
    modules: {
      toolbar: false,
    },
  });

  useEffect(() => {
    if (quill && dashboardInsight?.stuff?.htmlContent)
      quill.clipboard.dangerouslyPasteHTML(
        dashboardInsight?.stuff?.htmlContent ?? "<div></div>",
      );
  }, [dashboardInsight?.stuff?.htmlContent, quill]);

  return (
    <div className="w-100 h-100 d-flex flex-col justify-content-center align-items-center">
      <div
        style={{ overflowY: "auto", width: "100%", height: "100%" }}
        className="html-card-view"
      >
        <div ref={quillRef} />
      </div>
    </div>
  );
};

export default HtmlInsightCard;
