import { PropertiesSelection } from "@joyhub-integration/shared";
import {
  formatted,
  safeDivide,
  subtractCountOfTotal,
  toPercentage,
} from "../utils/number";
import {
  calculateInstantNumber,
  calculateInstantPercentage,
} from "./calculateInsight";
import { CountOfTotal, getInstantData } from "./dataService";
import {
  AverageDaysFromMoveOutToReady,
  AverageDaysVacant,
  MoveIns7Day,
  MoveOuts7Day,
  NewSignsLast7Days,
  RenewalsLast7Days,
} from "./insightLibrary/backendInsightIds";
import { KnownInsight } from "./insightsService";

interface WeeklyInsightDefinition {
  name: string;
  insightIds: number[];
  insightCalculationType: "NUMBER" | "PERCENTAGE";
  postProcess?: (n: number | CountOfTotal) => number | CountOfTotal;
}

const insights: WeeklyInsightDefinition[] = [
  {
    name: "New leases signed",
    insightIds: [NewSignsLast7Days],
    insightCalculationType: "NUMBER",
  },
  {
    name: "Renewals",
    insightIds: [RenewalsLast7Days],
    insightCalculationType: "NUMBER",
  },
  {
    name: "Move Ins",
    insightIds: [MoveIns7Day],
    insightCalculationType: "NUMBER",
  },
  {
    name: "Move Outs",
    insightIds: [MoveOuts7Day],
    insightCalculationType: "NUMBER",
  },
  {
    name: "Avg Days Vacant",
    insightIds: [AverageDaysVacant],
    insightCalculationType: "NUMBER",
    postProcess: (n: number | CountOfTotal) =>
      Math.round(((n as number) / 30) * 7),
  },
  {
    name: "Avg Days - Make Ready",
    insightIds: [AverageDaysFromMoveOutToReady],
    insightCalculationType: "NUMBER",
    postProcess: (n: number | CountOfTotal) =>
      Math.round(((n as number) / 30) * 7),
  },
];

export interface WeeklyInsightWithValue extends WeeklyInsightDefinition {
  value: string;
  change: string;
  typeOfChange: "positive" | "negative" | "constant";
}

export async function calculateWeeklySummaryValues(
  insightsMap: Record<string, KnownInsight>,
  selection: PropertiesSelection,
): Promise<WeeklyInsightWithValue[]> {
  const insightIds = insights.reduce((prev, current) => {
    current.insightIds.forEach((insightId) => prev.add(insightId));
    return prev;
  }, new Set<number>());
  const sevenDaysAgo = new Date();
  sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 7);
  const instantDataNowPromise = getInstantData(
    Array.from(insightIds),
    selection,
  );
  const instantData7DaysAgoPromise = getInstantData(
    Array.from(insightIds),
    selection,
    sevenDaysAgo,
  );
  return Promise.all([instantDataNowPromise, instantData7DaysAgoPromise]).then(
    ([instantDataNow, instantData7DaysAgo]) => {
      return insights.map((insight) => {
        const isNumericCalculation =
          insight.insightCalculationType === "NUMBER";
        if (isNumericCalculation) {
          let valueNow = calculateInstantNumber(
            insight.insightIds,
            instantDataNow.overall,
            insightsMap,
          );
          let value7DaysAgo = calculateInstantNumber(
            insight.insightIds,
            instantData7DaysAgo.overall,
            insightsMap,
          );
          if (insight.postProcess) {
            valueNow.value = insight.postProcess(valueNow.value) as number;
            value7DaysAgo.value = insight.postProcess(
              value7DaysAgo.value,
            ) as number;
          }
          const change = valueNow.value - value7DaysAgo.value;
          return {
            ...insight,
            value: formatted(valueNow.value, valueNow.unit),
            change: formatted(change, valueNow.unit),
            typeOfChange:
              change > 0 ? "positive" : change < 0 ? "negative" : "constant",
          };
        } else {
          let valueNow = calculateInstantPercentage(
            insight.insightIds,
            instantDataNow.overall,
          );
          let value7DaysAgo = calculateInstantPercentage(
            insight.insightIds,
            instantData7DaysAgo.overall,
          );
          if (insight.postProcess) {
            valueNow = insight.postProcess(valueNow) as CountOfTotal;
            value7DaysAgo = insight.postProcess(value7DaysAgo) as CountOfTotal;
          }
          const change = subtractCountOfTotal(valueNow, value7DaysAgo);
          const changeAsNumber =
            safeDivide(change.count, change.total) ?? Number.NaN;
          return {
            ...insight,
            value: toPercentage(valueNow),
            change: toPercentage(change),
            typeOfChange:
              changeAsNumber > 0
                ? "positive"
                : changeAsNumber < 0
                  ? "negative"
                  : "constant",
          };
        }
      });
    },
  );
}

export default insights;
