import { faUser, faUserAlien } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useLocation } from "react-router";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Navbar,
  UncontrolledDropdown,
} from "reactstrap";
import { environment, Platform } from "../../services/platformService";
import { setSessionOrganization } from "../../services/sessionOrganization";
import { logoutUrl } from "../../services/sessionService";
import PropertiesPicker from "../picker/PropertiesPicker";
import PropertyPicker from "../picker/PropertyPicker";
import { ModernNavlink } from "./ModernNavlink";
import OrgSwitcher from "./OrgSwitcher";

const logOut = () => {
  setSessionOrganization(null);
  document.location.href = logoutUrl();
};

export const ModernNavbar: React.FC<{ platform: Platform }> = ({
  platform,
}) => {
  const { admin, superAdmin, organization } = platform;
  const theme = organization.configuration.navbarTheme ?? "dark";

  const isBiApp = organization.application === "BI";

  const { pathname } = useLocation();

  return !platform?.embedInfo ? (
    <Navbar color={theme} className={`navbar-expand app-nav navbar-${theme}`}>
      <OrgSwitcher modern />
      <div className="navbar-nav flex-grow-1">
        {isBiApp ? (
          <>
            <ModernNavlink to="/" prefix="/dashboards">
              Portfolio
            </ModernNavlink>
            <ModernNavlink to="/properties" prefix="/properties">
              Properties
            </ModernNavlink>
            <ModernNavlink to="/reports/my" prefix="/reports">
              Reports
            </ModernNavlink>
            {admin && (
              <ModernNavlink to="/admin" prefix="/admin">
                Setup
              </ModernNavlink>
            )}
          </>
        ) : organization.application === "PC" ? (
          <>
            {admin && <ModernNavlink to="/users">Users</ModernNavlink>}
            {superAdmin && (
              <ModernNavlink to="/admin/organization">
                Organization
              </ModernNavlink>
            )}
          </>
        ) : null}
        {/*<a
          className="nav-link"
          target="_blank"
          rel="noopener"
          href="https://support.revolutionre.com"
        >
          Help
        </a>*/}
        <div className="ms-auto d-flex flex-shrink-0">
          {!isBiApp ? null : pathname?.startsWith("/properties/") ? (
            <PropertyPicker right className="me-2" />
          ) : (
            <PropertiesPicker right className="me-2" />
          )}
          <UncontrolledDropdown className="nav-item">
            <DropdownToggle tag="button" className="nav-link" caret>
              <FontAwesomeIcon
                icon={environment === "Prod" ? faUser : faUserAlien}
              />
            </DropdownToggle>
            <DropdownMenu end>
              <DropdownItem onClick={logOut}>Logout</DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </div>
      </div>
    </Navbar>
  ) : null;
};
