import {
  DashboardKind,
  numerically,
  PropertiesSelection,
  PureDateIO,
} from "@joyhub-integration/shared";
import { AxiosResponse } from "axios";
import loadImage, { LoadImageResult } from "blueimp-load-image";
import { Buffer } from "buffer";
import { sortBy } from "lodash";
import { colsPerRow } from "../components/dashboard/colsPerRow";
import { DashboardDto } from "../components/dashboard/dashboardDto";
import {
  apiUrl,
  axiosBlobConfig,
  axiosConfig,
  axiosJsonConfig,
} from "../utils/api";
import { asPropertiesQuery } from "../utils/useQueryParams";
import axios from "./axios";
import { InstantInsights, RangedInsights } from "./dataService";
import {
  OtherDashboardInsight as DashboardInsight,
  Insight,
  InsightVisualizationType,
  OtherDashboardInsight,
} from "./models";
import { SendEmailResult } from "./scheduledEmailsService";

export interface DashboardDefinition {
  layout?: {
    lg: any[];
  };
  insights: Array<DashboardInsight>;
  isNew?: boolean;
}

export interface BaseDashboard {
  id: number;
  organizationId?: number;
  identifier: string;
  name: string;
  description: string;
  kind: DashboardKind;
  definition: DashboardDefinition;
  category: string[];
  edited: string;
  shared?: boolean;
  internal?: boolean;
  role_id?: number;
}

export interface Dashboard extends BaseDashboard {
  favorite?: boolean;
  viewed?: string;
  immutable?: boolean;
  hidden?: boolean;
  numberOfInsights: number;
}

export type DashboardHistoryInfo = {
  id: number;
  edited: PureDateIO<true>;
  editor: string;
};

export interface Property {
  id: number;
  name: string;
}

export interface DashboardBoardRestPropsType {
  editMode: boolean;
  smCharts: OtherDashboardInsight<Record<string, any>>[];
  mdCharts: OtherDashboardInsight<Record<string, any>>[];
  customComponentCharts: OtherDashboardInsight<Record<string, any>>[];
  loaded: boolean;
  rangeDataBreakout?: RangedInsights;
  rangeDataNoBreakout?: RangedInsights;
  rangeDataBreakoutAYearAgo?: RangedInsights;
  rangeDataNoBreakoutAYearAgo?: RangedInsights;
  instantDataNow?: InstantInsights;
  instantDataAYearAgo?: InstantInsights;
}

type SendRequest = {
  properties: PropertiesSelection;
  recipients: string[];
  propertyId?: number;
};

type DownloadPDFRequest = {
  properties: PropertiesSelection;
  propertyId?: number;
};

export const CanonicalDashboardOrder = [
  "Overview",
  "Leasing",
  "Rent",
  "Financials",
  "Marketing",
];

export const propertyDashboardUrl = (id: number, did?: string) =>
  `/properties/${id}/dashboards/${did ?? "_home"}?${asPropertiesQuery(id)}`;

export const insightUrl = (id: number, selection?: PropertiesSelection) =>
  `/insights/${id}?${asPropertiesQuery(selection)}`;

export const dashboardUrl = (
  dashboard?: Dashboard,
  selection?: PropertiesSelection,
) => `/dashboards/${dashboard?.identifier}?${asPropertiesQuery(selection)}`;

export const dashboardInsightUrl = (
  dashboard?: Dashboard,
  insightId?: string,
  selection?: PropertiesSelection,
) =>
  `/dashboards/${dashboard?.id}/dashboard_insight/${insightId}?kind=${
    dashboard?.kind
  }&${asPropertiesQuery(selection)}`;

const generateUUID = (template: string) => {
  let d = new Date().getTime(),
    d2 = (performance && performance.now && performance.now() * 1000) || 0;
  return template.replace(/[xy]/g, (c) => {
    let r = Math.random() * 16;
    if (d > 0) {
      r = (d + r) % 16 | 0;
      d = Math.floor(d / 16);
    } else {
      r = (d2 + r) % 16 | 0;
      d2 = Math.floor(d2 / 16);
    }
    return (c === "x" ? r : (r & 0x7) | 0x8).toString(16);
  });
};

const dashboardService = {
  identifier(): string {
    return generateUUID("dxxxxxxx-xxxx");
  },

  addInsightToOldDashboard(
    dashboardId: number,
    insight: Insight,
    name: string,
    visualizationType: InsightVisualizationType,
    dimensionId: number | undefined,
    linkedReport: string | undefined,
    stuff: object | undefined,
  ): Promise<BaseDashboard> {
    const insightId = insight.id;
    return this.getDashboardById(dashboardId).then((currentDashboard) => {
      const currentInsights = currentDashboard.definition.insights
        .map((di) => di.id)
        .sort(numerically);
      const maxId = currentInsights.length
        ? currentInsights[currentInsights.length - 1]
        : -1;
      const newId = maxId + 1;
      const newInsight: DashboardInsight = {
        id: newId,
        name: name,
        insightId: insightId,
        dimensionId: dimensionId,
        dateFrom: new Date(),
        dateTo: new Date(),
        interval: "YEAR",
        visualizationType: visualizationType,
        linkedReport,
        stuff,
      };
      const height = ["NUMBER", "PERCENTAGE", "YOY CHANGE"].includes(
        visualizationType,
      )
        ? 1
        : visualizationType === "SEQUENCE"
          ? Math.ceil(insight.insightIds.length / colsPerRow.lg)
          : insight.customComponent
            ? insight.customComponent.height
            : 2;
      const width =
        visualizationType === "SEQUENCE"
          ? insight.insightIds.length < colsPerRow.lg
            ? insight.insightIds.length
            : colsPerRow.lg
          : insight.customComponent
            ? insight.customComponent.width
            : height;
      const newGridItem = {
        x: 0,
        y: 0,
        i: newId.toString(),
        h: height,
        w: width,
      };
      if (currentDashboard.definition.layout) {
        const layoutCopy = {
          ...currentDashboard.definition.layout,
          lg: currentDashboard.definition.layout.lg.map((item) => ({
            ...item,
            y: item.y + height,
          })),
        };
        layoutCopy.lg.push(newGridItem);
        currentDashboard.definition.layout = layoutCopy;
      } else {
        currentDashboard.definition.layout = {
          lg: [newGridItem],
        };
      }
      currentDashboard.definition.insights.push(newInsight);
      const dto = {
        identifier: currentDashboard.identifier,
        name: currentDashboard.name,
        description: currentDashboard.description,
        shared: currentDashboard.shared,
        internal: currentDashboard.internal,
        kind: currentDashboard.kind,
        definition: currentDashboard.definition,
        category: currentDashboard.category,
      } as DashboardDto;
      return this.updateDashboard(currentDashboard.id, dto);
    });
  },

  addInsightToDashboard(
    dashboardId: number,
    insight: Insight,
    name: string,
    visualizationType: InsightVisualizationType,
    dimensionId: number | undefined,
    linkedReport: string | undefined,
    stuff: object | undefined,
    xy: [number, number],
  ): Promise<BaseDashboard> {
    const insightId = insight.id;
    return this.getDashboardById(dashboardId).then((currentDashboard) => {
      const currentInsights = currentDashboard.definition.insights
        .map((di) => di.id)
        .sort(numerically);
      const maxId = currentInsights.length
        ? currentInsights[currentInsights.length - 1]
        : -1;
      const newId = maxId + 1;
      const newInsight: DashboardInsight = {
        id: newId,
        name: name,
        insightId: insightId,
        dimensionId: dimensionId,
        dateFrom: new Date(),
        dateTo: new Date(),
        interval: "YEAR",
        visualizationType: visualizationType,
        linkedReport,
        stuff,
      };
      const newGridItem = {
        x: xy[0],
        y: xy[1],
        i: newId.toString(),
        width: 1,
      };
      if (currentDashboard.definition.layout) {
        const { layout } = currentDashboard.definition;
        const newLayout = [...layout.lg, newGridItem];
        currentDashboard.definition.layout.lg = newLayout;
      } else {
        currentDashboard.definition.layout = {
          lg: [newGridItem],
        };
      }
      currentDashboard.definition.insights.push(newInsight);
      const dto = {
        identifier: currentDashboard.identifier,
        name: currentDashboard.name,
        description: currentDashboard.description,
        shared: currentDashboard.shared,
        internal: currentDashboard.internal,
        kind: currentDashboard.kind,
        definition: currentDashboard.definition,
        category: currentDashboard.category,
      } as DashboardDto;
      return this.updateDashboard(currentDashboard.id, dto);
    });
  },

  editDashboardInsight(
    dashboardId: number,
    dashboardInsightId: number,
    insightId: number | undefined,
    name: string,
    visualizationType: InsightVisualizationType,
    dimensionId: number | undefined,
    linkedReport: string | undefined,
    stuff: object | undefined,
  ): Promise<BaseDashboard> {
    return this.getDashboardById(dashboardId).then((currentDashboard) => {
      const currentDashboardInsight = currentDashboard.definition.insights.find(
        (di) => di.id === dashboardInsightId,
      );
      if (!currentDashboardInsight)
        return Promise.reject(
          `Dashboard insight with id ${dashboardInsightId} does not exist`,
        );
      currentDashboardInsight.name = name;
      currentDashboardInsight.insightId =
        insightId ?? currentDashboardInsight.insightId;
      currentDashboardInsight.visualizationType = visualizationType;
      currentDashboardInsight.dimensionId = dimensionId;
      currentDashboardInsight.linkedReport = linkedReport;
      currentDashboardInsight.stuff = stuff;
      const dto = {
        identifier: currentDashboard.identifier,
        name: currentDashboard.name,
        description: currentDashboard.description,
        shared: currentDashboard.shared,
        internal: currentDashboard.internal,
        kind: currentDashboard.kind,
        definition: currentDashboard.definition,
        category: currentDashboard.category,
      } as DashboardDto;
      return this.updateDashboard(currentDashboard.id, dto);
    });
  },

  getDashboardInsight(
    dashboardId: number,
    dashboardInsightId: number,
  ): Promise<DashboardInsight> {
    return this.getDashboardById(dashboardId).then((currentDashboard) => {
      const currentDashboardInsight = currentDashboard.definition.insights.find(
        (di) => di.id === dashboardInsightId,
      );
      if (!currentDashboardInsight)
        return Promise.reject(
          `Dashboard insight with id ${dashboardInsightId} does not exist`,
        );
      const dto = {
        identifier: currentDashboard.identifier,
        name: currentDashboard.name,
        description: currentDashboard.description,
        shared: currentDashboard.shared,
        internal: currentDashboard.internal,
        kind: currentDashboard.kind,
        definition: currentDashboard.definition,
        category: currentDashboard.category,
      } as DashboardDto;
      return this.updateDashboard(currentDashboard.id, dto).then(
        (d) =>
          d.definition.insights.find(
            (di) => di.id === dashboardInsightId,
          ) as DashboardInsight,
      );
    });
  },

  deleteDashboardInsight(
    dashboardId: number,
    dashboardInsightId: number,
  ): Promise<void> {
    return this.getDashboardById(dashboardId).then((currentDashboard) => {
      const index = currentDashboard.definition.insights.findIndex(
        (di) => di.id === dashboardInsightId,
      );
      if (index === -1)
        return Promise.reject(
          `Dashboard insight with id ${dashboardInsightId} does not exist`,
        );
      currentDashboard.definition.insights = [
        ...currentDashboard.definition.insights.filter(
          (insight) => insight.id !== dashboardInsightId,
        ),
      ];
      if (currentDashboard.definition.layout) {
        const newLayout: any = {};
        const entries: [string, any[]][] = Object.entries(
          currentDashboard.definition.layout,
        );
        for (const [key, value] of entries) {
          newLayout[key] = value.filter(
            (item) => item.i !== dashboardInsightId.toString(),
          );
        }
        currentDashboard.definition.layout = newLayout;
      }
      const dto = {
        identifier: currentDashboard.identifier,
        name: currentDashboard.name,
        description: currentDashboard.description,
        shared: currentDashboard.shared,
        internal: currentDashboard.internal,
        kind: currentDashboard.kind,
        definition: currentDashboard.definition,
        category: currentDashboard.category,
      } as DashboardDto;
      return this.updateDashboard(dashboardId, dto).then(() => {});
    });
  },

  async setDashboardLayoutAndName(
    id: number,
    layout: any,
    name?: string,
  ): Promise<BaseDashboard> {
    return this.getDashboardById(id).then((currentDashboard) => {
      currentDashboard.definition.layout = layout;
      const dto = {
        identifier: currentDashboard.identifier,
        name: name ?? currentDashboard.name,
        description: currentDashboard.description,
        shared: currentDashboard.shared,
        internal: currentDashboard.internal,
        kind: currentDashboard.kind,
        definition: currentDashboard.definition,
        category: currentDashboard.category,
      } as DashboardDto;
      return this.updateDashboard(id, dto);
    });
  },

  setDashboardName(id: number, name: string): Promise<BaseDashboard> {
    if (name.length === 0)
      return Promise.reject(`Name must be a non empty string`);
    return this.getDashboardById(id).then((currentDashboard) => {
      currentDashboard.name = name;
      const dto = {
        identifier: currentDashboard.identifier,
        name: currentDashboard.name,
        description: currentDashboard.description,
        shared: currentDashboard.shared,
        internal: currentDashboard.internal,
        kind: currentDashboard.kind,
        definition: currentDashboard.definition,
        category: currentDashboard.category,
      } as DashboardDto;
      return this.updateDashboard(id, dto);
    });
  },

  /** If you specify hidden: true then you will also get back hidden dashboards. */
  getDashboards(
    hidden: boolean = false,
    canonicalOrder: boolean = true,
    kind: DashboardKind,
  ): Promise<Array<Dashboard>> {
    return axios
      .get(apiUrl(`/dashboards?kind=${kind}`), axiosConfig)
      .then((res) => res.data.dashboards as Array<Dashboard>)
      .then((res) => res.filter((d) => hidden || !d.hidden))
      .then((res) =>
        canonicalOrder
          ? sortBy(res, [
              (dashboard) => {
                const index = CanonicalDashboardOrder.indexOf(dashboard.name);
                return index === -1 ? Number.MAX_VALUE : index;
              },
              "name",
            ])
          : res,
      );
  },

  getDashboardById(id: number): Promise<Dashboard> {
    return axios
      .get(apiUrl(`/dashboards/${id}`), axiosConfig)
      .then((res) => res.data as Dashboard);
  },

  getAvailableDashboardCategories(): Promise<string[]> {
    return axios
      .get(apiUrl("/dashboards/categories?kind=Dashboard"), axiosConfig)
      .then((res) => res.data.categories as string[]);
  },

  favoriteDashboard(id: number, favorite: boolean): Promise<void> {
    return axios.put(
      apiUrl(`/dashboards/${id}/favorite`),
      favorite,
      axiosJsonConfig,
    );
  },

  hideDashboard(id: number, hidden: boolean | null): Promise<void> {
    return axios.put(
      apiUrl(`/dashboards/${id}/hidden`),
      hidden,
      axiosJsonConfig,
    );
  },

  nameDashboard(id: number, name: string | null): Promise<void> {
    return axios.put(apiUrl(`/dashboards/${id}/name`), name, axiosJsonConfig);
  },

  viewDashboard(id: number): Promise<void> {
    return axios.put(apiUrl(`/dashboards/${id}/viewed`), {}, axiosConfig);
  },

  copyDashboard(
    id: number,
    identifier: string,
    name: string,
  ): Promise<BaseDashboard> {
    return axios
      .post(apiUrl("/dashboards/copy"), { id, identifier, name }, axiosConfig)
      .then((res) => res.data as BaseDashboard);
  },

  async deleteDashboard(id: Number): Promise<void> {
    return axios.delete(apiUrl(`/dashboards/${id}`), axiosConfig);
  },

  createDashboard<T = BaseDashboard>(dashboardDto: DashboardDto): Promise<T> {
    return axios
      .post(apiUrl("/dashboards"), dashboardDto, axiosConfig)
      .then((res) => res.data as T);
  },

  updateDashboard(
    id: number,
    dashboardDto: DashboardDto,
  ): Promise<BaseDashboard> {
    return axios
      .put(apiUrl(`/dashboards/${id}`), dashboardDto, axiosConfig)
      .then((res) => res.data as BaseDashboard);
  },

  getDashboardHistories(id: number): Promise<DashboardHistoryInfo[]> {
    return axios
      .get<{
        history: DashboardHistoryInfo[];
      }>(apiUrl(`/dashboards/${id}/history`), axiosConfig)
      .then((res) => res.data.history);
  },

  getDashboardHistory(
    id: number,
    history: number,
  ): Promise<{ definition: any }> {
    return axios
      .get<{
        definition: any;
      }>(apiUrl(`/dashboards/${id}/history/${history}`), axiosConfig)
      .then((res) => res.data);
  },

  sendDashboard(id: number, request: SendRequest) {
    return axios
      .post<SendEmailResult>(
        apiUrl(`/reports/${id}/send`),
        request,
        axiosJsonConfig,
      )
      .then((res) => res.data);
  },

  downloadDashboardPDF(id: number, request: DownloadPDFRequest) {
    return axios.post(
      apiUrl(`/reports/${id}/download`),
      request,
      axiosBlobConfig,
    );
  },

  uploadCardImage(file: File): Promise<AxiosResponse> {
    return loadImage(file, { maxWidth: 1280, maxHeight: 1280, canvas: true })
      .then((result: LoadImageResult) => {
        const canvas = result.image as HTMLCanvasElement; // I know this to be a canvas, because I requested it there ^
        return new Promise<ArrayBuffer>((resolve, reject) => {
          canvas.toBlob(async (blob: Blob | null) => {
            if (!blob) {
              reject("Unable to create blob from uploaded image");
            } else {
              resolve(await blob.arrayBuffer());
            }
          }, "image/png"); // most browsers like png
        });
      })
      .then((buffer: ArrayBuffer) => {
        return axios.post(
          apiUrl(`/dashboards/imageUpload`),
          {
            body: Buffer.from(buffer).toString("base64"),
          },
          axiosJsonConfig,
        );
      });
  },
};

export default dashboardService;
