import { mapDefined } from "@joyhub-integration/shared";
import React, { useContext, useMemo, useState } from "react";
import {
  Alert,
  Button,
  FormGroup,
  FormText,
  Input,
  Label,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import { generateTemplate } from "../../../services/goalsService";
import {
  Applications,
  AverageDaysVacant,
  AverageNotReadyDuration,
  BadDebt,
  CapitalImprovement,
  CashFlow,
  Delinquencies,
  EmployeeUnits,
  ExpenseFactor,
  GrossPotentialRent,
  InPlaceRent,
  LeadToApplicationConversion,
  LeadToMoveInConversion,
  LossToLease,
  MoveIns,
  NetOperatingIncome,
  NetRentalIncome,
  NewSignsLast30Days,
  NoiAfterDebtService,
  NonIncomeUnits,
  Occupancy,
  PercentTradeOutNewSignsLast30Days,
  PercentTradeOutRenewalsLast30Days,
  RenewalRateId,
  RentConcessions,
  SiteVisitCount,
  TotalAdvertisingMarketing,
  TotalCommercialIncome,
  TotalContractServices,
  TotalDebtService,
  TotalGeneralAdministrative,
  TotalInsurance,
  TotalLandscape,
  TotalManagementFees,
  TotalMiscExpenses,
  TotalNetPotentialRent,
  TotalOperatingExpenses,
  TotalOperatingRevenue,
  TotalOtherIncome,
  TotalPropertyPayroll,
  TotalRentalIncomeAdjustments,
  TotalRepairsMaintenance,
  TotalTaxesFees,
  TotalUnitTurnover,
  TotalUtilityExpenses,
  UtilityExpensesCable,
  UtilityExpensesElectricity,
  UtilityExpensesGas,
  UtilityExpensesPest,
  UtilityExpensesTrash,
  UtilityExpensesWater,
  VacancyLoss,
  WorkOrderAverageAgeToCompletion,
} from "../../../services/insightLibrary/backendInsightIds";
import { errorMessage } from "../../../utils/api";
import PlatformContext from "../../app/PlatformContext";
import FilteringPicker from "../../common/FilteringPicker";
import UncontrolledModal from "../../common/modal/UncontrolledModal";

interface GoalsTemplateGeneratorModalProps {
  onClose: () => void;
}

const GoalsTemplateGeneratorModal: React.FC<
  GoalsTemplateGeneratorModalProps
> = (props) => {
  const [serverError, setServerError] = useState("");
  const platform = useContext(PlatformContext).platform!;
  const allProperties = Object.values(platform.propertiesMap);
  const goalInsights = useMemo(
    () =>
      mapDefined(
        defaultGoalInsightIds,
        (insightId) => platform.insightsMap[insightId],
      ),
    [platform.insightsMap],
  );

  const currentYear = new Date().getFullYear();
  const [year, setYear] = useState(currentYear);
  const [selectedProperties, setSelectedProperties] = useState(
    new Array<number>(),
  );
  const [selectedInsights, setSelectedInsights] = useState(new Array<number>());

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!year || !selectedProperties.length || !selectedInsights.length) return;
    setServerError("");
    generateTemplate(year, selectedProperties, selectedInsights)
      .then(props.onClose)
      .catch((err) => setServerError(errorMessage(err)));
  };

  return (
    <UncontrolledModal onClosed={props.onClose} onFormSubmit={onSubmit}>
      <ModalHeader>Build Goal Template</ModalHeader>
      <ModalBody>
        <Alert color="danger" isOpen={!!serverError}>
          {serverError}
        </Alert>
        <p>
          Choose the properties and insights for which you want to set goals.
          Open the template in Excel and enter goal values. Return here to
          upload the template.
        </p>
        <FormGroup>
          <Label>Year</Label>
          <Input
            type="number"
            value={year}
            onChange={(e) => setYear(parseInt(e.target.value, 10))}
            min={currentYear - 1}
            max={currentYear + 1}
          />
          <FormText color="muted">
            The template will be prefilled with 12 month inputs for this year.
          </FormText>
        </FormGroup>
        <div className="d-flex" style={{ gap: "1rem" }}>
          <div className="w-50">
            <p>Properties</p>
            <FilteringPicker
              options={allProperties}
              label="property_name"
              selection={selectedProperties}
              setSelection={setSelectedProperties}
              maxHeight="30vh"
            />
          </div>
          <div className="w-50">
            <p>Insights</p>
            <FilteringPicker
              options={goalInsights}
              label="name"
              selection={selectedInsights}
              setSelection={setSelectedInsights}
              maxHeight="30vh"
            />
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={props.onClose}>
          Cancel
        </Button>
        <Button
          type="submit"
          color="primary"
          disabled={
            !year || !selectedProperties.length || !selectedInsights.length
          }
        >
          Download Template
        </Button>
      </ModalFooter>
    </UncontrolledModal>
  );
};

const defaultGoalInsightIds = [
  Applications,
  AverageDaysVacant,
  AverageNotReadyDuration,
  BadDebt,
  CapitalImprovement,
  CashFlow,
  Delinquencies,
  EmployeeUnits,
  ExpenseFactor,
  GrossPotentialRent,
  InPlaceRent,
  LeadToApplicationConversion,
  LeadToMoveInConversion,
  LossToLease,
  MoveIns,
  NetOperatingIncome,
  NetRentalIncome,
  NewSignsLast30Days,
  NoiAfterDebtService,
  NonIncomeUnits,
  Occupancy,
  PercentTradeOutNewSignsLast30Days,
  PercentTradeOutRenewalsLast30Days,
  RenewalRateId,
  RentConcessions,
  SiteVisitCount,
  TotalAdvertisingMarketing,
  TotalCommercialIncome,
  TotalContractServices,
  TotalDebtService,
  TotalGeneralAdministrative,
  TotalInsurance,
  TotalLandscape,
  TotalManagementFees,
  TotalMiscExpenses,
  TotalNetPotentialRent,
  TotalOperatingExpenses,
  TotalOperatingRevenue,
  TotalOtherIncome,
  TotalPropertyPayroll,
  TotalRentalIncomeAdjustments,
  TotalRepairsMaintenance,
  TotalTaxesFees,
  TotalUnitTurnover,
  TotalUtilityExpenses,
  UtilityExpensesCable,
  UtilityExpensesElectricity,
  UtilityExpensesGas,
  UtilityExpensesPest,
  UtilityExpensesTrash,
  UtilityExpensesWater,
  VacancyLoss,
  WorkOrderAverageAgeToCompletion,
];

export default GoalsTemplateGeneratorModal;
