import { faPlus } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  DashboardKindEnum,
  PropertiesSelection,
} from "@joyhub-integration/shared";
import { useMemo } from "react";
import { useDrop } from "react-dnd";
import { Button } from "reactstrap";
import { useBreakpoint } from "../../../../../hooks/useBreakpoint";
import {
  DashboardBoardRestPropsType,
  Dashboard as DashboardType,
} from "../../../../../services/dashboardService";
import { OtherDashboardInsight } from "../../../../../services/models";
import { RowItem } from "./RowItem";

interface BoardRowPropsType {
  data: Record<string, any>[];
  dashboard: DashboardType;
  index: number;
  selection: PropertiesSelection;
  dashboardKind:
    | DashboardKindEnum.Dashboard
    | DashboardKindEnum.PropertyDashboard;
  onUpdateLayout: (
    itemRowIndex: number,
    itemNo: number,
    update: number | "left" | "right",
  ) => void;
  onUpdateWidth: (itemRowIndex: number, itemNo: number, width: number) => void;
  setDashboardInsightToEdit: (di: OtherDashboardInsight) => void;
  setDashboardInsightToRemove: (di: OtherDashboardInsight) => void;
  toggleAddEditInsight: (xy: [number, number]) => void;
}

export const BoardRow = (
  props: BoardRowPropsType & DashboardBoardRestPropsType,
) => {
  const {
    data,
    index,
    editMode,
    onUpdateLayout,
    onUpdateWidth,
    toggleAddEditInsight,
    ...rest
  } = props;
  const breakpoint = useBreakpoint();
  const [{ isOver, canDrop }, drop] = useDrop(
    () => ({
      accept: "card",
      canDrop: () => {
        return true;
      },
      drop: () => ({ rowIndex: index }),
      collect: (monitor) => ({
        isOver: !!monitor.isOver(),
        canDrop: !!monitor.canDrop(),
      }),
    }),
    [],
  );
  const maxX = useMemo(() => {
    let maxX = 0;
    if (data) for (const item of data) maxX = Math.max(maxX, 1 + item.x);
    return maxX;
  }, [data]);

  return (
    <div
      ref={editMode ? drop : undefined}
      style={{
        marginTop: editMode ? 10 : undefined,
        padding: 10,
        border: editMode ? "1px solid #ddd" : undefined,
        borderRadius: 5,
        background: editMode
          ? isOver && canDrop
            ? "#a9cbcb"
            : "#eee"
          : undefined,
        minHeight: "5rem",
        display: "flex",
        flexDirection: breakpoint === "lg" ? "row" : "column",
        gap: editMode ? 10 : 20,
      }}
    >
      {data &&
        data.map((item, idx) => (
          <RowItem
            onUpdateLayout={onUpdateLayout}
            onUpdateWidth={onUpdateWidth}
            key={item.i}
            data={item}
            last={idx === data.length - 1}
            {...rest}
            editMode={editMode}
          />
        ))}
      {editMode ? (
        <Button
          color="primary"
          outline
          className="d-flex p-2 border-0 align-items-center justify-content-center"
          onClick={() => toggleAddEditInsight([maxX, index])}
          title="Add Card"
          style={{ flexGrow: data?.length ? undefined : "1" }}
        >
          <FontAwesomeIcon icon={faPlus} />
          {data?.length ? null : <span className="ms-1">Add Card</span>}
        </Button>
      ) : null}
    </div>
  );
};
