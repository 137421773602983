import { PureDate } from "@joyhub-integration/shared";
import {
  InsightRangeValue,
  InsightValues,
  InstantInsights,
  RangedInsights,
  isCountOfTotal,
} from "../../services/dataService";

export type CDActualTarget = {
  actual: number | undefined;
  target: number | undefined;
};

export type CDActualTargetDelta = CDActualTarget & {
  delta: number | undefined;
};

export const numberValue = (value: any): number | undefined =>
  typeof value === "number"
    ? value
    : isCountOfTotal(value)
      ? !value.total
        ? undefined
        : value.count / value.total
      : undefined;

export const insightValue = (
  insights: InsightValues | undefined,
  id: number,
) => (insights == null ? undefined : numberValue(insights[id]));

export const previousInsights = (
  rangedInsights: RangedInsights,
  date: PureDate,
  ago: number,
): InsightRangeValue | undefined => {
  const previousDate = date.lastDayOfPriorMonth(ago);
  previousDate.setDate(1); // how the data arrive
  const dateString = previousDate.toJSON();
  return rangedInsights?.overall.find((i) => i.date === dateString);
};

export const financialData = (
  insights: InstantInsights | undefined,
  actual: number,
  budget: number,
): CDActualTargetDelta | undefined => {
  return insights == null
    ? undefined
    : {
        actual: insightValue(insights.overall, actual),
        target: insightValue(insights.overall, budget),
        delta: undefined,
      };
};

export const goaledData = (
  insights: InstantInsights | undefined,
  insight: number,
): CDActualTarget | undefined => {
  return insights == null
    ? undefined
    : {
        actual: insightValue(insights.overall, insight),
        target: insightValue(insights.goals.overall, insight),
      };
};
