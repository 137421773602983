import { faPlus } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useCallback, useEffect, useState } from "react";
import { ReactComponent as RevolutionRELogo } from "../../../images/logo-h.svg";
import {
  Integration,
  IntegrationVendor,
  IntegrationVendors,
  getIntegrations,
} from "../../../services/integrationsService";
import { dateOf } from "../../../utils/date";
import Loading from "../../app/Loading";
import withAlertModal, {
  WithAlertModalProps,
} from "../../common/alert/withAlertModal";
import AddEditIntegrationModal from "../integrations/addEditIntegrationModal/AddEditIntegrationModal";
import vendorImages from "../integrations/vendorImages";
import ConnectionCard from "./ConnectionCard";
import "./propertyConnect.css";

const PropertyConnect: React.FC<WithAlertModalProps> = ({
  onUnexpectedError,
}) => {
  const [connections, setConnections] = useState<Integration[]>();
  const [integrationSelected, setIntegrationSelected] = useState<Integration>();
  const [vendorSelected, setVendorSelected] = useState<IntegrationVendor>();

  const fetchConnections = useCallback(() => {
    return getIntegrations()
      .then((integrations) => {
        setConnections(integrations);
      })
      .catch(onUnexpectedError);
  }, [onUnexpectedError]);

  useEffect(() => {
    fetchConnections();
  }, [fetchConnections]);

  function onAddEditModalClose() {
    setVendorSelected(undefined);
    setIntegrationSelected(undefined);
  }

  function onConnectorAdded(integration: Integration) {
    fetchConnections().then(onAddEditModalClose);
  }

  if (!connections) return <Loading />;
  return (
    <div className="property-connect-main">
      <h1>
        <RevolutionRELogo style={{ width: "14rem" }} />
        <div className="logo-container">
          <span className="logo-prop">Property</span>
          <span className="logo-bar">|</span>
          <span className="logo-connect">Connect</span>
        </div>
      </h1>

      <div className="d-flex flex-col property-connect-connections-container">
        <h2 className="property-connect-connections-list-header">
          Connections
        </h2>
        {connections.length === 0 ? (
          <div className="d-flex flex-col justify-content-center align-items-center property-connect-connections-none">
            You do not have any connections yet. Start by selecting a connector
            type below.
          </div>
        ) : (
          <div className="property-connect-connections-list">
            {connections.map((connection) => (
              <ConnectionCard
                key={connection.id}
                id={connection.id}
                name={connection.name}
                vendorType={connection.vendor}
                dataSyncActive={connection.active}
                lastSynced={dateOf(connection.last_sync?.started)}
                showEdit={() => setIntegrationSelected(connection)}
              />
            ))}
          </div>
        )}
      </div>
      <div className="property-connect-connector-types-container">
        <div className="property-connect-connector-types">
          <div
            className="property-connect-connector-type"
            onClick={() => setVendorSelected("Entrata")}
          >
            <FontAwesomeIcon icon={faPlus} size="lg" />
            <div className="d-flex flex-row justify-content-center w-100">
              <img src={vendorImages["Entrata"]} alt="Entrata Logo" />
            </div>
          </div>
          <div
            className="property-connect-connector-type"
            onClick={() => setVendorSelected("RealPage")}
          >
            <FontAwesomeIcon icon={faPlus} size="lg" />
            <div className="d-flex flex-row justify-content-center w-100">
              <img src={vendorImages["RealPage"]} alt="RealPage Logo" />
            </div>
          </div>
        </div>
        <div className="property-connect-connector-types">
          <div
            className="property-connect-connector-type"
            onClick={() => setVendorSelected("Yardi")}
          >
            <FontAwesomeIcon icon={faPlus} size="lg" />
            <div className="d-flex flex-row justify-content-center w-100">
              <img src={vendorImages["Yardi"]} alt="Yardi Logo" />
            </div>
          </div>
        </div>
      </div>
      {integrationSelected ? (
        <AddEditIntegrationModal
          id={integrationSelected.id}
          vendor={integrationSelected.vendor}
          onSubmit={onConnectorAdded}
          onClose={onAddEditModalClose}
          setSelectedIntegrationVendor={() => {
            return IntegrationVendors.find(
              (v) => v === integrationSelected?.vendor,
            );
          }}
        />
      ) : vendorSelected ? (
        <AddEditIntegrationModal
          vendor={vendorSelected}
          onSubmit={onConnectorAdded}
          onClose={onAddEditModalClose}
          setSelectedIntegrationVendor={setVendorSelected}
        />
      ) : null}
    </div>
  );
};

export default withAlertModal(PropertyConnect);
