import { ReportInterval } from "@joyhub-integration/shared";
import { VisualizationSpec } from "react-vega";
import { getTemporalInfo } from "../../../vegaSpecs/util";

const layer = (field: string, format?: string) => ({
  params: [
    {
      name: field,
      select: { type: "point", fields: ["column"] },
      bind: "legend",
    },
  ],
  mark: { type: "line", interpolate: "monotone" },
  encoding: {
    y: {
      field,
      title: null,
      type: "quantitative",
      axis: { format },
      scale: { zero: false },
    },
    tooltip: { field: `${field}Tooltip`, type: "nominal" },
    opacity: {
      condition: { param: field, value: 1 },
      value: 0.2,
    },
  },
});

const line = ({
  xAxisFormat,
  yAxisFormat,
  y2AxisFormat,
  y2,
  interval,
  isPropertyOrDimension,
  colors,
}: {
  xAxisFormat?: string;
  yAxisFormat?: string;
  y2AxisFormat?: string;
  y2: boolean;
  interval: ReportInterval | undefined;
  isPropertyOrDimension?: boolean;
  colors?: string[];
}) => {
  const layers = [layer("y", yAxisFormat)];
  if (y2) {
    layers.push(layer("y2", y2AxisFormat));
  }
  const { timeUnit, timeFormat, xTooltip } = getTemporalInfo(
    interval,
    xAxisFormat,
  );

  return {
    $schema: "https://vega.github.io/schema/vega-lite/v5.json",
    height: "container",
    width: "container",
    data: { name: "data" },
    transform: [
      {
        calculate: `${xTooltip} + ' - ' + datum.column + ': ' + format(datum.y, '${
          yAxisFormat ?? ",d"
        }')`,
        as: "yTooltip",
      },
      y2
        ? {
            calculate: `${xTooltip} + ' - ' + datum.column + ': ' + format(datum.y2, '${
              y2AxisFormat ?? ",d"
            }')`,
            as: "y2Tooltip",
          }
        : {},
    ],
    encoding: {
      x: {
        field: "x",
        title: null,
        type: "nominal",
        timeUnit: isPropertyOrDimension ? undefined : timeUnit,
        axis: isPropertyOrDimension
          ? undefined
          : {
              format: timeFormat ?? xAxisFormat,
              formatType: timeFormat ? "time" : undefined,
              labelAngle: -90,
            },
      },
      color: {
        field: "column",
        sort: { field: "*", order: "ascending", op: "count" },
        scale: {
          range: colors,
        },
      },
    },
    layer: layers,
    resolve: { scale: { y: "independent" } },
  } as VisualizationSpec;
};

export default line;
