import {
  NonGroupPropertiesSelection,
  PropertiesByColumn,
  PropertiesByIds,
  asPropertyIds,
  exhaustiveCheck,
  isAllProperties,
  isPropertiesByColumns,
  isPropertiesByIdOrIds,
  isPropertiesByMetadata,
} from "@joyhub-integration/shared";
import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  FormGroup,
  Input,
  Label,
  ModalBody,
  ModalFooter,
  ModalHeader,
  UncontrolledAlert,
} from "reactstrap";
import { unexpectedError } from "../../../constants";
import {
  createRole,
  editRole,
  getRoleById,
} from "../../../services/rolesService";
import { Role } from "../../../services/usersService";
import PlatformContext from "../../app/PlatformContext";
import UncontrolledModal from "../../common/modal/UncontrolledModal";
import ViewPropertiesPermissionsFormGroup, {
  PropertiesSelectionType,
} from "./ViewPropertiesPermissionsFormGroup";

interface AddEditRoleModalProps {
  id?: number;
  onClose: () => void;
  onSubmit: (role: Role) => void;
}

const AddEditRoleModal: React.FC<AddEditRoleModalProps> = (props) => {
  const [serverError, setServerError] = useState("");
  const [name, setName] = useState("");
  const [administrator, setAdministrator] = useState(false);
  const [propertiesSelectionType, setPropertiesSelectionType] =
    useState<PropertiesSelectionType>("none");
  const platform = useContext(PlatformContext).platform!;
  const allProperties = Object.values(platform.propertiesMap);
  const [byIdsSelection, setByIdsSelection] = useState<PropertiesByIds>({
    ids: [],
  });
  const [byColumnSelection, setByColumnSelection] =
    useState<PropertiesByColumn>({
      columns: {},
    });

  useEffect(() => {
    if (props.id) {
      getRoleById(props.id).then(({ name, properties_selection: sel }) => {
        if (sel == null) {
          setPropertiesSelectionType("none");
        } else if (isAllProperties(sel)) {
          setPropertiesSelectionType("all");
        } else if (isPropertiesByIdOrIds(sel)) {
          setPropertiesSelectionType("ids");
          setByIdsSelection({ ids: asPropertyIds(sel) });
        } else if (isPropertiesByColumns(sel)) {
          setPropertiesSelectionType("columns");
          setByColumnSelection(sel);
        } else if (isPropertiesByMetadata(sel)) {
          setPropertiesSelectionType("none");
        } else {
          exhaustiveCheck(sel);
        }

        setName(name);
        // react-hooks/exhaustive-deps suggests "You can also do a functional
        // update 'setAdministrator(a => ...)' if you only need 'administrator'
        // in the 'setAdministrator' call"
        setAdministrator(administrator);
      });
    }
  }, [administrator, props.id]);

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    let propertiesSelection: NonGroupPropertiesSelection | null;
    if (administrator || propertiesSelectionType === "all") {
      propertiesSelection = {};
    } else if (propertiesSelectionType === "none") {
      propertiesSelection = null;
    } else if (propertiesSelectionType === "ids") {
      propertiesSelection = byIdsSelection;
    } else if (propertiesSelectionType === "columns") {
      propertiesSelection = byColumnSelection;
    } else {
      propertiesSelection = exhaustiveCheck(propertiesSelectionType);
    }

    const roleDto = {
      name,
      administrator,
      propertiesSelection,
    };
    const promise = props.id
      ? editRole(props.id, roleDto)
      : createRole(roleDto);
    promise
      .then((role) => props.onSubmit(role))
      .catch((err) => {
        const response = err.response;
        if (response && response.status === 400 && response.data.message) {
          setServerError(response.data.message);
        } else {
          setServerError(unexpectedError);
        }
      });
  };

  return (
    <UncontrolledModal onClosed={props.onClose} onFormSubmit={onSubmit}>
      <ModalHeader>
        {props.id ? `Edit Property Access ${name}` : "Add Property Access"}
      </ModalHeader>
      <ModalBody>
        {serverError && (
          <UncontrolledAlert color="danger">{serverError}</UncontrolledAlert>
        )}
        <FormGroup>
          <Label>Name</Label>
          <Input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </FormGroup>
        <h5>Permissions</h5>
        <ViewPropertiesPermissionsFormGroup
          allProperties={allProperties}
          propertiesSelectionType={propertiesSelectionType}
          setPropertiesSelectionType={setPropertiesSelectionType}
          byIdsSelection={byIdsSelection}
          setByIdsSelection={setByIdsSelection}
          byColumnSelection={byColumnSelection}
          setByColumnSelection={setByColumnSelection}
        />
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={props.onClose}>
          Cancel
        </Button>
        <Button type="submit" color="primary">
          Save
        </Button>{" "}
      </ModalFooter>
    </UncontrolledModal>
  );
};

export default AddEditRoleModal;
