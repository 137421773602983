import React, { useState } from "react";
import {
  Button,
  Col,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  ModalBody,
  ModalFooter,
  ModalHeader,
  UncontrolledAlert,
} from "reactstrap";
import { unexpectedError } from "../../constants";
import dashboardService, {
  BaseDashboard,
} from "../../services/dashboardService";
import UncontrolledModal from "../common/modal/UncontrolledModal";
import EditIdentifier from "./EditIdentifier";

interface CopyModalProps {
  id: number;
  originalName: string;
  onSubmit: (d: BaseDashboard) => void;
  onClose: () => void;
}

interface ValidationError {
  message?: string;
  field?: string;
}

const CopyModal: React.FC<CopyModalProps> = ({
  id,
  originalName,
  onClose,
  onSubmit: onSubmitProp,
}) => {
  const [serverError, setServerError] = useState("");
  const [validationError, setValidationError] = useState<ValidationError>({});
  const [identifier, setIdentifier] = useState<string>(() =>
    dashboardService.identifier(),
  );
  const [name, setName] = useState<string>(() => `Copy of ${originalName}`);

  function validateForm(): ValidationError {
    if (!name) {
      return { message: "Name is required.", field: "name" };
    } else if (!identifier) {
      return { message: "Identifier is required.", field: "identifier" };
    }
    return {};
  }

  function onSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    const validationError = validateForm();
    setValidationError(validationError);
    if (!Object.keys(validationError).length) {
      setServerError("");
      dashboardService
        .copyDashboard(id, identifier, name)
        .then((d) => onSubmitProp(d))
        .catch(() => setServerError(unexpectedError));
    }
  }

  return (
    <UncontrolledModal onClosed={onClose} onFormSubmit={onSubmit}>
      <ModalHeader>
        Copy dashboard
        <EditIdentifier identifier={identifier} setIdentifier={setIdentifier} />
      </ModalHeader>
      <ModalBody>
        {serverError ? (
          <UncontrolledAlert color="danger">{serverError}</UncontrolledAlert>
        ) : null}
        <FormGroup row>
          <Label sm={3}>Name</Label>
          <Col sm={9}>
            <Input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              invalid={validationError.field === "name"}
            />
            {validationError.field === "name" && validationError.message ? (
              <FormFeedback>{validationError.message}</FormFeedback>
            ) : null}
          </Col>
        </FormGroup>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={onClose}>
          Cancel
        </Button>
        <Button type="submit" className="jh-btn-primary">
          Copy
        </Button>{" "}
      </ModalFooter>
    </UncontrolledModal>
  );
};

export default CopyModal;
