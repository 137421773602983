import { FinancialImportDto } from "@joyhub-integration/shared";
import React from "react";
import { Button, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import {
  useOnUnexpectedError,
  useSetAlert,
} from "../../common/alert/withAlertModal";
import UncontrolledModal from "../../common/modal/UncontrolledModal";
import { deleteFinancialImport } from "./financialImportsApi";

type DeleteFinancialImportModalProps = {
  value: FinancialImportDto<true>;
  onSubmit: () => void;
  onClose: () => void;
};

const DeleteFinancialImportModal: React.FC<DeleteFinancialImportModalProps> = ({
  value,
  onClose,
  onSubmit,
}) => {
  const onUnexpectedError = useOnUnexpectedError();
  const setAlert = useSetAlert();

  const doDelete = async () => {
    try {
      await deleteFinancialImport(value.id);
      setAlert(`${value.description} was deleted.`, true);
      onSubmit();
    } catch (e) {
      onUnexpectedError(e);
    }
  };

  return (
    <UncontrolledModal size="lg" onFormSubmit={doDelete} onClosed={onClose}>
      <ModalHeader toggle={onClose}>
        Delete Financials: {value.description}
      </ModalHeader>
      <ModalBody>
        This will permanently delete all financials imported with this file. It
        will not restore any prior financials that were loaded in a previous
        import.
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={onClose}>
          Cancel
        </Button>
        <Button type="submit" color="danger">
          Delete
        </Button>
      </ModalFooter>
    </UncontrolledModal>
  );
};

export default DeleteFinancialImportModal;
