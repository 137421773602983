import {
  faKey,
  faPlus,
  faRedo,
  faTrash,
} from "@fortawesome/pro-light-svg-icons";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import {
  deleteTransferUser,
  getIntegrationById,
  getTransferUsers,
  Integration,
  TransferUser,
  transferUserKinds,
} from "../../../services/integrationsService";
import withAlertModal, {
  WithAlertModalProps,
} from "../../common/alert/withAlertModal";
import { LoadilyFadily } from "../../common/allFadily";
import ActionBar from "../../common/button/ActionBar";
import { ButtonWithIconProps } from "../../common/button/ButtonWithIcon";
import TableWithSelection, {
  KeyValue,
} from "../../common/table/TableWithSelection";
import { ModernCrumbar } from "../../layout/ModernCrumbar";
import DeleteModal from "../common/DeleteModal";
import AddTransferUserModal from "./AddTransferUserModal";
import EditTransferUserModal from "./EditTransferUserModal";

type TransferUsersParams = {
  id: string;
};

const TransferUsersPage: React.FC<WithAlertModalProps> = ({
  setAlert,
  onUnexpectedError,
}) => {
  const params = useParams<TransferUsersParams>();
  const [transferUsers, setTransferUsers] = useState<Array<TransferUser>>();
  const [selected, setSelected] = useState<TransferUser>();
  const [integration, setIntegration] = useState<Integration>();
  const [loaded, setLoaded] = useState(false);
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(0);
  const systemId = parseInt(params.id!);
  const [addingUser, setAddingUser] = useState<boolean>(false);
  const [deletingUser, setDeletingUser] = useState<boolean>(false);
  const [resettingPassword, setResettingPassword] = useState(false);

  useEffect(() => {
    setLoading(true);
    Promise.all([getTransferUsers(systemId), getIntegrationById(systemId)])
      .then(([users, i]) => {
        setTransferUsers(users);
        setIntegration(i);
      })
      .catch(onUnexpectedError)
      .finally(() => {
        setLoading(false);
        setLoaded(true);
      });
  }, [onUnexpectedError, systemId, refresh]);

  const buttonProps: ButtonWithIconProps[] = [
    {
      label: "Add User",
      icon: faPlus,
      onClick: () => setAddingUser(true),
      className: "jh-btn-primary",
    },
    {
      icon: faKey,
      disabled: !selected,

      onClick: () => setResettingPassword(true),
      tooltip: "Reset Password",
    },
    {
      icon: faTrash,
      disabled: !selected,
      onClick: () => setDeletingUser(true),
      tooltip: "Delete User",
    },
  ];

  const rightButtonProps: ButtonWithIconProps[] = [
    {
      icon: faRedo,
      onClick: () => setRefresh((r) => 1 + r),
      outline: true,
      tooltip: "Refresh",
      disabled: loading,
    },
  ];

  const tableCols: Array<KeyValue<TransferUser>> = [
    {
      key: "username",
      title: "Username",
    },
    {
      key: "email",
      title: "Email Address",
    },
    {
      key: "kind",
      title: "Behaviour",
      toValue: (t) => transferUserKinds[t.kind ?? ""],
    },
    {
      key: "password",
      title: "Password",
      toValue: (t) => (t.password ? "Yes" : "No"),
    },
  ];

  return (
    <>
      <ModernCrumbar
        primary="Manage Integrations"
        primaryPath="/admin/integrations"
        secondary={`"${integration?.name ?? "?"}" Transfer Users`}
      />
      <LoadilyFadily loaded={loaded} className="jh-page-layout">
        <ActionBar
          buttonProps={buttonProps}
          rightButtonProps={rightButtonProps}
        />
        <div className="jh-page-content pt-0 admin-page page-scroll">
          <TableWithSelection<TransferUser>
            selected={selected}
            onSelectedChange={(selected) => setSelected(selected)}
            columns={tableCols}
            rows={transferUsers}
            sortColumn="started"
            sortDirection="desc"
          />
        </div>
      </LoadilyFadily>
      {addingUser && integration && (
        <AddTransferUserModal
          integration={integration}
          onClose={() => {
            setAddingUser(false);
            setRefresh((r) => 1 + r);
          }}
        />
      )}
      {resettingPassword && integration && selected && (
        <EditTransferUserModal
          integration={integration}
          transferUser={selected}
          onClose={() => {
            setResettingPassword(false);
            setRefresh((r) => 1 + r);
          }}
        />
      )}
      {deletingUser && selected && (
        <DeleteModal<TransferUser>
          id={selected.id}
          entityName="Transfer User"
          identificationKey="username"
          onSubmit={() => {
            setAlert(
              `Transfer user ${selected.username} deleted successfully`,
              true,
            );
            setSelected(undefined);
            setDeletingUser(false);
            setRefresh((r) => 1 + r);
          }}
          onClose={() => setDeletingUser(false)}
          getEntity={() => selected}
          deleteEntity={(id) => deleteTransferUser(systemId, id)}
        />
      )}
    </>
  );
};

export default withAlertModal(TransferUsersPage);
