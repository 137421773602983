import { find } from "lodash";
import { ChangeEvent, useCallback, useEffect, useState } from "react";
import { FormGroup, Input, Label } from "reactstrap";
import { usePropertyId } from "../../../../hooks/usePropertyId";
import { IframeInsightId } from "../../../../services/insightLibrary/insightDefinitions";
import insightLibraryService from "../../../../services/insightLibrary/insightsLibraryService";
import { ValidationError } from "../../../admin/integrations/addEditIntegrationModal/common";
import { IframeConfig } from "../../../IframeInsightCard";

interface IframeCardPropsType {
  dashboardInsight?: Record<string, any>;
  validationError: ValidationError;
  onUnexpectedError: (e?: any) => void;
  updateDashboardInsight: (fieldName: string, newConfig: any) => void;
}

export const IframeCard = ({
  dashboardInsight,
  onUnexpectedError,
  updateDashboardInsight,
}: IframeCardPropsType) => {
  const { isPropertyDashboard, propertyId } = usePropertyId();
  const [iframeUrl, setIframeUrl] = useState<string>("");
  const [iframeHeight, setIframeHeight] = useState<number>(0);

  const updateIframeConfig = useCallback(
    (key: keyof IframeConfig, value: string | number) => {
      const defaultIframe = dashboardInsight?.stuff?.iframe || {};
      const updatedIframe =
        isPropertyDashboard && propertyId
          ? {
              ...defaultIframe,
              [propertyId]: {
                ...defaultIframe?.[propertyId],
                ...{ [key]: value },
              },
            }
          : { ...defaultIframe, ...{ [key]: value } };

      updateDashboardInsight("stuff", {
        iframe: updatedIframe,
      });
      updateDashboardInsight("linkedReport", undefined);
      updateDashboardInsight("name", "Iframe card");
    },
    [
      dashboardInsight?.stuff,
      isPropertyDashboard,
      propertyId,
      updateDashboardInsight,
    ],
  );

  const handleHeightChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newHeight = isNaN(e.target.valueAsNumber)
      ? 0
      : e.target.valueAsNumber;
    updateIframeConfig("height", newHeight);
  };

  const handleUrlChange = (e: ChangeEvent<HTMLInputElement>) => {
    updateIframeConfig("url", e.target.value);
  };

  useEffect(() => {
    const setupInitialData = async () => {
      try {
        const insights = await insightLibraryService.getInsights();
        const iframeInsight = find(insights, { id: IframeInsightId });
        updateDashboardInsight("insight", iframeInsight);
      } catch (error) {
        onUnexpectedError(error);
      }
    };

    setupInitialData();
  }, [onUnexpectedError, updateDashboardInsight]);

  useEffect(() => {
    const iframe = dashboardInsight?.stuff?.iframe;
    const currentIframe: IframeConfig | undefined =
      isPropertyDashboard && propertyId ? iframe?.[propertyId] : iframe;

    setIframeHeight(currentIframe?.height ?? 0);
    setIframeUrl(currentIframe?.url ?? "");
  }, [dashboardInsight?.stuff?.iframe, isPropertyDashboard, propertyId]);

  return (
    <div className="d-flex flex-col p-4" style={{ minHeight: "375px" }}>
      <FormGroup>
        <Label for="iframeUrl">URL</Label>
        <Input
          id="iframeUrl"
          type="text"
          value={iframeUrl}
          onChange={handleUrlChange}
          placeholder="https://example.com"
        />
      </FormGroup>
      <FormGroup>
        <Label for="iframeHeight">Height</Label>
        <Input
          id="iframeHeight"
          type="number"
          value={iframeHeight}
          onChange={handleHeightChange}
          min={1}
        />
      </FormGroup>
    </div>
  );
};
