import {
  FinancialImportResponse,
  PureDate,
  financeBookNames,
} from "@joyhub-integration/shared";
import React from "react";
import { dateToMY } from "../../../utils/date";
import { Revealer } from "../../craport/editor/Revealer";

const FinancialImportResponsePreview: React.FC<{
  value: FinancialImportResponse<true>;
}> = ({ value }) => {
  return (
    <>
      {value.warnings.length > 0 && (
        <Revealer label={`${value.warnings.length} Warnings`}>
          <div>
            {value.warnings.map((wrn, idx) => (
              <div key={idx}>{wrn}</div>
            ))}
          </div>
        </Revealer>
      )}
      <div>
        <strong>Description: </strong>
        {`${value.description}`}
      </div>
      <div>
        <strong>Account Tree: </strong>
        {value.tree_name}
      </div>
      <div>
        <strong>Financial Book: </strong>
        {`${financeBookNames[value.book]}`}
      </div>
      {value.imports.length === 0 ? (
        <div>
          <strong>No Financials Present</strong>
        </div>
      ) : (
        <>
          <div>
            <strong>Financials ({value.imports.length}):</strong>
          </div>
          <div className="overflow-auto" style={{ maxHeight: "33vh" }}>
            {value.imports.map(
              ({
                property_code,
                property_name,
                from_date,
                to_date,
                account_count,
              }) => (
                <div key={property_code} className="ms-3 mt-1">
                  {property_name} – {dateToMY(new PureDate(from_date))} to{" "}
                  {dateToMY(new PureDate(to_date))}
                  {account_count == null ? "" : `, ${account_count} accounts`}
                </div>
              ),
            )}
          </div>
        </>
      )}
    </>
  );
};

export default FinancialImportResponsePreview;
