import { faPlus } from "@fortawesome/pro-light-svg-icons";
import { checkPermission, DashboardKindEnum } from "@joyhub-integration/shared";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { BooleanParam, useQueryParam } from "use-query-params";
import DeleteModal from "../../components/admin/common/DeleteModal";
import PlatformContext, {
  usePlatformContext,
} from "../../components/app/PlatformContext";
import dashboardService, {
  BaseDashboard,
  Dashboard,
} from "../../services/dashboardService";
import withAlertModal, {
  WithAlertModalProps,
} from "../common/alert/withAlertModal";
import { LoadilyFadily } from "../common/allFadily";
import ButtonWithIconAndText from "../common/button/ButtonWithIconAndText";
import { ModernCrumbar } from "../layout/ModernCrumbar";
import AddEditDashboard from "./AddEditDashboard";
import DashboardCard from "./DashboardCard";
import DashboardSwitcher from "./DashboardSwitcher";

type DashboardScreenProps = {
  kind: DashboardKindEnum.Dashboard | DashboardKindEnum.PropertyDashboard;
};

const DashboardScreen: React.FC<DashboardScreenProps & WithAlertModalProps> = ({
  setAlert,
  onUnexpectedError,
  kind,
}) => {
  const [dashboards, setDashboards] = useState<Dashboard[]>([]);
  const [addingNewDashboard, setAddingNewDashboard] = useState<boolean>(false);
  const [selected, setSelected] = useState<number | null>(null);
  const [hidden] = useQueryParam("hidden", BooleanParam);

  const [isEditingDashboard, setIsEditingDashboard] = useState<Boolean>(false);
  const [isDeleteDashboard, setIsDeleteDashboard] = useState<Boolean>(false);
  const [loaded, setLoaded] = useState(false);

  const { organization_role } = useContext(PlatformContext).platform!;

  const fetchMyDashboards = useCallback(
    async function fetchMyDashboards() {
      dashboardService
        .getDashboards(!!hidden, true, kind)
        .then((dashboards) => setDashboards(dashboards))
        .catch(onUnexpectedError)
        .finally(() => setLoaded(true));
    },
    [hidden, kind, onUnexpectedError],
  );

  useEffect(() => {
    fetchMyDashboards().then();
  }, [fetchMyDashboards]);

  async function afterDashboardDeleted(dashboard: Dashboard) {
    fetchMyDashboards().then(() => {
      setAlert(`Dashboard ${dashboard.name} deleted.`, true);
      setSelected(null);
      setIsDeleteDashboard(false);
    });
  }

  function setDashboardStatus() {
    setAddingNewDashboard(false);
    setIsEditingDashboard(false);
    setSelected(null);
  }

  function onEditClick(id: number) {
    setSelected(id);
    setIsEditingDashboard(true);
  }

  function onDeleteClick(id: number) {
    setSelected(id);
    setIsDeleteDashboard(true);
  }

  async function onAddEditDashboardSubmit(dashboard: BaseDashboard) {
    fetchMyDashboards()
      .then(() => {
        setAlert(
          `Dashboard '${dashboard.name}' ${
            isEditingDashboard ? "updated" : "created"
          }.`,
          true,
        );
        setDashboardStatus();
      })
      .catch(onUnexpectedError);
  }

  function onClosingAddEditLightbox() {
    setAddingNewDashboard(false);
    setIsEditingDashboard(false);
    setIsDeleteDashboard(false);
    setSelected(null);
  }

  const doDeleteDashboard = (id: number, entity: Dashboard) =>
    entity.immutable
      ? dashboardService.hideDashboard(id, true)
      : dashboardService.deleteDashboard(id);

  const onUnhideClick = (id: number) =>
    dashboardService
      .hideDashboard(id, false)
      .then(fetchMyDashboards)
      .catch(onUnexpectedError);

  const platform = usePlatformContext().platform!;
  const propertyId =
    kind === DashboardKindEnum.PropertyDashboard
      ? +Object.keys(platform.propertiesMap)[0]
      : undefined;

  return (
    <>
      <ModernCrumbar>
        <DashboardSwitcher
          dashboards={dashboards}
          manage
          loaded={loaded}
          propertyId={propertyId}
        />
        {checkPermission("dashboards", "create", organization_role) ? (
          <ButtonWithIconAndText
            onClick={() => setAddingNewDashboard(true)}
            type="submit"
            color="primary"
            size="sm"
            icon={faPlus}
            text="New Dashboard"
            className="report-add-button ms-auto align-self-center"
          />
        ) : null}
      </ModernCrumbar>
      <LoadilyFadily loaded={loaded} className="jh-page-layout jh-page-content">
        {isDeleteDashboard && selected ? (
          <DeleteModal<Dashboard>
            id={selected}
            entityName="Dashboard"
            identificationKey="name"
            getEntity={dashboardService.getDashboardById}
            deleteEntity={doDeleteDashboard}
            onClose={onClosingAddEditLightbox}
            onSubmit={afterDashboardDeleted}
          />
        ) : null}
        {addingNewDashboard || isEditingDashboard ? (
          <AddEditDashboard
            kind={kind}
            onSubmit={(dashboard) => onAddEditDashboardSubmit(dashboard)}
            onClose={onClosingAddEditLightbox}
            id={isEditingDashboard && selected ? selected : undefined}
          />
        ) : null}
        <div className="flex-row flex-wrap card-container">
          {dashboards.map((dashboard) => (
            <DashboardCard
              key={dashboard.id}
              dashboard={dashboard}
              onEdit={onEditClick}
              onDelete={onDeleteClick}
              onUnhide={onUnhideClick}
            />
          ))}
        </div>
      </LoadilyFadily>
    </>
  );
};

export default withAlertModal(DashboardScreen);
