import { keys, sum } from "lodash";
import { useMemo } from "react";
import { Table } from "reactstrap";
import { InstantInsights } from "../../../services/dataService";
import {
  LeaseExpiryCount30Days,
  LeaseExpiryCount60Days,
  LeaseExpiryCount90Days,
  LeaseNoticeCount30Days,
  LeaseNoticeCount60Days,
  LeaseNoticeCount90Days,
  LeasePendingCount30Days,
  LeasePendingCount60Days,
  LeasePendingCount90Days,
  LeaseRenewalCount30Days,
  LeaseRenewalCount60Days,
  LeaseRenewalCount90Days,
  NoticeUnrentedUnitCount,
  VacantUnrentedUnitCount,
} from "../../../services/insightLibrary/backendInsightIds";
import { insightValue } from "../cdUtil";
import AnimatedNumber from "../visualizations/AnimatedNumber";
import DashboardCard from "./DashboardCard";
import { Skelington } from "./Skelington";

type CDExposure = {
  expiringLeases: number | undefined;
  noticeGiven: number | undefined;
  renewed: number | undefined;
  currentExposure: number | undefined;
};

type CDExposureData = {
  thirty: CDExposure;
  sixty: CDExposure;
  ninety: CDExposure;
};

const exposureData = (
  insights: InstantInsights | undefined,
): CDExposureData | undefined => {
  if (insights == null) return undefined;
  const exposure = (
    expiringId: number,
    noticeId: number,
    renewedId: number,
    pendingId: number,
  ): CDExposure => {
    const expiringLeases = insightValue(insights.overall, expiringId);
    const noticeGiven = insightValue(insights.overall, noticeId);
    const renewed = insightValue(insights.overall, renewedId);
    // TODO: This calculation assumes that lease counts and unit
    // counts are always in exact sync, that there will be no
    // vacant units with a lease still noted against them. For
    // example, if I vacate the unit while my lease ends next
    // month will my unit be both vacant unrented and lease pending?
    // A lease on notice may already be rented again so we need
    // to look at more than just leases.
    const currentExposure = sum([
      insightValue(insights.overall, VacantUnrentedUnitCount),
      insightValue(insights.overall, NoticeUnrentedUnitCount),
      insightValue(insights.overall, pendingId),
    ]);
    return {
      expiringLeases,
      noticeGiven,
      renewed,
      currentExposure,
    };
  };
  return {
    thirty: exposure(
      LeaseExpiryCount30Days,
      LeaseNoticeCount30Days,
      LeaseRenewalCount30Days,
      LeasePendingCount30Days,
    ),
    sixty: exposure(
      LeaseExpiryCount60Days,
      LeaseNoticeCount60Days,
      LeaseRenewalCount60Days,
      LeasePendingCount60Days,
    ),
    ninety: exposure(
      LeaseExpiryCount90Days,
      LeaseNoticeCount90Days,
      LeaseRenewalCount90Days,
      LeasePendingCount90Days,
    ),
  };
};
const fields = {
  expiringLeases: "Expiring Leases",
  noticeGiven: "Notice Given",
  renewed: "Renewed",
  currentExposure: "Current Exposure",
} as const;

const row = (field: keyof CDExposure, columns: CDExposure[]) => (
  <tr key={field}>
    <td>{fields[field]}</td>
    {columns.map((column, idx) => {
      const value = column[field];
      return (
        <td key={idx}>
          {value == null ? "–" : <AnimatedNumber>{value}</AnimatedNumber>}
        </td>
      );
    })}
  </tr>
);

const Exposure = ({
  insights,
  onClick,
}: {
  insights?: InstantInsights;
  onClick?: () => void;
}) => {
  const data = useMemo(() => exposureData(insights), [insights]);
  return (
    <DashboardCard title="Exposure" background="inner" onClick={onClick}>
      <Skelington value={data} height={5}>
        {({ thirty, sixty, ninety }) => (
          <Table className="mb-0" size="sm">
            <thead>
              <tr>
                <th />
                <th>30 days</th>
                <th>60 days</th>
                <th>90 days</th>
              </tr>
            </thead>
            <tbody>
              {keys(fields).map((field) =>
                row(field as keyof CDExposure, [thirty, sixty, ninety]),
              )}
            </tbody>
          </Table>
        )}
      </Skelington>
    </DashboardCard>
  );
};

export default Exposure;
