import React, { useState } from "react";
import {
  Button,
  ModalBody,
  ModalFooter,
  ModalHeader,
  UncontrolledAlert,
} from "reactstrap";
import { unexpectedError } from "../../../constants";
import {
  User,
  getInviteTemplate,
  reinviteUser,
} from "../../../services/usersService";
import UncontrolledModal from "../../common/modal/UncontrolledModal";
import EmailPreview from "./EmailPreview";

interface ReinviteModalProps {
  user: User;
  onClose: () => void;
  onSubmit: () => void;
}

const ReinviteModal: React.FC<ReinviteModalProps> = ({
  user,
  onClose,
  onSubmit,
}) => {
  const [serverError, setServerError] = useState<string>("");
  const [password, setPassword] = useState<string | undefined>();

  function doSubmit() {
    reinviteUser(user.id, password)
      .then(() => onSubmit())
      .catch((err) => {
        const response = err.response;
        if (response && response.status === 400 && response.data.message) {
          setServerError(response.data.message);
        } else {
          setServerError(unexpectedError);
        }
      });
  }

  return (
    <UncontrolledModal onClosed={onClose}>
      <ModalHeader>Reinvite User</ModalHeader>
      <ModalBody>
        {serverError ? (
          <UncontrolledAlert color="danger">{serverError}</UncontrolledAlert>
        ) : null}
        <div className="mb-4">
          This will re-send an email invitation to the user '{user.email}'.
          Continue?
        </div>

        <EmailPreview
          user={user}
          templateFunction={getInviteTemplate}
          passwordCallback={setPassword}
        ></EmailPreview>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={onClose}>
          Cancel
        </Button>{" "}
        <Button color="primary" onClick={doSubmit}>
          Reinvite
        </Button>
      </ModalFooter>
    </UncontrolledModal>
  );
};

export default ReinviteModal;
