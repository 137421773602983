import { faHomeHeart, faUsers } from "@fortawesome/pro-light-svg-icons";
import { isPropertiesById } from "@joyhub-integration/shared";
import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { Spinner } from "reactstrap";
import {
  CountOfTotal,
  InstantInsights,
  getInstantData,
} from "../../services/dataService";
import {
  AllUnitCount,
  Occupancy,
  OccupiedUnitCount,
  TenantCount,
} from "../../services/insightLibrary/backendInsightIds";
import { InsightCustomComponentProps } from "../../services/models";
import { Property, getProperty } from "../../services/propertiesService";
import { formatted, toPercentage } from "../../utils/number";
import { ReactComponent as occupied } from "../common/portfolioOverview/Occupied.svg";
import PortfolioOverviewItem from "../common/portfolioOverview/PortfolioOverviewItem";
import { ReactComponent as unitOccupied } from "../common/portfolioOverview/UnitOccupied.svg";

const PropertyOverview: React.FC<InsightCustomComponentProps> = ({
  selection,
  // onUnexpectedError,
}) => {
  const [property, setProperty] = useState<Property>();
  const [instantData, setInstantData] = useState<InstantInsights>();

  const solePropertyId = isPropertiesById(selection) ? selection : undefined;

  useEffect(() => {
    if (solePropertyId != null && !property) {
      const insights = [
        AllUnitCount,
        Occupancy,
        OccupiedUnitCount,
        TenantCount,
      ];
      Promise.all([
        getProperty(solePropertyId),
        getInstantData(insights, selection),
      ]).then(([property, instantData]) => {
        ReactDOM.unstable_batchedUpdates(() => {
          setProperty(property);
          setInstantData(instantData);
        });
      });
      // .catch(onUnexpectedError);
    }
  }, [property, solePropertyId, selection]);

  if (solePropertyId == null) {
    return null;
  }
  if (!property || !instantData) {
    return <Spinner />;
  }

  const unitCount = formatted(instantData.overall[AllUnitCount] as number);
  const occupiedUnitCount = formatted(
    instantData.overall[OccupiedUnitCount] as number,
  );
  const residents = formatted(instantData.overall[TenantCount] as number);
  const occupancy = toPercentage(
    instantData.overall[Occupancy] as CountOfTotal,
  );
  return (
    <div className="w-100 h-100 d-flex flex-row property-overview align-items-center p-3">
      <PortfolioOverviewItem
        value={unitCount}
        icon={faHomeHeart}
        label={unitCount === "1" ? "Unit" : "Units"}
        className="property-overview-item"
      />

      <PortfolioOverviewItem
        value={occupiedUnitCount}
        svg={unitOccupied}
        label="Occupied"
        className="property-overview-item"
      />

      <PortfolioOverviewItem
        value={occupancy}
        svg={occupied}
        label="Occupancy"
        className="property-overview-item"
      />

      <PortfolioOverviewItem
        value={residents}
        icon={faUsers}
        label="Residents"
        className="property-overview-item"
      />
    </div>
  );
};

export default PropertyOverview;
