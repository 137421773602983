import { isPropertiesById } from "@joyhub-integration/shared";
import { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { usePropertyId } from "../../hooks/usePropertyId";
import { InsightCustomComponentProps } from "../../services/models";
import { apiUrl } from "../../utils/api";
import { usePlatformContext } from "../app/PlatformContext";

export type IndependentImageCardConfig = {
  isPropertyImage: boolean;
  imageGuid: string;
};

type IndependentImageInsightCardConfig = {
  image:
    | IndependentImageCardConfig
    | Record<number, IndependentImageCardConfig>;
};

export const IndependentImageInsightCard: React.FC<
  InsightCustomComponentProps<IndependentImageInsightCardConfig>
> = ({ dashboardInsight, selection }) => {
  const solePropertyId = isPropertiesById(selection) ? selection : undefined;
  const propertiesMap = usePlatformContext().platform?.propertiesMap;

  const { isPropertyDashboard, propertyId } = usePropertyId();
  const [imageGuid, setImageGuid] = useState<string>("");
  const [isPropertyImage, setIsPropertyImage] = useState<boolean>(false);

  const [imageSrc, setImageSrc] = useState<string>("");
  const location = useLocation();

  useEffect(() => {
    const imageConfig: IndependentImageCardConfig | undefined =
      isPropertyDashboard && propertyId
        ? (
            dashboardInsight?.stuff?.image as Record<
              number,
              IndependentImageCardConfig
            >
          )?.[propertyId]
        : (dashboardInsight?.stuff?.image as IndependentImageCardConfig);

    setImageGuid(imageConfig?.imageGuid ?? "");
    setIsPropertyImage(!!imageConfig?.isPropertyImage);
  }, [dashboardInsight?.stuff, isPropertyDashboard, propertyId]);

  useEffect(() => {
    const propertyImageGuid =
      isPropertyImage &&
      solePropertyId &&
      propertiesMap?.[solePropertyId]?.image_guid;

    const imageUrl = propertyImageGuid
      ? apiUrl(`/properties/${solePropertyId}/image/${propertyImageGuid}`)
      : apiUrl(`/dashboards/image/${imageGuid}`);

    const embedMatch = location.pathname.match(/\/embed\/(.*)/);
    const isEmbedLink = embedMatch && embedMatch[1];

    setImageSrc(
      `${imageUrl}${isEmbedLink && imageUrl ? `?embedUUID=${embedMatch[1]}` : ""}`,
    );
  }, [imageGuid, isPropertyImage, location, propertiesMap, solePropertyId]);

  return imageSrc ? (
    <div className="d-flex align-items-center justify-content-center w-100 h-100">
      <img
        style={{ borderRadius: 5, maxWidth: "100%", maxHeight: "100%" }}
        src={imageSrc}
        alt=""
      />
    </div>
  ) : null;
};
