import { AdHocRule } from "@joyhub-integration/shared";
import { JSONEditor } from "@json-editor/json-editor";
import { useCallback, useMemo, useState } from "react";
import { ruleSchema } from "./ruleSchema";

interface AdHocInsightRuleEditorPropsType {
  setRule: (value: AdHocRule) => void;
  setRuleErrors: (value: any) => void;
  rule: AdHocRule;
}

export const AdHocInsightRuleEditor = (
  props: AdHocInsightRuleEditorPropsType,
) => {
  const { setRule, setRuleErrors, rule } = props;

  const [, setEditor] = useState<any>();
  const [initialValue] = useState(rule);

  const schema = useMemo(
    () => ({
      ...ruleSchema,
    }),
    [],
  );

  const onRef = useCallback(
    (el: HTMLDivElement | null) => {
      if (el) {
        const editor = new JSONEditor(el, {
          theme: "bootstrap4",
          schema,
          startval: initialValue,
          prompt_before_delete: false,
          show_opt_in: true,
          show_errors: "always",
          disable_properties: true,
          disable_edit_json: true,
        });
        editor.on("change", () => {
          setRule(editor.getValue());
          setRuleErrors(editor.validate());
        });
        setEditor(editor);
      } else if (!el) {
        setEditor((e: any) => {
          e?.destroy(); // sus...
        });
      }
    },
    [initialValue, schema, setEditor, setRule, setRuleErrors],
  );

  return <div className="insight-definition-editor" ref={onRef} />;
};
