import { faClose } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { ChromePicker } from "react-color";
import "./style.css";

interface ColorPickerPropsType {
  color: string;
  updateColor: (color: string) => void;
  removeColor: () => void;
}

export const ColorPicker = (props: ColorPickerPropsType) => {
  const { color, updateColor, removeColor } = props;

  const [displayColorPicker, setDisplayColorPicker] = useState(false);

  const handleClick = () => {
    setDisplayColorPicker(!displayColorPicker);
  };

  const handleClose = () => {
    setDisplayColorPicker(false);
  };

  const handleChange = (newColor: { hex: string }) => {
    updateColor(newColor.hex);
  };

  return (
    <div>
      <div className="d-flex position-relative">
        <div
          style={{ width: 42, height: 42, background: color }}
          onClick={handleClick}
        ></div>
        <div
          onClick={handleClick}
          className="d-flex justify-content-center align-items-center bg-white px-2"
          style={{
            width: "calc(100% - 42px)",
            border: "none",
            borderRadius: 0,
          }}
        >
          {color}
        </div>
        {!displayColorPicker && (
          <FontAwesomeIcon
            className="weekly-coffee position-absolute close-btn"
            icon={faClose}
            style={{
              top: -7,
              right: -7,
              height: 16,
              width: 16,
              borderRadius: 10,
            }}
            onClick={removeColor}
          />
        )}
      </div>

      {displayColorPicker ? (
        <div style={{ position: "absolute", zIndex: "2" }}>
          <div
            style={{
              position: "fixed",
              top: "0px",
              right: "0px",
              bottom: "0px",
              left: "0px",
            }}
            onClick={handleClose}
          ></div>
          <ChromePicker color={color} onChange={handleChange} />
        </div>
      ) : null}
    </div>
  );
};
