import { useEffect, useState } from "react";
import { usePropertyId } from "../../hooks/usePropertyId";
import { InsightCustomComponentProps } from "../../services/models";

export type IframeConfig = {
  url: string;
  height: number;
};

type IframeInsightCardConfig = {
  iframe: IframeConfig | Record<number, IframeConfig>;
};

const IframeInsightCard: React.FC<
  InsightCustomComponentProps<IframeInsightCardConfig>
> = ({ dashboardInsight }) => {
  const { isPropertyDashboard, propertyId } = usePropertyId();
  const [iframeUrl, setIframeUrl] = useState<string>("");
  const [iframeHeight, setIframeHeight] = useState<number>(0);

  useEffect(() => {
    const iframeConfig: IframeConfig | undefined =
      isPropertyDashboard && propertyId
        ? (dashboardInsight?.stuff?.iframe as Record<number, IframeConfig>)?.[
            propertyId
          ]
        : (dashboardInsight?.stuff?.iframe as IframeConfig);

    setIframeHeight(iframeConfig?.height ?? 0);
    setIframeUrl(iframeConfig?.url ?? "");
  }, [dashboardInsight, isPropertyDashboard, propertyId]);

  return (
    <div className="w-100 h-100 iframe-card">
      <iframe
        src={iframeUrl}
        style={{ height: `${iframeHeight}rem` }}
        className="w-100 border-0"
      />
    </div>
  );
};

export default IframeInsightCard;
