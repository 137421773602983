import {
  ReportSheetData,
  baseSortOrder,
  newSortOrder,
} from "@joyhub-integration/shared";
import clsx from "clsx";
import { useState } from "react";
import { Table } from "reactstrap";
import { TableHeader } from "../../craport/GenericReportTable";
import Body from "./Body";

const JhTable = ({ data }: { data: ReportSheetData }) => {
  const [sortOrder, setSortOrder] = useState(() => baseSortOrder(data.columns));

  const onResort = (index: number) => {
    if (data.columns[index].type !== "NoValue") {
      setSortOrder(newSortOrder(data.columns, sortOrder, index));
    }
  };

  return (
    <Table
      className={clsx(
        "generic-report-table",
        "properties-table",
        "jh-max-height",
        `grouping-${data.grouping?.layout ?? "None"}`,
      )}
      borderless
    >
      <TableHeader
        columns={data.columns}
        sortOrder={sortOrder}
        onHeaderClick={onResort}
      />
      <Body columns={data.columns} rows={data.rows} sortOrder={sortOrder} />
    </Table>
  );
};

export default JhTable;
