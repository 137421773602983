import {
  AccountTreeEntity,
  FullAccountTreeNode,
} from "@joyhub-integration/shared";
import axios from "../../services/axios";
import { apiUrl, axiosConfig } from "../../utils/api";
import { jhItem } from "../common/JhSelect/JhSelect";

export type TreesResponse = {
  trees: AccountTreeEntity[];
};

export type AccountsResponse = {
  accounts: FullAccountTreeNode[];
};

export const financeBookOptions = [
  jhItem("accrual", "Accrual"),
  jhItem("cash", "Cash"),
];

export const financeBookAndBudgetOptions = [
  ...financeBookOptions,
  jhItem("budget", "Budget"),
];

export const getGlTrees = async (system: number) => {
  return axios
    .get<TreesResponse>(apiUrl(`/gl/trees?system=${system}`), axiosConfig)
    .then((res) => res.data.trees);
};

export const getAllGlTrees = async () => {
  return axios
    .get<TreesResponse>(apiUrl(`/gl/trees`), axiosConfig)
    .then((res) => res.data.trees);
};

export const getFullGlTree = async (system: number, tree: number) => {
  return axios
    .get<AccountsResponse>(
      apiUrl(`/gl/accounts?tree=${tree}&system=${system}`),
      axiosConfig,
    )
    .then((res) => res.data.accounts);
};
