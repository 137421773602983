import {
  faSortAmountDown,
  faSortAmountUp,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { compare as naturally } from "natural-orderby";
import {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import VisibilitySensor from "react-visibility-sensor";
import { Table } from "reactstrap";
import RowWithSelection, {
  GarbageMemoizedRowWithSelection,
} from "./RowWithSelection";
import "./tableWithSelection.css";

const naturallyCompare = naturally();

export interface Selectable {
  id: number | string;
}

export interface KeyValue<T> {
  key: string; // keyof T;
  title: string;
  toValue?: (t: T, idx: number) => string | ReactNode;
  sortValue?: (t: T) => string | number | null | undefined;
  sortable?: boolean;
}

interface TableWithSelectionProps<T extends Selectable> {
  selected?: T;
  onSelectedChange: (selected?: T) => void;
  columns: Array<KeyValue<T>>;
  rows?: Array<T>;
  sortColumn?: string;
  sortDirection?: "asc" | "desc";
  singleLineRows?: boolean;
  rowStyle?: React.CSSProperties;
  moar?: () => void;
  onScrollEnd?: () => void;
  onSortChanged?: (field: string, dir: string) => void;
  /** The properties table is so bad and slow it has to use some garbage memoization. This is garbage because it
   * means the row only re-renders if it is selected or deselected, not if any values change. This breaks re-
   * rendering of all admin pages. We can't validly memoize things because input values to the table change on
   * every render; definitely the columns, maybe the rows, and row sort depends on columns.. So we can't memo
   * anything without many changes. Instead we just let the properties table do its old bad thing until we can
   * replace it.
   */
  garbageMemoization?: boolean;
}

function TableWithSelection<T extends Selectable>({
  selected,
  onSelectedChange,
  columns,
  rows,
  sortColumn,
  sortDirection,
  singleLineRows,
  rowStyle = {},
  moar,
  onScrollEnd,
  onSortChanged,
  garbageMemoization,
}: TableWithSelectionProps<T>) {
  const [sortCol, setSortColumn] = useState(
    Math.max(
      0,
      columns.findIndex((c) => c.key === sortColumn),
    ),
  );
  const [ascending, setSortAscending] = useState(sortDirection !== "desc");

  const lastRowRef = useRef(null);

  useEffect(() => {
    const handleLastRowVisible = (entries: any) => {
      entries.forEach((entry: { isIntersecting: any }) => {
        if (entry.isIntersecting && onScrollEnd) {
          onScrollEnd();
        }
      });
    };

    const observer = new IntersectionObserver(handleLastRowVisible, {
      root: null,
      rootMargin: "0px",
      threshold: 0,
    });

    const target = lastRowRef.current;
    if (target) observer.observe(target);
    return () => {
      if (target) observer.unobserve(target);
    };
  }, [onScrollEnd]);

  const sortClicked = (idx: number) => {
    const isAsc = sortCol === idx ? !ascending : true;
    setSortColumn(idx);
    setSortAscending(isAsc);
    if (onSortChanged) onSortChanged(columns[idx].key, isAsc ? "asc" : "desc");
  };

  const headers = columns.map((col, idx) => {
    const thProps =
      col.sortable === false
        ? {}
        : { onClick: () => sortClicked(idx), style: { cursor: "pointer" } };
    return (
      <th key={`col-${col.key}`} {...thProps}>
        {col.title}
        {idx === sortCol ? (
          <FontAwesomeIcon
            icon={ascending ? faSortAmountUp : faSortAmountDown}
            size="sm"
            color="#666"
            style={{ marginLeft: ".33em" }}
            aria-label={ascending ? "Ascending" : "Descending"}
          />
        ) : null}
      </th>
    );
  });

  const defaultCellValue = useCallback((wor: T, col: KeyValue<T>): string => {
    const row = wor as any;
    return row[col.key] !== undefined && row[col.key] !== null
      ? String(row[col.key])
      : "";
  }, []);

  const toVal = useCallback(
    (row: T) => {
      const col = columns[sortCol];
      if (col.sortValue) {
        return col.sortValue(row);
      } else if (col.toValue) {
        const val = col.toValue(row, -1);
        if (typeof val === "string") return val; // could be react node
      }
      return defaultCellValue(row, col);
    },
    [columns, defaultCellValue, sortCol],
  );

  const sortedRows = useMemo(
    () =>
      rows?.toSorted((a, b) => {
        const av = toVal(a),
          bv = toVal(b);
        let cmp: number;
        if (av == null) {
          if (bv == null) cmp = 0;
          else cmp = -1;
        } else if (bv == null) {
          cmp = 1;
        } else if (typeof av === "number" && typeof bv === "number") {
          cmp = av - bv;
        } else {
          cmp = naturallyCompare(av, bv);
        }
        if (cmp === 0) cmp = naturallyCompare(a.id, b.id);
        return ascending ? cmp : -cmp;
      }),
    [rows, toVal, ascending],
  );

  const onRowClick = useCallback(
    (row?: T) => {
      if (!!selected && row?.id === selected.id) {
        onSelectedChange(undefined);
      } else {
        onSelectedChange(row);
      }
    },
    [selected, onSelectedChange],
  );

  const cellValue = useCallback(
    (row: T, col: KeyValue<T>, idx: number) => {
      return col.toValue ? col.toValue(row, idx) : defaultCellValue(row, col);
    },
    [defaultCellValue],
  );

  const RowComponent = garbageMemoization
    ? GarbageMemoizedRowWithSelection
    : RowWithSelection;

  return (
    <div className="jh-table-wrapper">
      <Table className="jh-with-selection-table">
        <thead>
          <tr>{headers}</tr>
        </thead>
        <tbody>
          {sortedRows?.length ? (
            sortedRows.map((row, i) => (
              <RowComponent
                key={`rowWithSelection-${row.id ?? i}`}
                style={rowStyle}
                ref={i === sortedRows.length - 1 ? lastRowRef : null}
                row={row}
                columns={columns}
                isSelected={selected?.id === row.id}
                singleLineRows={singleLineRows}
                onRowClick={onRowClick}
                cellValue={cellValue}
              />
            ))
          ) : (
            <tr>
              <td
                className="jh-table-with-selection-no-results"
                colSpan={columns.length + 1}
              >
                {sortedRows === undefined ? "Loading..." : "No Results"}
              </td>
            </tr>
          )}
          {moar ? (
            <tr>
              <td colSpan={columns.length} style={{ padding: 0 }}>
                <VisibilitySensor
                  onChange={(visible: boolean) => visible && moar?.()}
                >
                  <div style={{ height: "4px" }} />
                </VisibilitySensor>
              </td>
            </tr>
          ) : null}
        </tbody>
      </Table>
    </div>
  );
}

export default TableWithSelection;
