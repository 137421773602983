import {
  PropertiesCmp,
  PropertiesComparison,
  PropertiesSelection,
  exhaustiveCheck,
  ignoreCaseIncludesMatcher,
  isAllProperties,
  isPropertiesByGroup,
  isPropertiesById,
  isPropertiesComparison,
} from "@joyhub-integration/shared";
import clsx from "clsx";
import { sortBy } from "lodash";
import { orderBy } from "natural-orderby";
import React, { useMemo, useState } from "react";
import { DropdownItem, Input, Label } from "reactstrap";
import { Property, propertyMatcher } from "../../services/propertiesService";
import JhSelect, { jhItem } from "../common/JhSelect/JhSelect";
import { PropertyGroup } from "../properties/propertyGroupService";

const vsKey = (sel: PropertiesCmp | undefined): string =>
  sel == null
    ? ""
    : isAllProperties(sel)
      ? "all"
      : isPropertiesByGroup(sel)
        ? `group:${sel.group}`
        : isPropertiesById(sel)
          ? `${sel}`
          : exhaustiveCheck(sel);

const ComparisonSelector: React.FC<{
  allProperties: Property[];
  propertyGroups: PropertyGroup[];
  selection: PropertiesSelection;
  setSelection: (sel: PropertiesComparison) => void;
}> = ({ allProperties, propertyGroups, selection, setSelection }) => {
  const [filterText, setFilterText] = useState<string>("");

  const sortedEndianProperties = useMemo(
    () =>
      orderBy(
        allProperties.filter((p) => p.front_end),
        (p) => p.property_name,
        "asc",
      ),
    [allProperties],
  );

  const selectedProperties = useMemo(
    () =>
      new Set(
        isPropertiesComparison(selection)
          ? selection.comparison.filter(isPropertiesById)
          : [],
      ),
    [selection],
  );

  const selectedGroups = useMemo(
    () =>
      new Set(
        isPropertiesComparison(selection)
          ? selection.comparison
              .filter(isPropertiesByGroup)
              .map((sel) => sel.group)
          : [],
      ),
    [selection],
  );

  const allSelected = useMemo(
    () =>
      isPropertiesComparison(selection) &&
      selection.comparison.some((sel) => isAllProperties(sel)),
    [selection],
  );

  const comparison = useMemo(
    () => (isPropertiesComparison(selection) ? selection.comparison : []),
    [selection],
  );

  const vs = useMemo(
    () => (isPropertiesComparison(selection) ? selection.vs : undefined),
    [selection],
  );

  const filteredProperties = useMemo(
    () => sortedEndianProperties.filter(propertyMatcher(filterText)),
    [sortedEndianProperties, filterText],
  );

  const matcher = useMemo(
    () => ignoreCaseIncludesMatcher(filterText),
    [filterText],
  );

  const sortedGroups = useMemo(
    () => sortBy(propertyGroups, "name"),
    [propertyGroups],
  );

  const filteredGroups = useMemo(
    () => sortedGroups.filter((g) => matcher(g.name)),
    [sortedGroups, matcher],
  );

  const vsOptions = useMemo(
    () => [
      ...sortedEndianProperties.map((p) =>
        jhItem(vsKey(p.id), p.property_name, "property"),
      ),
      ...sortedGroups.map((g) =>
        jhItem(vsKey({ group: g.id }), g.name, "group"),
      ),
      jhItem(vsKey({}), "All properties", "portfolio"),
    ],
    [sortedEndianProperties, sortedGroups],
  );

  const vsOption = useMemo(
    () => vsOptions.find((o) => o.value === vsKey(vs)) ?? null,
    [vsOptions, vs],
  );

  const onPropertyClick = (e: React.MouseEvent<HTMLElement>, id: number) => {
    if (e.ctrlKey || e.metaKey || e.shiftKey) {
      const updated = selectedProperties.has(id)
        ? comparison.filter((sel) => sel !== id)
        : [...comparison, id];
      setSelection({ comparison: updated, vs });
    } else {
      setSelection({ comparison: [id], vs });
    }
  };

  const onGroupClick = (e: React.MouseEvent<HTMLElement>, id: number) => {
    if (e.ctrlKey || e.metaKey || e.shiftKey) {
      const updated = selectedGroups.has(id)
        ? comparison.filter(
            (sel) => !isPropertiesByGroup(sel) || sel.group !== id,
          )
        : [...comparison, { group: id }];
      setSelection({ comparison: updated, vs });
    } else {
      setSelection({ comparison: [{ group: id }], vs });
    }
  };

  const onAllClick = (e: React.MouseEvent<HTMLElement>) => {
    if (e.ctrlKey || e.metaKey || e.shiftKey) {
      const updated = allSelected
        ? comparison.filter((sel) => !isAllProperties(sel))
        : [...comparison, {}];
      setSelection({ comparison: updated, vs });
    } else {
      setSelection({ comparison: [{}], vs });
    }
  };

  const setVs = (vs: PropertiesCmp | undefined) => {
    setSelection({ comparison, vs });
  };

  function onFilterChange(e: React.ChangeEvent<HTMLInputElement>) {
    setFilterText(e.target.value);
  }

  return (
    <>
      <Input
        className="dashboard-property-dropdown-filter my-2 mx-3"
        type="text"
        value={filterText}
        onChange={onFilterChange}
        placeholder="Filter"
      />

      <div
        style={{ maxHeight: "50vh", overflowY: "scroll" }}
        className="px-3 pb-2"
      >
        {!filteredProperties.length ? (
          <div className="dashboard-property-dropdown-item">
            {sortedEndianProperties.length ? "No matches" : "No properties"}
          </div>
        ) : null}

        {filteredProperties.map((p) => (
          <DropdownItem
            key={p.id}
            className={clsx(
              "dashboard-property-dropdown-item bordered comparison",
              {
                active: selectedProperties.has(p.id),
              },
            )}
            onClick={(e) => onPropertyClick(e, p.id)}
            toggle={false}
          >
            <span className="item">{p.property_name}</span>
          </DropdownItem>
        ))}

        {filteredGroups.map((g) => (
          <DropdownItem
            key={g.id}
            className={clsx(
              "dashboard-property-dropdown-item bordered comparison",
              {
                active: selectedGroups.has(g.id),
              },
            )}
            onClick={(e) => onGroupClick(e, g.id)}
            toggle={false}
          >
            <span className="item group">{g.name}</span>
          </DropdownItem>
        ))}

        {matcher("All properties") && (
          <DropdownItem
            className={clsx("dashboard-property-dropdown-item comparison", {
              active: allSelected,
            })}
            onClick={(e) => onAllClick(e)}
            toggle={false}
          >
            <span className="item portfolio">All properties</span>
          </DropdownItem>
        )}
      </div>

      <div className="flex-row align-items-baseline mt-2 mx-3">
        <Label className="me-2">Compare with:</Label>
        <JhSelect
          className="flex-grow-1 comparison"
          isSearchable={true}
          options={vsOptions}
          value={vsOption}
          onValueUpdate={(item) => {
            setVs(
              item == null
                ? undefined
                : item.value === "all"
                  ? {}
                  : item.value.startsWith("group:")
                    ? { group: parseInt(item.value.substring(6)) }
                    : parseInt(item.value),
            );
          }}
          menuPlacement="top"
          isClearable
          formatOptionLabel={(option) => (
            <span className={clsx(option.className)}>{option.label}</span>
          )}
          pointy
        />
      </div>
    </>
  );
};

export default ComparisonSelector;
