import { faMap, faNotebook, faUser } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { capitalize, entries, find, map } from "lodash";
import { useMemo } from "react";
import { InstantInsights } from "../../../services/dataService";
import {
  AllUnitCount,
  Leased,
  LeasedUnitCount,
  Occupancy as OccupancyId,
  OccupiedUnitCount,
} from "../../../services/insightLibrary/backendInsightIds";
import { Property } from "../../../services/propertiesService";
import { apiUrl } from "../../../utils/api";
import PropertyComparableCell from "../../properties/PropertyComparableCell";
import { insightValue } from "../cdUtil";
import DashboardCard from "./DashboardCard";
import { Skelington } from "./Skelington";

type CDPropertyData = {
  name: string;
  image?: string;
  location: {
    address: string | undefined;
    city: string | undefined;
    state: string | undefined;
    zip: string | undefined;
  };
  metadata: {
    class: string | undefined;
    vintage: string | undefined;
  };
  personnel: {
    owner: string | undefined;
    manager: string | undefined;
  };
  totalUnits: number | undefined;
  occupiedUnits: number | undefined;
  occupancyPercent: number | undefined;
  leasedUnits: number | undefined;
  leasedPercent: number | undefined;
};

const propertyData = (
  property: Property | undefined,
  insights: InstantInsights | undefined,
): CDPropertyData | undefined => {
  if (property == null) return undefined;
  const metadata = (attr: string, alt: string): string | undefined =>
    (property.metadata[attr] ?? property.metadata[alt])?.toString();
  const fuzzyMetadata = (attr: string): string | undefined =>
    find(property.metadata, (_, key) =>
      key.toLowerCase().includes(attr.toLowerCase()),
    )?.toString();

  const overall = insights?.overall;
  return {
    name: property.property_name,
    image: property.image_guid
      ? apiUrl(
          `/properties/${property.id}/image/${property.image_guid}?full=true`,
        )
      : undefined,
    location: {
      address: property.address,
      city: metadata("City", "Property City"),
      state: metadata("State", "Property State"),
      zip: property.zip_code,
    },
    metadata: {
      class: metadata("Class", "Property Class"),
      vintage: metadata("Vintage", "Property Vintage"),
    },
    personnel: {
      owner: fuzzyMetadata("owner"),
      manager: fuzzyMetadata("manager"),
    },
    totalUnits: insightValue(overall, AllUnitCount),
    occupiedUnits: insightValue(overall, OccupiedUnitCount),
    occupancyPercent: insightValue(overall, OccupancyId),
    leasedUnits: insightValue(overall, LeasedUnitCount),
    leasedPercent: insightValue(overall, Leased),
  };
};

const formatPercent = (value: number | undefined) =>
  value?.toLocaleString("en-US", {
    style: "percent",
    maximumFractionDigits: 1,
  }) ?? "–";

const PropertySnapshot = ({
  property,
  insights,
}: {
  property?: Property;
  insights?: InstantInsights;
}) => {
  const data = useMemo(
    () => propertyData(property, insights),
    [property, insights],
  );
  return (
    <DashboardCard background="none">
      <Skelington value={data} height={12}>
        {({
          location,
          metadata,
          personnel,
          totalUnits,
          occupiedUnits,
          occupancyPercent,
          leasedUnits,
          leasedPercent,
        }) => (
          <div className="d-flex gap-4 flex-wrap">
            {data?.image != null && (
              <div
                style={{
                  background: `url(${data.image}) no-repeat 0 33% / cover`,
                  minWidth: "66%",
                  minHeight: "15em",
                }}
                className="rounded flex-grow-1"
              />
            )}
            <div
              className="d-grid mb-0 card p-4 gap-2 flex-grow-1"
              style={{
                gridTemplateColumns: "repeat(auto-fit, minmax(12em, 1fr))",
              }}
            >
              <div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <h2 className="fs-5 fw-bold">
                    <FontAwesomeIcon icon={faMap} size="sm" className="me-2" />
                    {location.address}
                  </h2>
                  <div style={{ marginLeft: 15, marginBottom: 8 }}>
                    <PropertyComparableCell
                      is_comparable={property?.is_comparable}
                    ></PropertyComparableCell>
                  </div>
                </div>
                {map(
                  entries(location).filter(
                    ([key, value]) => value != null && key !== "address",
                  ),
                  ([key, value]) => (
                    <div key={key} className="mb-2">
                      <span className="fw-bold">{capitalize(key)}:&nbsp;</span>
                      <span>{value}</span>
                    </div>
                  ),
                )}
                {map(
                  entries(metadata).filter(([key, value]) => value != null),
                  ([key, value]) => (
                    <div key={key} className="mb-2">
                      <span className="fw-bold">{capitalize(key)}:&nbsp;</span>
                      <span>{value}</span>
                    </div>
                  ),
                )}
                {(personnel.manager || personnel.owner) && (
                  <>
                    <h2 className="fs-5 fw-bold mt-4">
                      <FontAwesomeIcon
                        icon={faUser}
                        size="sm"
                        className="me-2"
                      />
                      Personnel
                    </h2>
                    {map(
                      entries(personnel).filter(
                        ([key, value]) => value != null,
                      ),
                      ([key, value]) => (
                        <div key={key} className="mb-2">
                          <span className="fw-bold">
                            {capitalize(key)}:&nbsp;
                          </span>
                          <span>{value}</span>
                        </div>
                      ),
                    )}
                  </>
                )}
              </div>
              <div>
                <h2 className="fs-5 fw-bold">
                  <FontAwesomeIcon
                    icon={faNotebook}
                    size="sm"
                    className="me-2"
                  />
                  Key Stats
                </h2>
                <div className="mb-2">
                  <span className="fw-bold">Total Units: &nbsp;</span>
                  <span>{totalUnits ?? "–"}</span>
                </div>
                <div className="mb-2">
                  <span className="fw-bold">Occupied Units: &nbsp;</span>
                  <span>{occupiedUnits ?? "–"}</span>
                </div>
                <div className="mb-2">
                  <span className="fw-bold">Occupancy: &nbsp;</span>
                  <span>{formatPercent(occupancyPercent)}</span>
                </div>
                <div className="mb-2">
                  <span className="fw-bold">Leased Unit: &nbsp;</span>
                  <span>{leasedUnits ?? "–"}</span>
                </div>
                <div className="mb-2">
                  <span className="fw-bold">Leased: &nbsp;</span>
                  <span>{formatPercent(leasedPercent)}</span>
                </div>
              </div>
            </div>
          </div>
        )}
      </Skelington>
    </DashboardCard>
  );
};

export default PropertySnapshot;
