import { GenericReportData } from "@joyhub-integration/shared";
import { useEffect, useState } from "react";
import { LoadilyFadily } from "../common/allFadily";
import { getReportData } from "../craport/Craport";
import { GenericReportTable } from "../craport/GenericReportTable";

const DrillIn = ({
  propertyId,
  reportId,
}: {
  propertyId: number;
  reportId: string;
  error?: string;
}) => {
  const [data, setData] = useState<GenericReportData<true>>();
  const [error, setError] = useState<string>();

  useEffect(() => {
    setData(undefined);
    setError(undefined);
    if (reportId != null)
      getReportData(reportId, { properties: propertyId, sheet: 0 })
        .then(setData)
        .catch(() => {
          setError(`Error loading report: ${reportId}`);
        });
  }, [propertyId, reportId]);

  return (
    <LoadilyFadily loaded={data != null || error != null}>
      {data && (
        <div className="overflow-auto w-100 h-100 px-4 pb-4">
          <GenericReportTable data={data.sheets[0]} />
        </div>
      )}
      {error && <div className="p-4">{error}</div>}
    </LoadilyFadily>
  );
};

export default DrillIn;
