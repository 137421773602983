import React, { useContext, useEffect, useState } from "react";
import Select from "react-select";
import { FormGroup, InputGroup, Label } from "reactstrap";
import { ManualConfiguration } from "../../../../services/integrationsService";
import PlatformContext from "../../../app/PlatformContext";
import { IntegrationFormProps } from "./common";

const options = [
  { label: "Unset", value: "" },
  { label: "Yardi", value: "Yardi" },
  { label: "RealPage", value: "RealPage" },
  { label: "Entrata", value: "Entrata" },
] as const;

const ManualForm: React.FC<IntegrationFormProps> = (props) => {
  const { superAdmin } = useContext(PlatformContext).platform!;
  const [manualConfig, setManualConfig] = useState<ManualConfiguration>({});

  useEffect(() => {
    if (props.configuration && Object.keys(props.configuration).length) {
      const config = props.configuration as ManualConfiguration;
      setManualConfig(config);
    }
  }, [props.configuration]);

  function onConfigChange(value: string | null | undefined) {
    const newConfig = {
      ...manualConfig,
      financials: value || undefined,
    };
    setManualConfig(newConfig);
    props.setConfiguration(newConfig);
  }

  return superAdmin ? (
    <>
      <FormGroup>
        <Label>Financial Shape</Label>
        <InputGroup>
          <Select
            className="w-100"
            value={
              options.find((o) => o.value === manualConfig.financials) ??
              options[0]
            }
            options={options}
            onChange={(e) => onConfigChange(e?.value)}
          />
        </InputGroup>
      </FormGroup>
    </>
  ) : null;
};

export default ManualForm;
