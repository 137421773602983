import React, { ReactChild, ReactNode } from "react";
import { Link } from "react-router";
import { Card, CardBody, CardFooter, CardHeader, CardTitle } from "reactstrap";
import "./jhCard.css";

interface JhCardProps {
  [key: string]: any;
  icon?: ReactChild;
  title?: string | ReactChild;
  tag?: React.ElementType;
  linkTo?: string;
  linkTitle?: string;
  children: {
    JhCardFooter?: ReactNode;
    JhCardBody?: ReactNode;
    action?: ReactNode;
  };
  className?: string;
  bodyClass?: string;
}

const JhCard: React.FC<JhCardProps> = ({
  children,
  icon,
  title,
  className,
  bodyClass,
  tag,
  linkTo,
  linkTitle,
  ...rest
}) => {
  const { JhCardBody, JhCardFooter, action } = children;

  return (
    <Card
      className={`jh-card jh-card-container ${className}`}
      tag={tag}
      {...rest}
    >
      {action}
      {title ? (
        <CardHeader>
          <div className="flex-col">
            {icon ? <div className="jh-card-icon">{icon}</div> : null}
            <CardTitle
              tag={linkTo ? Link : undefined}
              to={linkTo}
              title={linkTitle}
              className="jh-card-title"
            >
              {title}
            </CardTitle>
          </div>
        </CardHeader>
      ) : null}
      <CardBody className={bodyClass}>{JhCardBody}</CardBody>
      {JhCardFooter ? <CardFooter>{JhCardFooter}</CardFooter> : null}
    </Card>
  );
};

export default JhCard;
