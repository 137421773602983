import { AxiosRequestConfig } from "axios";
import { unexpectedError } from "../constants";

const PrRE = /^pr-([0-9]+)\..*$/;

const prMatch = document.location.hostname.match(PrRE);
export const prId = prMatch ? prMatch[1] : undefined;

let apiBase = process.env.REACT_APP_API_URL!;

export const apiUrl = (api: string) => `${apiBase}${api}`;

// How to keep this out of the production build?

const checkForPullRequestAPI = (): Promise<void> => {
  if (prId != null) {
    const prApiUrl = `api-pr${prId}.dev.revolutionre.com`;
    return fetch(`https://dns.google/resolve?name=${prApiUrl}`)
      .then((res) => res.json())
      .then((res) => {
        console.info(`DNS lookup PR ${prId}`, res);
        if (res.Answer?.length) {
          apiBase = `https://${prApiUrl}`;
        }
      })
      .catch((e) => console.warn(e));
  } else {
    return Promise.resolve();
  }
};

export const apiReady = checkForPullRequestAPI();

export const axiosConfig: AxiosRequestConfig = {
  withCredentials: true,
};

export const axiosJsonConfig: AxiosRequestConfig = {
  ...axiosConfig,
  headers: {
    "Content-Type": "application/json",
  },
};

export const axiosBlobConfig: AxiosRequestConfig = {
  ...axiosConfig,
  headers: {
    "Content-Type": "application/json",
  },
  responseType: "blob",
};

export const idSuffix = (id?: number) => (id == null ? "" : `/${id}`);

export const errorMessage = (err: any) => {
  const response = err?.response;
  return response?.status === 400 && response?.data?.message
    ? response?.data?.message
    : unexpectedError;
};
